import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    IconButton,
    Drawer,
    Tabs,
    Tab,
    Chip,
    Divider,
    Avatar,
    Paper
} from '@mui/material';
import {
    Close as CloseIcon,
    CalendarViewDay as TimelineIcon,
    Info as InfoIcon,
    PersonOutline as PatientIcon,
    AccessTime as TimeIcon,
} from '@mui/icons-material';
import PlanningDetailTimeline from './PlanningDetailTimeline';
import TransportInfoPanel from './TransportInfoPanel';
import { getTransportTypeStyle } from './transportTypeUtils';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            style={{
                flexGrow: 1,
                display: value === index ? 'flex' : 'none',
                flexDirection: 'column',
                overflow: 'auto',
                overflowX: 'hidden',
                width: '100%',
                maxWidth: '100%',
                boxSizing: 'border-box'
            }}
            {...other}
        >
            {children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const VehicleTimelineDrawer = ({
    open,
    onClose,
    selectedTransport,
    selectedTransportId,
    selectedPlanningId
}) => {
    const [activeTab, setActiveTab] = useState(0);
    const drawerWidth = 500;

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    // Abrufen der Transporttyp-Info
    const transportType = selectedTransport?.transport_type || '';
    const typeInfo = getTransportTypeStyle(transportType);

    // Formatieren der Abfahrtszeit
    const formattedTime = selectedTransport?.departure_time
        ? new Date(`1970-01-01T${selectedTransport.departure_time}`).toLocaleTimeString('de-DE', {
            hour: '2-digit',
            minute: '2-digit',
        })
        : '00:00';

    // Text für Zeitbeschreibung
    const timeLabel = selectedTransport?.transport_type === 'DIALYSEFAHRT'
        ? selectedTransport?.return_trip
            ? 'Wunschzeit Abfahrt'
            : 'Ankunft Zielort'
        : selectedTransport?.transport_type === 'TERMINFAHRT'
            ? 'Ankunft Zielort'
            : 'Wunschzeit Abfahrt';

    // Untertext für Transporttyp
    let transportSubtype = '';
    if (selectedTransport?.ambulatory) {
        transportSubtype = selectedTransport?.return_trip ? 'Ambulant Rückfahrt' : 'Ambulant Hinfahrt';
    } else if (selectedTransport?.return_trip && selectedTransport?.linked_transport_type === 'TERMINFAHRT') {
        transportSubtype = 'Rück. von Terminfahrt';
    } else if (selectedTransport?.transport_type === 'DIALYSEFAHRT') {
        transportSubtype = selectedTransport?.return_trip ? 'Dialyse Rückfahrt' : 'Dialyse Hinfahrt';
    }

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                position: 'absolute',
                right: 0,
                height: '100%',
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    position: 'relative',
                    boxSizing: 'border-box',
                    height: '100%',
                    backgroundColor: '#ffffff',
                    borderLeft: '1px solid #e0e0e0',
                    boxShadow: '-4px 0 8px -2px rgba(0, 0, 0, 0.1)',
                    borderRadius: '12px 0 0 0',
                    padding: '0',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                },
            }}
            variant="persistent"
            anchor="right"
            open={open}
        >
            {/* Modernisierter Header */}
            <Paper
                elevation={1}
                sx={{
                    borderRadius: '12px 0 0 0',
                    mb: 1,
                    overflow: 'visible'
                }}
            >
                {/* Oberer Bereich mit Typ und Schließen-Button */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        p: 2,
                        pb: 1.5,
                        background: 'linear-gradient(to right, #f9f9f9, #ffffff)',
                    }}
                >
                    {/* Transporttyp mit Chip */}
                    <Box display="flex" alignItems="center">
                        <Chip
                            icon={typeInfo.icon}
                            label={transportType}
                            sx={{
                                backgroundColor: typeInfo.backgroundColor,
                                color: typeInfo.color,
                                fontWeight: 600,
                                borderRadius: '6px',
                                height: '28px',
                                mr: 1
                            }}
                        />

                        {transportSubtype && (
                            <Typography
                                variant="caption"
                                sx={{
                                    color: 'text.secondary',
                                    fontStyle: 'italic'
                                }}
                            >
                                {transportSubtype}
                            </Typography>
                        )}
                    </Box>

                    {/* Schließen-Button */}
                    <IconButton
                        onClick={onClose}
                        sx={{
                            color: '#495057',
                            backgroundColor: '#f0f0f0',
                            '&:hover': { backgroundColor: '#e0e0e0' }
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Divider />

                {/* Informationsbereich */}
                <Box sx={{ display: 'flex', p: 2, justifyContent: 'space-between' }}>
                    {/* Zeitinfo */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TimeIcon sx={{ color: 'text.secondary', mr: 1 }} />
                        <Box>
                            <Typography variant="h6" sx={{ fontWeight: 600, lineHeight: 1.2 }}>
                                {formattedTime}
                            </Typography>
                            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                {timeLabel}
                            </Typography>
                        </Box>
                    </Box>

                    {/* Patient-Info */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar
                            sx={{
                                width: 38,
                                height: 38,
                                bgcolor: 'primary.light',
                                mr: 1
                            }}
                        >
                            <PatientIcon />
                        </Avatar>
                        <Box>
                            <Typography variant="subtitle1" sx={{ fontWeight: 600, lineHeight: 1.2 }}>
                                {selectedTransport?.first_name} {selectedTransport?.last_name}
                            </Typography>
                            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                Patient
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Paper>

            {/* Tabs */}
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                variant="fullWidth"
                sx={{
                    minHeight: '56px',
                    borderBottom: 1,
                    borderColor: 'divider',
                    bgcolor: '#f8f9fa',
                    '& .MuiTab-root': {
                        fontWeight: 600,
                        fontSize: '0.9rem',
                        transition: '0.2s',
                        '&.Mui-selected': {
                            color: '#1976d2',
                            backgroundColor: '#fff',
                        }
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: '#1976d2',
                        height: 3
                    }
                }}
            >
                <Tab icon={<TimelineIcon />} label="Zeitplan" id="tab-0" iconPosition="start" />
                <Tab icon={<InfoIcon />} label="Details" id="tab-1" iconPosition="start" />
            </Tabs>

            {/* Content */}
            <Box sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                width: '100%',
                bgcolor: '#ffffff'
            }}>
                <TabPanel value={activeTab} index={0}>
                    {selectedPlanningId ? (
                        <PlanningDetailTimeline planning_id={selectedPlanningId} showHeader={false} />
                    ) : (
                        <Box sx={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            p: 4,
                            maxWidth: '100%'
                        }}>
                            <Typography variant="h6" sx={{ mb: 2, color: 'text.primary' }}>
                                Keine Planungsdetails verfügbar
                            </Typography>
                            <Typography variant="body2" color="text.secondary" align="center">
                                Für diesen Transport liegt noch keine Planung vor oder die Planung konnte nicht geladen werden.
                            </Typography>
                        </Box>
                    )}
                </TabPanel>

                <TabPanel value={activeTab} index={1}>
                    {selectedTransportId && (
                        <TransportInfoPanel transport_id={selectedTransportId} />
                    )}
                </TabPanel>
            </Box>
        </Drawer>
    );
};

VehicleTimelineDrawer.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedTransport: PropTypes.object,
    selectedTransportId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedPlanningId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default VehicleTimelineDrawer;