import React from 'react';
import {
    LocalShipping as TransportIcon,
    LocalHospital as MedicalIcon,
    FreeBreakfast as BreakIcon,
    AccessTime as TimeIcon,
    //LocalTaxi as TaxiIcon,
    DirectionsOutlined as RouteIcon
} from '@mui/icons-material';
//import HealingIcon from '@mui/icons-material/Healing';
import HomeIcon from '@mui/icons-material/Home';

// Zentralisierte Definition der Transporttypen-Stile
export const TRANSPORT_TYPES = {
    DIALYSEFAHRT: {
        label: 'Dialysefahrt',
        icon: <MedicalIcon fontSize="small" />,
        color: '#2196f3',
        backgroundColor: '#e3f2fd',
        gradient: 'linear-gradient(135deg, #2196f3, #64b5f6)',
        hoverGradient: 'linear-gradient(135deg, #1976d2, #42a5f5)',
        textColor: 'white',
        iconColor: 'rgba(255, 255, 255, 0.9)',
        shadow: '0 2px 8px rgba(33, 150, 243, 0.4)',
        hoverShadow: '0 4px 12px rgba(33, 150, 243, 0.6)',
        border: '1px solid rgba(255, 255, 255, 0.2)'
    },
    TERMINFAHRT: {
        label: 'Terminfahrt',
        icon: <TimeIcon fontSize="small" />,
        color: '#ff9800',
        backgroundColor: '#fff8e1',
        gradient: 'linear-gradient(135deg, #ff9800, #ffb74d)',
        hoverGradient: 'linear-gradient(135deg, #f57c00, #ffa726)',
        textColor: 'white',
        iconColor: 'rgba(255, 255, 255, 0.9)',
        shadow: '0 2px 8px rgba(255, 152, 0, 0.4)',
        hoverShadow: '0 4px 12px rgba(255, 152, 0, 0.6)',
        border: '1px solid rgba(255, 255, 255, 0.2)'
    },
    ENTLASSUNG: {
        label: 'Entlassung',
        icon: <HomeIcon fontSize="small" />,
        color: '#4caf50',
        backgroundColor: '#e8f5e9',
        gradient: 'linear-gradient(135deg, #4caf50, #81c784)',
        hoverGradient: 'linear-gradient(135deg, #388e3c, #66bb6a)',
        textColor: 'white',
        iconColor: 'rgba(255, 255, 255, 0.9)',
        shadow: '0 2px 8px rgba(76, 175, 80, 0.4)',
        hoverShadow: '0 4px 12px rgba(76, 175, 80, 0.6)',
        border: '1px solid rgba(255, 255, 255, 0.2)'
    },
    EINWEISUNG: {
        label: 'Einweisung',
        icon: <RouteIcon fontSize="small" />,
        color: '#9c27b0',
        backgroundColor: '#f3e5f5',
        gradient: 'linear-gradient(135deg, #9c27b0, #ba68c8)',
        hoverGradient: 'linear-gradient(135deg, #7b1fa2, #ab47bc)',
        textColor: 'white',
        iconColor: 'rgba(255, 255, 255, 0.9)',
        shadow: '0 2px 8px rgba(156, 39, 176, 0.4)',
        hoverShadow: '0 4px 12px rgba(156, 39, 176, 0.6)',
        border: '1px solid rgba(255, 255, 255, 0.2)'
    },
    VERLEGUNG: {
        label: 'Verlegung',
        icon: <TransportIcon fontSize="small" />,
        color: '#757575',
        backgroundColor: '#f5f5f5',
        gradient: 'linear-gradient(135deg, #757575, #9e9e9e)',
        hoverGradient: 'linear-gradient(135deg, #616161, #bdbdbd)',
        textColor: 'white',
        iconColor: 'rgba(255, 255, 255, 0.9)',
        shadow: '0 2px 8px rgba(117, 117, 117, 0.4)',
        hoverShadow: '0 4px 12px rgba(117, 117, 117, 0.6)',
        border: '1px solid rgba(255, 255, 255, 0.2)'
    },
    BREAK: {
        label: 'Pause',
        icon: <BreakIcon fontSize="small" />,
        color: '#d32f2f', // Dunkles Rot
        backgroundColor: '#ffebee', // Helles Rot für Hintergrund
        gradient: 'linear-gradient(135deg, #d32f2f, #e57373)', // Verlauf von Dunkelrot zu Hellrot
        hoverGradient: 'linear-gradient(135deg, #b71c1c, #f44336)', // Stärkerer Hover-Effekt
        textColor: 'white',
        iconColor: 'rgba(255, 255, 255, 0.9)',
        shadow: '0 2px 8px rgba(211, 47, 47, 0.4)', // Roter Schatten für leichte Tiefe
        hoverShadow: '0 4px 12px rgba(211, 47, 47, 0.6)', // Intensiverer Schatten beim Hover
        border: '1px solid rgba(255, 255, 255, 0.2)'
    },
    DEFAULT: {
        label: 'Standard',
        icon: <TransportIcon fontSize="small" />,
        color: '#3f51b5',
        backgroundColor: '#e8eaf6',
        gradient: 'linear-gradient(135deg, #3f51b5, #7986cb)',
        hoverGradient: 'linear-gradient(135deg, #303f9f, #5c6bc0)',
        textColor: 'white',
        iconColor: 'rgba(255, 255, 255, 0.9)',
        shadow: '0 2px 8px rgba(63, 81, 181, 0.4)',
        hoverShadow: '0 4px 12px rgba(63, 81, 181, 0.6)',
        border: '1px solid rgba(255, 255, 255, 0.2)'
    }
};

// Hilfsfunktion, um Transporttyp-Informationen zu erhalten
export const getTransportTypeStyle = (type) => {
    return TRANSPORT_TYPES[type] || TRANSPORT_TYPES.DEFAULT;
};