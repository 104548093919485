import React, { useState, useMemo } from 'react';
import {
    Typography,
    FormControlLabel,
    Box,
    Switch,
    Grid,
    DialogActions,
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    Paper,
    Chip,
    Card,
    CardContent,
    Tooltip,
    Divider
} from '@mui/material';
import {
    DatePicker,
    LocalizationProvider
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/de'; // Damit das deutsche Locale geladen wird
import { useTheme } from '@mui/material/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

// Import des ausgelagerten RestrictedTimePicker
import RestrictedTimePicker from './RestrictedTimePicker';

const SerialAppointmentsDialog = ({
    open,
    onClose,
    onSave,
    formData,
    setFormData,
    timeErrors,
    setTimeErrors,
    getTimeConstraints,
    validateTime
}) => {
    const theme = useTheme();

    // State für das aktuell angezeigte Tab (Hinfahrten oder Rückfahrten)
    const [activeTab, setActiveTab] = useState('outbound');

    // Ermitteln des letzten gesetzten Zeitwerts für Hinfahrten und Rückfahrten
    const lastSetTimes = useMemo(() => {
        const result = { outbound: {}, return: {} };
        const weekdays = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];

        let lastOutboundTime = null;
        let lastOutboundDay = null;
        let lastReturnTime = null;
        let lastReturnDay = null;

        // Durchlaufen der Wochentage und speichern der gesetzten Zeiten
        for (const day of weekdays) {
            if (formData.recurring_times?.[day]) {
                lastOutboundTime = formData.recurring_times[day];
                lastOutboundDay = day;
            }
            if (formData.recurring_return_times?.[day]) {
                lastReturnTime = formData.recurring_return_times[day];
                lastReturnDay = day;
            }

            // Für jeden Wochentag speichern wir die zuvor gesetzten Zeiten
            if (lastOutboundTime && day !== lastOutboundDay) {
                result.outbound[day] = {
                    time: lastOutboundTime,
                    fromDay: lastOutboundDay
                };
            }

            if (lastReturnTime && day !== lastReturnDay) {
                result.return[day] = {
                    time: lastReturnTime,
                    fromDay: lastReturnDay
                };
            }
        }

        return result;
    }, [formData.recurring_times, formData.recurring_return_times]);

    // Checkbox-Handling Wochentage (Hinfahrten)
    const handleDayChange = (event, day) => {
        setFormData((prevData) => ({
            ...prevData,
            recurring_days: {
                ...prevData.recurring_days,
                [day]: event.target.checked,
            },
        }));
    };

    // Checkbox-Handling Wochentage (Rückfahrten)
    const handleReturnDayChange = (event, day) => {
        setFormData((prevData) => ({
            ...prevData,
            recurring_return_days: {
                ...prevData.recurring_return_days,
                [day]: event.target.checked,
            },
        }));
    };

    // Zeit-Handling Hinfahrt
    const handleTimeChange = (day, time) => {
        if (time && time.trim() !== "") {
            setFormData((prevData) => ({
                ...prevData,
                recurring_times: {
                    ...prevData.recurring_times,
                    [day]: time,
                },
            }));
        } else {
            alert(`Bitte geben Sie eine gültige Uhrzeit für ${day} ein.`);
        }
    };

    // Zeit-Handling Rückfahrt
    const handleReturnTimeChange = (day, time) => {
        if (time && time.trim() !== "") {
            setFormData((prevData) => ({
                ...prevData,
                recurring_return_times: {
                    ...prevData.recurring_return_times,
                    [day]: time,
                },
            }));
        } else {
            alert(`Bitte geben Sie eine gültige Uhrzeit für ${day} ein.`);
        }
    };

    // Zeit vom Vorgänger-Tag übernehmen
    const copyTimeFromPrevious = (day, isReturn = false) => {
        const sourceInfo = isReturn
            ? lastSetTimes.return[day]
            : lastSetTimes.outbound[day];

        if (!sourceInfo) return;

        if (isReturn) {
            // Uhrzeit im Model aktualisieren
            handleReturnTimeChange(day, sourceInfo.time);

            // Auch die Anzeige im TimePicker aktualisieren
            // Suchen aller RestrictedTimePicker-Instanzen und Aktualisierung des entsprechenden
            const pickers = document.querySelectorAll(`[data-testid="return-datepicker-${day}"] input`);
            if (pickers.length > 0) {
                pickers[0].value = sourceInfo.time;

                // Event auslösen, damit der TimePicker die Änderung erkennt
                const event = new Event('change', { bubbles: true });
                pickers[0].dispatchEvent(event);
            }
        } else {
            // Uhrzeit im Model aktualisieren
            handleTimeChange(day, sourceInfo.time);

            // Auch die Anzeige im TimePicker aktualisieren
            const pickers = document.querySelectorAll(`[data-testid="datepicker-${day}"] input`);
            if (pickers.length > 0) {
                pickers[0].value = sourceInfo.time;

                // Event auslösen, damit der TimePicker die Änderung erkennt
                const event = new Event('change', { bubbles: true });
                pickers[0].dispatchEvent(event);
            }
        }
    };

    // Zusammenfassung der ausgewählten Tage
    const getRecurringSummary = (days, times) => {
        const daysAndTimes = [];

        // Extrahiere nur die Wochentage und Zeiten
        for (const [day, isSelected] of Object.entries(days || {})) {
            if (['valid_from', 'valid_to', 'break_from', 'break_to'].includes(day)) continue; // Felder ignorieren
            if (isSelected === true) {
                const time = times?.[day] || 'Keine Zeit angegeben';
                daysAndTimes.push(`${day}: ${time}`);
            }
        }

        return daysAndTimes.length > 0 ? daysAndTimes.join(', ') : 'Keine Tage oder Zeiten ausgewählt';
    };

    const handleSaveDialog = () => {
        // Zeitvalidierungen (TimePicker) überprüfen
        const hasTimeErrors = Object.values(timeErrors).some(error => !!error);

        // Tage-Checks
        const hasRecurringDays = formData.recurring_days && Object.values(formData.recurring_days).some(isSelected => isSelected === true);
        const hasRecurringReturnDays = formData.recurring_return_days && Object.values(formData.recurring_return_days).some(isSelected => isSelected === true);

        if (!hasRecurringDays && !hasRecurringReturnDays && (formData.is_recurring || formData.is_recurring_return)) {
            alert('Bitte wählen Sie mindestens einen Tag aus, wenn regelmäßige Fahrten aktiviert sind.');
            return;
        }
        if (hasTimeErrors) {
            alert('Bitte korrigieren Sie die markierten Zeitangaben.');
            return;
        }

        // ** Validierungen für valid_from / valid_to**
        const validFrom = formData.recurring_days?.valid_from;
        const validTo = formData.recurring_days?.valid_to;

        /*
        // Falls "valid_from" eingetragen ist, muss es mindestens "heute" sein (kein Datum in der Vergangenheit)
        if (validFrom && dayjs(validFrom).isBefore(dayjs().startOf('day'))) {
            alert(`Das Datum "gültig ab" darf nicht in der Vergangenheit liegen (${validFrom}).`);
            return;
        }*/

        // valid_from <= valid_to
        if (validFrom && validTo && dayjs(validFrom).isAfter(dayjs(validTo))) {
            alert(`Das Datum "gültig ab" (${validFrom}) darf nicht nach "gültig bis" (${validTo}) liegen.`);
            return;
        }

        // Validierungen für break_from / break_to
        const breakFrom = formData.recurring_days?.break_from;
        const breakTo = formData.recurring_days?.break_to;

        // Wenn eine Pause eingetragen wurde, prüfen wir die Regeln
        if (breakFrom || breakTo) {
            // Regel 1: Beide Felder müssen vorhanden sein
            if (!breakFrom || !breakTo) {
                alert('Bitte geben Sie sowohl ein "Pause von" als auch ein "Pause bis" Datum ein.');
                return;
            }

            // Regel 2: Beide Daten müssen heute oder in der Zukunft liegen
            if (dayjs(breakFrom).isBefore(dayjs().startOf('day'))) {
                alert(`Das Datum "Pause von" darf nicht in der Vergangenheit liegen (${breakFrom}).`);
                return;
            }
            if (dayjs(breakTo).isBefore(dayjs().startOf('day'))) {
                alert(`Das Datum "Pause bis" darf nicht in der Vergangenheit liegen (${breakTo}).`);
                return;
            }

            // Regel 3: break_to muss nach break_from liegen
            if (dayjs(breakFrom).isAfter(dayjs(breakTo))) {
                alert(`Das Datum "Pause von" (${breakFrom}) darf nicht nach "Pause bis" (${breakTo}) liegen.`);
                return;
            }
        }

        onSave();
    };

    // Render-Funktion für die DatePicker mit einheitlichem Styling
    const renderDatePicker = (label, value, onChange, minDateToday = true) => (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DatePicker
                label={label}
                value={value ? dayjs(value) : null}
                onChange={onChange}
                format="DD.MM.YYYY"
                minDate={minDateToday ? dayjs().startOf('day') : undefined}
                slotProps={{
                    textField: {
                        fullWidth: true,
                        variant: "outlined",
                        size: "medium",
                        sx: {
                            bgcolor: theme.palette.background.paper,
                            borderRadius: 1,
                            '& .MuiOutlinedInput-root': {
                                '&:hover fieldset': {
                                    borderColor: theme.palette.primary.main,
                                },
                            },
                        }
                    }
                }}
            />
        </LocalizationProvider>
    );

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="lg"
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    maxWidth: '1200px',
                    borderRadius: 2,
                    overflow: 'hidden'
                },
            }}
        >
            <DialogTitle sx={{ py: 2 }}>
                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                    {/* Überschrift */}
                    <Typography variant="h5" fontWeight="bold">Serieneinstellungen</Typography>

                    <Box display="flex" alignItems="center">
                        <CalendarTodayIcon sx={{ mr: 1 }} />
                        <Typography variant="subtitle1" fontWeight="medium">Zeiträume konfigurieren</Typography>
                    </Box>
                </Box>
            </DialogTitle>

            <DialogContent sx={{ py: 3 }}>
                {/* Einheitlicher Zeiteinstellungen-Bereich für Gültigkeit und Pause */}
                <Card variant="outlined" sx={{ mb: 4, boxShadow: theme.shadows[2] }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <CalendarTodayIcon sx={{ mr: 1 }} /> Zeiträume
                        </Typography>

                        <Grid container spacing={3}>
                            {/* Spalte: Gültigkeit */}
                            <Grid item xs={12} md={6}>
                                <Paper
                                    elevation={0}
                                    sx={{
                                        p: 2,
                                        borderRadius: 2,
                                        bgcolor: theme.palette.background.default,
                                        border: `1px solid ${theme.palette.divider}`
                                    }}
                                >
                                    <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'medium', color: theme.palette.text.primary }}>
                                        Gültigkeit der Serie
                                    </Typography>

                                    <Box display="flex" gap={2} flexDirection="column">
                                        {renderDatePicker(
                                            "Gültig ab",
                                            formData.recurring_days?.valid_from,
                                            (newDate) => {
                                                if (!newDate) {
                                                    setFormData(prev => ({
                                                        ...prev,
                                                        recurring_days: {
                                                            ...prev.recurring_days,
                                                            valid_from: null
                                                        }
                                                    }));
                                                    return;
                                                }
                                                if (newDate.isBefore(dayjs().startOf('day'))) {
                                                    alert('Datum liegt in der Vergangenheit.');
                                                    return;
                                                }
                                                setFormData(prev => ({
                                                    ...prev,
                                                    recurring_days: {
                                                        ...prev.recurring_days,
                                                        valid_from: newDate.format('YYYY-MM-DD')
                                                    }
                                                }));
                                            }
                                        )}

                                        {renderDatePicker(
                                            "Gültig bis",
                                            formData.recurring_days?.valid_to,
                                            (newDate) => {
                                                if (!newDate) {
                                                    setFormData(prev => ({
                                                        ...prev,
                                                        recurring_days: {
                                                            ...prev.recurring_days,
                                                            valid_to: null
                                                        }
                                                    }));
                                                    return;
                                                }
                                                if (newDate.isBefore(dayjs().startOf('day'))) {
                                                    alert('Datum liegt in der Vergangenheit.');
                                                    return;
                                                }
                                                if (formData.recurring_days?.valid_from) {
                                                    const fromDate = dayjs(formData.recurring_days.valid_from);
                                                    if (fromDate.isAfter(newDate)) {
                                                        alert('Das Datum "gültig bis" muss >= "gültig ab" sein.');
                                                        return;
                                                    }
                                                }
                                                setFormData(prev => ({
                                                    ...prev,
                                                    recurring_days: {
                                                        ...prev.recurring_days,
                                                        valid_to: newDate.format('YYYY-MM-DD')
                                                    }
                                                }));
                                            }
                                        )}
                                    </Box>
                                </Paper>
                            </Grid>

                            {/* Spalte: Pausenzeitraum */}
                            <Grid item xs={12} md={6}>
                                <Paper
                                    elevation={0}
                                    sx={{
                                        p: 2,
                                        borderRadius: 2,
                                        bgcolor: theme.palette.background.default,
                                        border: `1px solid ${theme.palette.divider}`
                                    }}
                                >

                                    <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'medium', color: theme.palette.text.primary }}>
                                        Pausenzeitraum
                                        <Typography variant="body2" sx={{ display: 'inline', marginLeft: 1, color: theme.palette.text.secondary }}>
                                            Serie aussetzen von - bis:
                                        </Typography>
                                    </Typography>
                                    <Box display="flex" gap={2} flexDirection="column">
                                        {renderDatePicker(
                                            "Aussetzen von",
                                            formData.recurring_days?.break_from,
                                            (newDate) => {
                                                if (!newDate) {
                                                    setFormData(prev => ({
                                                        ...prev,
                                                        recurring_days: {
                                                            ...prev.recurring_days,
                                                            break_from: null
                                                        }
                                                    }));
                                                    return;
                                                }
                                                if (newDate.isBefore(dayjs().startOf('day'))) {
                                                    alert('Datum liegt in der Vergangenheit.');
                                                    return;
                                                }
                                                if (formData.recurring_days?.break_to) {
                                                    const toDate = dayjs(formData.recurring_days.break_to);
                                                    if (newDate.isAfter(toDate)) {
                                                        alert('Das Datum "Pause von" muss vor "Pause bis" liegen.');
                                                        return;
                                                    }
                                                }
                                                setFormData(prev => ({
                                                    ...prev,
                                                    recurring_days: {
                                                        ...prev.recurring_days,
                                                        break_from: newDate.format('YYYY-MM-DD')
                                                    }
                                                }));
                                            }
                                        )}

                                        {renderDatePicker(
                                            "Aussetzen bis",
                                            formData.recurring_days?.break_to,
                                            (newDate) => {
                                                if (!newDate) {
                                                    setFormData(prev => ({
                                                        ...prev,
                                                        recurring_days: {
                                                            ...prev.recurring_days,
                                                            break_to: null
                                                        }
                                                    }));
                                                    return;
                                                }
                                                if (newDate.isBefore(dayjs().startOf('day'))) {
                                                    alert('Datum liegt in der Vergangenheit.');
                                                    return;
                                                }
                                                if (formData.recurring_days?.break_from) {
                                                    const fromDate = dayjs(formData.recurring_days.break_from);
                                                    if (fromDate.isAfter(newDate)) {
                                                        alert('Das Datum "Pause bis" muss nach "Pause von" liegen.');
                                                        return;
                                                    }
                                                }
                                                setFormData(prev => ({
                                                    ...prev,
                                                    recurring_days: {
                                                        ...prev.recurring_days,
                                                        break_to: newDate.format('YYYY-MM-DD')
                                                    }
                                                }));
                                            }
                                        )}
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                {/* Tabs für Hin- und Rückfahrten */}
                <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
                    <Button
                        variant={activeTab === 'outbound' ? 'contained' : 'outlined'}
                        onClick={() => setActiveTab('outbound')}
                        startIcon={<DirectionsCarIcon />}
                        sx={{ px: 3, py: 1, borderRadius: 2 }}
                    >
                        Hinfahrten
                    </Button>
                    <Button
                        data-testid="recurring-return-button"
                        variant={activeTab === 'return' ? 'contained' : 'outlined'}
                        onClick={() => setActiveTab('return')}
                        startIcon={<SwapHorizIcon />}
                        sx={{ px: 3, py: 1, borderRadius: 2 }}
                    >
                        Rückfahrten
                    </Button>

                    <Box ml={4}>
                        <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'gray' }}>
                            Hinfahrten: {getRecurringSummary(formData.recurring_days, formData.recurring_times)}
                        </Typography>
                        <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'gray' }}>
                            Rückfahrten: {getRecurringSummary(formData.recurring_return_days, formData.recurring_return_times)}
                        </Typography>
                    </Box>
                </Box>

                {/* Inhalt: Hinfahrten */}
                {activeTab === 'outbound' && (
                    <Box>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formData.is_recurring || false}
                                    onChange={(event) => setFormData((prevData) => ({
                                        ...prevData,
                                        is_recurring: event.target.checked,
                                    }))}
                                    color="primary"
                                />
                            }
                            label={
                                <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                                    Regelmäßige Hinfahrten
                                </Typography>
                            }
                            labelPlacement="end"
                        />

                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            {['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'].map(day => (
                                <Grid item xs={12} sm={6} md={4} key={day}>
                                    <Paper
                                        elevation={1}
                                        sx={{
                                            p: 2,
                                            borderRadius: 2,
                                            opacity: formData.is_recurring ? 1 : 0.7
                                        }}
                                    >
                                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        data-testid={`switch-${day}`}
                                                        checked={!!formData.recurring_days?.[day]}
                                                        disabled={!formData.is_recurring}
                                                        onChange={(event) => handleDayChange(event, day)}
                                                        color="primary"
                                                        size="small"
                                                    />
                                                }
                                                label={
                                                    <Typography variant="subtitle1" fontWeight="bold">
                                                        {day}
                                                    </Typography>
                                                }
                                            />

                                            {lastSetTimes.outbound[day] && (
                                                <Tooltip title={`Zeit von ${lastSetTimes.outbound[day].fromDay} übernehmen (${lastSetTimes.outbound[day].time} Uhr)`}>
                                                    <Chip
                                                        label={`${lastSetTimes.outbound[day].time} Uhr`}
                                                        size="small"
                                                        clickable
                                                        color="primary"
                                                        variant="outlined"
                                                        onClick={() => copyTimeFromPrevious(day, false)}
                                                        onDelete={() => copyTimeFromPrevious(day, false)}
                                                        deleteIcon={<ContentCopyIcon fontSize="small" />}
                                                        disabled={!formData.is_recurring || !formData.recurring_days?.[day]}
                                                    />
                                                </Tooltip>
                                            )}
                                        </Box>

                                        <RestrictedTimePicker
                                            data-testid={`datepicker-${day}`}
                                            day={day}
                                            time={formData.recurring_times?.[day]}
                                            onChange={handleTimeChange}
                                            transportType={formData.transport_type}
                                            isReturn={false}
                                            timeErrors={timeErrors}
                                            setTimeErrors={setTimeErrors}
                                            getTimeConstraints={getTimeConstraints}
                                            validateTime={validateTime}
                                            disabled={!formData.is_recurring || !formData.recurring_days?.[day]}
                                        />
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}

                {/* Inhalt: Rückfahrten */}
                {activeTab === 'return' && (
                    <Box>
                        <FormControlLabel
                            control={
                                <Switch
                                    data-testid="recurring-return-switch"
                                    checked={formData.is_recurring_return || false}
                                    onChange={(event) => setFormData((prevData) => ({
                                        ...prevData,
                                        is_recurring_return: event.target.checked,
                                    }))}
                                    color="primary"
                                />
                            }
                            label={
                                <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                                    Regelmäßige Rückfahrten
                                </Typography>
                            }
                            labelPlacement="end"
                        />

                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            {['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'].map(day => (
                                <Grid item xs={12} sm={6} md={4} key={`return-${day}`}>
                                    <Paper
                                        elevation={1}
                                        sx={{
                                            p: 2,
                                            borderRadius: 2,
                                            opacity: formData.is_recurring_return ? 1 : 0.7
                                        }}
                                    >
                                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        data-testid={`return-switch-${day}`}
                                                        checked={!!formData.recurring_return_days?.[day]}
                                                        disabled={!formData.is_recurring_return}
                                                        onChange={(event) => handleReturnDayChange(event, day)}
                                                        color="secondary"
                                                        size="small"
                                                    />
                                                }
                                                label={
                                                    <Typography variant="subtitle1" fontWeight="bold">
                                                        {day}
                                                    </Typography>
                                                }
                                            />

                                            {lastSetTimes.return[day] && (
                                                <Tooltip title={`Zeit von ${lastSetTimes.return[day].fromDay} übernehmen (${lastSetTimes.return[day].time} Uhr)`}>
                                                    <Chip
                                                        label={`${lastSetTimes.return[day].time} Uhr`}
                                                        size="small"
                                                        clickable
                                                        color="secondary"
                                                        variant="outlined"
                                                        onClick={() => copyTimeFromPrevious(day, true)}
                                                        onDelete={() => copyTimeFromPrevious(day, true)}
                                                        deleteIcon={<ContentCopyIcon fontSize="small" />}
                                                        disabled={!formData.is_recurring_return || !formData.recurring_return_days?.[day]}
                                                    />
                                                </Tooltip>
                                            )}
                                        </Box>

                                        <RestrictedTimePicker
                                            data-testid={`return-datepicker-${day}`}
                                            day={day}
                                            time={formData.recurring_return_times?.[day]}
                                            onChange={handleReturnTimeChange}
                                            transportType={formData.transport_type}
                                            isReturn={true}
                                            timeErrors={timeErrors}
                                            setTimeErrors={setTimeErrors}
                                            getTimeConstraints={getTimeConstraints}
                                            validateTime={validateTime}
                                            disabled={!formData.is_recurring_return || !formData.recurring_return_days?.[day]}
                                        />
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}
            </DialogContent>

            <Divider />

            <DialogActions sx={{ px: 3, py: 2 }}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Button
                        onClick={onClose}
                        variant="text"
                        sx={{ color: theme.palette.text.secondary }}
                        tabIndex={-1}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        onClick={handleSaveDialog}
                        variant="contained"
                        color="primary"
                        sx={{ px: 3 }}
                        tabIndex={-1}
                    >
                        Einstellungen speichern
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default SerialAppointmentsDialog;