// MailTemplatesPage.js
import React, { useState, useEffect } from 'react';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Typography,
    DialogContentText,
    FormControlLabel,
    Switch,
    Divider,
    Tabs,
    Tab,
    Grid
} from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import { useUser } from '../components/userContext';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SendIcon from '@mui/icons-material/Send';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import { useThemeContext, THEME_CLASSIC } from '../theme/ThemeContext';
import {
    DrkCard,
    DrkInfoBox,
    DrkDialogCard,
    DrkDataGrid,
    DrkFormField,
    DrkActionBar,
    DrkPreviewFrame,
    DrkDialog
} from '../components/DrkComponents';

// Template-Typen für das Dropdown
const templateTypes = [
    { value: 'registration', label: 'Registrierung' },
    { value: 'passwordReset', label: 'Passwort zurücksetzen' },
    { value: 'bookingConfirmation', label: 'Buchungsbestätigung' },
    { value: 'appointmentChange', label: 'Terminverschiebung' },
    { value: 'custom', label: 'Benutzerdefiniert' }
];

function MailTemplatesPage() {
    const { currentTheme } = useThemeContext();
    const isClassic = currentTheme === THEME_CLASSIC;

    // States für die Verwaltung von Mail-Templates
    const [templates, setTemplates] = useState([]);
    const [filteredTemplates, setFilteredTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [serverError, setServerError] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [searchTerm, setSearchTerm] = useState('');
    const { user } = useUser();

    // States für das Bearbeiten und Erstellen von Templates
    const [isEditMode, setIsEditMode] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [editedTemplate, setEditedTemplate] = useState({
        name: '',
        type: 'custom',
        subject: '',
        description: '',
        content: '',
        preheader_text: '',
        show_color_bar: true,
        show_social_links: true,
        footer_text: ''
    });

    // States für Vorschau und Test-E-Mail
    const [previewMode, setPreviewMode] = useState('visual');
    const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
    const [previewHtml, setPreviewHtml] = useState('');
    const [testEmail, setTestEmail] = useState('');
    const [openTestMailDialog, setOpenTestMailDialog] = useState(false);
    const [previewLoading, setPreviewLoading] = useState(false);

    // State für Bestätigungsdialog beim Löschen
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [templateToDelete, setTemplateToDelete] = useState(null);

    // Format-Hilfe für den Texteditor
    const [showFormatHelp, setShowFormatHelp] = useState(false);

    // Lade Templates beim ersten Laden
    useEffect(() => {
        if (user) {
            fetchTemplates();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    // Filtere Templates basierend auf Suchbegriff
    useEffect(() => {
        const filtered = templates.filter(template => {
            const name = template.name || '';
            const description = template.description || '';
            const subject = template.subject || '';
            return (
                name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                description.toLowerCase().includes(searchTerm.toLowerCase()) ||
                subject.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });
        setFilteredTemplates(filtered);
    }, [searchTerm, templates]);

    // Lade alle Templates vom Server
    const fetchTemplates = async () => {
        try {
            setIsLoading(true);

            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/mail-templates`, {
                method: 'GET',
                headers: {
                    Authorization: user.api_key,
                },
            });

            if (!response.ok) {
                throw new Error('Fehler beim Laden der Mail-Templates');
            }

            const data = await response.json();

            // Formatiere die Daten für das DataGrid
            const formattedData = data.data.map(template => ({
                id: template.template_id,
                ...template
            }));

            setTemplates(formattedData);
            setFilteredTemplates(formattedData);
            setServerError(null);
        } catch (err) {
            console.error(err);
            setServerError('Fehler beim Laden der Mail-Templates: ' + err.message);
        } finally {
            setIsLoading(false);
        }
    };

    // Template hinzufügen
    const handleAddTemplate = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/mail-templates`, {
                method: 'POST',
                headers: {
                    'Authorization': user.api_key,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editedTemplate),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Fehler beim Hinzufügen des Templates');
            }

            // Erfolg anzeigen
            setAlertSeverity('success');
            setAlertMessage('Template erfolgreich hinzugefügt');
            setOpenAlert(true);

            // Dialog schließen und Daten neu laden
            setOpenEditDialog(false);
            fetchTemplates();

            // Alert nach 3 Sekunden ausblenden
            setTimeout(() => setOpenAlert(false), 3000);
        } catch (error) {
            console.error('Fehler beim Hinzufügen des Templates:', error.message);
            setServerError('Fehler beim Hinzufügen des Templates: ' + error.message);
        }
    };

    // Template aktualisieren
    const handleUpdateTemplate = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/mail-templates/${editedTemplate.template_id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': user.api_key,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editedTemplate),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Fehler beim Aktualisieren des Templates');
            }

            // Erfolg anzeigen
            setAlertSeverity('success');
            setAlertMessage('Template erfolgreich aktualisiert');
            setOpenAlert(true);

            // Dialog schließen und Daten neu laden
            setOpenEditDialog(false);
            fetchTemplates();

            // Alert nach 3 Sekunden ausblenden
            setTimeout(() => setOpenAlert(false), 3000);
        } catch (error) {
            console.error('Fehler beim Aktualisieren des Templates:', error.message);
            setServerError('Fehler beim Aktualisieren des Templates: ' + error.message);
        }
    };

    // Template löschen
    const handleDeleteTemplate = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/mail-templates/${templateToDelete}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': user.api_key,
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Fehler beim Löschen des Templates');
            }

            // Erfolg anzeigen
            setAlertSeverity('success');
            setAlertMessage('Template erfolgreich gelöscht');
            setOpenAlert(true);

            // Dialog schließen und Daten neu laden
            setDeleteDialogOpen(false);
            setTemplateToDelete(null);
            fetchTemplates();

            // Alert nach 3 Sekunden ausblenden
            setTimeout(() => setOpenAlert(false), 3000);
        } catch (error) {
            console.error('Fehler beim Löschen des Templates:', error.message);
            setServerError('Fehler beim Löschen des Templates: ' + error.message);
        }
    };

    // Vorschau eines Templates generieren
    const generatePreview = async (templateData = null) => {
        try {
            setPreviewLoading(true);
            setServerError(null);

            // Entweder das übergebene Template oder das aus dem State verwenden
            const templateToPreview = templateData || editedTemplate;

            // Allgemeine Template-Optionen aus dem aktuellen Template
            const templateOptions = {
                preheader_text: templateToPreview.preheader_text || '',
                footer_text: templateToPreview.footer_text || '',
                show_color_bar: templateToPreview.show_color_bar !== undefined ? templateToPreview.show_color_bar : true,
                show_social_links: templateToPreview.show_social_links !== undefined ? templateToPreview.show_social_links : true
            };

            // Teste, ob die Daten für den gewählten Templatetyp vorhanden sind
            let previewData = {
                ...templateOptions // Template-Optionen in allen Fällen hinzufügen
            };

            // Je nach Templatetyp werden unterschiedliche Testdaten vorbereitet
            switch (templateToPreview.type) {
                case 'registration':
                    previewData = {
                        ...previewData,
                        token: 'sample-token-12345',
                        firstName: 'Max',
                        lastName: 'Mustermann'
                    };
                    break;
                case 'passwordReset':
                    previewData = {
                        ...previewData,
                        token: 'reset-token-67890',
                        firstName: 'Erika',
                        lastName: 'Musterfrau'
                    };
                    break;
                case 'bookingConfirmation':
                    previewData = {
                        ...previewData,
                        bookingId: 'B-2025-0427',
                        date: '2025-12-15',
                        time: '14:30',
                        patientName: 'Hans Schmidt',
                        from: 'Musterstraße 10, 12345 Musterstadt',
                        to: 'Klinikum Musterhausen, Klinikstr. 1',
                        additionalInfo: 'Bitte 10 Minuten vor der angegebenen Zeit bereit sein.'
                    };
                    break;
                case 'appointmentChange':
                    previewData = {
                        ...previewData,
                        bookingId: 'B-2025-0427',
                        patientName: 'Hans Schmidt',
                        oldDate: '2025-12-15',
                        oldTime: '14:30',
                        newDate: '2025-12-17',
                        newTime: '10:15',
                        reason: 'Aufgrund einer Terminverschiebung müssen wir Ihren Transport leider verlegen.'
                    };
                    break;
                case 'custom':
                    previewData = {
                        ...previewData,
                        title: templateToPreview.subject,
                        content: templateToPreview.content
                    };
                    break;
                default:
                    previewData = {};
            }

            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/mail-templates/preview`, {
                method: 'POST',
                headers: {
                    'Authorization': user.api_key,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    templateType: templateToPreview.type,
                    data: previewData
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Fehler bei der Vorschau-Generierung');
            }

            const data = await response.json();
            setPreviewHtml(data.html);
            setOpenPreviewDialog(true);
        } catch (error) {
            console.error('Fehler bei der Vorschau-Generierung:', error.message);
            setServerError('Fehler bei der Vorschau-Generierung: ' + error.message);
        } finally {
            setPreviewLoading(false);
        }
    };

    // Test-E-Mail versenden
    const sendTestEmail = async () => {
        try {
            if (!testEmail) {
                setServerError('Bitte eine Test-E-Mail-Adresse eingeben');
                return;
            }

            setPreviewLoading(true);
            setServerError(null);

            // Allgemeine Template-Optionen aus dem aktuellen Template
            const templateOptions = {
                preheader_text: editedTemplate.preheader_text || '',
                footer_text: editedTemplate.footer_text || '',
                show_color_bar: editedTemplate.show_color_bar !== undefined ? editedTemplate.show_color_bar : true,
                show_social_links: editedTemplate.show_social_links !== undefined ? editedTemplate.show_social_links : false
            };

            // Entsprechende Testdaten basierend auf dem Template-Typ zusammenstellen
            let testData = {
                ...templateOptions // Template-Optionen in allen Fällen hinzufügen
            };

            switch (editedTemplate.type) {
                case 'registration':
                    testData = {
                        ...testData,
                        token: 'sample-token-12345',
                        firstName: 'Max',
                        lastName: 'Mustermann'
                    };
                    break;
                case 'passwordReset':
                    testData = {
                        ...testData,
                        token: 'reset-token-67890',
                        firstName: 'Erika',
                        lastName: 'Musterfrau'
                    };
                    break;
                case 'bookingConfirmation':
                    testData = {
                        ...testData,
                        bookingId: 'B-2025-0427',
                        date: '2025-12-15',
                        time: '14:30',
                        patientName: 'Hans Schmidt',
                        from: 'Musterstraße 10, 12345 Musterstadt',
                        to: 'Klinikum Musterhausen, Klinikstr. 1',
                        additionalInfo: 'Bitte 10 Minuten vor der angegebenen Zeit bereit sein.'
                    };
                    break;
                case 'appointmentChange':
                    testData = {
                        ...testData,
                        bookingId: 'B-2025-0427',
                        patientName: 'Hans Schmidt',
                        oldDate: '2025-12-15',
                        oldTime: '14:30',
                        newDate: '2025-12-17',
                        newTime: '10:15',
                        reason: 'Aufgrund einer Terminverschiebung müssen wir Ihren Transport leider verlegen.'
                    };
                    break;
                case 'custom':
                    testData = {
                        ...testData,
                        title: editedTemplate.subject,
                        content: editedTemplate.content
                    };
                    break;
                default:
                    testData = {};
            }

            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/mail-templates/send-test`, {
                method: 'POST',
                headers: {
                    'Authorization': user.api_key,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    to: testEmail,
                    templateType: editedTemplate.type,
                    data: testData
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Fehler beim Senden der Test-E-Mail');
            }

            // Erfolgsmeldung anzeigen
            setAlertSeverity('success');
            setAlertMessage(`Test-E-Mail erfolgreich an ${testEmail} gesendet`);
            setOpenAlert(true);
            setOpenTestMailDialog(false);

            // Alert nach 3 Sekunden ausblenden
            setTimeout(() => setOpenAlert(false), 3000);
        } catch (error) {
            console.error('Fehler beim Senden der Test-E-Mail:', error.message);
            setServerError('Fehler beim Senden der Test-E-Mail: ' + error.message);
        } finally {
            setPreviewLoading(false);
        }
    };

    // Handler für die Suche
    const handleSearchChange = (newSearchTerm) => {
        setSearchTerm(newSearchTerm);
    };

    // Handler für das Ändern eines Template-Felds
    const handleTemplateFieldChange = (field, value) => {
        setEditedTemplate(prev => ({
            ...prev,
            [field]: value
        }));
    };

    // Öffnen des Dialog für neues Template
    const handleOpenAddDialog = () => {
        setEditedTemplate({
            name: '',
            type: 'custom',
            subject: '',
            description: '',
            content: '',
            preheader_text: '',
            show_color_bar: true,
            show_social_links: true,
            footer_text: ''
        });
        setIsEditMode(false);
        setOpenEditDialog(true);
    };

    // Öffnen des Bearbeitungsdialogs für ein bestehendes Template
    const handleEditTemplate = (templateId) => {
        // Zuerst das richtige Template aus unserer Templates-Liste finden
        const templateToEdit = templates.find(template => template.id === templateId);
        if (templateToEdit) {
            // Für Debugging-Zwecke
            console.log("Bearbeite Template:", templateToEdit);

            // Alle Felder korrekt setzen, mit Fallback-Werten für fehlende oder null/undefined Werte
            setEditedTemplate({
                ...templateToEdit,
                preheader_text: templateToEdit.preheader_text || '',
                show_color_bar: templateToEdit.show_color_bar !== undefined ? templateToEdit.show_color_bar : true,
                show_social_links: templateToEdit.show_social_links !== undefined ? templateToEdit.show_social_links : true,
                footer_text: templateToEdit.footer_text || ''
            });
            setIsEditMode(true);
            setOpenEditDialog(true);
        }
    };

    // Handler für den Lösch-Dialog
    const handleDeleteClick = (templateId) => {
        setTemplateToDelete(templateId);
        setDeleteDialogOpen(true);
    };

    // Format-Hilfe Dialog für den benutzerdefinierten Inhalt
    const FormatHelpDialog = () => (
        <DrkInfoBox sx={{ mt: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>Formatierungshilfe</Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography variant="subtitle2">Eingabe</Typography>
                    <Box component="pre" sx={{ bgcolor: '#f5f5f5', p: 1, borderRadius: 1 }}>
                        *fett*
                        _kursiv_
                        ==markiert==
                        --durchgestrichen--
                        [Link-Text](https://example.com)
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle2">Ergebnis</Typography>
                    <Box sx={{ bgcolor: '#f5f5f5', p: 1, borderRadius: 1 }}>
                        <strong>fett</strong><br />
                        <em>kursiv</em><br />
                        <mark>markiert</mark><br />
                        <del>durchgestrichen</del><br />
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="outlined" size="small" onClick={() => setShowFormatHelp(false)}>
                    Schließen
                </Button>
            </Box>
        </DrkInfoBox>
    );

    // Spalten für das DataGrid
    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 200,
            renderCell: (params) => (
                <Typography variant="body1" fontWeight="medium">
                    {params.value}
                </Typography>
            )
        },
        {
            field: 'type',
            headerName: 'Typ',
            width: 150,
            renderCell: (params) => {
                const typeLabel = templateTypes.find(t => t.value === params.value)?.label || params.value;
                return (
                    <Typography variant="body2">
                        {typeLabel}
                    </Typography>
                );
            }
        },
        {
            field: 'subject',
            headerName: 'Betreff',
            width: 250,
            renderCell: (params) => (
                <Typography variant="body2">
                    {params.value}
                </Typography>
            )
        },
        {
            field: 'description',
            headerName: 'Beschreibung',
            width: 300,
            renderCell: (params) => (
                <Typography variant="body2" noWrap>
                    {params.value}
                </Typography>
            )
        },
        {
            field: 'modified_at',
            headerName: 'Zuletzt geändert',
            width: 200,
            renderCell: (params) => {
                // Versuchen, das Datum zu parsen
                const modifiedDate = params.row.modified_at ? new Date(params.row.modified_at) : null;

                if (!modifiedDate) {
                    return <Typography variant="body2">-</Typography>;
                }

                // Datum und Uhrzeit formatieren
                const formattedDate = modifiedDate.toLocaleDateString('de-DE', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                });

                const formattedTime = modifiedDate.toLocaleTimeString('de-DE', {
                    hour: '2-digit',
                    minute: '2-digit',
                });

                const formattedDateTime = `${formattedDate} ${formattedTime}`;

                return (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            height: '100%',
                        }}
                    >
                        {/* Datum und Uhrzeit */}
                        <Typography variant="body2">{formattedDateTime}</Typography>

                        {/* Benutzer, der die Änderung vorgenommen hat */}
                        {params.row.last_modified_by && (
                            <Typography variant="caption" style={{ fontStyle: 'italic', color: 'gray' }}>
                                {params.row.last_modified_by}
                            </Typography>
                        )}
                    </div>
                );
            },
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Aktionen',
            width: 150,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Bearbeiten"
                    onClick={() => handleEditTemplate(params.row.id)}
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Löschen"
                    onClick={() => handleDeleteClick(params.row.template_id)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<VisibilityIcon />}
                    label="Vorschau"
                    onClick={() => generatePreview(params.row)}
                />
            ],
        },
    ];

    // Aktionen für die Dialoge
    const editDialogActions = (
        <>
            <Button onClick={() => setOpenEditDialog(false)} color="inherit">
                Abbrechen
            </Button>
            <Button
                onClick={() => generatePreview()}
                color="primary"
                startIcon={<VisibilityIcon />}
                disabled={previewLoading}
            >
                Vorschau
            </Button>
            <Button
                onClick={() => setOpenTestMailDialog(true)}
                color="secondary"
                startIcon={<SendIcon />}
            >
                Test-Mail
            </Button>
            <Button
                onClick={isEditMode ? handleUpdateTemplate : handleAddTemplate}
                color="primary"
                variant="contained"
                disabled={!editedTemplate.name || !editedTemplate.subject}
            >
                {isEditMode ? 'Speichern' : 'Erstellen'}
            </Button>
        </>
    );

    const previewDialogActions = (
        <Button onClick={() => setOpenPreviewDialog(false)}>
            Schließen
        </Button>
    );

    const testMailDialogActions = (
        <>
            <Button onClick={() => setOpenTestMailDialog(false)} color="inherit">
                Abbrechen
            </Button>
            <Button
                onClick={sendTestEmail}
                color="primary"
                variant="contained"
                disabled={!testEmail || previewLoading}
            >
                Senden
            </Button>
        </>
    );

    const deleteDialogActions = (
        <>
            <Button onClick={() => setDeleteDialogOpen(false)} color="inherit">
                Abbrechen
            </Button>
            <Button onClick={handleDeleteTemplate} color="error">
                Löschen
            </Button>
        </>
    );

    return (
        <Box m={1} mt={5}>
            <Typography variant="h4" gutterBottom>
                E-Mail-Templates verwalten
            </Typography>

            <Typography variant="body1" paragraph>
                Hier können Sie E-Mail-Templates verwalten, die für verschiedene Benachrichtigungen im System verwendet werden.
            </Typography>

            {serverError && <Alert severity="error" sx={{ mb: 2 }}>{serverError}</Alert>}
            {openAlert && <Alert severity={alertSeverity} sx={{ mb: 2 }}>{alertMessage}</Alert>}

            {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <DrkCard elevation={isClassic ? 2 : 0} sx={{ maxWidth: '100%' }}>
                    {/* Action Bar mit Suche und Template-Erstellung */}
                    <DrkActionBar
                        searchTerm={searchTerm}
                        onSearchChange={handleSearchChange}
                        searchPlaceholder="Template suchen..."
                        actions={[
                            {
                                label: "Neues Template",
                                icon: <AddIcon />,
                                onClick: handleOpenAddDialog,
                                color: "primary"
                            }
                        ]}
                    />

                    {/* DataGrid für die Template-Liste */}
                    <DrkDataGrid
                        storageKey="mailTemplatesList"
                        rows={filteredTemplates}
                        columns={columns}
                        autoHeight
                        disableSelectionOnClick
                        emptyText="Keine E-Mail-Templates gefunden"
                        sx={{ width: '100%' }}
                    />
                </DrkCard>
            )}

            {/* Dialog zum Bearbeiten/Erstellen von Templates */}
            <DrkDialog
                open={openEditDialog}
                onClose={() => setOpenEditDialog(false)}
                title={isEditMode ? 'Template bearbeiten' : 'Neues Template erstellen'}
                maxWidth="md"
                fullWidth
                actions={editDialogActions}
            >
                <DrkDialogCard>
                    <DrkFormField
                        label="Name"
                        value={editedTemplate.name}
                        onChange={(e) => handleTemplateFieldChange('name', e.target.value)}
                        required
                    />

                    <DrkFormField
                        label="Typ"
                        type="select"
                        options={templateTypes}
                        value={editedTemplate.type}
                        onChange={(e) => handleTemplateFieldChange('type', e.target.value)}
                    />

                    <DrkFormField
                        label="Betreff"
                        value={editedTemplate.subject}
                        onChange={(e) => handleTemplateFieldChange('subject', e.target.value)}
                        required
                    />

                    <DrkFormField
                        label="Beschreibung"
                        value={editedTemplate.description}
                        onChange={(e) => handleTemplateFieldChange('description', e.target.value)}
                    />

                    <Divider sx={{ my: 2 }} />

                    {/* Erweiterte Optionen für die E-Mail */}
                    <Typography variant="h6" gutterBottom>
                        Erweiterte Optionen
                    </Typography>

                    <DrkFormField
                        label="Preheader Text"
                        value={editedTemplate.preheader_text || ''}
                        onChange={(e) => handleTemplateFieldChange('preheader_text', e.target.value)}
                        helperText="Text, der in der Vorschau in E-Mail-Clients angezeigt wird"
                    />

                    <Box display="flex" justifyContent="space-between" mt={2}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={editedTemplate.show_color_bar === undefined ? true : editedTemplate.show_color_bar}
                                    onChange={(e) => handleTemplateFieldChange('show_color_bar', e.target.checked)}
                                />
                            }
                            label="Farbbalken anzeigen"
                        />

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={editedTemplate.show_social_links === undefined ? true : editedTemplate.show_social_links}
                                    onChange={(e) => handleTemplateFieldChange('show_social_links', e.target.checked)}
                                />
                            }
                            label="Social Media Links anzeigen"
                        />
                    </Box>

                    <DrkFormField
                        label="Footer Text"
                        value={editedTemplate.footer_text || ''}
                        onChange={(e) => handleTemplateFieldChange('footer_text', e.target.value)}
                    />

                    <Divider sx={{ my: 2 }} />

                    {/* Nur für benutzerdefinierte Templates: Inhalt bearbeiten */}
                    {editedTemplate.type === 'custom' && (
                        <>
                            <Box display="flex" alignItems="center" mb={1}>
                                <Typography variant="h6">E-Mail-Inhalt</Typography>
                                <Button
                                    variant="text"
                                    startIcon={<TextFormatIcon />}
                                    onClick={() => setShowFormatHelp(!showFormatHelp)}
                                    sx={{ ml: 2 }}
                                >
                                    Formatierungshilfe
                                </Button>
                            </Box>

                            {showFormatHelp && <FormatHelpDialog />}

                            <DrkFormField
                                multiline
                                rows={10}
                                value={editedTemplate.content}
                                onChange={(e) => handleTemplateFieldChange('content', e.target.value)}
                                placeholder="Inhaltstext der E-Mail. Für Formatierung: *fett*, _kursiv_, [Link](url)"
                            />
                        </>
                    )}

                    {/* Hinweis für vordefinierte Template-Typen */}
                    {editedTemplate.type !== 'custom' && (
                        <DrkInfoBox sx={{ mt: 2 }}>
                            <Typography variant="body2">
                                Bei diesem Template-Typ werden die Inhalte automatisch generiert.
                                Der Inhalt kann über die Vorschau angezeigt werden.
                            </Typography>
                        </DrkInfoBox>
                    )}
                </DrkDialogCard>
            </DrkDialog>

            {/* Dialog für die Vorschau */}
            <DrkDialog
                open={openPreviewDialog}
                onClose={() => setOpenPreviewDialog(false)}
                title={`Vorschau: ${editedTemplate.name}`}
                maxWidth="md"
                fullWidth
                actions={previewDialogActions}
            >
                <DrkDialogCard>
                    <Tabs
                        value={previewMode}
                        onChange={(e, newValue) => setPreviewMode(newValue)}
                        sx={{ mb: 2, borderBottom: 1, borderColor: 'divider' }}
                    >
                        <Tab label="Visuelle Vorschau" value="visual" />
                        <Tab label="HTML-Code" value="code" />
                    </Tabs>

                    {previewMode === 'visual' ? (
                        <DrkPreviewFrame
                            html={previewHtml}
                            loading={previewLoading}
                            height="600px"
                            showCopyButton={false}
                        />
                    ) : (
                        <DrkPreviewFrame
                            html={previewHtml}
                            loading={previewLoading}
                            height="500px"
                            onCopy={(html) => {
                                navigator.clipboard.writeText(html);
                                setAlertSeverity('success');
                                setAlertMessage('HTML-Code in die Zwischenablage kopiert');
                                setOpenAlert(true);
                                setTimeout(() => setOpenAlert(false), 3000);
                            }}
                        />
                    )}
                </DrkDialogCard>
            </DrkDialog>

            {/* Dialog für das Senden einer Test-E-Mail */}
            <DrkDialog
                open={openTestMailDialog}
                onClose={() => setOpenTestMailDialog(false)}
                title="Test-E-Mail senden"
                maxWidth="sm"
                actions={testMailDialogActions}
            >
                <DrkDialogCard>
                    <DialogContentText>
                        Geben Sie die E-Mail-Adresse ein, an die die Test-E-Mail gesendet werden soll.
                    </DialogContentText>
                    <DrkFormField
                        label="E-Mail-Adresse"
                        type="email"
                        value={testEmail}
                        onChange={(e) => setTestEmail(e.target.value)}
                        autoFocus
                    />
                </DrkDialogCard>
            </DrkDialog>

            {/* Dialog zur Bestätigung des Löschens */}
            <DrkDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                title="Template löschen"
                maxWidth="sm"
                actions={deleteDialogActions}
            >
                <DrkDialogCard>
                    <DialogContentText>
                        Möchten Sie dieses Template wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.
                    </DialogContentText>
                </DrkDialogCard>
            </DrkDialog>
        </Box>
    );
}

export default MailTemplatesPage;