// stationList.js
import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    IconButton,
    CircularProgress,
    Typography,
    TextField,
    InputAdornment,
    Alert,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    List,
    ListItem,
    ListItemText,
    Menu,
    MenuItem
} from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import VehicleSplitButton from './vehicleSplitButton';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../components/userContext';
import PersistentDataGrid from '../../components/PersistentDataGrid';
import { useSnackbar } from 'notistack';

// Bereits vorhandener ConfirmationDialog (z. B. für das Löschen einer Station)
const ConfirmationDialog = ({ open, onClose, onConfirm, title, description }) => {
    return (
        <Dialog open={open} onClose={() => onClose(false)}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(false)}>Abbrechen</Button>
                <Button onClick={() => onConfirm(true)} color="primary">Ja</Button>
            </DialogActions>
        </Dialog>
    );
};

// Hilfsfunktion, um Bilder asynchron zu laden
const fetchImage = async (imageId, setImageCache) => {
    if (!imageId) return;
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URI}/api/images/${imageId}`);
        const data = await response.json();
        if (data.image) {
            setImageCache(prev => ({ ...prev, [imageId]: data.image }));
        }
    } catch (error) {
        console.error('Fehler beim Laden des Bildes:', error);
    }
};

const StationsList = ({ globalSearch }) => {
    // States für Stationsliste und globale UI
    const [stations, setStations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [serverError, setServerError] = useState(null);
    const [imageCache, setImageCache] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedStation, setSelectedStation] = useState(null);
    const [dialogAction, setDialogAction] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const { user } = useUser();

    // States für den "Fahrzeug zuordnen"-Dialog
    const [addVehicleDialogOpen, setAddVehicleDialogOpen] = useState(false);
    const [vehicleSearch, setVehicleSearch] = useState('');
    const [allVehicles, setAllVehicles] = useState([]);
    const [currentStation, setCurrentStation] = useState(null);

    // States für den Modal-Dialog, der alle Fahrzeuge anzeigt
    const [openVehiclesModal, setOpenVehiclesModal] = useState(false);
    const [vehiclesModalData, setVehiclesModalData] = useState([]);

    // States für das Kontextmenü und den Bestätigungsdialog zum Entfernen eines Fahrzeugs aus der Wache
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [confirmRemoveDialogOpen, setConfirmRemoveDialogOpen] = useState(false);

    // Snackbar
    const { enqueueSnackbar } = useSnackbar();
    const showSnackbar = (message, variant) => {
        enqueueSnackbar(message, { variant: variant || 'default' });
    };


    // Bilder zu den Stationen laden
    useEffect(() => {
        stations.forEach(station => {
            if (station.image_id && !imageCache[station.image_id]) {
                fetchImage(station.image_id, setImageCache);
            }
        });
        // eslint-disable-next-line
    }, [stations]);

    // Stationen laden
    useEffect(() => {
        fetchStations();
        // eslint-disable-next-line
    }, []);

    // globalSearch prop übernehmen
    useEffect(() => {
        setSearchQuery(globalSearch);
    }, [globalSearch]);

    // Stationsliste per API abrufen
    const fetchStations = async () => {
        try {
            setIsLoading(true);
            setServerError(null);
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/stations`, {
                headers: { Authorization: user.api_key }
            });
            if (!response.ok) {
                throw new Error('Fehler beim Laden der Wachen');
            }
            const data = await response.json();
            setStations(data);
        } catch (error) {
            setServerError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    // Löschen einer Station (Beispiel)
    const handleDeleteStation = async (id) => {
        console.log('Deleting station with ID', id);
        // Hier rufen wir das neue DELETE-Endpoint auf:
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/stations/${id}`, {
                method: 'DELETE',
                headers: {
                    Authorization: user.api_key
                }
            });
            if (!response.ok) {
                if (response.status === 404) {
                    // Station nicht gefunden
                    throw new Error('Station nicht gefunden oder gehört zu anderem Tenant.');
                }
                if (response.status === 403) {
                    // Keine Admin-Rechte
                    throw new Error('Nur Admins dürfen eine Station löschen.');
                }
                // Sonstiges
                const errorData = await response.json();
                throw new Error(errorData.error || 'Fehler beim Löschen.');
            }

            // Erfolg
            showSnackbar('Station erfolgreich gelöscht.', 'success');
            fetchStations(); // Tabelle neu laden
        } catch (error) {
            console.error(error);
            showSnackbar(`Löschen fehlgeschlagen: ${error.message}`, 'error');
        }
    };

    const handleEditStation = (id) => {
        navigate(`/stationpage/${id}`);
    };

    // Öffnet den "Fahrzeug zuordnen"-Dialog
    const openAddVehicleDialog = (station) => {
        setCurrentStation(station);
        setAddVehicleDialogOpen(true);
        setVehicleSearch('');
        fetchVehicles();
    };

    // Fahrzeuge abrufen
    const fetchVehicles = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/vehicles`, {
                headers: { Authorization: user.api_key }
            });
            if (!response.ok) {
                throw new Error('Fahrzeugliste konnte nicht geladen werden');
            }
            const data = await response.json();
            setAllVehicles(data);
        } catch (error) {
            console.error(error);
        }
    };

    // Fahrzeug der Station zuordnen (station_id setzen)
    const assignVehicleToStation = async (vehicle) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/vehicles/assignStation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user.api_key
                },
                body: JSON.stringify({
                    vehicle_id: vehicle.vehicle_id,
                    station_id: currentStation.station_id
                })
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Fehler bei der Zuordnung');
            }

            showSnackbar('Fahrzeug erfolgreich zur Wache hinzugefügt', 'success');
            setAddVehicleDialogOpen(false);
            setCurrentStation(null);
            fetchStations();
        } catch (error) {
            console.error(error);
            showSnackbar(`Zuordnung fehlgeschlagen: ${error.message}`, 'error');
        }
    };

    const handleRemoveVehicleFromStation = async (vehicle) => {
        setAnchorEl(null);
        setSelectedVehicle(vehicle);
        setConfirmRemoveDialogOpen(true);
    }


    // Kontextmenü öffnen und Fahrzeug speichern (in der Grid-Zeile)
    // eslint-disable-next-line
    const handleContextMenu = (event, vehicle) => {
        event.stopPropagation(); // Verhindert, dass auch das Button-Click-Navigationsevent getriggert wird.
        setAnchorEl(event.currentTarget);
        setSelectedVehicle(vehicle);
    };

    const handleCloseContextMenu = () => {
        setAnchorEl(null);
    };

    // Menüeintrag "Fahrzeug aus Wache entfernen" auswählen (in der Grid-Zeile)
    const handleRemoveVehicle = () => {
        setConfirmRemoveDialogOpen(true);
        handleCloseContextMenu();
    };

    // Bestätigungsdialog für das Entfernen auswerten (wird sowohl in der Grid-Zeile als auch im Modal genutzt)
    const confirmRemoveVehicle = async (confirm) => {
        setConfirmRemoveDialogOpen(false);
        if (confirm && selectedVehicle) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URI}/api/vehicles/removeStation`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: user.api_key
                    },
                    body: JSON.stringify({
                        vehicle_id: selectedVehicle.vehicle_id
                    })
                });
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Fehler beim Entfernen des Fahrzeugs aus der Wache');
                }

                showSnackbar('Fahrzeug erfolgreich aus der Wache entfernt', 'success');
                // Nach erfolgreicher Entfernung Stationsliste neu laden
                fetchStations();
                // Falls der Modal-Dialog offen ist, aktualisieren wir auch die Liste dort:
                if (openVehiclesModal) {
                    setVehiclesModalData(prev => prev.filter(v => v.vehicle_id !== selectedVehicle.vehicle_id));
                }
            } catch (error) {
                console.error(error);
                showSnackbar(`Entfernen fehlgeschlagen: ${error.message}`, 'error');
            } finally {
                setSelectedVehicle(null);
            }
        } else {
            setSelectedVehicle(null);
        }
    };

    // Spalten-Definition für den DataGrid
    const columns = [
        {
            field: 'name',
            headerName: 'Wachenname',
            width: 430,
            renderCell: (params) => {
                const imageSrc = imageCache[params.row.image_id];
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%', marginTop: 2, marginBottom: 2 }}>
                        <Box
                            component="img"
                            src={imageSrc || '/default-station.png'}
                            alt="Station"
                            sx={{ width: 80, height: 60, objectFit: 'cover', borderRadius: 2, border: '0px solid #ddd' }}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="body1" fontWeight="bold">{params.row.name}</Typography>
                            <Typography variant="body2" color="text.secondary">
                                {params.row.street} {params.row.house_number}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {params.row.postal_code} {params.row.city}
                            </Typography>
                        </Box>
                    </Box>
                );
            },
        },
        {
            field: 'vehicles',
            headerName: 'Zugeordnete Fahrzeuge',
            width: 700,
            renderCell: (params) => {
                const vehicles = params.row.vehicles || [];
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', width: '100%', marginTop: 2 }}>
                        {vehicles.slice(0, 3).map(veh => (
                            <VehicleSplitButton
                                veh={veh}
                                onRemove={(veh) => {
                                    handleRemoveVehicleFromStation(veh);
                                }}
                            />
                        ))}
                        {vehicles.length > 3 && (
                            <Button
                                variant="text"
                                onClick={() => {
                                    setVehiclesModalData(vehicles);
                                    setOpenVehiclesModal(true);
                                }}
                            >
                                +{vehicles.length - 3} mehr
                            </Button>
                        )}
                        {/* Plus-Button für Fahrzeug hinzufügen */}
                        <IconButton
                            color="primary"
                            onClick={() => openAddVehicleDialog(params.row)}
                            sx={{ marginLeft: 'auto' }}
                            title="Fahrzeug hinzufügen"
                        >
                            <AddCircleIcon />
                        </IconButton>
                    </Box>
                );
            },
        },
        {
            field: 'betreiber',
            headerName: 'Sonstige Informationen',
            width: 240,
            renderCell: (params) => (
                <Typography variant="body2" sx={{ marginTop: 2 }}>
                    Betreiber: {params.row.betreiber} <br />
                </Typography>
            ),
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Aktionen',
            width: 150,
            getActions: (params) => {
                const stationId = params.row.station_id;
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Bearbeiten"
                        onClick={() => handleEditStation(stationId)}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Löschen"
                        onClick={() => {
                            setSelectedStation(params.row);
                            setDialogAction('delete');
                            setDialogOpen(true);
                        }}
                        showInMenu
                    />,
                ];
            },
        },
    ];

    const rows = stations.map(st => ({ id: st.station_id, ...st }));

    const filteredRows = rows.filter(r => {
        const text = searchQuery.toLowerCase();
        return (
            r.name?.toLowerCase().includes(text) ||
            r.betreiber?.toLowerCase().includes(text) ||
            r.city?.toLowerCase().includes(text)
        );
    });

    const handleDialogConfirm = async (yes) => {
        setDialogOpen(false);
        if (yes && dialogAction === 'delete' && selectedStation) {
            await handleDeleteStation(selectedStation.station_id);
            fetchStations();
        }
        setSelectedStation(null);
        setDialogAction('');
    };

    // eslint-disable-next-line
    const handleAddNewStation = () => {
        navigate(`/stationpage`);
    };

    return (
        <Box sx={{ mt: 2 }}>
            {serverError && <Alert severity="error">{serverError}</Alert>}
            {isLoading ? (
                <CircularProgress />
            ) : (
                <div style={{ width: '100%' }}>
                    <PersistentDataGrid
                        storageKey="stationsList"
                        rows={filteredRows}
                        columns={columns}
                        getRowHeight={() => 'auto'}
                        pageSize={5}
                        rowsPerPageOptions={[5, 10, 20]}
                    />
                </div>
            )}

            {/* Bestätigungsdialog (z. B. für das Löschen einer Station) */}
            <ConfirmationDialog
                open={dialogOpen}
                title="Wache löschen?"
                description={selectedStation ? `Möchten Sie die Station "${selectedStation.name}" wirklich löschen?` : ''}
                onClose={handleDialogConfirm}
                onConfirm={handleDialogConfirm}
            />

            {/* Dialog zur Fahrzeugauswahl (Fahrzeug zuordnen) */}
            <Dialog open={addVehicleDialogOpen} onClose={() => setAddVehicleDialogOpen(false)} fullWidth maxWidth="sm">
                <DialogTitle>Fahrzeug zuordnen</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Fahrzeug suchen"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={vehicleSearch}
                        onChange={e => setVehicleSearch(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: vehicleSearch && (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setVehicleSearch('')}>
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Box my={2}>
                        <Button
                            variant="text"
                            color="primary"
                            onClick={() => {
                                setAddVehicleDialogOpen(false);
                                navigate('/vehiclepage', {
                                    state: {
                                        stationId: currentStation?.station_id,
                                        redirectTab: 0
                                    }
                                });
                            }}
                        >
                            Neues Fahrzeug hinzufügen
                        </Button>
                    </Box>
                    <List>
                        {allVehicles
                            .filter(veh =>
                                veh.vehicle_name.toLowerCase().includes(vehicleSearch.toLowerCase()) ||
                                (veh.license_plate && veh.license_plate.toLowerCase().includes(vehicleSearch.toLowerCase()))
                            )
                            .map(veh => (
                                <ListItem button key={veh.vehicle_id} onClick={() => assignVehicleToStation(veh)}>
                                    <ListItemText primary={veh.vehicle_name} secondary={veh.license_plate || ''} />
                                </ListItem>
                            ))
                        }
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddVehicleDialogOpen(false)}>Abbrechen</Button>
                </DialogActions>
            </Dialog>

            {/* Dialog, um alle Fahrzeuge anzuzeigen */}
            <Dialog
                open={openVehiclesModal}
                onClose={() => setOpenVehiclesModal(false)}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>Alle Fahrzeuge</DialogTitle>
                <DialogContent>
                    <List>
                        {vehiclesModalData.map(veh => (
                            <ListItem
                                key={veh.vehicle_id}
                                button
                                // Beim Klick auf die Zeile wird zur Detailseite navigiert.
                                onClick={() => {
                                    setOpenVehiclesModal(false);
                                    navigate(`/vehiclepage/${veh.vehicle_id}`);
                                }}
                                // secondaryAction zeigt den Remove-Button pro Zeile an.
                                secondaryAction={
                                    <IconButton edge="end" onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedVehicle(veh);
                                        setConfirmRemoveDialogOpen(true);
                                    }}>
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            >
                                <ListItemText primary={veh.vehicle_name} secondary={veh.license_plate || ''} />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
            </Dialog>

            {/* Kontextmenü für Fahrzeug entfernen (in der Grid-Zeile) */}
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseContextMenu}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuItem onClick={handleRemoveVehicle}>
                    Fahrzeug aus Wache entfernen
                </MenuItem>
            </Menu>

            {/* Bestätigungsdialog für das Entfernen eines Fahrzeugs aus der Wache */}
            <Dialog
                open={confirmRemoveDialogOpen}
                onClose={() => confirmRemoveVehicle(false)}
            >
                <DialogTitle>Fahrzeug entfernen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Wirklich Fahrzeug "{selectedVehicle?.vehicle_name}" aus der Wache entfernen?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => confirmRemoveVehicle(false)}>Nein</Button>
                    <Button onClick={() => confirmRemoveVehicle(true)} color="primary">Ja</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default StationsList;
