import React, { useEffect, useState } from 'react';
import { useUser } from '../../components/userContext';
import {
    Typography,
    Box,
    Paper,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Button,
    CircularProgress,
} from '@mui/material';
import {
    LocalHospital as HospitalIcon,
    Person as PersonIcon,
    Phone as PhoneIcon,
    Edit as EditIcon,
    Schedule as ScheduleIcon,
    ArrowForward as ArrowForwardIcon,
    ArrowBack as ArrowBackIcon,
    DirectionsCar as VehicleIcon,
} from '@mui/icons-material';
import AddressCell from '../../components/AddressCell';

/**
 * Wiederverwendbare Komponente für die Anzeige von Transport-Informationen
 * @param {Object} props - Component properties
 * @param {string} props.transport_id - ID des Transports, dessen Informationen angezeigt werden sollen
 */
function TransportInfoPanel({ transport_id }) {
    const { user } = useUser();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [transport, setTransport] = useState(null);

    useEffect(() => {
        if (transport_id) {
            fetchTransport();
        }
        // eslint-disable-next-line
    }, [transport_id]);

    async function fetchTransport() {
        setLoading(true);
        setError(null);
        try {
            const transportResponse = await fetch(`${process.env.REACT_APP_API_URI}/api/transport/${transport_id}`, {
                headers: { 'Authorization': user.api_key },
            });
            if (!transportResponse.ok) {
                throw new Error(`Fehler beim Laden des Transports: ${transportResponse.status}`);
            }
            const transportData = await transportResponse.json();
            setTransport(transportData);
        } catch (err) {
            console.error(err);
            setError(err.message || 'Fehler beim Laden der Transportdaten');
        } finally {
            setLoading(false);
        }
    }

    if (loading) {
        return (
            <Paper elevation={3} sx={{ p: 2, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </Paper>
        );
    }

    if (error) {
        return (
            <Paper elevation={3} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" gutterBottom>
                    Transport-Informationen
                </Typography>
                <Box sx={{ color: 'error.main', my: 2 }}>
                    <Typography variant="body1">{error}</Typography>
                </Box>
            </Paper>
        );
    }

    if (!transport) {
        return (
            <Paper elevation={3} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" gutterBottom>
                    Transport-Informationen
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Keine Transportdaten verfügbar.
                </Typography>
            </Paper>
        );
    }

    return (
        <Box sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" gutterBottom>
                Transport-Informationen
            </Typography>
            <List dense>
                <ListItem>
                    <ListItemIcon>
                        <VehicleIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                        primary="Fahrzeug"
                        secondary={
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="body2" sx={{ color: 'primary.main' }}>
                                    {transport.vehicle_name || 'Fahrzeug ?'}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'primary.main' }}>
                                    aus Wache: {transport.station_name || '???'}
                                </Typography>
                            </div>
                        }
                    />
                </ListItem>
                <Divider sx={{ my: 1 }} />
                <ListItem>
                    <ListItemIcon>
                        <HospitalIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                        primary="Transportart"
                        secondary={
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="body2">
                                    {transport.transport_type || '???'}
                                </Typography>
                                {transport.transport_type === 'DIALYSEFAHRT' && (
                                    <Typography variant="caption" sx={{ fontStyle: 'italic', color: 'gray' }}>
                                        {transport.return_trip ? 'Dialyse Rückfahrt' : 'Dialyse Hinfahrt'}
                                    </Typography>
                                )}
                            </div>
                        }
                    />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <PersonIcon color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary="Patient" secondary={`${transport.first_name || '???'} ${transport.last_name || '???'}`} />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <PhoneIcon color="info" />
                    </ListItemIcon>
                    <ListItemText primary="Rückrufnummer" secondary={transport.phone_number || '???'} />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <ArrowBackIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                        primary="Von"
                        secondary={
                            <AddressCell
                                address={{
                                    institution: transport.from_institution,
                                    station: transport.from_station,
                                    street: transport.from_street,
                                    houseNumber: transport.from_house_number,
                                    postalCode: transport.from_postal_code,
                                    city: transport.from_city,
                                }}
                            />
                        }
                    />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <ArrowForwardIcon color="success" />
                    </ListItemIcon>
                    <ListItemText
                        primary="Nach"
                        secondary={
                            <AddressCell
                                address={{
                                    institution: transport.to_institution,
                                    station: transport.to_station,
                                    street: transport.to_street,
                                    houseNumber: transport.to_house_number,
                                    postalCode: transport.to_postal_code,
                                    city: transport.to_city,
                                }}
                            />
                        }
                    />
                </ListItem>
            </List>

            <Box>
                <Typography variant="subtitle1" gutterBottom>
                    Aktionen
                </Typography>
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<EditIcon />}
                    fullWidth
                    sx={{ mb: 1 }}
                    onClick={() => alert('Transport verschieben - noch nicht implementiert')}
                >
                    Transport verschieben
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<ScheduleIcon />}
                    fullWidth
                    onClick={() => alert('Transport umplanen - noch nicht implementiert')}
                >
                    Transport umplanen
                </Button>
            </Box>
        </Box>
    );
}

export default TransportInfoPanel;