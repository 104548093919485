// src/components/VehicleSplitButton.js
import React, { useState } from 'react';
import {
    Box,
    Button,
    Menu,
    MenuItem,
    Typography
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

/**
 * @param {object} props
 * @param {object} props.veh - Fahrzeugobjekt (enthält z.B. vehicle_id, vehicle_name, license_plate, imageSrc, ...)
 * @param {function} props.onRemove - Callback, um das Fahrzeug aus der Wache zu entfernen
 */
const VehicleSplitButton = ({ veh, onRemove }) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);

    // Menü öffnen
    const handleButtonClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    // Menü schließen
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    // „Fahrzeug bearbeiten“
    const handleEditClick = () => {
        handleMenuClose();
        navigate(`/vehiclepage/${veh.vehicle_id}`);
    };

    // „Fahrzeug aus Wache entfernen“
    const handleRemoveClick = () => {
        handleMenuClose();
        onRemove(veh);
    };

    return (
        <>
            {/* 
        Haupt-Button, der den Fahrzeugnamen (und optional das Kennzeichen/Bild) zeigt.
        Wir hängen den „3-Punkte“-Icon (MoreVertIcon) ans Ende (endIcon). 
      */}
            <Button
                variant="outlined"
                onClick={handleButtonClick}
                endIcon={<MoreVertIcon />}
                sx={{
                    mr: 1,
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    // ggf. mehr Styling
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                        {veh.vehicle_name}
                    </Typography>
                    {veh.radio_call_sign && (
                        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                            {veh.radio_call_sign}
                        </Typography>
                    )}
                </Box>
            </Button>

            {/* Kontext-Menü */}
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClick={handleMenuClose}
            >
                <MenuItem onClick={handleEditClick}>
                    <EditIcon fontSize="small" style={{ marginRight: 8 }} />
                    Fahrzeug bearbeiten
                </MenuItem>

                <MenuItem onClick={handleRemoveClick}>
                    <DeleteIcon fontSize="small" style={{ marginRight: 8 }} />
                    Fahrzeug aus Wache entfernen
                </MenuItem>
            </Menu>
        </>
    );
};

export default VehicleSplitButton;