// src/pages/vehicles/VehicleHistoryDialog.js
import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    Typography,
    Button,
    IconButton
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import VehicleHistoryEntryCard from '../../components/VehicleHistoryEntryCard'; // Komponente zum Rendern einzelner Einträge

const VehicleHistoryDialog = ({ open, onClose, title, entries = [] }) => {
    const [showHelp, setShowHelp] = useState(false);

    const handleToggleHelp = () => setShowHelp(!showHelp);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            {/* HEADER mit Titel und Info-Button */}
            <Box display="flex" alignItems="center" justifyContent="space-between" px={2} pt={2}>
                <DialogTitle sx={{ p: 0 }}>{title || 'Historie für Fahrzeug'}</DialogTitle>
                <IconButton onClick={handleToggleHelp} aria-label="Info anzeigen" sx={{ marginLeft: 'auto' }}>
                    <InfoIcon />
                </IconButton>
            </Box>

            <DialogContent dividers>
                {/* Hilfe-Text einblenden, falls showHelp = true */}
                {showHelp && (
                    <Box mb={2} p={2} border="1px solid #eee" borderRadius="4px" bgcolor="#f5f5f5">
                        <Typography variant="subtitle2" gutterBottom>
                            Historie der Änderungen
                        </Typography>
                        <Typography variant="body2" paragraph>
                            Hier werden alle relevanten Änderungen an diesem Fahrzeug protokolliert,
                            einschließlich Schichtplan-Zuordnungen, Unverfügbarkeiten, und Wachenwechsel.
                        </Typography>
                    </Box>
                )}

                {/* Anzeige der History-Einträge */}
                {entries.length === 0 ? (
                    <Typography variant="body2">Keine Historie-Einträge vorhanden.</Typography>
                ) : (
                    entries.map((entry) => (
                        <VehicleHistoryEntryCard key={entry.historie_id} entry={entry} />
                    ))
                )}
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>Schließen</Button>
            </DialogActions>
        </Dialog>
    );
};

export default VehicleHistoryDialog;