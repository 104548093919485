import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

/**
 * AnimatedText Component
 * 
 * A reusable component for displaying animated text with configurable properties:
 * - Text content
 * - Color theme (blue, red, green)
 * - Size (small, medium, large)
 * - Animation toggle
 * 
 * @param {Object} props
 * @param {string} props.text - The text to display
 * @param {string} props.colorTheme - Color theme: 'blue' (default), 'red', 'green', or 'yellow'
 * @param {string} props.size - Size: 'small', 'medium', or 'large' (default)
 * @param {boolean} props.animated - Whether to show animations (default: true)
 * @param {Object} props.sx - Additional sx props to apply to the root Box
 */
const AnimatedText = ({ 
  text = "Loading...", 
  colorTheme = "blue", 
  size = "medium",
  animated = true,
  sx = {}
}) => {
  const theme = useTheme();
  
  // Define color configurations based on theme
  const colorConfigs = {
    blue: {
      backgroundColor: 'rgba(25, 118, 210, 0.1)',
      textColor: theme.palette.primary.main,
      shadowColor: 'rgba(25, 118, 210, 0.4)'
    },
    red: {
      backgroundColor: 'rgba(211, 47, 47, 0.1)',
      textColor: theme.palette.error.main,
      shadowColor: 'rgba(211, 47, 47, 0.4)'
    },
    green: {
      backgroundColor: 'rgba(46, 125, 50, 0.1)',
      textColor: theme.palette.success.main,
      shadowColor: 'rgba(46, 125, 50, 0.4)'
    },
    yellow: {
      backgroundColor: 'rgba(255, 193, 7, 0.1)',
      textColor: 'rgba(245, 173, 0, 1)', // Warmes Gelb für den Text
      shadowColor: 'rgba(255, 193, 7, 0.4)'
    }
  };
  
  // Define size configurations
  const sizeConfigs = {
    small: {
      py: 0.5,
      px: 1,
      typography: 'body2',
      fontWeight: 400
    },
    medium: {
      py: 1,
      px: 2, 
      typography: 'body1',
      fontWeight: 400
    },
    large: {
      py: 1,
      px: 2,
      typography: 'h6',
      fontWeight: 400
    }
  };
  
  // Get selected configurations
  const colorConfig = colorConfigs[colorTheme] || colorConfigs.blue;
  const sizeConfig = sizeConfigs[size] || sizeConfigs.medium;
  
  return (
    <Box
      sx={{
        mt: 2,
        py: sizeConfig.py,
        px: sizeConfig.px,
        borderRadius: 2,
        backgroundColor: colorConfig.backgroundColor,
        textAlign: 'center',
        animation: animated ? 'pulse 1.5s infinite' : 'none',
        position: 'relative',
        overflow: 'hidden',
        '&::before': animated ? {
          content: '""',
          position: 'absolute',
          top: 0,
          left: '-100%',
          width: '100%',
          height: '100%',
          background: 'linear-gradient(to right, transparent, rgba(255,255,255,0.3), transparent)',
          animation: 'shimmer 2s infinite',
        } : {},
        '@keyframes shimmer': {
          '0%': { left: '-100%' },
          '100%': { left: '100%' }
        },
        '@keyframes pulse': {
          '0%': { boxShadow: `0 0 0 0 ${colorConfig.shadowColor}` },
          '70%': { boxShadow: `0 0 0 8px rgba(0,0,0,0)` },
          '100%': { boxShadow: `0 0 0 0 rgba(0,0,0,0)` }
        },
        ...sx
      }}
    >
      <Typography
        variant={sizeConfig.typography}
        sx={{
          fontWeight: sizeConfig.fontWeight,
          color: colorConfig.textColor,
          textShadow: '0px 0px 1px rgba(0,0,0,0.1)'
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default AnimatedText;