import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Box,
    Button,
    CircularProgress,
    Typography,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import { useNavigate } from 'react-router-dom';

/**
 * Props, die wir hier erwarten:
 *   open                : Boolean - ob der Dialog angezeigt wird
 *   onClose             : Funktion - schließt den Dialog
 *   user                : Objekt - enthält Benutzer-Infos (z.B. user.api_key)
 *   id                  : String/Number - die ID des Transports
 *   preparedApiData     : Objekt - die zusammengebauten API-Daten für das Update
 */
const SeriesChangeDialog = ({
    open,
    onClose,
    user,
    id,
    preparedApiData
}) => {
    const navigate = useNavigate();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [seriesChangeResults, setSeriesChangeResults] = useState(null);

    // Standardmäßig heute als Voreinstellung
    const [seriesChangeDateFrom, setSeriesChangeDateFrom] = useState(dayjs());

    // Die eigentliche Logik zum Aktualisieren der Serie
    const handleSeriesChangeConfirm = async () => {
        setIsSubmitting(true);

        try {
            // In jedem Fall wird das aktuell ausgewählte Datum übernommen
            const dateFrom = seriesChangeDateFrom.format('YYYY-MM-DD');

            // Beispielhafter API-Aufruf: /api/transports/:id/cancel_series_from
            const response = await fetch(
                `${process.env.REACT_APP_API_URI}/api/transports/${id}/cancel_series_from`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: user.api_key,
                    },
                    body: JSON.stringify({
                        transportData: preparedApiData,
                        dateFrom: dateFrom,
                        reason: 'Serienänderung',
                    }),
                }
            );

            if (!response.ok) {
                throw new Error('Fehler beim Aktualisieren der Serie');
            }

            const result = await response.json();
            setSeriesChangeResults(result);
        } catch (error) {
            console.error('Fehler:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    // Behandlung des "Schließen"-Verhaltens
    const handleClose = () => {
        // Nur schließen, wenn nicht gerade gesendet wird
        if (!isSubmitting && !seriesChangeResults) {
            // State zurücksetzen und wirklich schließen
            onClose();
        } else if (seriesChangeResults) {
            // Falls wir gerade das Result anzeigen, dann zum Abschluss
            setSeriesChangeResults(null);
            onClose();
            // Beispiel: Danach woanders hin navigieren:
            navigate('/transportseries');
        }
    };

    // Prüfung, ob ausgewähltes Datum heute ist
    const isDateToday = dayjs(seriesChangeDateFrom).isSame(dayjs(), 'day');

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ fontWeight: 'bold', color: '#E46450' }}>
                Änderung der Serie
            </DialogTitle>
            <DialogContent>
                {/* Noch keine Ergebnisse -> Zeige Auswahldialog */}
                {!seriesChangeResults ? (
                    <>
                        <DialogContentText sx={{ mb: 0, color: 'text.secondary' }}>
                            Ab wann soll die Änderung der Serie gelten?
                        </DialogContentText>

                        <Typography variant="body2" sx={{ mb: 4, fontStyle: 'italic', color: 'text.secondary' }}>
                            Ab diesem Datum gelten die Änderungen für alle Transporte der Serie.
                        </Typography>

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                                <DatePicker
                                    label="Ab diesem Datum"
                                    value={seriesChangeDateFrom}
                                    onChange={(newValue) => setSeriesChangeDateFrom(newValue)}
                                    format="DD.MM.YYYY"
                                    // Erlaubtes Zeitfenster: heute bis heute+10 Tage
                                    minDate={dayjs()}
                                    maxDate={dayjs().add(10, 'day')}
                                />
                            </LocalizationProvider>

                            {/* Button "Jetzt" nur anzeigen, wenn das Datum != heute ist */}
                            {!isDateToday && (
                                <Button
                                    variant="text"
                                    onClick={() => setSeriesChangeDateFrom(dayjs())}
                                    disabled={isSubmitting}
                                >
                                    Jetzt
                                </Button>
                            )}
                        </Box>
                    </>
                ) : (
                    // Nach erfolgreicher Änderung -> Zusammenfassung anzeigen
                    <Box sx={{ py: 1 }}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Zusammenfassung der Änderung:
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary={`${seriesChangeResults.canceledCount || 0} Transporte bearbeitet und neu angelegt.`}
                                    secondary={`ab dem ${seriesChangeDateFrom.format('DD.MM.YYYY')}`}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="Neue Transporte werden entsprechend der Serieneinstellungen generiert"
                                    secondary="Bei der nächsten Ausführung des Hintergrundprozesses"
                                />
                            </ListItem>
                        </List>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                {/* Footer-Bereich, variiert je nach Status */}
                {!seriesChangeResults ? (
                    <>
                        <Button onClick={handleClose} disabled={isSubmitting}>
                            Abbrechen
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSeriesChangeConfirm}
                            disabled={!seriesChangeDateFrom || isSubmitting}
                        >
                            Speichern
                        </Button>
                    </>
                ) : (
                    <Button onClick={handleClose} autoFocus>
                        Schließen
                    </Button>
                )}
            </DialogActions>

            {/* Wenn noch was lädt */}
            {isSubmitting && (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                    <CircularProgress />
                </Box>
            )}
        </Dialog>
    );
};

export default SeriesChangeDialog;