// breakUtils.js
export async function deleteBreak(breakId, apiKey, apiUri) {
    const url = `${apiUri}/api/planning/break/${breakId}`;

    const response = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Authorization': apiKey,
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.error || 'Ein Fehler ist aufgetreten');
    }
    return data;
}

export const updateBreak = async (breakData, apiKey, apiUri) => {
    const { planning_detail_id, planned_start, planned_end } = breakData;

    // Format der API-Anfrage anpassen
    const requestData = {
        planned_start: planned_start,
        planned_end: planned_end
    };

    const response = await fetch(`${apiUri}/api/planning/break/${planning_detail_id}`, {
        method: 'PUT',
        headers: {
            'Authorization': apiKey,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
    });

    if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Fehler beim Aktualisieren der Pause');
    }

    return await response.json();
};
