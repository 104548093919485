//vehiclesList.js
import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Typography,
    Alert,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    TextField,
    Tooltip,
    Stack,
    Chip
} from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import HistoryIcon from '@mui/icons-material/History';
import WarningIcon from '@mui/icons-material/Warning';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../components/userContext';
import PersistentDataGrid from '../../components/PersistentDataGrid';
import { useSnackbar } from 'notistack';
import VehicleHistoryDialog from '../dialogs/VehicleHistoryDialog'; // Importieren Sie den neuen Dialog
import { getVehicleStatusInfo } from './vehicleStatusUtils';

// Bereits existierende Buttons/Komponenten
import StationSplitButton from './StationSplitButton';
import ShiftAssignmentSplitButton from './ShiftAssignmentSplitButton';

// ConfirmationDialog analog wie gehabt
const ConfirmationDialog = ({ open, onClose, onConfirm, title, description }) => (
    <Dialog open={open} onClose={() => onClose(false)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <DialogContentText>{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => onClose(false)}>Abbrechen</Button>
            <Button onClick={() => onConfirm(true)} color="primary">Ja</Button>
        </DialogActions>
    </Dialog>
);

const VehiclesList = ({ globalSearch }) => {
    const [vehicles, setVehicles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [serverError, setServerError] = useState(null);
    const [imageCache, setImageCache] = useState({});
    const [shiftTemplatesMap, setShiftTemplatesMap] = useState({});

    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [dialogAction, setDialogAction] = useState('');

    // Für das Entfernen einer Schichtzuordnung
    const [removeShiftDialogOpen, setRemoveShiftDialogOpen] = useState(false);
    const [selectedShiftAssignment, setSelectedShiftAssignment] = useState(null);

    // Für das Hinzufügen einer neuen Schichtzuordnung
    const [addShiftDialogOpen, setAddShiftDialogOpen] = useState(false);
    const [allShiftTemplates, setAllShiftTemplates] = useState([]);
    const [shiftSearch, setShiftSearch] = useState('');
    const [currentVehicleForShift, setCurrentVehicleForShift] = useState(null);

    // Modal für „Alle Schichten"
    const [openShiftsModal, setOpenShiftsModal] = useState(false);
    const [shiftsModalData, setShiftsModalData] = useState([]);

    // -- NEU: Stationen-Dialog (Add Station) --
    const [addStationDialogOpen, setAddStationDialogOpen] = useState(false);
    const [stationSearch, setStationSearch] = useState('');
    const [allStations, setAllStations] = useState([]);
    const [currentVehicleForStation, setCurrentVehicleForStation] = useState(null);

    const navigate = useNavigate();
    const { user } = useUser();
    const [searchQuery, setSearchQuery] = useState('');

    // -- Snackbar --
    const { enqueueSnackbar } = useSnackbar();
    const showSnackbar = (message, variant = 'default') => {
        enqueueSnackbar(message, { variant });
    };

    // State für die Fahrzeug-Historie
    const [showVehicleHistoryDialog, setShowVehicleHistoryDialog] = useState(false);
    const [vehicleHistoryData, setVehicleHistoryData] = useState([]);
    const [vehicleHistoryTitle, setVehicleHistoryTitle] = useState(''); // z.B. "Historie für KTW-001"

    // ---------------------------
    //   FETCH-Daten / useEffect
    // ---------------------------
    const fetchVehicles = async () => {
        try {
            setIsLoading(true);
            setServerError(null);
            const response = await fetch(
                `${process.env.REACT_APP_API_URI}/api/vehicles`,
                { headers: { Authorization: user.api_key } }
            );
            if (!response.ok) {
                throw new Error('Fehler beim Laden der Fahrzeuge');
            }
            const data = await response.json();
            setVehicles(data);
        } catch (error) {
            setServerError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    // Bilddaten cachen
    const fetchImage = async (imageId) => {
        if (!imageId) return;
        if (imageCache[imageId]) return; // schon im Cache
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/images/${imageId}`);
            const data = await response.json();
            if (data.image) {
                setImageCache(prev => ({ ...prev, [imageId]: data.image }));
            }
        } catch (error) {
            console.error('Fehler beim Laden des Bildes:', error);
        }
    };

    const fetchShiftTemplates = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URI}/api/shift-templates`,
                { headers: { Authorization: user.api_key } }
            );
            if (!response.ok) {
                throw new Error('Fehler beim Laden der Schichttemplates');
            }
            const data = await response.json();
            // Mapping { template_id: { ...template }, ...}
            const map = {};
            data.forEach(t => {
                map[t.template_id] = t;
            });
            setShiftTemplatesMap(map);

            // Alle Templates für "Add Shift"
            setAllShiftTemplates(data);
        } catch (error) {
            console.error(error);
        }
    };

    // NEU: Laden der Stationen
    const fetchStations = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/stations`, {
                headers: { Authorization: user.api_key }
            });
            if (!response.ok) {
                throw new Error('Fehler beim Laden der Wachen');
            }
            const data = await response.json();
            setAllStations(data);
        } catch (error) {
            alert(error.message);
        }
    };

    const handleShowVehicleHistory = async (vehicle) => {
        if (!vehicle) return;
        try {
            setServerError(null);
            // Abruf von /api/vehicles/:id/history
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/vehicles/${vehicle.vehicle_id}/history`, {
                headers: {
                    Authorization: user.api_key
                }
            });
            if (!response.ok) {
                throw new Error('Fehler beim Abrufen der Fahrzeug-Historie');
            }
            const data = await response.json();
            setVehicleHistoryData(data);
            setVehicleHistoryTitle(`Historie für ${vehicle.vehicle_name}`);
            setShowVehicleHistoryDialog(true);
        } catch (error) {
            console.error('Fehler bei handleShowVehicleHistory:', error);
            setServerError(error.message);
        }
    };

    const handleCloseVehicleHistory = () => {
        setShowVehicleHistoryDialog(false);
        setVehicleHistoryData([]);
    };


    useEffect(() => {
        fetchVehicles();
        fetchShiftTemplates();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // Bilder nachladen
        vehicles.forEach(v => {
            if (v.image_id) {
                fetchImage(v.image_id);
            }
        });
        // eslint-disable-next-line
    }, [vehicles]);

    useEffect(() => {
        setSearchQuery(globalSearch || '');
    }, [globalSearch]);

    // --------------------------------------------
    //        Allgemeine Fahrzeug-Events
    // --------------------------------------------
    const handleDialogConfirm = async (yes) => {
        setDialogOpen(false);
        if (yes && dialogAction === 'delete' && selectedVehicle) {
            // Hier kommt unser Delete-Request
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URI}/api/vehicles/${selectedVehicle.vehicle_id}`,
                    {
                        method: 'DELETE',
                        headers: {
                            Authorization: user.api_key
                        }
                    }
                );
                if (!response.ok) {
                    if (response.status === 404) {
                        throw new Error('Fahrzeug nicht gefunden oder gehört zu anderem Tenant.');
                    }
                    if (response.status === 403) {
                        throw new Error('Nur ein Admin darf Fahrzeuge löschen.');
                    }
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Fehler beim Löschen.');
                }

                // Erfolg
                showSnackbar('Fahrzeug erfolgreich gelöscht.', 'success');
                await fetchVehicles();
            } catch (error) {
                console.error(error);
                showSnackbar(`Löschen fehlgeschlagen: ${error.message}`, 'error');
            }
        }
        setSelectedVehicle(null);
        setDialogAction('');
    };

    // Beispiel: Entfernen der Wache (Menü in StationSplitButton)
    const handleRemoveVehicleFromStation = async (vehicle) => {
        if (!window.confirm(
            `Möchten Sie das Fahrzeug "${vehicle.vehicle_name}" aus der Wache "${vehicle.station_name}" entfernen?`
        )) {
            return;
        }
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URI}/api/vehicles/removeStation`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: user.api_key
                    },
                    body: JSON.stringify({ vehicle_id: vehicle.vehicle_id })
                }
            );
            if (!response.ok) {
                const errData = await response.json();
                throw new Error(errData.error || 'Fehler beim Entfernen.');
            }
            await fetchVehicles();
        } catch (err) {
            alert(err.message);
        }
    };

    // --------------------------------------------
    //        Schicht-Zuordnungen
    // --------------------------------------------
    // eslint-disable-next-line
    const handleRemoveShiftClick = (shift) => {
        setSelectedShiftAssignment(shift);
        setRemoveShiftDialogOpen(true);
    };

    const handleOpenShiftsModal = (allShifts) => {
        setShiftsModalData(allShifts);
        setOpenShiftsModal(true);
    };
    const handleCloseShiftsModal = () => {
        setOpenShiftsModal(false);
    };

    const handleRemoveShiftFromModal = (shift) => {
        setSelectedShiftAssignment(shift);
        setRemoveShiftDialogOpen(true);
        // Modal bleibt offen, wir entfernen nur Eintrag nach Bestätigung
    };

    const confirmRemoveShift = async (yes) => {
        setRemoveShiftDialogOpen(false);
        setOpenShiftsModal(false);
        if (yes && selectedShiftAssignment) {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URI}/api/vehicles/removeShiftAssignment`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: user.api_key
                        },
                        body: JSON.stringify({
                            assignment_id: selectedShiftAssignment.assignment_id
                        })
                    }
                );
                if (!response.ok) {
                    const errData = await response.json();
                    throw new Error(errData.error || 'Fehler beim Löschen der Schicht.');
                }
                showSnackbar('Schichtmodell erfolgreich entfernt', 'success');
                await fetchVehicles();
            } catch (err) {
                showSnackbar(`Entfernen fehlgeschlagen: ${err.message}`, 'error');
            } finally {
                setSelectedShiftAssignment(null);
            }
        } else {
            setSelectedShiftAssignment(null);
        }
    };

    // ADD SHIFT
    const openAddShiftDialog = (vehicle) => {
        setCurrentVehicleForShift(vehicle);
        setShiftSearch('');
        setAddShiftDialogOpen(true);
    };

    const assignShiftToVehicle = async (template) => {
        if (!currentVehicleForShift) return;
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URI}/api/vehicles/addShiftAssignment`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: user.api_key
                    },
                    body: JSON.stringify({
                        vehicle_id: currentVehicleForShift.vehicle_id,
                        template_id: template.template_id
                        // valid_from usw. falls nötig
                    })
                }
            );
            if (!response.ok) {
                const errData = await response.json();
                throw new Error(errData.error || 'Fehler beim Hinzufügen der Schicht.');
            }
            showSnackbar('Schichtmodell erfolgreich hinzugefügt', 'success');
            setAddShiftDialogOpen(false);
            setCurrentVehicleForShift(null);
            await fetchVehicles();
        } catch (err) {
            showSnackbar(`Zuordnung fehlgeschlagen: ${err.message}`, 'error');
        }
    };

    // --------------------------------------------
    //        NEU: Wache hinzufügen (wenn leer)
    // --------------------------------------------
    const openAddStationDialog = (vehicle) => {
        setCurrentVehicleForStation(vehicle);
        setStationSearch('');
        setAddStationDialogOpen(true);
        fetchStations(); // holt sich frische Wachen
    };

    const assignStationToVehicle = async (station) => {
        if (!currentVehicleForStation) return;
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URI}/api/vehicles/assignStation`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: user.api_key
                    },
                    body: JSON.stringify({
                        vehicle_id: currentVehicleForStation.vehicle_id,
                        station_id: station.station_id
                    })
                }
            );
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Fehler bei der Zuordnung');
            }
            // Erfolg → Dialog zu und Liste neu laden
            setAddStationDialogOpen(false);
            setCurrentVehicleForStation(null);
            await fetchVehicles();
        } catch (error) {
            alert(error.message);
        }
    };

    // --------------------------------------------
    //          DataGrid Spalten-Definition
    // --------------------------------------------
    const columns = [
        {
            field: 'vehicle_data',
            headerName: 'Fahrzeugdaten',
            width: 555,
            renderCell: (params) => {
                const vehicle = params.row;
                const imageSrc = vehicle.image_id ? imageCache[vehicle.image_id] : '/default-vehicle.jpg';
                // Status-Info für die Hintergrundfarbe abrufen
                const statusInfo = getVehicleStatusInfo(vehicle.status);

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'stretch',
                            p: 2,
                            m: 1,
                            maxWidth: 360,
                            minHeight: 74,
                            border: '1px solid',
                            borderColor: 'grey.300',
                            borderRadius: 2,
                            boxShadow: 2,
                            backgroundColor: statusInfo.bg, // Status-Hintergrundfarbe anwenden
                            transition: 'box-shadow 0.3s',
                            '&:hover': {
                                boxShadow: 4,
                            },
                        }}
                    >
                        {/* Linker Bereich: Bild als Hintergrund mit Gradient */}
                        <Box
                            sx={{
                                flex: '0 0 120px',
                                position: 'relative',
                                borderRadius: 1,
                                overflow: 'hidden',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Box
                                component="img"
                                src={imageSrc}
                                alt="Fahrzeug"
                                sx={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    objectFit: 'contain', // Behält die Dimensionen des Bildes bei
                                    borderRadius: 1
                                }}
                            />
                        </Box>
                        {/* Rechter Bereich: Fahrzeugdaten */}
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', pl: 2 }}>
                            <Typography variant="subtitle1" fontWeight="bold" color="primary">
                                {vehicle.vehicle_name}
                            </Typography>
                            {vehicle.radio_call_sign && (
                                <Typography variant="caption" color="text.secondary">
                                    Funkrufname: {vehicle.radio_call_sign}
                                </Typography>
                            )}
                            {vehicle.license_plate && (
                                <Typography variant="caption" color="text.secondary">
                                    Kennzeichen: {vehicle.license_plate}
                                </Typography>
                            )}
                            <Typography variant="caption" color="text.secondary">
                                Wache: {vehicle.station_name ? vehicle.station_name : 'keine Wache zugeordnet'}
                            </Typography>
                            {vehicle.station_id !== vehicle.default_station_id && (
                                <Stack>
                                    <Typography variant="caption" color="#e57373">
                                        Stammwache:
                                    </Typography>
                                    <Typography variant="caption" color="#e57373">
                                        {vehicle.default_station_name}
                                    </Typography>
                                </Stack>
                            )}
                        </Box>
                    </Box>
                );
            },
        },
        // Fahrzeugstatus
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                const vehicle = params.row;
                const statusInfo = getVehicleStatusInfo(vehicle.status);

                return (
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%'
                    }}>
                        <Chip
                            label={statusInfo.text}
                            sx={{
                                backgroundColor: statusInfo.bg,
                                color: statusInfo.color,
                                fontWeight: 'bold',
                                minWidth: '120px',
                                '& .MuiChip-label': {
                                    px: 2
                                }
                            }}
                        />
                    </Box>
                );
            }
        },
        {
            field: 'warning',
            headerName: '⚠',
            width: 50,
            renderCell: (params) => {
                const { station_id, default_station_id } = params.row;
                if (station_id !== default_station_id) {
                    return (
                        <Box sx={{
                            display: 'flex', flexWrap: 'wrap', gap: 1,
                            width: '100%', height: '100%',
                            alignItems: 'center', justifyContent: 'flex-start'
                        }}>
                            <Tooltip title="Zugeordnete Wache weicht von der Stammwache ab!" arrow>
                                <WarningIcon color="error" />
                            </Tooltip>
                        </Box>
                    );
                }
                return null;
            }
        },
        {
            field: 'station',
            headerName: 'Zugeordnete Wache',
            width: 460,
            renderCell: (params) => {
                const vehicle = params.row;
                const stationId = vehicle.station_id;
                const stationName = vehicle.station_name;

                // Falls KEINE station_id: "Add-Button"
                if (!stationId) {
                    return (
                        <IconButton
                            color="primary"
                            onClick={(e) => {
                                e.stopPropagation();
                                openAddStationDialog(vehicle);
                            }}
                            title="Wache hinzufügen"
                        >
                            <AddCircleIcon />
                        </IconButton>
                    );
                }

                // Sonst (station_id != null) –> unsere vorhandene StationSplitButton
                return (
                    <Box sx={{
                        display: 'flex', flexWrap: 'wrap', gap: 1,
                        width: '100%', height: '100%',
                        alignItems: 'center', justifyContent: 'flex-start'
                    }}>
                        <StationSplitButton
                            stationName={stationName}
                            stationId={stationId}
                            vehicle={vehicle}
                            onRemoveStation={handleRemoveVehicleFromStation}
                        />
                    </Box>
                );
            },
        },
        {
            field: 'shift_assignments',
            headerName: 'Zugeordnete Schichten',
            width: 400,
            renderCell: (params) => {
                const vehicle = params.row;
                const shifts = vehicle.shift_assignments || [];
                const visible = shifts.slice(0, 3);
                const hiddenCount = shifts.length - 3;

                return (
                    <Box sx={{
                        display: 'flex', flexWrap: 'wrap', gap: 1,
                        width: '100%', height: '100%',
                        alignItems: 'center', justifyContent: 'flex-start'
                    }}>
                        {visible.map((shift) => {
                            const template = shiftTemplatesMap[shift.template_id];
                            const shiftName = template ? template.name : `Shift ${shift.template_id}`;
                            const isHoliday = template && template.holiday;

                            return (
                                <ShiftAssignmentSplitButton
                                    key={shift.assignment_id}
                                    shift={shift}
                                    shiftPlanName={shiftName}
                                    onRemove={(s) => {
                                        setSelectedShiftAssignment(s);
                                        setRemoveShiftDialogOpen(true);
                                    }}
                                    isHoliday={isHoliday}
                                />
                            );
                        })}
                        {hiddenCount > 0 && (
                            <Button variant="text" onClick={() => handleOpenShiftsModal(shifts)}>
                                +{hiddenCount} mehr
                            </Button>
                        )}
                        <IconButton
                            color="primary"
                            onClick={() => openAddShiftDialog(vehicle)}
                            sx={{ marginLeft: 'auto' }}
                            title="Schichtmodell hinzufügen"
                        >
                            <AddCircleIcon />
                        </IconButton>
                    </Box>
                );
            },
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Aktionen',
            width: 140,
            getActions: (params) => {
                const vehicleId = params.row.vehicle_id;
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Bearbeiten"
                        onClick={() => navigate(`/vehiclepage/${vehicleId}`)}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Löschen"
                        onClick={() => {
                            setSelectedVehicle(params.row);
                            setDialogAction('delete');
                            setDialogOpen(true);
                        }}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        icon={<HistoryIcon />}
                        label="Historie anzeigen"
                        onClick={() => handleShowVehicleHistory(params.row)}
                        showInMenu
                    />
                ];
            },
        },
    ];

    // --------------------------------------------
    //              DataGrid Rows
    // --------------------------------------------
    const rows = vehicles.map(v => ({ id: v.vehicle_id, ...v }));
    const filteredRows = rows.filter(r => {
        const text = searchQuery.toLowerCase();
        return (
            r.vehicle_name?.toLowerCase().includes(text) ||
            r.license_plate?.toLowerCase().includes(text)
        );
    });

    return (
        <Box sx={{ mt: 2 }}>
            {serverError && <Alert severity="error">{serverError}</Alert>}
            {isLoading ? (
                <CircularProgress />
            ) : (
                <div style={{ width: '100%' }}>
                    <PersistentDataGrid
                        storageKey="vehiclesList"
                        rows={filteredRows}
                        columns={columns}
                        getRowHeight={() => 'auto'}
                        pageSize={5}
                        rowsPerPageOptions={[5, 10, 20]}
                    />
                </div>
            )}

            {/* Dialog: Fahrzeug löschen */}
            <ConfirmationDialog
                open={dialogOpen}
                title="Fahrzeug löschen?"
                description={selectedVehicle ? `Möchten Sie "${selectedVehicle.vehicle_name}" wirklich löschen?` : ''}
                onClose={handleDialogConfirm}
                onConfirm={handleDialogConfirm}
            />

            {/* Dialog: Schicht entfernen */}
            <ConfirmationDialog
                open={removeShiftDialogOpen}
                title="Schichtmodell entfernen?"
                description={
                    selectedShiftAssignment
                        ? `Möchten Sie dieses Schichtmodell wirklich entfernen?`
                        : ''
                }
                onClose={confirmRemoveShift}
                onConfirm={confirmRemoveShift}
            />

            {/* Modal: Alle Schichtmodelle */}
            <Dialog
                open={openShiftsModal}
                onClose={handleCloseShiftsModal}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>Alle Schichtmodelle</DialogTitle>
                <DialogContent>
                    <List>
                        {shiftsModalData.map((shift) => {
                            const template = shiftTemplatesMap[shift.template_id];
                            const shiftName = template ? template.name : `Shift ${shift.template_id}`;
                            return (
                                <ListItem
                                    key={shift.assignment_id}
                                    button
                                    onClick={() => {
                                        // z.B. zur Detailseite
                                        navigate(`/shiftplanpage/${shift.template_id}`);
                                        setOpenShiftsModal(false);
                                    }}
                                    secondaryAction={(
                                        <IconButton
                                            edge="end"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleRemoveShiftFromModal(shift);
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    )}
                                >
                                    <ListItemText primary={shiftName} />
                                </ListItem>
                            );
                        })}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseShiftsModal}>Schließen</Button>
                </DialogActions>
            </Dialog>

            {/* Dialog: Schichtmodell hinzufügen */}
            <Dialog
                open={addShiftDialogOpen}
                onClose={() => setAddShiftDialogOpen(false)}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>Schichtmodell hinzufügen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Bitte wählen Sie ein Schichtmodell aus.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Schichtmodell suchen"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={shiftSearch}
                        onChange={e => setShiftSearch(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: shiftSearch && (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShiftSearch('')}>
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <List>
                        {allShiftTemplates
                            .filter(t =>
                                t.name.toLowerCase().includes(shiftSearch.toLowerCase())
                            )
                            .map(template => (
                                <ListItem
                                    key={template.template_id}
                                    button
                                    onClick={() => assignShiftToVehicle(template)}
                                >
                                    <ListItemText
                                        primary={template.name}
                                        secondary={template.description}
                                    />
                                </ListItem>
                            ))
                        }
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddShiftDialogOpen(false)}>Abbrechen</Button>
                </DialogActions>
            </Dialog>

            {/* Dialog: Wache hinzufügen (NEU) */}
            <Dialog
                open={addStationDialogOpen}
                onClose={() => setAddStationDialogOpen(false)}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>Wache zuordnen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Bitte wählen Sie eine Wache aus.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Wache suchen"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={stationSearch}
                        onChange={e => setStationSearch(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: stationSearch && (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setStationSearch('')}>
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />

                    <List>
                        {allStations
                            .filter(s =>
                                s.name?.toLowerCase().includes(stationSearch.toLowerCase()) ||
                                s.city?.toLowerCase().includes(stationSearch.toLowerCase())
                            )
                            .map(station => (
                                <ListItem
                                    key={station.station_id}
                                    button
                                    onClick={() => assignStationToVehicle(station)}
                                >
                                    <ListItemText
                                        primary={station.name}
                                        secondary={`${station.street || ''} ${station.house_number || ''}, ${station.postal_code || ''} ${station.city || ''}`}
                                    />
                                </ListItem>
                            ))
                        }
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddStationDialogOpen(false)}>Abbrechen</Button>
                </DialogActions>
            </Dialog>

            {/* Historie-Dialog */}
            {showVehicleHistoryDialog && (
                <VehicleHistoryDialog
                    open={showVehicleHistoryDialog}
                    onClose={handleCloseVehicleHistory}
                    title={vehicleHistoryTitle}
                    entries={vehicleHistoryData}
                />
            )}
        </Box>
    );
};

export default VehiclesList;