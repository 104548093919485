import React, { useState, useEffect, useCallback } from 'react';
import {
    Box,
    Tabs,
    Tab,
    Typography,
    Card,
    CardContent,
    Button,
    Divider,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    Alert,
    AlertTitle,
    CircularProgress,
    Paper
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import { useUser } from '../../components/userContext';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
dayjs.locale('de');

export default function ImportExportPage() {
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useUser();

    // Tabs-State erweitern um "dummy-data"
    const [tabValue, setTabValue] = useState('import');
    const [latestUploads, setLatestUploads] = useState([]);
    // eslint-disable-next-line
    const [pollingActive, setPollingActive] = useState(true);

    // State für Info-Dialoge
    const [openVorhaltungInfo, setOpenVorhaltungInfo] = useState(false);
    const [openInstitutionsInfo, setOpenInstitutionsInfo] = useState(false);

    // State für Exportdateien
    const [exportFiles, setExportFiles] = useState([]);

    // Neue States für Dummy-Daten Tab
    const [dummyCount, setDummyCount] = useState(60);
    const [postalCodePrefix, setPostalCodePrefix] = useState('88');
    const [securityWord, setSecurityWord] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);
    const [generationResult, setGenerationResult] = useState(null);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    // Tabs umschalten
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // Dialog öffnen / schließen
    const handleOpenVorhaltungInfo = () => setOpenVorhaltungInfo(true);
    const handleCloseVorhaltungInfo = () => setOpenVorhaltungInfo(false);

    const handleOpenInstitutionsInfo = () => setOpenInstitutionsInfo(true);
    const handleCloseInstitutionsInfo = () => setOpenInstitutionsInfo(false);

    // File-Upload Funktion
    const uploadFile = async (file, fileCategory) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('file_category', fileCategory);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/file-import`, {
                method: 'POST',
                headers: {
                    Authorization: user.api_key
                },
                body: formData
            });

            if (!response.ok) {
                const errData = await response.json();
                throw new Error(errData.error || 'Fehler beim Hochladen');
            }

            const data = await response.json();
            enqueueSnackbar(`Upload erfolgreich: ${data.filename}`, { variant: 'success' });
        } catch (err) {
            console.error(err);
            enqueueSnackbar(`Upload fehlgeschlagen: ${err.message}`, { variant: 'error' });
        }
    };

    // Reusable DropZone
    const DropZone = ({ onFileAccepted, acceptFileName }) => {
        const onDrop = useCallback(
            (acceptedFiles) => {
                if (!acceptedFiles || acceptedFiles.length === 0) return;
                const file = acceptedFiles[0];
                if (file.name !== acceptFileName) {
                    enqueueSnackbar(`Falscher Dateiname! Erwartet: ${acceptFileName}`, { variant: 'warning' });
                    return;
                }
                onFileAccepted(file);
            },
            // eslint-disable-next-line
            [enqueueSnackbar, onFileAccepted, acceptFileName]
        );

        const { getRootProps, getInputProps, isDragActive } = useDropzone({
            onDrop,
            multiple: false
        });

        return (
            <Box
                {...getRootProps()}
                sx={{
                    border: '2px dashed #aaaaaa',
                    borderRadius: '8px',
                    p: 2,
                    textAlign: 'center',
                    cursor: 'pointer',
                    backgroundColor: isDragActive ? '#eee' : 'transparent'
                }}
            >
                <input {...getInputProps()} />
                {isDragActive ? (
                    <Typography variant="body1">Datei hier ablegen ...</Typography>
                ) : (
                    <Typography variant="body1">
                        Datei hierher ziehen oder zum Auswählen klicken (erwartet: {acceptFileName})
                    </Typography>
                )}
            </Box>
        );
    };

    // API-Aufruf zum Abrufen der exportierten Dateien
    const fetchExportFiles = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/export-files`, {
                method: 'GET',
                headers: {
                    Authorization: user.api_key
                }
            });
            if (!response.ok) {
                throw new Error('Fehler beim Abrufen der Exportdateien');
            }
            const data = await response.json();
            setExportFiles(data.files || []);
        } catch (err) {
            enqueueSnackbar(`Fehler: ${err.message}`, { variant: 'error' });
        }
    };

    useEffect(() => {
        fetchExportFiles();
        // eslint-disable-next-line
    }, []);

    // Neue API-Route aufrufen: /import-files/latest
    const fetchLatestUploads = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/import-files/latest`, {
                method: 'GET',
                headers: {
                    Authorization: user.api_key
                }
            });
            if (!response.ok) {
                throw new Error('Fehler beim Abrufen der letzten Uploads');
            }
            const data = await response.json();
            if (data.status === 'success') {
                setLatestUploads(data.data || []);
            }
        } catch (err) {
            console.error(err);
            enqueueSnackbar(`Fehler beim Laden des Status: ${err.message}`, { variant: 'warning' });
        }
    };

    // Polling Effekt: alle 10s fetchLatestUploads aufrufen
    useEffect(() => {
        if (!pollingActive) return;

        fetchLatestUploads(); // Beim ersten Render sofort
        const intervalId = setInterval(() => {
            fetchLatestUploads();
        }, 10000); // 10 sek.

        return () => clearInterval(intervalId);
        // eslint-disable-next-line
    }, [pollingActive]);

    // Manueller Reload
    const handleManualReload = () => {
        fetchLatestUploads();
    };

    // Card 1: Vorhaltung
    const handleVorhaltungUpload = (file) => {
        uploadFile(file, 'vorhaltung');
    };

    // Card 2: institutions_osm
    const handleInstitutionsUpload = (file) => {
        uploadFile(file, 'institutions_osm');
    };

    // Datei-Download-Funktion
    const handleDownload = async (filename) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/export-files/download/${filename}`, {
                method: 'GET',
                headers: {
                    Authorization: user.api_key
                }
            });
            if (!response.ok) {
                throw new Error('Fehler beim Herunterladen der Datei');
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (err) {
            enqueueSnackbar(`Fehler: ${err.message}`, { variant: 'error' });
        }
    };

    // Funktion zur Formatierung des Dateinamens
    const formatFilename = (filename) => {
        const match = filename.match(/transportliste_\d+_(\d{4})(\d{2})(\d{2})_(\d{2})(\d{2})(\d{2})/);
        if (match) {
            const formattedDate = dayjs(`${match[1]}-${match[2]}-${match[3]}T${match[4]}:${match[5]}:${match[6]}`).format('DD.MM.YYYY HH:mm');
            return `Transportliste - Export vom ${formattedDate}`;
        }
        return filename;
    };

    // NEUE FUNKTIONEN FÜR DUMMY-DATEN-TAB

    // Funktion zur Generierung von Dummy-Daten
    const generateDummyData = async () => {
        if (securityWord.toLowerCase() !== 'dummy') {
            enqueueSnackbar('Bitte geben Sie das Sicherheitswort "dummy" ein, um fortzufahren.', { variant: 'warning' });
            return;
        }

        setIsGenerating(true);
        setGenerationResult(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/transports/dummy`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user.api_key
                },
                body: JSON.stringify({
                    count: dummyCount,
                    postalCodePrefix: postalCodePrefix
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Fehler bei der Generierung der Dummy-Daten');
            }

            const result = await response.json();
            setGenerationResult(result);
            enqueueSnackbar(`${result.message}`, { variant: 'success' });
        } catch (err) {
            console.error(err);
            enqueueSnackbar(`Fehler: ${err.message}`, { variant: 'error' });
        } finally {
            setIsGenerating(false);
            setSecurityWord(''); // Sicherheitswort zurücksetzen
        }
    };

    // Dialog zur Bestätigung der Dummy-Daten-Generierung
    const handleOpenConfirmDialog = () => {
        if (securityWord.toLowerCase() !== 'dummy') {
            enqueueSnackbar('Bitte geben Sie das Sicherheitswort "dummy" ein, um fortzufahren.', { variant: 'warning' });
            return;
        }

        if (dummyCount < 1 || dummyCount > 300) {
            enqueueSnackbar('Die Anzahl muss zwischen 1 und 300 liegen.', { variant: 'warning' });
            return;
        }

        setOpenConfirmDialog(true);
    };

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
    };

    const handleConfirmGenerate = () => {
        setOpenConfirmDialog(false);
        generateDummyData();
    };

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h4" sx={{ mb: 2 }}>
                Import / Export
            </Typography>

            {/* TABS: "Import" | "Export" | "Dummy-Daten" */}
            <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label="Import" value="import" />
                <Tab label="Export" value="export" />
                <Tab label="Dummy-Daten" value="dummy-data" />
            </Tabs>

            {tabValue === 'import' && (
                <Box sx={{ mt: 2 }}>
                    {/* CARD 1: Vorhaltung */}
                    <Card variant="outlined" sx={{ mb: 3 }}>
                        <CardContent>
                            {/* Titelzeile: Überschrift + Info-Button */}
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="h6" gutterBottom>
                                    Import der Datei vorhaltung.csv
                                </Typography>
                                <IconButton onClick={handleOpenVorhaltungInfo}>
                                    <InfoIcon />
                                </IconButton>
                            </Box>

                            <Typography variant="body2" paragraph>
                                Import der Vorhaltung im CSV Format. Vorsicht: Die CSV-Datei muss genau der Spezifikation
                                entsprechen und <strong>vorhaltung.csv</strong> heißen.
                            </Typography>

                            {/* Drag & Drop Feld */}
                            <DropZone onFileAccepted={handleVorhaltungUpload} acceptFileName="vorhaltung.csv" />

                            {/* Fallback-Button */}
                            <Box sx={{ mt: 2 }}>
                                <Button
                                    variant="contained"
                                    component="label"
                                    color="primary"
                                >
                                    Datei auswählen
                                    <input
                                        hidden
                                        type="file"
                                        onChange={(e) => {
                                            const file = e.target.files[0];
                                            if (file) {
                                                if (file.name !== 'vorhaltung.csv') {
                                                    enqueueSnackbar('Falscher Dateiname! Erwartet: vorhaltung.csv', { variant: 'warning' });
                                                    return;
                                                }
                                                handleVorhaltungUpload(file);
                                            }
                                        }}
                                    />
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>

                    {/* CARD 2: institutions_osm */}
                    <Card variant="outlined" sx={{ mb: 3 }}>
                        <CardContent>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="h6" gutterBottom>
                                    Import der Datei institutions_osm.csv
                                </Typography>
                                <IconButton onClick={handleOpenInstitutionsInfo}>
                                    <InfoIcon />
                                </IconButton>
                            </Box>

                            <Typography variant="body2" paragraph>
                                Import der Objekte aus OpenStreetMap im CSV Format. Die Datei muss exakt
                                <strong> institutions_osm.csv</strong> heißen.
                            </Typography>

                            {/* Drag & Drop Feld */}
                            <DropZone
                                onFileAccepted={handleInstitutionsUpload}
                                acceptFileName="institutions_osm.csv"
                            />

                            {/* Fallback-Button */}
                            <Box sx={{ mt: 2 }}>
                                <Button
                                    variant="contained"
                                    component="label"
                                    color="primary"
                                >
                                    Datei auswählen
                                    <input
                                        hidden
                                        type="file"
                                        onChange={(e) => {
                                            const file = e.target.files[0];
                                            if (file) {
                                                if (file.name !== 'institutions_osm.csv') {
                                                    enqueueSnackbar('Falscher Dateiname! Erwartet: institutions_osm.csv', { variant: 'warning' });
                                                    return;
                                                }
                                                handleInstitutionsUpload(file);
                                            }
                                        }}
                                    />
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>

                    {/* CARD 3: Status/Log */}
                    <Card variant="outlined">
                        <CardContent>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="h6">Status-Bericht (letzte 5 Uploads)</Typography>
                                <Button variant="outlined" onClick={handleManualReload}>Neu laden</Button>
                            </Box>

                            <Divider sx={{ my: 2 }} />

                            {latestUploads.length === 0 ? (
                                <Typography variant="body2" color="text.secondary">
                                    Keine Uploads vorhanden.
                                </Typography>
                            ) : (
                                <List dense>
                                    {latestUploads.map((item) => (
                                        <ListItem key={item.import_files_id} sx={{ mb: 1 }}>
                                            <ListItemText
                                                primary={`
                          [${item.import_status.toUpperCase()}] 
                          ${item.filename} (${item.file_category})
                        `}
                                                secondary={`
                          ${dayjs(item.create_date).format('DD.MM.YYYY HH:mm:ss')} 
                          - Log: ${item.import_log || 'Kein Log-Eintrag'}
                        `}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            )}
                        </CardContent>
                    </Card>
                </Box>
            )}

            {tabValue === 'export' && (
                <Box sx={{ mt: 2 }}>
                    <Typography variant="h6">Exportierte Dateien</Typography>
                    <Divider sx={{ my: 2 }} />
                    {exportFiles.length === 0 ? (
                        <Typography variant="body2" color="text.secondary">
                            Keine Exportdateien verfügbar.
                        </Typography>
                    ) : (
                        <List>
                            {exportFiles.map((file, index) => (
                                <ListItem key={index} sx={{ mb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <ListItemText
                                        primary={formatFilename(file.filename)}
                                        secondary={<Typography variant="caption" color="textSecondary">{file.filename}</Typography>}
                                    />
                                    <Button variant="contained" onClick={() => handleDownload(file.filename)}>
                                        DOWNLOAD
                                    </Button>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Box>
            )}

            {/* NEUER TAB: Dummy-Daten */}
            {tabValue === 'dummy-data' && (
                <Box sx={{ mt: 2 }}>
                    <Card variant="outlined">
                        <CardContent>
                            <Box display="flex" alignItems="center" mb={2}>
                                <WarningIcon color="warning" sx={{ mr: 1 }} />
                                <Typography variant="h6">
                                    Erzeugung von Dummy-Transportdaten
                                </Typography>
                            </Box>

                            <Alert severity="warning" sx={{ mb: 3 }}>
                                <AlertTitle>Achtung! Testdaten-Generator</AlertTitle>
                                <Typography variant="body2">
                                    Sie erstellen hiermit Testdaten in Ihrer Datenbank. Diese Test-Transporte werden in den Transportlisten
                                    erscheinen und können normal bearbeitet werden. Bitte verwenden Sie diese Funktion NUR in Test- und
                                    Schulungsumgebungen, niemals in Produktivsystemen mit Echtdaten.
                                </Typography>
                            </Alert>

                            <Box display="flex" flexDirection="column" gap={3} mb={4}>
                                <TextField
                                    label="Anzahl der Transporte"
                                    type="number"
                                    value={dummyCount}
                                    onChange={(e) => setDummyCount(Math.min(Math.max(parseInt(e.target.value) || 0, 1), 300))}
                                    helperText="Bitte geben Sie eine Zahl zwischen 1 und 300 ein."
                                    InputProps={{ inputProps: { min: 1, max: 300 } }}
                                    fullWidth
                                />

                                <TextField
                                    label="Postleitzahlen-Bereich (Präfix)"
                                    value={postalCodePrefix}
                                    onChange={(e) => setPostalCodePrefix(e.target.value)}
                                    helperText="Die ersten Ziffern der Postleitzahl, z.B. '88' für den Bereich 88xxx"
                                    fullWidth
                                />

                                <TextField
                                    label="Sicherheitswort eingeben"
                                    value={securityWord}
                                    onChange={(e) => setSecurityWord(e.target.value)}
                                    helperText="Geben Sie 'dummy' ein, um die Generierung zu bestätigen"
                                    error={securityWord !== '' && securityWord.toLowerCase() !== 'dummy'}
                                    fullWidth
                                />
                            </Box>

                            <Button
                                variant="contained"
                                color="warning"
                                size="large"
                                fullWidth
                                startIcon={isGenerating ? <CircularProgress size={24} color="inherit" /> : null}
                                onClick={handleOpenConfirmDialog}
                                disabled={isGenerating}
                            >
                                {isGenerating ? 'Generiere Dummy-Daten...' : 'Dummy-Daten erzeugen'}
                            </Button>

                            {generationResult && (
                                <Paper elevation={3} sx={{ mt: 4, p: 3, bgcolor: '#f5f5f5' }}>
                                    <Typography variant="h6" gutterBottom>
                                        Ergebnis der Dummy-Daten-Generierung
                                    </Typography>
                                    <Divider sx={{ mb: 2 }} />

                                    <Typography variant="body1" fontWeight="bold" color="success.main" paragraph>
                                        {generationResult.message}
                                    </Typography>

                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Statistik:
                                        </Typography>
                                        <List dense>
                                            <ListItem>
                                                <ListItemText primary={`Transporte für heute: ${generationResult.details?.today || 0}`} />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText primary={`Transporte für morgen: ${generationResult.details?.tomorrow || 0}`} />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText primary={`Verwendetes PLZ-Präfix: ${generationResult.details?.postal_code_prefix || postalCodePrefix}`} />
                                            </ListItem>
                                        </List>
                                    </Box>

                                    <Typography variant="body2" color="text.secondary">
                                        Die erzeugten Transporte wurden mit Ihrer Benutzer-ID und Mandanten-ID erstellt und sind sofort in der Transportliste verfügbar.
                                    </Typography>
                                </Paper>
                            )}
                        </CardContent>
                    </Card>
                </Box>
            )}

            {/* DIALOG: Vorhaltung-Info */}
            <Dialog open={openVorhaltungInfo} onClose={handleCloseVorhaltungInfo} maxWidth="md" fullWidth>
                <DialogTitle>Dokumentation: vorhaltung.csv</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText sx={{ mb: 2 }}>
                        Dieses CSV dient dem Import der Vorhaltungen. Die Datei muss exakt <strong>vorhaltung.csv</strong> heißen.
                        Sie muss folgende Spalten (Header) enthalten:
                    </DialogContentText>
                    <Box>
                        <Typography variant="body2">
                            <ul>
                                <li><code>ID</code></li>
                                <li><code>LK</code></li>
                                <li><code>Standort</code></li>
                                <li><code>Betreiber</code></li>
                                <li><code>RM</code></li>
                                <li><code>Schichtbezeichnung org. intern</code></li>
                                <li><code>gültig ab</code></li>
                                <li><code>gültig bis</code></li>
                                <li><code>Zeit von</code></li>
                                <li><code>Zeit bis</code></li>
                                <li><code>Pause</code></li>
                                <li><code>Std. tgl.</code></li>
                                <li><code>Mo Werktag gerade</code> usw. (alle Spalten für Mo/Di/Mi/Do/Fr/Sa/So ... gerade/ungerade/Feiertag)</li>
                                <li><code>Besonderheiten</code></li>
                                <li><code>Text Vorhaltung</code></li>
                            </ul>
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 2 }}>
                            Hinweis: Wenn diese Reihenfolge oder Spaltennamen nicht exakt passen, wird der Import abgebrochen (status = rejected).
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseVorhaltungInfo} variant="contained" color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>

            {/* DIALOG: Institutions OSM-Info */}
            <Dialog open={openInstitutionsInfo} onClose={handleCloseInstitutionsInfo} maxWidth="md" fullWidth>
                <DialogTitle>Dokumentation: institutions_osm.csv</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText sx={{ mb: 2 }}>
                        Dieses CSV dient dem Import von Objekten aus OpenStreetMap. Die Datei muss exakt <strong>institutions_osm.csv</strong> heißen.
                        Sie muss folgende Spalten (Header) enthalten:
                    </DialogContentText>
                    <Box>
                        <Typography variant="body2">
                            <ul>
                                <li><code>osm_id</code></li>
                                <li><code>name</code></li>
                                <li><code>street</code></li>
                                <li><code>number</code></li>
                                <li><code>postal_code</code></li>
                                <li><code>city</code></li>
                                <li><code>operator</code></li>
                                <li><code>phone_number</code></li>
                                <li><code>usecase</code></li>
                                <li><code>type</code></li>
                            </ul>
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 2 }}>
                            Hinweis: Bei Abweichungen in der Schreibweise oder Reihenfolge wird der Import abgelehnt.
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseInstitutionsInfo} variant="contained" color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>

            {/* DIALOG: Bestätigung für Dummy-Daten-Generierung */}
            <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <WarningIcon color="warning" sx={{ mr: 1 }} />
                        Dummy-Daten erzeugen bestätigen
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Sind Sie sicher, dass Sie {dummyCount} Dummy-Transporte mit dem PLZ-Präfix "{postalCodePrefix}" erstellen möchten?
                        Dieser Vorgang kann nicht rückgängig gemacht werden und die Transporte erscheinen in den regulären Transportlisten.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmDialog} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={handleConfirmGenerate} color="warning" variant="contained">
                        Ja, Dummy-Daten erzeugen
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}