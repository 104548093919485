// src/components/ShiftAssignmentSplitButton.js
import React, { useState } from 'react';
import {
    Box,
    Button,
    Menu,
    MenuItem
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import HolidayIcon from '@mui/icons-material/Celebration';
import { useNavigate } from 'react-router-dom';

/**
 * shift: { assignment_id, template_id, ... }
 * shiftPlanName: string (z.B. 'Tagdienst')
 * onRemove: (shift) => void  // Callback zum Entfernen
 * isHoliday: boolean // Flag, ob es ein Feiertags-Schichtplan ist
 * customStyles: object // Optional: Zusätzliche Styles für den Button
 */
const ShiftAssignmentSplitButton = ({ shift, shiftPlanName, onRemove, isHoliday = false, customStyles = {} }) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);

    // Öffnet das Menü
    const handleButtonClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    // Schließt das Menü
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    // „Schichtmodell bearbeiten" → Navigiert zum Shift-Template
    const handleEditShift = () => {
        handleMenuClose();
        navigate(`/shiftplanpage/${shift.template_id}`);
    };

    // „Schichtmodell entfernen" → ruft onRemove(shift) auf
    const handleRemove = () => {
        handleMenuClose();
        onRemove(shift);
    };

    // Style-Anpassungen für Feiertage
    const holidayStyles = isHoliday ? {
        backgroundColor: '#ffebee',
        borderColor: '#ffcdd2',
        color: '#d32f2f',
        '&:hover': {
            backgroundColor: '#ffcdd2',
            borderColor: '#e57373'
        }
    } : {};

    return (
        <Box sx={{ mr: 1, mb: 1, display: 'inline-block' }}>
            <Button
                variant="outlined"
                size="small"
                onClick={handleButtonClick}
                endIcon={<MoreVertIcon />}
                sx={{
                    textTransform: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    ...holidayStyles,
                    ...customStyles
                }}
            >
                {isHoliday && (
                    <HolidayIcon fontSize="small" sx={{ mr: 0.5, fontSize: '1rem' }} />
                )}
                {shiftPlanName}
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClick={handleMenuClose}
            >
                <MenuItem onClick={handleEditShift}>
                    <EditIcon fontSize="small" sx={{ mr: 1 }} />
                    Schichtmodell bearbeiten
                </MenuItem>
                <MenuItem onClick={handleRemove}>
                    <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
                    Schichtmodell aus Fahrzeug entfernen
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default ShiftAssignmentSplitButton;