import React, { useState } from 'react';
import {
    Button, TextField, Container, Typography, Alert, Box, Link,
    Grid, FormControl, Card, CardContent, Divider
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { isPasswordValid } from '../misc/helperFunctions';
import { Autocomplete } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';

// Kompakte Version der Institution-Autocomplete-Komponente
const RegistrationInstitutionAutocomplete = ({ value, onChange, label = "Institution", variant = "outlined", error, helperText, ...otherProps }) => {
    const [options, setOptions] = useState([]);
    // eslint-disable-next-line
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const DUMMY_API_KEY = 'registration-temp-key';

    const fetchInstitutions = async (start, limit, searchTerm) => {
        try {
            if (searchTerm && searchTerm.length > 0) {
                const response = await fetch(`${process.env.REACT_APP_API_URI}/api/alllazyinstitutions?start=${start}&limit=${limit}&searchTerm=${searchTerm}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': DUMMY_API_KEY,
                    },
                });

                if (!response.ok) {
                    console.error('Institution search failed:', await response.text());
                    return;
                }

                const data = await response.json();
                if (Array.isArray(data)) {
                    setOptions(data);
                } else {
                    setOptions([]);
                }
            }
        } catch (error) {
            console.error('Error fetching institutions:', error);
        }
    };

    return (
        <Autocomplete
            value={value}
            onChange={onChange}
            onOpen={() => {
                setOpen(true);
                fetchInstitutions(0, 50, searchTerm);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onInputChange={(event, newInputValue) => {
                setSearchTerm(newInputValue);
                fetchInstitutions(0, 50, newInputValue);
            }}
            options={options}
            filterOptions={(options) => options}
            isOptionEqualToValue={(option, value) => option?.institution_id === value?.institution_id}
            getOptionLabel={(option) => {
                if (!option) return '';
                let label = option.object_institution || 'Unbekannt';
                if (option.station) {
                    label += ` - Station: ${option.station}`;
                }
                return label;
            }}
            renderOption={(props, option) => (
                <li {...props} key={option.institution_id || option.object_institution}>
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>
                                {option.object_institution || 'Unbekannt'}
                                {option.station ? ` - Station: ${option.station}` : ''}
                            </div>
                            <div style={{ fontSize: '0.8rem', color: 'gray' }}>
                                {option.street_and_number || 'Unbekannt'} -
                                {option.city || 'Unbekannt'}
                            </div>
                        </div>
                    </div>
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant={variant}
                    error={error}
                    helperText={helperText}
                    {...otherProps}
                />
            )}
        />
    );
};

const RegistrationPage = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        institution_id: '',
    });

    const [selectedInstitution, setSelectedInstitution] = useState(null);
    const navigate = useNavigate();
    const [serverError, setServerError] = useState('');
    const [errors, setErrors] = useState({});

    const handleChange = (event) => {
        const { name, value } = event.target;
        const lowercaseValue = name === 'email' ? value.toLowerCase() : value;

        setFormData((prevData) => ({
            ...prevData,
            [name]: lowercaseValue,
        }));

        handleFieldValidation(name, lowercaseValue);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        let newErrors = {};

        if (!validateEmail(formData.email)) {
            newErrors.email = "Ungültige E-Mail-Adresse";
        }

        if (!formData.password) newErrors.password = 'Passwort ist erforderlich';
        else if (!isPasswordValid(formData.password)) {
            newErrors.password = 'Passwort muss mindestens 6 Zeichen lang sein, einen Großbuchstaben und ein Sonderzeichen enthalten';
        }

        if (!formData.confirmPassword) newErrors.confirmPassword = 'Passwortbestätigung ist erforderlich';

        if (formData.password && formData.confirmPassword && formData.password !== formData.confirmPassword) {
            newErrors.confirmPassword = 'Passwörter stimmen nicht überein';
        }

        if (!formData.institution_id) newErrors.institution_id = 'Bitte wählen Sie eine Einrichtung aus';

        setErrors(newErrors);
        setServerError('');

        const isValid = Object.keys(newErrors).length === 0;

        if (isValid) {
            const userExists = await checkUserExists(formData.email);
            if (userExists === true) {
                setServerError('Benutzer mit dieser E-Mail-Adresse existiert bereits.');
            } else if (userExists === false) {
                await createUser(formData.institution_id);
            }
        }
    };

    const checkUserExists = async (username) => {
        const url = process.env.REACT_APP_API_URI + `/api/users/${username}`;
        try {
            const response = await fetch(url);
            if (response.ok) {
                const data = await response.json();
                if (data) {
                    return true; // User exists
                }
            } else if (response.status === 404) {
                return false; // User not found
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message);
            }
        } catch (error) {
            console.error('Error checking user:', error.message);
            setServerError(error.message);
            return null; // Error state
        }
    };

    const createUser = async (institutionId) => {
        const userUrl = process.env.REACT_APP_API_URI + '/api/users';
        const userData = {
            username: formData.email,
            institution_id: institutionId,
            registration_status: 'in Bearbeitung',
            password: formData.password,
            account_activated: false,
            role: 'user',
        };

        try {
            const userResponse = await fetch(userUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });

            if (!userResponse.ok) {
                const userErrorData = await userResponse.json();
                throw new Error(userErrorData.message);
            }

            const userResult = await userResponse.json();
            console.log('New user created:', userResult);

            navigate('/confirmation');

        } catch (error) {
            console.error('Error creating user:', error.message);
            setServerError(error.message);
        }
    };

    const handleFieldValidation = (name, value) => {
        let newErrors = { ...errors };

        switch (name) {
            case 'email':
                newErrors.email = validateEmail(value) ? '' : "Ungültige E-Mail-Adresse";
                break;

            case 'password':
                if (!value) {
                    newErrors.password = 'Passwort ist erforderlich';
                } else {
                    newErrors.password = isPasswordValid(value)
                        ? ''
                        : 'Passwort muss mindestens 6 Zeichen lang sein, einen Großbuchstaben und ein Sonderzeichen enthalten';
                }
                break;

            case 'confirmPassword':
                newErrors.confirmPassword =
                    formData.password === value ? '' : 'Passwörter stimmen nicht überein';
                break;

            case 'institution_id':
                newErrors.institution_id = value ? '' : 'Bitte wählen Sie eine Einrichtung aus';
                break;

            default:
                break;
        }

        setErrors(newErrors);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return emailRegex.test(email);
    };

    return (

        <Container maxWidth="lg" sx={{ py: 5 }}>
            {/* Willkommenskarte */}
            <Card
                elevation={2}
                sx={{
                    mb: 4,
                    borderRadius: '16px',
                    overflow: 'hidden'
                }}
            >
                {/* Farbiger Balken am oberen Rand */}
                <Box sx={{ height: '8px', bgcolor: '#E46450' }} />

                <CardContent sx={{ p: 3 }}>
                    <Box sx={{ textAlign: 'center', mb: 3 }}>
                        <Typography variant="h4" fontWeight="bold" color="#E46450" gutterBottom>
                            Willkommen bei KTW-Anmeldung.de!
                        </Typography>
                        <Typography variant="body1" color="text.secondary" sx={{ maxWidth: '800px', mx: 'auto' }}>
                            Hier finden Sie alle wichtigen Informationen zu unserem Krankentransportdienst.
                        </Typography>
                    </Box>

                    <Grid container spacing={4} sx={{ mt: 2 }}>
                        <Grid item xs={12} md={4}>
                            <Box sx={{
                                p: 2,
                                height: '100%',
                                borderRadius: '12px',
                                bgcolor: 'rgba(228, 100, 80, 0.05)',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center'
                            }}>
                                <Box sx={{
                                    width: '60px',
                                    height: '60px',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    bgcolor: 'rgba(228, 100, 80, 0.1)',
                                    mb: 2
                                }}>
                                    <InfoOutlinedIcon sx={{ fontSize: '30px', color: '#E46450' }} />
                                </Box>
                                <Typography variant="h6" gutterBottom fontWeight="medium">Servicezeiten</Typography>
                                <Typography>
                                    Unser regulärer Fahrdienst steht Ihnen von <strong>07:00 bis 17:00 Uhr</strong> zur Verfügung.
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box sx={{
                                p: 2,
                                height: '100%',
                                borderRadius: '12px',
                                bgcolor: 'rgba(228, 100, 80, 0.05)',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center'
                            }}>
                                <Box sx={{
                                    width: '60px',
                                    height: '60px',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    bgcolor: 'rgba(228, 100, 80, 0.1)',
                                    mb: 2
                                }}>
                                    <InfoOutlinedIcon sx={{ fontSize: '30px', color: '#E46450' }} />
                                </Box>
                                <Typography variant="h6" gutterBottom fontWeight="medium">Gewichtslimit</Typography>
                                <Typography>
                                    Bitte beachten Sie, dass unser Service ausschließlich für Patienten mit einem <strong>Gewicht unter 150 Kg</strong> vorgesehen ist.
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box sx={{
                                p: 2,
                                height: '100%',
                                borderRadius: '12px',
                                bgcolor: 'rgba(228, 100, 80, 0.05)',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center'
                            }}>
                                <Box sx={{
                                    width: '60px',
                                    height: '60px',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    bgcolor: 'rgba(228, 100, 80, 0.1)',
                                    mb: 2
                                }}>
                                    <DoneOutlineOutlinedIcon sx={{ fontSize: '30px', color: '#E46450' }} />
                                </Box>
                                <Typography variant="h6" gutterBottom fontWeight="medium">Online Verwaltung</Typography>
                                <Typography>
                                    Sie haben die Möglichkeit, Ihre eigenen Bestellungen <strong>online</strong> anzulegen und zu verwalten.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box sx={{
                        mt: 6,
                        p: 2,
                        bgcolor: 'rgba(228, 100, 80, 0.08)',
                        borderRadius: '8px',
                        textAlign: 'center'
                    }}>
                        <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                            Registrieren Sie sich in dem unten stehenden Formular.
                            Nachdem Sie die Registrierung durchgeführt haben,
                            wird diese von der Leitstelle geprüft und freigegeben.
                        </Typography>
                    </Box>
                </CardContent>
            </Card>

            {/* Registrierungsformular */}
            <Card
                elevation={2}
                sx={{
                    borderRadius: '16px',
                    overflow: 'hidden'
                }}
            >
                {/* Farbiger Balken am oberen Rand */}
                <Box sx={{ height: '8px', bgcolor: '#E46450' }} />

                <CardContent sx={{ p: { xs: 2, sm: 4 } }}>
                    <Typography
                        variant="h5"
                        gutterBottom
                        textAlign="center"
                        fontWeight="500"
                        color="#E46450"
                        sx={{ mb: 4 }}
                    >
                        Registrierung
                    </Typography>

                    {errors && Object.values(errors).filter(Boolean).join(', ') &&
                        <Alert
                            severity="error"
                            sx={{
                                my: 2,
                                borderRadius: '8px',
                                '& .MuiAlert-icon': { alignItems: 'center' }
                            }}
                        >
                            {Object.values(errors).filter(Boolean).join(', ')}
                        </Alert>
                    }

                    {typeof serverError === 'string' && serverError &&
                        <Alert
                            severity="error"
                            sx={{
                                my: 2,
                                borderRadius: '8px',
                                '& .MuiAlert-icon': { alignItems: 'center' }
                            }}
                        >
                            {serverError}
                        </Alert>
                    }

                    <Box component="form" onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            {/* Accountinformationen */}
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mb: 1,
                                    gap: 1
                                }}>
                                    <EmailOutlinedIcon color="primary" />
                                    <Typography variant="h6" color="primary" fontWeight="500">
                                        Account Informationen
                                    </Typography>
                                </Box>
                                <Divider sx={{ mb: 2 }} />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Benutzername (Mailadresse)"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    error={!!errors.email}
                                    helperText={errors.email}
                                    InputProps={{
                                        sx: { borderRadius: '8px' }
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                {/* Leere Spalte für Layout-Balance */}
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Passwort"
                                    name="password"
                                    type="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    error={!!errors.password}
                                    helperText={errors.password}
                                    InputProps={{
                                        sx: { borderRadius: '8px' }
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Passwort bestätigen"
                                    name="confirmPassword"
                                    type="password"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    error={!!errors.confirmPassword}
                                    helperText={errors.confirmPassword}
                                    InputProps={{
                                        sx: { borderRadius: '8px' }
                                    }}
                                />
                            </Grid>

                            {/* Einrichtungsinformationen */}
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mb: 1,
                                    gap: 1
                                }}>
                                    <BusinessOutlinedIcon color="primary" />
                                    <Typography variant="h6" color="primary" fontWeight="500">
                                        Einrichtungsinformationen
                                    </Typography>
                                </Box>
                                <Divider sx={{ mb: 2 }} />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth error={!!errors.institution_id}>
                                    <RegistrationInstitutionAutocomplete
                                        value={selectedInstitution}
                                        onChange={(event, newValue) => {
                                            setSelectedInstitution(newValue);
                                            const institutionId = newValue ? newValue.institution_id : '';
                                            setFormData((prevData) => ({
                                                ...prevData,
                                                institution_id: institutionId,
                                            }));
                                            handleFieldValidation('institution_id', institutionId);
                                        }}
                                        label="Institution eingeben (Klinik, Alten- oder Pflegeheim, Arztpraxis,...)"
                                        error={!!errors.institution_id}
                                        helperText={errors.institution_id}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '8px'
                                            }
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        {/* Submit Button */}
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                startIcon={<LockOutlinedIcon />}
                                sx={{
                                    minWidth: '200px',
                                    borderRadius: '30px',
                                    py: 1.5,
                                    bgcolor: '#E46450',
                                    '&:hover': {
                                        bgcolor: '#d13b27',
                                        boxShadow: '0 6px 15px rgba(228, 100, 80, 0.4)',
                                    },
                                    boxShadow: '0 4px 10px rgba(228, 100, 80, 0.3)',
                                    textTransform: 'none',
                                    fontSize: '1rem'
                                }}
                            >
                                Registrieren
                            </Button>
                        </Box>

                        {/* Login Link */}
                        <Box sx={{ mt: 4, textAlign: 'center' }}>
                            <Typography variant="body1">
                                Haben Sie schon einen Zugang?{' '}
                                <Link href="/login" sx={{ color: '#E46450', textDecoration: 'none', fontWeight: 'medium' }}>
                                    Zur Anmeldung
                                </Link>
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
            </Card>

        </Container>
    );
};

export default RegistrationPage;