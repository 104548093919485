// src/pages/shifts/ShiftPlanList.js
import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    IconButton,
    CircularProgress,
    Typography,
    TextField,
    InputAdornment,
    Alert,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
    Menu,
    MenuItem,
    Chip
} from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HolidayIcon from '@mui/icons-material/Celebration';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../components/userContext';
import PersistentDataGrid from '../../components/PersistentDataGrid';
import { useSnackbar } from 'notistack';

const WEEKDAY_LABELS = {
    0: 'Sonntag',
    1: 'Montag',
    2: 'Dienstag',
    3: 'Mittwoch',
    4: 'Donnerstag',
    5: 'Freitag',
    6: 'Samstag'
};

function formatTime(timeStr) {
    if (!timeStr) return '';
    const [hh, mm] = timeStr.split(':');
    return `${hh}:${mm} Uhr`;
}

function formatBreakDuration(breakStr) {
    if (!breakStr) return '';
    const [hh, mm] = breakStr.split(':').map(Number);
    const totalMinutes = hh * 60 + mm;
    return `${totalMinutes} Min Pause`;
}

const ConfirmationDialog = ({ open, onClose, onConfirm, title, description }) => (
    <Dialog open={open} onClose={() => onClose(false)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <DialogContentText>{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => onClose(false)}>Abbrechen</Button>
            <Button onClick={() => onConfirm(true)} color="primary">Ja</Button>
        </DialogActions>
    </Dialog>
);

const ShiftPlanList = ({ globalSearch }) => {
    const [shiftTemplates, setShiftTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [serverError, setServerError] = useState(null);

    // Dialog zum Bestätigen von "Schichtplan löschen"
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [dialogAction, setDialogAction] = useState('');

    // Suche
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const { user } = useUser();

    // 1) Speichert pro Template-ID eine Liste aller zugeordneten Fahrzeuge
    const [shiftVehiclesMap, setShiftVehiclesMap] = useState({});
    // 2) Dialog "Fahrzeug hinzufügen"
    const [isAddVehicleDialogOpen, setIsAddVehicleDialogOpen] = useState(false);
    const [addVehicleSearch, setAddVehicleSearch] = useState('');
    const [allVehicles, setAllVehicles] = useState([]);
    const [currentTemplateForVehicle, setCurrentTemplateForVehicle] = useState(null);

    // 3) Modal "Alle Fahrzeuge" (wenn mehr als 3)
    const [openVehiclesModal, setOpenVehiclesModal] = useState(false);
    const [vehiclesModalData, setVehiclesModalData] = useState([]);

    // 4) Fahrzeug-aus-Schicht-entfernen-Dialog
    const [removeVehicleDialogOpen, setRemoveVehicleDialogOpen] = useState(false);
    const [selectedVehicleAssignment, setSelectedVehicleAssignment] = useState(null);

    // 5) Snackbar
    const { enqueueSnackbar } = useSnackbar();
    const showSnackbar = (message, variant) => {
        enqueueSnackbar(message, { variant: variant || 'default' });
    };

    useEffect(() => {
        fetchShiftTemplates();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setSearchQuery(globalSearch || '');
    }, [globalSearch]);

    const fetchShiftTemplates = async () => {
        try {
            setIsLoading(true);
            setServerError(null);

            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/shift-templates`, {
                headers: {
                    Authorization: user.api_key,
                },
            });
            if (!response.ok) {
                throw new Error('Fehler beim Laden der Schichtpläne');
            }
            const data = await response.json();
            setShiftTemplates(data);

            // Fahrzeuge pro Template laden
            data.forEach((tmpl) => {
                loadVehiclesForTemplate(tmpl.template_id);
            });
        } catch (error) {
            setServerError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const loadVehiclesForTemplate = async (templateId) => {
        try {
            const resp = await fetch(
                `${process.env.REACT_APP_API_URI}/api/shift-templates/${templateId}/vehicles`,
                { headers: { Authorization: user.api_key } }
            );
            if (!resp.ok) {
                console.warn(`Konnte Fahrzeuge für Template ${templateId} nicht laden`);
                return;
            }
            const vehiclesForThisTemplate = await resp.json();
            setShiftVehiclesMap(prev => ({
                ...prev,
                [templateId]: vehiclesForThisTemplate
            }));
        } catch (err) {
            console.error(err);
        }
    };

    // Template löschen – existierte bereits
    const handleDeleteTemplate = async (id) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URI}/api/shift-templates/${id}`,
                {
                    method: 'DELETE',
                    headers: {
                        Authorization: user.api_key
                    }
                }
            );
            if (!response.ok) {
                if (response.status === 404) {
                    throw new Error('Schichtplan nicht gefunden oder gehört zu anderem Tenant');
                }
                if (response.status === 403) {
                    throw new Error('Nur Admins dürfen Schichtpläne löschen');
                }
                const errorData = await response.json();
                throw new Error(errorData.error || 'Fehler beim Löschen');
            }

            // Erfolg
            showSnackbar('Schichtplan erfolgreich gelöscht', 'success');
            fetchShiftTemplates();  // Liste neu laden
        } catch (err) {
            console.error(err);
            showSnackbar(`Löschen fehlgeschlagen: ${err.message}`, 'error');
        }
    };

    const handleEditTemplate = (id) => {
        navigate(`/shiftplanpage/${id}`);
    };

    // Dialog-Bestätigung "Schichtplan löschen"
    const handleDialogConfirm = async (yes) => {
        setDialogOpen(false);
        if (yes && dialogAction === 'delete' && selectedTemplate) {
            await handleDeleteTemplate(selectedTemplate.template_id);
            fetchShiftTemplates();
        }
        setSelectedTemplate(null);
        setDialogAction('');
    };

    // ----------------------------------------
    // Fahrzeug aus Schichtplan entfernen
    // ----------------------------------------
    const handleRemoveVehicleFromShift = async (assignment) => {
        // assignment enthält assignment_id und template_id
        if (!assignment) return;
        try {
            const resp = await fetch(
                `${process.env.REACT_APP_API_URI}/api/shift-templates/removeVehicle`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: user.api_key
                    },
                    body: JSON.stringify({ assignment_id: assignment.assignment_id })
                }
            );
            if (!resp.ok) {
                const errData = await resp.json();
                throw new Error(errData.error || 'Fehler beim Entfernen des Fahrzeugs aus dem Schichtplan.');
            }
            // Erfolg -> reload
            showSnackbar('Fahrzeug erfolgreich aus Schichtplan entfernt', 'success');
            // Fahrzeuge neu laden
            await loadVehiclesForTemplate(selectedVehicleAssignment.template_id);
            setRemoveVehicleDialogOpen(false);
            setSelectedVehicleAssignment(null);
            setOpenVehiclesModal(false);
        } catch (err) {
            showSnackbar(`Entfernen fehlgeschlagen: ${err.message}`, 'error');
        }
    };

    // Bestätigungsdialog
    const confirmRemoveVehicleDialog = async (yes) => {
        setRemoveVehicleDialogOpen(false);
        if (yes && selectedVehicleAssignment) {
            await handleRemoveVehicleFromShift(selectedVehicleAssignment);
        }
        setSelectedVehicleAssignment(null);
    };

    // ----------------------------------------
    // UI-Renderer
    // ----------------------------------------

    // Spalte 1: Card
    const renderTemplateCard = (params) => {
        const row = params.row;
        const isHolidayTemplate = row.holiday || false; // Verwende das neue holiday-Flag

        return (
            <Card
                sx={{
                    borderRadius: 2,
                    padding: 1,
                    maxWidth: 300,
                    height: '75%',
                    boxShadow: isHolidayTemplate ? '0 2px 8px rgba(244, 67, 54, 0.3)' : 2,
                    marginTop: 2,
                    marginBottom: 8,
                    borderLeft: isHolidayTemplate ? '4px solid #f44336' : 'none',
                    backgroundColor: isHolidayTemplate ? '#fff8f8' : 'white'
                }}
            >
                <CardContent sx={{ padding: '8px !important' }}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                        <Typography variant="subtitle1" fontWeight="bold">
                            {row.name}
                        </Typography>
                        {isHolidayTemplate && (
                            <Chip
                                icon={<HolidayIcon />}
                                label="Feiertag"
                                size="small"
                                sx={{
                                    backgroundColor: '#ffebee',
                                    color: '#d32f2f',
                                    borderColor: '#ef9a9a',
                                    '& .MuiChip-icon': {
                                        color: '#d32f2f'
                                    }
                                }}
                            />
                        )}
                    </Box>
                    <Typography variant="body2">
                        {row.description || ''}
                    </Typography>
                    {row.additional_information && (
                        <Typography variant="body2" color="text.secondary">
                            {row.additional_information}
                        </Typography>
                    )}
                </CardContent>
            </Card>
        );
    };

    // (NEU) Kleiner Button + Menü, analog StationSplitButton / VehiclesList
    const VehicleSplitButton = ({ assignment }) => {
        // assignment enthält vehicle_id, vehicle_name, assignment_id, template_id ...
        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (event) => {
            event.stopPropagation();
            setAnchorEl(event.currentTarget);
        };
        const handleCloseMenu = () => {
            setAnchorEl(null);
        };

        return (
            <>
                <Button
                    variant="outlined"
                    sx={{
                        mr: 1,
                        mb: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        // ggf. mehr Styling
                    }}
                    endIcon={<MoreVertIcon />}
                    onClick={handleClick}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                            {assignment.vehicle_name}
                        </Typography>
                        {assignment.radio_call_sign && (
                            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                {assignment.radio_call_sign}
                            </Typography>
                        )}
                    </Box>
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                    onClick={handleCloseMenu}
                >
                    <MenuItem
                        onClick={() => {
                            // Fahrzeug bearbeiten
                            navigate(`/vehiclepage/${assignment.vehicle_id}`);
                        }}
                    >
                        <EditIcon fontSize="small" sx={{ mr: 1 }} />
                        Fahrzeug bearbeiten
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            // Fahrzeug aus Schichtplan entfernen
                            // => Bestätigungsdialog
                            setSelectedVehicleAssignment(assignment);
                            setRemoveVehicleDialogOpen(true);
                        }}
                    >
                        <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
                        Fahrzeug aus Schichtplan entfernen
                    </MenuItem>
                </Menu>
            </>
        );
    };

    // Spalte 2: Zugeordnete Fahrzeuge
    const renderVehiclesColumn = (params) => {
        const row = params.row; // shift_template
        const templateId = row.template_id;
        const vehicles = shiftVehiclesMap[templateId] || [];

        const visible = vehicles.slice(0, 3);
        const hiddenCount = vehicles.length - 3;

        return (
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', width: '100%', marginTop: 2 }}>
                {visible.map((veh) => (
                    <VehicleSplitButton
                        key={veh.assignment_id}
                        assignment={{
                            ...veh,
                            template_id: templateId  // wichtig für removeVehicle
                        }}
                    />
                ))}
                {hiddenCount > 0 && (
                    <Button
                        variant="text"
                        onClick={() => {
                            setVehiclesModalData(vehicles);
                            setOpenVehiclesModal(true);
                        }}
                        sx={{ mb: 1 }}
                    >
                        +{hiddenCount} mehr
                    </Button>
                )}
                <IconButton
                    color="primary"
                    onClick={() => openAddVehicleDialog(row)}
                    sx={{ marginLeft: 'auto' }}
                    title="Fahrzeug hinzufügen"
                >
                    <AddCircleIcon />
                </IconButton>
            </Box>
        );
    };

    // Dialog öffnen
    const openAddVehicleDialog = (shiftTemplate) => {
        setCurrentTemplateForVehicle(shiftTemplate);
        setAddVehicleSearch('');
        setIsAddVehicleDialogOpen(true);
        fetchAllVehicles();
    };

    const fetchAllVehicles = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_API_URI}/api/vehicles`, {
                headers: { Authorization: user.api_key }
            });
            if (!resp.ok) {
                throw new Error('Fahrzeugliste konnte nicht geladen werden');
            }
            const data = await resp.json();
            setAllVehicles(data);
        } catch (err) {
            console.error(err);
        }
    };

    const addVehicleToTemplate = async (vehicle) => {
        if (!currentTemplateForVehicle) return;
        try {
            const templateId = currentTemplateForVehicle.template_id;
            const resp = await fetch(
                `${process.env.REACT_APP_API_URI}/api/shift-templates/${templateId}/addVehicle`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: user.api_key
                    },
                    body: JSON.stringify({
                        vehicle_id: vehicle.vehicle_id
                    })
                }
            );
            if (!resp.ok) {
                const errData = await resp.json();
                throw new Error(errData.error || 'Fehler beim Hinzufügen des Fahrzeugs');
            }
            showSnackbar('Fahrzeug erfolgreich hinzugefügt', 'success');
            setIsAddVehicleDialogOpen(false);
            setCurrentTemplateForVehicle(null);
            await loadVehiclesForTemplate(templateId);
        } catch (err) {
            showSnackbar(`Hinzufügen fehlgeschlagen: ${err.message}`, 'error');
        }
    };

    // Spalte 3: Schicht-Details
    const renderDetailsColumn = (params) => {
        const row = params.row;
        const details = row.details || [];
        const isHolidayTemplate = row.holiday || false; // Verwende direkt das Holiday-Flag
        const sorted = [...details].sort((a, b) => a.day_of_week - b.day_of_week);

        const dayLines = sorted.map(d => {
            const wochentag = WEEKDAY_LABELS[d.day_of_week] || `Tag ${d.day_of_week}`;
            return `${wochentag}: ${formatTime(d.start_time)} - ${formatTime(d.end_time)}`;
        });

        const firstDetail = sorted[0];
        const pauseText = firstDetail?.break_duration
            ? `täglich ${formatBreakDuration(firstDetail.break_duration)}`
            : '';

        const recurrence = firstDetail?.recurrence_pattern || '';

        return (
            <Box sx={{ whiteSpace: 'pre-line', marginTop: 2, marginBottom: 2 }}>
                {dayLines.map((line, idx) => (
                    <Typography key={idx} variant="body2">
                        {line}
                    </Typography>
                ))}
                <Typography variant="body2" sx={{ mt: 1 }}>
                    {pauseText}
                </Typography>
                <Typography variant="body2">
                    Rhythmus: {recurrence} Wochen
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: isHolidayTemplate ? '#d32f2f' : 'inherit',
                        fontWeight: isHolidayTemplate ? 'bold' : 'normal'
                    }}
                >
                    Feiertag: {isHolidayTemplate ? 'JA' : 'NEIN'}
                    {isHolidayTemplate && (
                        <HolidayIcon fontSize="small" sx={{ ml: 1, color: '#d32f2f' }} />
                    )}
                </Typography>
            </Box>
        );
    };

    // Spalte 4: Aktionen
    const columns = [
        {
            field: 'templateInfo',
            headerName: 'Schichtplan',
            width: 400,
            renderCell: renderTemplateCard
        },
        {
            field: 'vehicles',
            headerName: 'Fahrzeuge',
            width: 730,
            renderCell: renderVehiclesColumn
        },
        {
            field: 'details',
            headerName: 'Details',
            width: 360,
            renderCell: renderDetailsColumn
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Aktionen',
            width: 130,
            getActions: (params) => {
                const templateId = params.row.template_id;
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Bearbeiten"
                        onClick={() => handleEditTemplate(templateId)}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Löschen"
                        onClick={() => {
                            setSelectedTemplate(params.row);
                            setDialogAction('delete');
                            setDialogOpen(true);
                        }}
                        showInMenu
                    />,
                ];
            },
        },
    ];

    const rows = shiftTemplates.map(st => ({
        id: st.template_id,
        ...st,
    }));

    const filteredRows = rows.filter(r => {
        const txt = searchQuery.toLowerCase();
        return (
            r.name?.toLowerCase().includes(txt) ||
            r.description?.toLowerCase().includes(txt) ||
            r.additional_information?.toLowerCase().includes(txt)
        );
    });

    return (
        <Box sx={{ mt: 2 }}>
            {serverError && <Alert severity="error">{serverError}</Alert>}
            {isLoading ? (
                <CircularProgress />
            ) : (
                <div style={{ width: '100%' }}>
                    <PersistentDataGrid
                        storageKey="shiftPlanList"
                        rows={filteredRows}
                        columns={columns}
                        getRowHeight={() => 'auto'}
                        pageSize={5}
                        rowsPerPageOptions={[5, 10, 20]}
                    />
                </div>
            )}

            {/* Bestätigungsdialog "Schichtplan löschen" */}
            <ConfirmationDialog
                open={dialogOpen}
                title="Schichtplan löschen?"
                description={
                    selectedTemplate
                        ? `Möchten Sie den Schichtplan "${selectedTemplate.name}" wirklich löschen?`
                        : ''
                }
                onClose={handleDialogConfirm}
                onConfirm={handleDialogConfirm}
            />

            {/* Bestätigungsdialog "Fahrzeug aus Schicht entfernen" */}
            <ConfirmationDialog
                open={removeVehicleDialogOpen}
                title="Fahrzeug entfernen?"
                description={
                    selectedVehicleAssignment
                        ? `Möchten Sie das Fahrzeug "${selectedVehicleAssignment.vehicle_name}" wirklich aus diesem Schichtplan entfernen?`
                        : ''
                }
                onClose={confirmRemoveVehicleDialog}
                onConfirm={confirmRemoveVehicleDialog}
            />

            {/* Dialog: Modal alle Fahrzeuge (wenn mehr als 3) */}
            <Dialog
                open={openVehiclesModal}
                onClose={() => setOpenVehiclesModal(false)}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>Alle Fahrzeuge</DialogTitle>
                <DialogContent>
                    <List>
                        {vehiclesModalData.map((veh) => (
                            <ListItem
                                key={veh.assignment_id}
                                button
                                onClick={() => {
                                    // Klick auf den Eintrag -> Vehicle-Detail
                                    navigate(`/vehiclepage/${veh.vehicle_id}`);
                                }}
                                secondaryAction={(
                                    <IconButton
                                        edge="end"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            // Fahrzeug löschen -> Bestätigungsdialog
                                            setSelectedVehicleAssignment({
                                                ...veh,
                                                template_id: veh.template_id || (veh.template_id ?? 0),
                                                assignment_id: veh.assignment_id || (veh.assignment_id ?? 0)
                                            });
                                            setRemoveVehicleDialogOpen(true);
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                            >
                                <ListItemText
                                    primary={veh.vehicle_name}
                                    secondary={veh.license_plate || ''}
                                />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenVehiclesModal(false)}>Schließen</Button>
                </DialogActions>
            </Dialog>

            {/* Dialog: Fahrzeug hinzufügen */}
            <Dialog
                open={isAddVehicleDialogOpen}
                onClose={() => setIsAddVehicleDialogOpen(false)}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>Fahrzeug zuordnen</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Fahrzeug suchen"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={addVehicleSearch}
                        onChange={e => setAddVehicleSearch(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: addVehicleSearch && (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setAddVehicleSearch('')}>
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <List>
                        {allVehicles
                            .filter(veh =>
                                veh.vehicle_name.toLowerCase().includes(addVehicleSearch.toLowerCase()) ||
                                (veh.license_plate && veh.license_plate.toLowerCase().includes(addVehicleSearch.toLowerCase()))
                            )
                            .map(veh => (
                                <ListItem
                                    button
                                    key={veh.vehicle_id}
                                    onClick={() => addVehicleToTemplate(veh)}
                                >
                                    <ListItemText
                                        primary={veh.vehicle_name}
                                        secondary={veh.license_plate || ''}
                                    />
                                </ListItem>
                            ))
                        }
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsAddVehicleDialogOpen(false)}>Abbrechen</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ShiftPlanList;