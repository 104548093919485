//vehiclesPage.js
import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    Alert,
    Container,
    IconButton,
    Menu,
    MenuItem,
    FormControl,
    FormControlLabel,
    Select,
    InputLabel,
    Grid2,
    Checkbox
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useUser } from '../../components/userContext';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

const VehiclePage = () => {
    const { user } = useUser();
    const navigate = useNavigate();
    const { vehicle_id } = useParams();
    const location = useLocation();
    const isEditMode = Boolean(vehicle_id);

    // Formular-State
    const [formData, setFormData] = useState({
        vehicle_name: '',
        license_plate: '',
        station_id: '',
        status: 'available',
        radio_call_sign: '',          // Neues Feld
        telemetry: true,              // Default TRUE
        vehicle_properties: {         // JSON-Objekt mit Defaults
            has_stretcher: false,
            has_crawler: false,
            has_vacuum_mattress: false
        },
        image_id: null,
        imageSrc: null
    });
    // Sonstige States
    const [stations, setStations] = useState([]);
    const [errors, setErrors] = useState({});
    const [serverError, setServerError] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);

    // Vorbelegung station_id, wenn über location.state übergeben
    useEffect(() => {
        if (!isEditMode && location.state?.stationId) {
            setFormData((prev) => ({ ...prev, station_id: location.state.stationId }));
        }
    }, [location, isEditMode]);

    // Daten laden (Stationen und ggf. Fahrzeug)
    useEffect(() => {
        fetchStations();
        if (isEditMode) {
            fetchVehicle(vehicle_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicle_id]);

    // Bild laden, sobald wir eine image_id haben
    useEffect(() => {
        if (formData.image_id) {
            fetchImage(formData.image_id);
        } else {
            // Falls kein Bild vorhanden, Default setzen
            setFormData((prev) => ({ ...prev, imageSrc: '/default-vehicle.png' }));
        }
        // eslint-disable-next-line
    }, [formData.image_id]);

    // Stationen abrufen
    const fetchStations = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/stations`, {
                headers: { Authorization: user.api_key }
            });
            if (response.ok) {
                const data = await response.json();
                setStations(data);
            }
        } catch (error) {
            console.error('Fehler beim Laden der Stationen:', error);
        }
    };

    // Ein Fahrzeug (Details) abrufen
    const fetchVehicle = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/vehicles`, {
                headers: { Authorization: user.api_key }
            });
            if (!response.ok) throw new Error('Fehler beim Laden der Fahrzeugdaten');

            const data = await response.json();
            const found = data.find((v) => v.vehicle_id === parseInt(id, 10));
            if (!found) throw new Error('Fahrzeug nicht gefunden');

            setFormData({
                vehicle_name: found.vehicle_name || '',
                license_plate: found.license_plate || '',
                station_id: found.station_id || '',
                default_station_id: found.default_station_id || '',
                status: found.status || 'available',
                radio_call_sign: found.radio_call_sign || '',
                telemetry: found.telemetry ?? true,
                vehicle_properties: {
                    has_stretcher: false,
                    has_crawler: false,
                    has_vacuum_mattress: false,
                    ...found.vehicle_properties
                },
                image_id: found.image_id || null,
                imageSrc: null
            });
        } catch (err) {
            console.error(err);
            setServerError(err.message);
        }
    };

    // Bild abrufen (analog stationPage) – wenn API einen direkten Endpunkt hat
    const fetchImage = async (imageId) => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URI}/api/images/${imageId}`, {
                headers: { Authorization: user.api_key }
            });
            // Erwartet hier, dass /api/images/:id ein JSON mit { image: "<base64 oder URL>" } zurückgibt
            // Falls die API stattdessen direkt ein Bild zurückliefert, einfach `setFormData((prev)=>({ ...prev, imageSrc: res.url }))`
            const data = await res.json();
            if (data.image) {
                setFormData((prev) => ({ ...prev, imageSrc: data.image }));
            } else {
                // Falls kein JSON mit image-Feld, fallback
                setFormData((prev) => ({ ...prev, imageSrc: '/default-vehicle.png' }));
            }
        } catch (error) {
            console.error('Fehler beim Laden des Fahrzeugbildes:', error);
            setFormData((prev) => ({ ...prev, imageSrc: '/default-vehicle.png' }));
        }
    };

    // Formularänderungen
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // Füge hinzu unter handleChange
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            vehicle_properties: {
                ...prev.vehicle_properties,
                [name]: checked
            }
        }));
    };

    const handleTelemetryChange = (e) => {
        setFormData(prev => ({
            ...prev,
            telemetry: e.target.checked
        }));
    };

    // Formularvalidierung
    const validateForm = () => {
        let newErrors = {};
        if (!formData.vehicle_name.trim()) {
            newErrors.vehicle_name = 'Fahrzeugname ist erforderlich';
        }
        // OValidierung für radio_call_sign
        if (formData.radio_call_sign.length > 50) {
            newErrors.radio_call_sign = 'Maximal 50 Zeichen erlaubt';
        }
        return newErrors;
    };

    // Speichern
    const handleSubmit = async (e) => {
        e.preventDefault();
        setServerError('');

        const newErrors = validateForm();
        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) return;

        try {
            let url = `${process.env.REACT_APP_API_URI}/api/vehicles`;
            let method = 'POST';
            if (isEditMode) {
                url += `/${vehicle_id}`;
                method = 'PUT';
            }

            // imageSrc raushalten (nur image_id speichern)
            const { imageSrc, ...payload } = formData;

            // Sicherstellen, dass vehicle_properties immer alle Felder enthält
            const finalPayload = {
                ...payload,
                vehicle_properties: {
                    has_stretcher: payload.vehicle_properties.has_stretcher || false,
                    has_crawler: payload.vehicle_properties.has_crawler || false,
                    has_vacuum_mattress: payload.vehicle_properties.has_vacuum_mattress || false
                }
            };

            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user.api_key
                },
                body: JSON.stringify(finalPayload)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Fehler beim Speichern');
            }

            // Wie in stationPage.js: nach Speichern zurück zur vorherigen Seite
            navigate(-1);
        } catch (error) {
            setServerError(error.message);
        }
    };

    // Datei-Upload
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const formDataFile = new FormData();
        formDataFile.append('image', file);

        try {
            // 1) Bild hochladen
            const uploadResponse = await fetch(`${process.env.REACT_APP_API_URI}/api/upload-image`, {
                method: 'POST',
                headers: { Authorization: user.api_key },
                body: formDataFile
            });
            const uploadData = await uploadResponse.json();
            if (!uploadData.success) {
                alert('Fehler beim Hochladen des Bildes');
                return;
            }

            // 2) Vehicle auf die neue Image-ID setzen
            if (isEditMode) {
                // Nur möglich, wenn bereits ein vehicle existiert
                await fetch(`${process.env.REACT_APP_API_URI}/api/vehicles/${vehicle_id}/set-image`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: user.api_key
                    },
                    body: JSON.stringify({ image_id: uploadData.file_id })
                });
                // Reload wie in stationPage
                window.location.reload();
            } else {
                // Falls neu, einfach image_id in State setzen (noch nicht in DB)
                setFormData((prev) => ({ ...prev, image_id: uploadData.file_id }));
            }
        } catch (err) {
            console.error('Fehler beim Hochladen des Bildes', err);
            alert('Fehler beim Hochladen des Bildes');
        }
    };

    // Menü öffnen/schließen
    const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    // Abbrechen
    const handleCancel = () => {
        navigate(-1);
    };

    return (
        <Container maxWidth="md">
            <Box mt={2} p={2} sx={{ backgroundColor: '#fff', borderRadius: 3, boxShadow: 3 }}>
                {/* Kopfbereich: Bild + Titel + Menü */}
                <Box display="flex" justifyContent="space-between">
                    <Box display="flex">
                        <Box
                            component="img"
                            src={formData.imageSrc || '/default-vehicle.jpg'}
                            alt="Fahrzeug"
                            sx={{
                                maxHeight: 160,
                                objectFit: 'cover',
                                borderRadius: 3,
                                border: '2px solid #ddd',
                                mb: 2
                            }}
                        />
                        <Typography variant="h5" fontWeight="bold" ml={2}>
                            {isEditMode ? 'Fahrzeug bearbeiten' : 'Neues Fahrzeug hinzufügen'}
                        </Typography>
                    </Box>

                    <Box>
                        <IconButton onClick={handleMenuOpen}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                            <MenuItem onClick={() => {
                                handleMenuClose();
                                document.getElementById('fileInput').click();
                            }}>
                                Bild hochladen oder ändern
                            </MenuItem>
                        </Menu>
                    </Box>
                </Box>

                {serverError && (
                    <Alert severity="error" sx={{ my: 2 }}>
                        {serverError}
                    </Alert>
                )}

                <form onSubmit={handleSubmit}>
                    <Grid2 container spacing={2}>
                        <Grid2 item size={12}>
                            <TextField
                                fullWidth
                                label="Fahrzeugname"
                                name="vehicle_name"
                                value={formData.vehicle_name}
                                onChange={handleChange}
                                margin="normal"
                                error={!!errors.vehicle_name}
                                helperText={errors.vehicle_name}
                            />
                        </Grid2>

                        <Grid2 item size={6}>
                            <TextField
                                fullWidth
                                label="Funkrufname"
                                name="radio_call_sign"
                                value={formData.radio_call_sign}
                                onChange={handleChange}
                                margin="normal"
                            />
                        </Grid2>


                        <Grid2 item size={6} >
                            <TextField
                                fullWidth
                                label="Kennzeichen"
                                name="license_plate"
                                value={formData.license_plate}
                                onChange={handleChange}
                                margin="normal"
                            />
                        </Grid2>

                        <Grid2 item size={12} sm={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="default_station_id-label">Stammwache</InputLabel>
                                <Select
                                    labelId="default_station_id-label"
                                    name="default_station_id"
                                    value={formData.default_station_id || ''}
                                    label="Stammwache"
                                    onChange={handleChange}
                                >
                                    <MenuItem value=""><em>keine</em></MenuItem>
                                    {stations.map((st) => (
                                        <MenuItem key={st.station_id} value={st.station_id}>
                                            {st.name} (ID {st.station_id})
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid2>

                        <Grid2 item size={12} sm={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="station_id-label">Zugeordnete Wache</InputLabel>
                                <Select
                                    labelId="station_id-label"
                                    name="station_id"
                                    value={formData.station_id || ''}
                                    label="Zugeordnete Wache"
                                    onChange={handleChange}
                                >
                                    <MenuItem value="">
                                        <em>keine</em>
                                    </MenuItem>
                                    {stations.map((st) => (
                                        <MenuItem key={st.station_id} value={st.station_id}>
                                            {st.name} (ID {st.station_id})
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid2>

                        <Grid2 item size={6} >
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="status-label">Status</InputLabel>
                                <Select
                                    labelId="status-label"
                                    name="status"
                                    value={formData.status}
                                    label="Status"
                                    onChange={handleChange}
                                >
                                    <MenuItem value="available">Verfügbar</MenuItem>
                                    <MenuItem value="inactive">Außer Dienst</MenuItem>
                                    {/* <MenuItem value="maintenance">Wartung</MenuItem> */}
                                </Select>
                            </FormControl>
                        </Grid2>

                        <Grid2 item xs={6} >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.telemetry}
                                        onChange={handleTelemetryChange}
                                        name="telemetry"
                                        color="primary"
                                    />
                                }
                                label="Telemetrie aktiviert"
                                sx={{ mt: 2 }}
                            />
                        </Grid2>
                    </Grid2>



                    <Grid2 item xs={12}>
                        <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                            Fahrzeugausstattung
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 3 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.vehicle_properties.has_stretcher}
                                        onChange={handleCheckboxChange}
                                        name="has_stretcher"
                                    />
                                }
                                label="Tragestuhl"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.vehicle_properties.has_crawler}
                                        onChange={handleCheckboxChange}
                                        name="has_crawler"
                                    />
                                }
                                label="Raupenantrieb"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.vehicle_properties.has_vacuum_mattress}
                                        onChange={handleCheckboxChange}
                                        name="has_vacuum_mattress"
                                    />
                                }
                                label="Vakuummatratze"
                            />
                        </Box>
                    </Grid2>

                    {/* Verstecktes Datei-Upload-Feld */}
                    <input
                        type="file"
                        id="fileInput"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />

                    <Box display="flex" justifyContent="space-between" mt={2}>
                        <Button variant="text" onClick={handleCancel}>
                            Eingabe Abbrechen
                        </Button>
                        <Button variant="contained" color="primary" type="submit">
                            Speichern
                        </Button>
                    </Box>
                </form>
            </Box>
        </Container>
    );
};

export default VehiclePage;
