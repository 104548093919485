import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Paper,
    Chip,
    Tooltip,
} from '@mui/material';
import {
    PauseCircleOutline as PendingIcon,
    ThumbUpOutlined as ApprovedIcon,
    EventAvailable as ScheduledIcon,
    LocalShipping as TransitIcon,
    CheckCircleOutline as FinishedIcon,
    CancelOutlined as CanceledIcon,
    DoDisturbOutlined as RejectedIcon,
    Person as PatientIcon,
    DateRange as DateIcon,
    AccessTime as TimeIcon,
    FreeBreakfast as BreakIcon,
    MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import AddressCell from '../../components/AddressCell';
import { getTransportTypeStyle } from './transportTypeUtils';
import TransportBarContextMenu from './TransportBarContextMenu';

// Hilfsfunktion für Statusfarben und Icons
const getStatusInfo = (status) => {
    switch (status) {
        case 'pending':
            return {
                icon: <PendingIcon />,
                label: 'in Prüfung',
                color: '#3f51b5',
                bgColor: '#e8eaf6'
            };
        case 'approved':
            return {
                icon: <ApprovedIcon />,
                label: 'Akzeptiert',
                color: '#3f51b5',
                bgColor: '#e8eaf6'
            };
        case 'scheduled':
            return {
                icon: <ScheduledIcon />,
                label: 'vorgeplant',
                color: '#4caf50',
                bgColor: '#e8f5e9'
            };
        case 'in_transit':
            return {
                icon: <TransitIcon />,
                label: 'unterwegs',
                color: '#4caf50',
                bgColor: '#e8f5e9'
            };
        case 'finished':
            return {
                icon: <FinishedIcon />,
                label: 'beendet',
                color: '#4caf50',
                bgColor: '#e8f5e9'
            };
        case 'canceled':
            return {
                icon: <CanceledIcon />,
                label: 'Storniert',
                color: '#9e9e9e',
                bgColor: '#f5f5f5'
            };
        default:
            return {
                icon: <RejectedIcon />,
                label: 'Abgelehnt',
                color: '#f44336',
                bgColor: '#ffebee'
            };
    }
};

// Hilfsfunktion für Datumsformatierung
const formatDateTime = (dateStr, timeStr) => {
    if (!dateStr) return { date: 'Unbekanntes Datum', time: '00:00' };

    const date = new Date(dateStr);
    const formattedDate = date.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });

    let formattedTime = '00:00';
    if (timeStr) {
        formattedTime = new Date(`1970-01-01T${timeStr}`).toLocaleTimeString('de-DE', {
            hour: '2-digit',
            minute: '2-digit'
        });
    }

    return { date: formattedDate, time: formattedTime };
};

// Hilfsfunktion zur Berechnung der Minutendauer einer Pause
const calculateBreakDuration = (transport) => {
    if (!transport.planned_start || !transport.planned_end) return 0;

    const start = dayjs(transport.planned_start);
    const end = dayjs(transport.planned_end);
    return end.diff(start, 'minute');
};

const TransportTypeChip = ({ transport }) => {
    const typeStyle = getTransportTypeStyle(transport.transport_type || 'DEFAULT');
    let label = typeStyle.label;
    let subtitle = null;

    if (transport.ambulatory) {
        subtitle = transport.return_trip ? 'Ambulant Rückfahrt' : 'Ambulant Hinfahrt';
    } else if (transport.return_trip && transport.linked_transport_type === 'TERMINFAHRT') {
        subtitle = 'Rück. von Terminfahrt';
    } else if (transport.transport_type === 'DIALYSEFAHRT') {
        subtitle = transport.return_trip ? 'Dialyse Rückfahrt' : 'Dialyse Hinfahrt';
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Chip
                icon={typeStyle.icon} // Icon aus typeStyle hinzufügen
                label={label}
                size="small"
                sx={{
                    fontSize: '0.75rem',
                    fontWeight: 500,
                    backgroundColor: typeStyle.backgroundColor,
                    color: typeStyle.color,
                    borderRadius: '4px',
                    mb: 0.5,
                    '& .MuiChip-icon': {
                        color: typeStyle.color, // Icon-Farbe an Chip-Farbe anpassen
                        marginLeft: '6px', // Etwas Abstand zum Rand
                    },
                }}
            />
            {subtitle && (
                <Typography variant="caption" sx={{ color: 'text.secondary', fontSize: '0.7rem' }}>
                    {subtitle}
                </Typography>
            )}
        </Box>
    );
};


const StatusCell = ({ status }) => {
    const statusInfo = getStatusInfo(status);

    return (
        <Tooltip title={statusInfo.label}>
            <Chip
                icon={statusInfo.icon}
                label={statusInfo.label}
                size="small"
                sx={{
                    color: statusInfo.color,
                    backgroundColor: statusInfo.bgColor,
                    borderRadius: '4px',
                    fontSize: '0.75rem',
                    fontWeight: 500,
                    '& .MuiChip-icon': {
                        color: statusInfo.color
                    }
                }}
            />
        </Tooltip>
    );
};

const DateTimeCell = ({ transport }) => {
    const { date, time } = formatDateTime(transport.departure_date, transport.departure_time);

    let dynamicText = '';
    if (transport.transport_type === 'BREAK') {
        // Bei 'BREAK' bleibt dynamicText bewusst leer:
        dynamicText = '';
    } else if (transport.transport_type === 'DIALYSEFAHRT') {
        // Unterscheidung nach Hin- oder Rückfahrt
        dynamicText = transport.return_trip
            ? 'Wunschzeit Abfahrt'
            : 'Ankunft Zielort';
    } else if (transport.transport_type === 'TERMINFAHRT') {
        dynamicText = 'Ankunft Zielort';
    } else {
        // Fallback für alle anderen Typen
        dynamicText = 'Wunschzeit Abfahrt';
    }

    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" mb={0.5}>
                <DateIcon fontSize="small" sx={{ mr: 0.5, color: 'text.secondary', fontSize: '1rem' }} />
                <Typography variant="body2">{date}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
                <TimeIcon fontSize="small" sx={{ mr: 0.5, color: 'text.secondary', fontSize: '1rem' }} />
                <Typography variant="body2">{time}</Typography>
            </Box>
            <Typography variant="caption" sx={{ color: 'text.secondary', mt: 0.5, fontSize: '0.7rem' }}>
                {dynamicText}
            </Typography>
        </Box>
    );
};

const VehicleTransportTable = ({
    transports,
    onSelectRow,
    selectedId,
    onDetails = () => { },
    onEdit = () => { },
    onDelete = () => { },
    onDuplicate = () => { },
    onAddBreakAfter = () => { },
    onShowRoundTrip = () => { }
}) => {

    // State für das Kontext-Menü
    const [menuState, setMenuState] = useState({
        open: false,
        anchorPosition: null,
        transport: null
    });

    // Funktion zum Öffnen des Menüs
    const handleOpenMenu = (event, transport) => {
        event.stopPropagation();
        console.log('handleOpenMenu', transport);
        setMenuState({
            open: true,
            anchorPosition: { x: event.clientX, y: event.clientY },
            transport: transport
        });
    };

    // Funktion zum Schließen des Menüs
    const handleCloseMenu = () => {
        setMenuState({
            open: false,
            anchorPosition: null,
            transport: null
        });
    };

    // Aktion mit Transport ausführen und Menü schließen
    const handleAction = (action, event) => {
        if (menuState.transport) {
            action(event, menuState.transport);
        }
        handleCloseMenu();
    };

    // Aktionsicon für die Zelle
    const ActionIcon = ({ transport }) => (
        <Box
            onClick={(e) => handleOpenMenu(e, transport)}
            sx={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                p: 0.5,
                borderRadius: '4px',
                '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' }
            }}
        >
            <MoreVertIcon fontSize="small" />
        </Box>
    );


    // Gruppierung der Transporte nach vehicle_id und Sortierung nach Abfahrtsdatum und -zeit
    const groupedTransports = useMemo(() => {
        const groups = {};
        transports.forEach((transport) => {
            const vehicleId = transport.vehicle_id || 'unassigned';
            if (!groups[vehicleId]) {
                groups[vehicleId] = {
                    vehicle_name: transport.vehicle_name || 'Nicht zugewiesen',
                    station_name: transport.station_name || 'Unbekannt',
                    lk: transport.lk || 'Unbekannt',
                    transports: [],
                    normalTransportsCount: 0,
                    breaksCount: 0,
                    totalBreakMinutes: 0
                };
            }
            groups[vehicleId].transports.push(transport);

            // Zählen von Transporten und Pausen sowie Berechnung der Pausendauer
            if (transport.transport_type === 'BREAK') {
                groups[vehicleId].breaksCount++;
                groups[vehicleId].totalBreakMinutes += calculateBreakDuration(transport);
            } else {
                groups[vehicleId].normalTransportsCount++;
            }
        });

        // Sortierung für jedes Fahrzeug nach Abfahrtsdatum und -zeit
        Object.keys(groups).forEach(vehicleId => {
            groups[vehicleId].transports.sort((a, b) => {
                const dateA = a.planned_start || '';
                const dateB = b.planned_start || '';
                const timeA = a.planned_start || '00:00:00';
                const timeB = b.planned_start || '00:00:00';

                // Erstelle Vergleichswerte für die Sortierung
                const fullDateA = `${dateA}T${timeA}`;
                const fullDateB = `${dateB}T${timeB}`;

                return fullDateA.localeCompare(fullDateB);
            });
        });

        return groups;
    }, [transports]);

    // Spaltendefinition
    const columns = [
        {
            field: 'transport_type',
            headerName: 'Transport Art',
            width: 150,
            renderCell: (transport) => <TransportTypeChip transport={transport} />,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 120,
            renderCell: (transport) => <StatusCell status={transport.status} />,
        },
        {
            field: 'departure_date',
            headerName: 'Transport Datum',
            width: 150,
            renderCell: (transport) => <DateTimeCell transport={transport} />,
        },
        {
            field: 'from_address',
            headerName: 'Von',
            width: 240,
            renderCell: (transport) => (
                <AddressCell
                    address={{
                        institution: transport.from_institution,
                        station: transport.from_station,
                        street: transport.from_street,
                        houseNumber: transport.from_house_number,
                        postalCode: transport.from_postal_code,
                        city: transport.from_city,
                    }}
                />
            ),
        },
        {
            field: 'to_address',
            headerName: 'Nach',
            width: 240,
            renderCell: (transport) => (
                <AddressCell
                    address={{
                        institution: transport.to_institution,
                        station: transport.to_station,
                        street: transport.to_street,
                        houseNumber: transport.to_house_number,
                        postalCode: transport.to_postal_code,
                        city: transport.to_city,
                    }}
                />
            ),
        },
        {
            field: 'patient_name',
            headerName: 'Patient',
            width: 140,
            renderCell: (transport) => {
                // Prüfen, ob wir hier keinen Inhalt anzeigen wollen
                if (transport.transport_type === 'BREAK') {
                    return null; // Kein Inhalt in der Zelle
                }

                // Bei allen anderen Transport-Typen Patient anzeigen
                return (
                    <Box display="flex" alignItems="center">
                        <PatientIcon
                            fontSize="small"
                            sx={{ mr: 0.5, color: 'text.secondary', fontSize: '1rem' }}
                        />
                        <Typography variant="body2">
                            {`${transport.first_name ?? ''} ${transport.last_name ?? ''}`}
                        </Typography>
                    </Box>
                );
            },
        },

        {
            field: 'create_date',
            headerName: 'Erstellt am/von',
            width: 200,
            renderCell: (transport) => {
                // Prüfen, ob wir hier keinen Inhalt anzeigen wollen
                if (transport.transport_type === 'BREAK') {
                    return null; // Kein Inhalt in der Zelle
                }
                const createDate = transport.create_date ? new Date(transport.create_date) : null;
                const formattedDate = createDate
                    ? createDate.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })
                    : 'Unbekanntes Datum';
                const formattedTime = createDate
                    ? createDate.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })
                    : '00:00';

                return (
                    <Box display="flex" flexDirection="column">
                        <Typography variant="body2">{`${formattedDate} ${formattedTime}`}</Typography>

                        {transport.transport_id && (
                            <Typography variant="caption" sx={{ color: 'text.secondary', fontSize: '0.7rem' }}>
                                Transport-Id: {transport.transport_id}
                            </Typography>
                        )}

                        {transport.username && (
                            <Typography variant="caption" sx={{ color: 'text.secondary', fontSize: '0.7rem' }}>
                                {transport.username}
                            </Typography>
                        )}

                        {transport.object_institution && (
                            <Typography variant="caption" sx={{ color: 'text.secondary', fontSize: '0.7rem' }}>
                                {transport.object_institution}
                            </Typography>
                        )}
                        {transport.auto_generated && (
                            <Typography variant="caption" sx={{ color: 'text.secondary', fontSize: '0.7rem' }}>
                                Automatisch generiert
                            </Typography>
                        )}

                    </Box>
                );
            },
        },
        {
            field: 'actions',
            headerName: 'Aktionen',
            width: 80,
            renderCell: (transport) => <ActionIcon transport={transport} />,
        },
    ];

    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflowY: 'auto',
            overflowX: 'visible', // Wichtig: Horizontalen Overflow sichtbar machen
            position: 'relative',  // Positionierungskontext setzen
        }}>
            {Object.entries(groupedTransports).map(([vehicleId, group]) => (
                <Paper
                    key={vehicleId}
                    elevation={1}
                    sx={{
                        mb: 3,
                        borderRadius: '8px',
                        overflow: 'hidden',
                    }}
                >
                    {/* Fahrzeug-Header */}
                    <Box sx={{
                        bgcolor: '#f0f2f5',
                        p: 1.5,
                        display: 'flex',
                        alignItems: 'center',
                        borderBottom: '1px solid #e0e0e0'
                    }}>
                        <Box
                            sx={{
                                width: 36,
                                height: 36,
                                borderRadius: '50%',
                                bgcolor: '#3f51b5',
                                color: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mr: 2,
                                fontWeight: 'bold'
                            }}
                        >
                            {group.lk}
                        </Box>
                        <Box>
                            <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#3f51b5' }}>
                                {group.vehicle_name}
                            </Typography>
                            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                {group.station_name}
                            </Typography>
                        </Box>

                        {/* Transport-Chip */}
                        <Chip
                            label={`${group.normalTransportsCount} Transport${group.normalTransportsCount !== 1 ? 'e' : ''}`}
                            size="small"
                            sx={{
                                ml: 2,
                                backgroundColor: '#e8eaf6',
                                color: '#3f51b5',
                                height: '20px',
                                fontSize: '0.75rem'
                            }}
                        />

                        {/* Pausen-Chip, nur wenn Pausen vorhanden sind */}
                        {group.breaksCount > 0 && (
                            <Chip
                                icon={<BreakIcon sx={{ fontSize: '0.875rem' }} />}
                                label={`${group.breaksCount} Pause${group.breaksCount !== 1 ? 'n' : ''}`}
                                size="small"
                                sx={{
                                    ml: 1,
                                    backgroundColor: '#ffebee',
                                    color: '#d32f2f',
                                    height: '20px',
                                    fontSize: '0.75rem',
                                    '& .MuiChip-icon': {
                                        color: '#d32f2f',
                                    }
                                }}
                            />
                        )}

                        {/* Pausen-Minuten-Chip, nur wenn Pausen vorhanden sind */}
                        {group.totalBreakMinutes > 0 && (
                            <Chip
                                label={`${group.totalBreakMinutes} Minuten Pause`}
                                size="small"
                                sx={{
                                    ml: 1,
                                    backgroundColor: '#ffebee',
                                    color: '#d32f2f',
                                    height: '20px',
                                    fontSize: '0.75rem'
                                }}
                            />
                        )}
                    </Box>

                    {/* Transporttabelle */}
                    <Box sx={{ p: 1 }}>
                        <Table size="small" sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.field}
                                            sx={{
                                                width: column.width,
                                                fontWeight: 600,
                                                color: '#3f51b5',
                                                borderBottom: '2px solid #e0e0e0',
                                                padding: '8px',
                                            }}
                                        >
                                            {column.headerName}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {group.transports.map((transport) => (
                                    <TableRow
                                        key={transport.transport_id}
                                        hover
                                        onClick={() => onSelectRow(transport.transport_id, transport.planning_id, transport)}
                                        selected={selectedId === transport.transport_id}
                                        sx={{
                                            cursor: 'pointer',
                                            '&:hover': { bgcolor: '#f5f5f5' },
                                            '&.Mui-selected': {
                                                bgcolor: '#e3f2fd',
                                                '&:hover': { bgcolor: '#bbdefb' }
                                            },
                                            '& td': {
                                                padding: '10px 8px',
                                                borderBottom: '1px solid #f0f0f0'
                                            },
                                        }}
                                    >
                                        {columns.map((column) => (
                                            <TableCell key={column.field}>
                                                {column.renderCell(transport)}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Paper>
            ))}

            {/* Keine Daten Nachricht */}
            {Object.keys(groupedTransports).length === 0 && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        my: 4,
                        p: 3,
                        bgcolor: '#f9f9f9',
                        borderRadius: '8px'
                    }}
                >
                    <Typography variant="h6" sx={{ color: '#3f51b5', mb: 1 }}>
                        Keine Transporte gefunden
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: 'center' }}>
                        Für den ausgewählten Tag und Filter wurden keine Transporte gefunden.
                    </Typography>
                </Box>
            )}

            <TransportBarContextMenu
                mode="forTableView"
                open={menuState.open}
                anchorPosition={menuState.anchorPosition}
                onClose={handleCloseMenu}
                transport={menuState.transport}
                onDetails={(e) => handleAction(onDetails, e)}
                onEdit={(e) => handleAction(onEdit, e)}
                onDelete={(e) => handleAction(onDelete, e)}
                onDuplicate={(e) => handleAction(onDuplicate, e)}
                onAddBreakAfter={(e) => handleAction(onAddBreakAfter, e)}
                onShowRoundTrip={(e) => handleAction(onShowRoundTrip, e)}
            />

        </Box>
    );
};

VehicleTransportTable.propTypes = {
    transports: PropTypes.arrayOf(
        PropTypes.shape({
            transport_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            vehicle_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            vehicle_name: PropTypes.string,
            station_name: PropTypes.string,
            transport_type: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            departure_date: PropTypes.string,
            departure_time: PropTypes.string,
            from_institution: PropTypes.string,
            from_station: PropTypes.string,
            from_street: PropTypes.string,
            from_house_number: PropTypes.string,
            from_postal_code: PropTypes.string,
            from_city: PropTypes.string,
            to_institution: PropTypes.string,
            to_station: PropTypes.string,
            to_street: PropTypes.string,
            to_house_number: PropTypes.string,
            to_postal_code: PropTypes.string,
            to_city: PropTypes.string,
            first_name: PropTypes.string,
            last_name: PropTypes.string,
            create_date: PropTypes.string,
            username: PropTypes.string,
            object_institution: PropTypes.string,
            auto_generated: PropTypes.bool,
            ambulatory: PropTypes.bool,
            return_trip: PropTypes.bool,
            linked_transport_type: PropTypes.string,
            planned_start: PropTypes.string,
            planned_end: PropTypes.string
        })
    ).isRequired,
    onSelectRow: PropTypes.func.isRequired,
    selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onDetails: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onDuplicate: PropTypes.func,
    onAddBreakAfter: PropTypes.func,
    onShowRoundTrip: PropTypes.func,
};

export default VehicleTransportTable;