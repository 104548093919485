import { Container, Divider, Paper, Typography } from '@mui/material';

export default function Impressum() {
    return (
        <Container maxWidth="md">
            <Paper elevation={3} sx={{ padding: '20px', borderRadius: '10px', background: '#f7f7f7' }}>
                <Typography variant="h6" color="#E46450" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>Impressum</Typography>
                <Divider />
                <Typography variant="body1" align="justify" mt={2}>
                    <b>Anbieterkennung nach § 5 TMG</b>
                    <br />
                    Deutsches Rotes Kreuz
                    <br />
                    Rettungsdienst Bodensee-Oberschwaben gGmbH
                    <br />
                    Birkenweg 12
                    <br />
                    88250 Weingarten
                    <br />
                    Deutschland
                    <br />
                    Telefon: 0751 509157-0
                    <br />
                    Mail: kontakt(at)drk-bos.de
                    <br />
                    <br />
                    <b>Geschäftsführung</b>
                    <br />
                    Volker Geier | Geschäftsführer
                    <br />
                    Frank Panschar | Prokurist
                    <br />
                    <br />
                    <b>Sitz der Gesellschaft</b>
                    <br />
                    Weingarten
                    <br />
                    <br />
                    <b>Handelsregister</b>
                    <br />
                    Nr. HRB 552018, Amtsgericht Ulm
                    <br />
                    <br />
                    Der Beauftragte für Medizinproduktesicherheit (gemäß MPBetreibV) ist unter medizinproduktesicherheit(at)drk-bos.de und der Hygienebeauftragter ist unter hygienebeauftragter(at)drk-bos.de erreichbar.
                    <br />
                    <br />
                    <b>Disclaimer</b>
                    <br />
                    <b>Haftungsbeschränkung:</b> Die Webseite wurde mit größtmöglicher Sorgfalt erstellt. Der Anbieter dieser Webseite übernimmt dennoch keine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten Inhalte und Informationen. Die Nutzung der Webseiteninhalte erfolgt auf eigene Gefahr. Allein durch die Nutzung der Website kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande.
                    <br />
                    <br />
                    <b>Haftungsausschluss</b>
                    <br />
                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                    <br />
                    <br />
                    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                    <br />
                    <br />
                    <b>Haftung für Links</b>
                    <br />
                    Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
                    <br />
                    <br />
                    Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                    <br />
                    <br />
                    <b>Urheberrecht</b>
                    <br />
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                    <br />
                    <br />
                    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                </Typography>
            </Paper>
        </Container>

    );
}
