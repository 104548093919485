import dayjs from 'dayjs';

export const BASE_HOUR_WIDTH = 84;
export const ROW_HEIGHT = 60;
export const TIME_LABELS_HEIGHT = 40;

export const PLANNING_TYPE_COLORS = {
    TO_START: '#4f86c6',
    MAIN: '#4f86c6',
    TO_GARAGE: '#4f86c6',
    BREAK: '#cc0000',
    SERVICE: '#4f86c6',
    LOADING: '#4f86c6',
    DELIVERY: '#4f86c6',
    CUSTOM: '#4f86c6',
    CLEANING: '#4f86c6',
    DEFAULT: '#4f86c6'
};

export function formatHourLabel(hour) {
    return `${hour.toString().padStart(2, '0')}:00`;
}

export function calcPosition(startTime, endTime, selectedDate, hourWidth) {
    const dayStart = dayjs(selectedDate).startOf('day');
    const start = dayjs(startTime);
    const end = dayjs(endTime);

    const startHours = start.diff(dayStart, 'hour', true);
    const endHours = end.diff(dayStart, 'hour', true);

    const left = startHours * hourWidth;
    const width = (endHours - startHours) * hourWidth;
    return { left, width };
}

export function parseWorkingIntervals(vehicle, selectedDate) {
    if (!vehicle.free_time_json?.workingIntervals) return [];
    const intervalsStr = vehicle.free_time_json.workingIntervals;
    return intervalsStr.map(([startStr, endStr]) => {
        const sHour = parseInt(startStr.substring(0, 2), 10);
        const sMin = parseInt(startStr.substring(3, 5), 10);
        const eHour = parseInt(endStr.substring(0, 2), 10);
        const eMin = parseInt(endStr.substring(3, 5), 10);

        const start = dayjs(selectedDate).hour(sHour).minute(sMin).second(0);
        let end;
        // Spezialfall: Fahrzeug arbeitet 24h
        if (startStr === '00:00' && endStr === '00:00') {
            end = dayjs(selectedDate).endOf('day');
        } else {
            end = dayjs(selectedDate).hour(eHour).minute(eMin).subtract(1, 'minute');
        }
        return [start, end];
    });
}