// drkTheme.js
import { createTheme } from '@mui/material/styles';

// Hauptfarbpalette für das DRK-Design
const drkRed = '#E46450';
const drkLightRed = 'rgba(228, 100, 80, 0.05)';
//const drkMediumRed = 'rgba(228, 100, 80, 0.1)';

const drkTheme = createTheme({
    palette: {
        primary: {
            main: drkRed,
            light: drkLightRed,
            dark: '#d13b27',
            contrastText: '#fff',
        },
        secondary: {
            main: '#4CAF50', // Erfolgsfarbe für bestätigte Aktionen
            light: 'rgba(76, 175, 80, 0.1)',
            dark: '#388E3C',
            contrastText: '#fff',
        },
        error: {
            main: '#F44336',
        },
        background: {
            default: '#fff',
            paper: '#fff',
            accent: drkLightRed,
            muted: '#f5f5f5',
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.6)',
            muted: 'rgba(0, 0, 0, 0.38)',
        },
    },
    typography: {
        fontFamily: [
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
        ].join(','),
        h4: {
            fontWeight: 700,
            color: drkRed,
        },
        h5: {
            fontWeight: 600,
            color: drkRed,
        },
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            fontWeight: 500,
        },
        colorHeader: {
            fontWeight: 600,
            color: 'white',
            fontSize: '1.5rem',
        },
    },
    shape: {
        borderRadius: 8,
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 16,
                    overflow: 'hidden',
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: 24,
                    '&:last-child': {
                        paddingBottom: 24,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    borderRadius: 30,
                    padding: '6px 16px',
                    boxShadow: '0 4px 10px rgba(228, 100, 80, 0.3)',
                    '&:hover': {
                        boxShadow: '0 6px 15px rgba(228, 100, 80, 0.4)',
                    },
                },
                containedPrimary: {
                    backgroundColor: drkRed,
                    '&:hover': {
                        backgroundColor: '#d13b27',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        borderRadius: 8,
                        '&:-webkit-autofill': {
                            WebkitBoxShadow: '0 0 0 1000px white inset',
                            WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)',
                        },
                    },
                    '& .MuiInputLabel-root': {
                        // Verschiebt das Label für Icon-Platz
                        '&.MuiInputLabel-formControl': {
                            transform: 'translate(14px, 8px) scale(1)',
                        },
                        '&.MuiInputLabel-shrink': {
                            transform: 'translate(14px, -6px) scale(0.75)',
                        },
                    },
                    // Spezielles Styling für Labels, wenn es ein Prefix/Icon gibt
                    '&.MuiTextField-hasIcon .MuiInputLabel-root': {
                        '&.MuiInputLabel-formControl': {
                            transform: 'translate(40px, 16px) scale(1)',
                        },
                        '&.MuiInputLabel-shrink': {
                            transform: 'translate(14px, -6px) scale(0.75)',
                        },
                    },
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                },
                icon: {
                    alignItems: 'center',
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: drkRed,
                    textDecoration: 'none',
                    fontWeight: 500,
                    '&:hover': {
                        textDecoration: 'underline',
                    },
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    margin: '16px 0',
                },
            },
        },
    },
});

export default drkTheme;