// transportList.js
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
    Typography, Button, Alert, Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle, Box, TextField, InputAdornment, IconButton, CircularProgress,
    Stack, FormControlLabel, Checkbox, Switch, Divider
} from '@mui/material';
import debounce from 'lodash.debounce';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useUser } from '../components/userContext';
import { useNavigate, useLocation } from 'react-router-dom';
import SettingsMenu from '../components/transportSettingsMenu';
// Import other necessary dependencies and components
import PauseIcon from '@mui/icons-material/Pause';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import DateRangeIcon from '@mui/icons-material/DateRange';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import InfoIcon from '@mui/icons-material/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';
import RefreshIcon from '@mui/icons-material/Refresh';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddressCell from '../components/AddressCell';
import HistoryEntryCard from '../components/HistoryEntryCard'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; // import plugin
import { renewToken } from '../misc/helperFunctions';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
dayjs.extend(utc); // use plugin

const ConfirmationDialog = ({ open, onClose, onConfirm, title, description, readonly, linkedTransportId }) => {
    const [reason, setReason] = useState(''); // Storno-Grund als State-Variable
    const [doDeleteLinkedItem, setDoDeleteLinkedItem] = useState(false); // Hier auf false setzen

    const handleReasonChange = (event) => {
        setReason(event.target.value);
    };

    const handleLinkedItemChange = (event) => {
        setDoDeleteLinkedItem(event.target.checked);
    };

    const handleConfirm = () => {
        onConfirm(true, reason, doDeleteLinkedItem); // Übergibt den Storno-Grund und die Entscheidung zu linkedItem an handleConfirmation
        setReason(''); // Clear reason after confirmation
    };

    const isStorno = title === 'Stornieren';

    return (
        <Dialog open={open} onClose={() => onClose(false)}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{description}</DialogContentText>
                {linkedTransportId && (
                    <FormControlLabel
                        control={<Switch
                            checked={doDeleteLinkedItem}
                            onChange={handleLinkedItemChange}
                        />}
                        label="Zugehörige Hin- oder Rückfahrt ebenfalls stornieren?"
                        labelPlacement="start" // Platzierung des Labels links vom Switch
                        sx={{ marginLeft: 0 }}
                    />
                )}
                {isStorno && (
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Storno-Grund"
                        type="text"
                        fullWidth
                        value={reason}
                        onChange={handleReasonChange}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(false)}>Abbrechen</Button>
                <Button onClick={handleConfirm} color="primary" disabled={readonly || (isStorno && (reason.trim() === ''))} >Ja</Button>
            </DialogActions>
        </Dialog>
    );
};

/**
 * Hilfsfunktion: Liefert (Anzeigetext, Farbcode) abhängig von transport.status
 * UND - falls status 'scheduled' oder 'in_transit' - die dynamischen Zeiten.
 */
function renderStatusWithTime(params) {
    const row = params.row;

    // Basierend auf row.status wählen wir das Icon & label
    let icon = null;
    let label = '';
    let color = '';

    switch (row.status) {
        case 'pending':
            icon = <PauseIcon style={{ color: 'blue', marginRight: 5 }} />;
            label = 'in Prüfung';
            color = 'blue';
            break;
        case 'approved':
            icon = <ThumbUpOffAltIcon style={{ color: 'blue', marginRight: 5 }} />;
            label = 'Akzeptiert';
            color = 'blue';
            break;
        case 'scheduled':
            icon = <DateRangeIcon style={{ color: 'green', marginRight: 5 }} />;
            label = 'vorgeplant';
            color = 'green';
            break;
        case 'in_transit':
            icon = <LocalShippingIcon style={{ color: 'green', marginRight: 5 }} />;
            label = 'unterwegs';
            color = 'green';
            break;
        case 'finished':
            icon = <CheckCircleOutlineIcon style={{ color: 'green', marginRight: 5 }} />;
            label = 'beendet';
            color = 'green';
            break;
        case 'canceled':
            icon = <DoDisturbIcon style={{ color: 'gray', marginRight: 5 }} />;
            label = 'Storniert';
            color = 'gray';
            break;
        default:
            icon = <DoDisturbIcon style={{ color: 'red', marginRight: 5 }} />;
            label = 'Abgelehnt';
            color = 'red';
            break;
    }

    // Nur bei 'scheduled' (vorgeplant) oder 'in_transit' (unterwegs) berechnen wir die Zeit
    // => best. Transporttypen
    // row.transport_type => 'TERMINFAHRT', 'ENTLASSUNG', 'EINWEISUNG', 'VERLEGUNG', 'DIALYSEFAHRT', ...
    //   - Dialyse-Hinfahrt => transport_type='DIALYSEFAHRT' && !return_trip
    //   - Dialyse-Rückfahrt => transport_type='DIALYSEFAHRT' && return_trip
    //   - Terminfahrt => transport_type='TERMINFAHRT'
    //   - Einweisung, Verlegung, Entlassung => ...
    // Offsets: +5min (Terminfahrt, Dialyse-Hin), +3min (Entlassung, Einweisung, Verlegung, Dialyse-Rück)

    const type = row.transport_type;
    const isDialyse = (type === 'DIALYSEFAHRT');
    const isReturn = !!row.return_trip; // true/false

    // offset in Minuten: 5 oder 3
    let offsetMinutes = 3;
    // Dialyse-Hinfahrt, Terminfahrt => +5
    if ((isDialyse && !isReturn) || type === 'TERMINFAHRT') {
        offsetMinutes = 5;
    }

    // Felder aus opheo_timestamps (falls vorhanden):
    // z.B. row.opheo_timestamps?.entladungsplanstart, row.opheo_timestamps?.entladungsstart, ...
    const ts = row.opheo_timestamps || {};

    // Falls scheduled => "vorgeplant": Anzeige der Plan-Startzeit (+Offset).
    // Falls in_transit => "unterwegs": Anzeige der Restminuten bis Planstart+Offset oder Start+Offset.
    let extraText = ''; // hier kommt "Ankunft Zielort 06:49 Uhr" oder "XXmin bis Abholung"
    if (row.status === 'scheduled' || row.status === 'in_transit') {
        // 1) Ermitteln, ob wir Beladung oder Entladung brauchen
        //    - Dialyse Hinfahrt / Terminfahrt => Entladung
        //    - Dialyse Rückfahrt, Einweisung, Verlegung, Entlassung => Beladung
        //    (bzw. so wie in der Aufgabenstellung definiert)

        // "Hinfahrt" => Entladungs* / "Rückfahrt" => Beladungs*
        let planKey = 'entladungsplanstart';
        let actualKey = 'entladungsstart';
        let labelAnkunftOderAbholung = 'Ankunft Zielort';

        // Für Rückfahrten => beladungs*
        // Oder: "ENTLASSUNG", "EINWEISUNG", "VERLEGUNG"
        // In der Aufgabe stand:
        //   Entlassung, Einweisung, Verlegung => immer Beladungs* 
        //   Dialyse Rückfahrt => Beladungs*
        //   Terminfahrt => Entladungs*
        //   Dialyse Hinfahrt => Entladungs*
        //   => wir leiten das hier vereinfacht ab:
        const isEntladung = (
            type === 'TERMINFAHRT' ||
            (isDialyse && !isReturn) // =Hinfahrt
        );
        if (!isEntladung) {
            planKey = 'beladungsplanstart';
            actualKey = 'beladungsstart';
            labelAnkunftOderAbholung = 'Abholung';
        }

        // Zeitstring aus DB
        const planStr = ts[planKey];
        const actualStr = ts[actualKey];

        if (row.status === 'scheduled') {
            // vorgeplant => wir nehmen planStr + offset => "Ankunft Zielort HH:MM Uhr"
            if (planStr) {
                const planTime = dayjs(planStr); // Tag/Monat/Jahr + Uhrzeit
                const adjusted = planTime.add(offsetMinutes, 'minute');
                const hhmm = adjusted.format('HH:mm'); // z.B. "06:49"
                extraText = `${labelAnkunftOderAbholung} ${hhmm} Uhr`;
            }
        }
        else if (row.status === 'in_transit') {
            // unterwegs => wir nehmen actualStr + offset => "XXmin bis Ankunft/Abholung"
            if (actualStr) {
                const actualTime = dayjs(actualStr);
                const target = actualTime.add(offsetMinutes, 'minute');
                const jetzt = dayjs();
                let diff = target.diff(jetzt, 'minute'); // diff in Minuten
                if (diff < 0) diff = 0; // Nicht negativ anzeigen => 0

                extraText = `${diff}min bis ${labelAnkunftOderAbholung}`;
            }
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {icon}
                <Typography variant="body2" style={{ color }}>{label}</Typography>
            </div>
            {/** Zusätzlicher Zeittext (falls vorhanden) */}
            {extraText && (
                <Typography variant="caption" style={{ marginLeft: '0px', color }}>
                    {extraText}
                </Typography>
            )}
        </div>
    );
}


const TransportList = () => {
    // State variables
    const { user } = useUser();// Anbemeldeter User
    const { settings, updateSetting } = useUser(); // Einstellungen des Users
    const allowedRoles = ['user', 'dialysis'];
    const navigate = useNavigate();

    const [transports, setTransports] = useState([]);
    const [selectedTransport, setSelectedTransport] = useState(null);
    const [selectedSeriesIdentifier, setSelectedSeriesIdentifier] = useState(null);
    const [linkedTransportId, setLinkedTransportId] = useState(null);
    const [dialogAction, setDialogAction] = useState(null);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogDescription, setDialogDescription] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [serverError, setServerError] = useState(null);
    // Add other necessary state variables
    const [isFiltering, setIsFiltering] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [statusFilter, setStatusFilter] = useState({
        pending: true,
        approved: true,
        rejected: false,
        scheduled: true,
        in_transit: true,
        finished: false,
        canceled: false,
    });
    const [dateFilter, setDateFilter] = useState(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const filterStatus = queryParams.get("filterStatus");
    const [isAutoLoading, setIsAutoLoading] = useState(false);
    const [isAutoRefreshEnabled, setIsAutoRefreshEnabled] = useState(true);

    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(true); // for loading Icon

    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [editDialogTransport, setEditDialogTransport] = useState(null);

    const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    const [showHelp, setShowHelp] = useState(false);
    const handleToggleHelp = () => {
        setShowHelp((prev) => !prev);
    };

    // Debounce-Mechanismus für die Suche
    // eslint-disable-next-line
    const debouncedSearch = useCallback(
        debounce((value) => {
            setIsFiltering(true); // Markiere, dass wir gerade filtern
            setSearchQuery(value.toLowerCase());
            setIsFiltering(false);
        }, 500),
        []
    );

    // Cleanup beim Unmount
    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    // Fetch transports from the API
    useEffect(() => {
        fetchTransports();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (filterStatus) {
            // Update the status filter based on the received parameter
            setStatusFilter({
                pending: filterStatus === 'pending',
                approved: filterStatus === 'approved',
                rejected: filterStatus === 'rejected',
                scheduled: filterStatus === 'scheduled',
                in_transit: filterStatus === 'in_transit',
                finished: filterStatus === 'finished',
                canceled: filterStatus === 'canceled',
            });
        }
    }, [filterStatus]);

    useEffect(() => {
        console.log('useEffect AUTO-REFRESH');
        fetchTransports();

        // Timer einrichten, um fetchTransports alle 5 Minuten aufzurufen
        const interval = setInterval(() => fetchTransports(true), 300000);

        // Bereinigungsfunktion, um den Timer zu löschen, wenn die Komponente demontiert wird
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, []);



    const fetchTransports = async (autoRefresh = false) => {
        // Wenn wir gerade filtern oder suchen, dann das Auto-Refresh überspringen
        if (autoRefresh && isFiltering) {
            console.log('Auto-Refresh übersprungen, da gerade gefiltert wird');
            return;
        }
        try {
            console.log('fetchTransports...');
            if (!autoRefresh) setIsLoading(true);
            else setIsAutoLoading(true);

            // API call to fetch transports
            let apiUrl = process.env.REACT_APP_API_URI + '/api/transports';

            // Check user role
            if (allowedRoles.includes(user?.role)) {
                apiUrl = process.env.REACT_APP_API_URI + '/api/transports/own?user_id=' + user.user_id;
            }

            const isAdmin = ['admin'].includes(user.role);
            if (isAdmin) {
                // Dann laden wir die "withPlanning"-Route:
                apiUrl = process.env.REACT_APP_API_URI + '/api/transports/withPlanning';
            }

            const response = await fetch(apiUrl, {
                headers: {
                    'Authorization': user.api_key,
                },
            });
            if (!response.ok) {
                throw new Error('Error fetching transports');
            }
            const data = await response.json();
            //console.log(data);
            setTransports(data);
            await renewToken();
            setIsLoading(false);
            setIsAutoLoading(false);
        } catch (error) {
            console.error('Error fetching transports:', error);
            setIsLoading(false);
            // Handle the error
        }
    };

    const fetchLinkedTransport = async (transportId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/transports/linked/${transportId}`, {
                method: 'GET',
                headers: {
                    'Authorization': user.api_key,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                console.error('Fehler beim Abrufen des gelinkten Transports:', await response.text());
                return null;
            }

            const linkedTransport = await response.json();
            return linkedTransport; // Gibt das gelinkte Transportobjekt zurück
        } catch (error) {
            console.error('Fehler beim Abrufen des gelinkten Transports:', error);
            return null;
        }
    };

    // Funktion zum Aktualisieren des `status`-Felds in der Tabelle
    const updateStatusField = useCallback(async () => {
        // Wenn wir gerade filtern oder suchen, dann das Status-Update überspringen
        if (isFiltering) {
            console.log('Status-Update übersprungen, da gerade gefiltert wird');
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/transports/status`, {
                headers: {
                    'Authorization': user.api_key,
                },
            });

            if (!response.ok) {
                throw new Error('Error fetching status');
            }

            const statusData = await response.json();

            // Sichere Aktualisierung des Status mit funktionalem Update
            setTransports(prevTransports => {
                // Nur aktualisieren, wenn wir nicht gerade filtern
                if (isFiltering) return prevTransports;

                return prevTransports.map(transport => {
                    const updatedStatus = statusData.find(status => status.transport_id === transport.transport_id);
                    return updatedStatus ? { ...transport, status: updatedStatus.status } : transport;
                });
            });
        } catch (error) {
            console.error('Error updating status field:', error);
        }
        // eslint-disable-next-line
    }, [user.api_key, setTransports]); // Abhängigkeiten des useCallback-Hooks

    // Auto-Refresh für den Status

    useEffect(() => {
        if (isFiltering) return;
        let statusInterval;

        // Nur starten, wenn nicht gefiltert wird
        if (!isFiltering) {
            statusInterval = setInterval(() => {
                updateStatusField();
            }, 60000); // 60 Sekunden
        }

        return () => {
            if (statusInterval) {
                clearInterval(statusInterval);
            }
        };
    }, [updateStatusField, isFiltering]);



    // Auto-Refresh für die Transportliste
    useEffect(() => {
        if (isFiltering) return;
        let interval;

        // Nur starten, wenn Auto-Refresh aktiviert ist und nicht gefiltert wird
        if (isAutoRefreshEnabled && !isFiltering) {
            interval = setInterval(() => fetchTransports(true), 300000); // 5 Minuten
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
        // eslint-disable-next-line
    }, [isAutoRefreshEnabled, isFiltering]);


    // Funktion zur Überprüfung der Benutzerrolle
    const isExpertRole = (user) => {
        return user?.role === 'dialysis' || user?.role === 'poweruser' || user?.role === 'admin' || user?.role === 'superadmin';
    };

    const toggleAutoRefresh = () => {
        const newValue = !settings.is_auto_refresh_enabled;
        updateSetting('is_auto_refresh_enabled', newValue);
        setIsAutoRefreshEnabled(newValue);
    };

    useEffect(() => {
        if (settings && typeof settings.is_auto_refresh_enabled !== 'undefined') {
            setIsAutoRefreshEnabled(settings.is_auto_refresh_enabled);
        }
    }, [settings]);

    // Ruft die Historie vom Server ab und öffnet das Modal
    const handleShowHistory = async (transportId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/transports/${transportId}/history`, {
                headers: {
                    'Authorization': user.api_key,
                }
            });
            if (!response.ok) {
                throw new Error('Fehler beim Abrufen der Historie');
            }
            const data = await response.json();
            setHistoryData(data);
            setOpenHistoryDialog(true);
        } catch (error) {
            console.error('Fehler bei handleShowHistory:', error);
            setServerError(error.message);
        }
    };

    const handleCloseHistory = () => {
        setOpenHistoryDialog(false);
    };

    const handleConfirmation = async (confirm, reason, doDeleteLinkedItem) => {
        setDialogOpen(false); // Close the dialog

        if (confirm) {
            let updatedTransport = { ...selectedTransport };

            // Workaround für JS Date und UTC timezone
            const datePart = dayjs(updatedTransport.departure_date).add(1, 'day').format('YYYY-MM-DD');
            const parsedDate = dayjs(datePart);
            const departureDateTime = parsedDate.isValid() ? parsedDate : null;
            updatedTransport.departure_date = departureDateTime;

            // Workaround für JS Date und UTC timezone bei birthdate im patient
            if (updatedTransport.birthdate) {
                const birthdatePart = dayjs(updatedTransport.birthdate).add(1, 'day').format('YYYY-MM-DD');
                const parsedBirthdate = dayjs(birthdatePart);
                const birthdate = parsedBirthdate.isValid() ? parsedBirthdate : null;
                updatedTransport.birthdate = birthdate;
            }

            if (dialogAction === 'löschen' || dialogAction === 'löschen_serie') {
                // Speichere den Storno-Grund
                updatedTransport.cancellation_reason = reason;
            }

            try {
                switch (dialogAction) {
                    case 'freigeben':
                        updatedTransport.status = 'approved';
                        await updateTransport(updatedTransport);
                        break;

                    case 'ablehnen':
                        updatedTransport.status = 'rejected';
                        await updateTransport(updatedTransport);
                        break;

                    case 'löschen':
                        await deleteTransport(updatedTransport.transport_id, reason);
                        if (doDeleteLinkedItem && linkedTransportId) {
                            await deleteTransport(linkedTransportId, reason);
                        }
                        break;

                    case 'löschen_serie':
                        await deleteTransportSeries(selectedSeriesIdentifier, reason);
                        break;

                    case 'create_return_trip':
                        await handleCreateReturnTrip(selectedTransport.transport_id);
                        break;

                    default:
                        break;
                }

                setServerError(null);
                // Refresh the transport list after successful action
                fetchTransports();

            } catch (error) {
                console.error('Error performing action:', error);
                setServerError(error.message);
            }
        }

        setSelectedTransport(null);
        setSelectedSeriesIdentifier(null);
        setDialogAction(null);
        setLinkedTransportId(null);
    };

    const handleColumnWidthChange = (params) => {
        console.log('Column width changed:', params);

        // Verwenden Sie params.colDef, um auf die Spalteninformationen zuzugreifen
        const { colDef, width } = params; // colDef.field enthält den Spaltennamen, width die neue Breite
        console.log(colDef);
        console.log(`Column ${colDef.field} width changed to ${width}`);

        // Aktualisierung der Spaltenbreiten in den Benutzereinstellungen
        const updatedColumnWidths = {
            ...settings.transportListWidths,
            [colDef.field]: width,
        };
        console.log('Updated column widths:', updatedColumnWidths);
        // Speichern der aktualisierten Einstellungen
        updateSetting('transportListWidths', updatedColumnWidths);
    };

    const updateTransport = async (updatedTransport) => {
        await fetch(`${process.env.REACT_APP_API_URI}/api/transports/${updatedTransport.transport_id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': user.api_key,
            },
            body: JSON.stringify(updatedTransport),
        });
    };

    const deleteTransport = async (transportId, reason) => {
        const response = await fetch(`${process.env.REACT_APP_API_URI}/api/transports/${transportId}/cancel`, {
            method: 'PUT',
            headers: {
                'Authorization': user.api_key,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ cancellation_reason: reason }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Fehler beim Löschen des Transports.');
        }
    };

    const deleteTransportSeries = async (seriesIdentifier, reason) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/transports/cancel_series/${seriesIdentifier}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': user.api_key,
                },
                body: JSON.stringify({ reason }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Fehler beim Stornieren der Transport-Serie.');
            }

            console.log('Transport-Serie erfolgreich storniert.');
        } catch (error) {
            console.error('Error deleting transport series:', error.message);
            // Handle error case
        }
    };

    const handleDelete = async (id) => {
        const fullTransport = findTransportById(id);
        if (fullTransport) {
            if (user?.role !== 'admin' && user?.role !== 'superadmin' && user?.role !== 'poweruser' && fullTransport.status === 'in_transit') {
                // Transport unterwegs, Dialog anzeigen
                setSelectedTransport(fullTransport);
                setDialogTitle('Stornierung nicht möglich');
                setDialogDescription('Die gewählte Krankentransportfahrt hat bereits begonnen und kann nicht mehr storniert werden – bitte melden Sie sich unter der 0751/50915220');
                setDialogOpen(true);
            } else {

                setLinkedTransportId(null);

                // Prüfen, ob eine gelinkte Fahrt existiert
                const fetchResult = await fetchLinkedTransport(fullTransport.transport_id);
                let myLinkedTransport;

                if (fetchResult) {
                    if (fetchResult.initial_trip_id === fullTransport.transport_id) {
                        myLinkedTransport = findTransportById(fetchResult.return_trip_id);
                    } else {
                        myLinkedTransport = findTransportById(fetchResult.initial_trip_id);
                    }
                }

                let extendedDescription = `Möchten Sie den Transport "${fullTransport.transport_type}" stornieren? Bitte geben Sie den Storno-Grund ein.`;

                if (myLinkedTransport) {
                    extendedDescription += `\n\nEs wurde eine verknüpfte Fahrt gefunden:\n` +
                        `Transport-Typ: ${myLinkedTransport.transport_type}\n` +
                        `Möchten Sie diese Fahrt ebenfalls stornieren?`;
                }

                setSelectedTransport(fullTransport);
                setDialogAction('löschen');
                setDialogTitle('Stornieren');
                setDialogDescription(extendedDescription);
                setDialogOpen(true);

                // Speichern der Information über die gelinkte Fahrt, um sie später zu verwenden
                if (myLinkedTransport) {
                    setLinkedTransportId(myLinkedTransport.transport_id); // Gelinkte Fahrt speichern
                }
            }
        }
    };

    const handleCreateReturnTrip = async (transportId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/transports/${transportId}/returntrip`, {
                method: 'POST',
                headers: {
                    'Authorization': user.api_key,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                const data = await response.json();
                console.error(data.message);
                alert("Fehler beim Erstellen der Rückfahrt: " + data.message);
                return;
            }

            const respData = await response.json();
            // respData.data enthält den neu erstellten Rückfahrt-Transport
            const newReturnTrip = respData.data;

            // Erfolg: Weiterleiten zur Detailseite der neuen Rückfahrt
            alert("Rückfahrt erfolgreich erstellt. Prüfen Sie das Transportdatum.");
            navigate(`/transport/${newReturnTrip.transport_id}`);

        } catch (error) {
            console.error('Fehler beim Aufruf der Rückfahrt-API:', error);
            alert("Fehler beim Erstellen der Rückfahrt: " + error.message);
        }
    };

    const handleDeleteSeries = (seriesIdentifier) => {
        console.log("Löschen der Serie mit series_identifier " + seriesIdentifier);
        setSelectedSeriesIdentifier(seriesIdentifier);  // Hier wird das series_identifier gespeichert
        setDialogAction('löschen_serie');
        setDialogTitle('Stornieren');
        setDialogDescription(`Möchten Sie die gesamte Serie stornieren?`);
        setDialogOpen(true);
    };

    const handleFreigeben = (id) => {
        console.log("Freigabe button clicked " + id);
        const fullTransport = findTransportById(id);
        if (fullTransport) {
            setSelectedTransport(fullTransport);
            setDialogAction('freigeben');
            setDialogTitle('Freigabe');
            setDialogDescription(`Möchten Sie den Transport "${fullTransport.transport_type}" freigeben?`);
            setDialogOpen(true);
        }
    };

    const handleEdit = (id) => {
        const fullTransport = findTransportById(id);
        if (fullTransport) {
            if (fullTransport.status === 'in_transit') {
                // Wenn der Transport "unterwegs" ist, zeige eine Warnmeldung
                setSelectedTransport(fullTransport);
                setDialogTitle('Bearbeitung nicht möglich');
                setDialogDescription(
                    'Die gewählte Krankentransportfahrt hat bereits begonnen und kann nicht mehr bearbeitet werden – bitte melden Sie sich unter der 0751/50915220'
                );
                setDialogOpen(true);
            } else {
                // Normale Bearbeitung erlauben
                navigate(`/transport/${id}`);
            }
        }
    };

    const handleEditClick = (transport) => {
        if (transport.is_recurring || transport.is_recurring_return) {
            // Wenn es sich um eine Serie handelt, Dialog öffnen
            setEditDialogTransport(transport);
            setEditDialogOpen(true);
        } else {
            // Andernfalls ursprüngliche handleEdit-Funktion aufrufen
            handleEdit(transport.transport_id);
        }
    };

    const handleCopy = (id) => {
        const fullTransport = findTransportById(id);
        if (fullTransport) {
            // Normale Bearbeitung erlauben
            navigate(`/transport/${id}/copy`);
        }
    };

    const handleCopyClick = (transport) => {
        handleCopy(transport.transport_id);
    };

    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
        setEditDialogTransport(null);
    };

    const handleEditSingleTransport = () => {
        // Einzelnen Transport bearbeiten
        navigate(`/transport/${editDialogTransport.transport_id}`);
        handleEditDialogClose();
    };

    const fetchHeadOfSeriesId = async (transport_id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/transports/series/head/${transport_id}`, {
                method: 'GET',
                headers: {
                    'Authorization': user.api_key,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                console.error('Fehler beim Abrufen des HeadOfSerie:', await response.text());
                return null;
            }

            const headOfSeries = await response.json();
            return headOfSeries.transport_id; // ID des HeadOfSerie zurückgeben
        } catch (error) {
            console.error('Fehler beim Abrufen des HeadOfSerie:', error);
            return null;
        }
    };

    const handleEditRecurringSeries = async () => {
        // Serie bearbeiten
        if (editDialogTransport.transport_id) {
            const headId = await fetchHeadOfSeriesId(editDialogTransport.transport_id); // Korrekt mit await
            if (headId) {
                console.log('headOfSeriesId: ', headId);
                navigate(`/transport/${headId}`);
                handleEditDialogClose();
            }
        }
    };

    const handleAblehnung = (id) => {
        console.log("Ablehnung button clicked " + id);
        const fullTransport = findTransportById(id);
        if (fullTransport) {
            setSelectedTransport(fullTransport);
            setDialogAction('ablehnen');
            setDialogTitle('Ablehnung');
            setDialogDescription(`Möchten Sie den Transport "${fullTransport.transport_type}" ablehnen?`);
            setDialogOpen(true);
        }
    };

    const handleStatusFilterChange = (status) => {
        setStatusFilter((prevStatusFilter) => ({
            ...prevStatusFilter,
            [status]: !prevStatusFilter[status],
        }));
    };

    function findTransportById(id) {
        const transportId = Number(id); // Konvertieren Sie die ID in eine Zahl
        for (let i = 0; i < transports.length; i++) {
            if (transports[i].transport_id === transportId) {
                return transports[i];
            }
        }
        return null;
    }

    // Define columns for the DataGrid
    const columns = [
        {
            field: 'transport_type',
            headerName: 'Transport Art',
            width: settings.transportListWidths?.transport_type || 150,
            renderCell: (params) => (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    <Typography variant="body2">
                        {params.row.transport_type}
                    </Typography>

                    {/* Zusatztext für Ambulante Fahrten */}
                    {params.row.ambulatory && (
                        <Typography variant="caption" style={{ fontStyle: 'italic', color: 'gray' }}>
                            {params.row.return_trip ? 'Ambulant Rückfahrt' : 'Ambulant Hinfahrt'}
                        </Typography>
                    )}

                    {/* Zusatztext für Rückfahrt einer Terminfahrt */}
                    {params.row.return_trip && params.row.linked_transport_type === 'TERMINFAHRT' && (
                        <Typography variant="caption" style={{ fontStyle: 'italic', color: 'gray' }}>
                            Rück. von Terminfahrt
                        </Typography>
                    )}

                    {/* Zusatztext für Dialysefahrten */}
                    {params.row.transport_type === 'DIALYSEFAHRT' && (
                        <Typography variant="caption" style={{ fontStyle: 'italic', color: 'gray' }}>
                            {params.row.return_trip ? 'Dialyse Rückfahrt' : 'Dialyse Hinfahrt'}
                        </Typography>
                    )}
                </div>
            ),
        },
        {
            field: 'status',
            headerName: 'Status',
            width: settings.transportListWidths?.status || 140,
            renderCell: renderStatusWithTime,
        },
        {
            field: 'departure_date',
            headerName: 'Transport Datum',
            width: settings.transportListWidths?.departure_date || 150,
            renderCell: (params) => {
                const departureDate = params.row.departure_date ? new Date(params.row.departure_date) : null;
                const departureTime = params.row.departure_time || '00:00:00';

                const formattedDate = departureDate
                    ? departureDate.toLocaleDateString('de-DE', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    })
                    : 'Unbekanntes Datum';

                const formattedTime = departureTime
                    ? new Date(`1970-01-01T${departureTime}`).toLocaleTimeString('de-DE', {
                        hour: '2-digit',
                        minute: '2-digit',
                    })
                    : '00:00';

                const formattedDateTime = `${formattedDate} ${formattedTime}`;

                const dynamicText = (() => {
                    if (params.row.transport_type === 'DIALYSEFAHRT') {
                        return params.row.return_trip ? 'Wunschzeit Abfahrt' : 'Ankunft Zielort';
                    } else if (params.row.transport_type === 'TERMINFAHRT') {
                        return 'Ankunft Zielort';
                    } else {
                        return 'Wunschzeit Abfahrt';
                    }
                })();

                return (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: '100%', // Zentriere alles vertikal
                    }}>
                        <Typography variant="body2">{formattedDateTime}</Typography>
                        <Typography variant="caption" style={{ fontStyle: 'italic', color: 'gray' }}>
                            {dynamicText}
                        </Typography>
                    </div>
                );
            },
        },
        {
            field: 'from_address',
            headerName: 'Von',
            width: settings.transportListWidths?.from_address || 240,
            renderCell: (params) => (
                <AddressCell
                    address={{
                        institution: params.row.from_institution,
                        station: params.row.from_station,
                        street: params.row.from_street,
                        houseNumber: params.row.from_house_number,
                        postalCode: params.row.from_postal_code,
                        city: params.row.from_city,
                    }}
                />
            ),
        },
        {
            field: 'to_address',
            headerName: 'Nach',
            width: settings.transportListWidths?.to_address || 240,
            renderCell: (params) => (
                <AddressCell
                    address={{
                        institution: params.row.to_institution,
                        station: params.row.to_station,
                        street: params.row.to_street,
                        houseNumber: params.row.to_house_number,
                        postalCode: params.row.to_postal_code,
                        city: params.row.to_city,
                    }}
                />
            ),
        },
        {
            field: 'patient_name',
            headerName: 'Patient',
            width: settings.transportListWidths?.patient_name || 140,
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">
                        {`${params.row.first_name} ${params.row.last_name}`}
                    </Typography>
                </div>
            ),
        },
        {
            field: 'is_infectious',
            headerName: 'Inf.',
            width: settings.transportListWidths?.is_infectious || 40,
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    {params.row.is_infectious ? <VaccinesIcon style={{ color: 'red' }} /> : null}
                </div>
            ),
        },
        {
            field: 'return_trip',
            headerName: 'Rück.',
            width: settings.transportListWidths?.return_trip || 40,
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    {params.row.return_trip ? <KeyboardReturnIcon /> : null}
                </div>
            ),
        },
        {
            field: 'is_recurring',
            headerName: 'Regel.',
            width: settings.transportListWidths?.is_recurring || 40,
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    {params.row.is_recurring || params.row.is_recurring_return ? <AccessTimeIcon /> : null}
                </div>
            ),
        },
        {
            field: 'linked_transport',
            headerName: 'Partner',
            width: 40,
            renderCell: (params) => {
                // SCRUM-112 (Rückfahrten für alle User anzeigen) 
                /*
                if (user?.role !== 'admin' && user?.role !== 'poweruser' && user?.role !== 'superadmin') {
                    return null; // Nicht sichtbar für andere Benutzer
                }*/

                if (!params.row.linked_transport_id) {
                    return null;
                }

                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                        <LinkIcon
                            style={{ color: 'green', cursor: 'pointer' }}
                            onClick={() => navigate(`/transport/${params.row.linked_transport_id}`)}
                        />
                    </div>
                );
            }
        },
        {
            field: 'actions2',
            type: 'actions',
            headerName: 'Aktionen',
            width: settings.transportListWidths?.actions2 || 120,
            getActions: (params) => {
                const actions = [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Bearbeiten"
                        onClick={() => handleEditClick(params.row)}
                        disabled={params.row.status === 'finished' || params.row.status === 'canceled'}
                    />,
                    <GridActionsCellItem
                        icon={<ContentCopyIcon />}
                        label="Bestellung kopieren"
                        onClick={() => handleCopyClick(params.row)}
                        showInMenu
                    />,


                ];
                actions.push(
                    <GridActionsCellItem
                        icon={<KeyboardReturnIcon />}
                        label="Rückfahrt hinzufügen"
                        onClick={() => {
                            const transport = params.row;
                            setSelectedTransport(transport);
                            setDialogAction('create_return_trip');
                            setDialogTitle('Rückfahrt erstellen');
                            setDialogDescription(`Möchten Sie eine Rückfahrt für den Transport für ${params.row.first_name} ${params.row.last_name} erstellen?`);
                            setDialogOpen(true);
                        }}
                        showInMenu
                        disabled={
                            params.row.return_trip === true ||
                            params.row.status === 'canceled' ||
                            !!params.row.linked_transport_id
                        }
                    />
                );
                actions.push(
                    <GridActionsCellItem
                        key="divider"
                        disabled
                        sx={{
                            '&.Mui-disabled': {
                                opacity: 1,
                                cursor: 'default',
                                '& .MuiSvgIcon-root': { display: 'none' }
                            }
                        }}
                        showInMenu
                        label=""
                        icon={<Divider />}
                    />
                )
                if (user?.role === 'admin' || user?.role === 'poweruser') {
                    actions.push(
                        <GridActionsCellItem
                            icon={<ThumbUpOffAltIcon />}
                            label="Freigeben"
                            onClick={() => handleFreigeben(params.row.transport_id)}
                            showInMenu
                            disabled={params.row.status === 'in_transit' || params.row.status === 'finished' || params.row.status === 'canceled'} // Deaktiviert, wenn der Status 'in_transit' ist
                        />,
                        <GridActionsCellItem
                            icon={<DoDisturbIcon />}
                            label="Ablehnen"
                            onClick={() => handleAblehnung(params.row.transport_id)}
                            showInMenu
                            disabled={params.row.status === 'in_transit' || params.row.status === 'finished' || params.row.status === 'canceled'} // Deaktiviert, wenn der Status 'in_transit' ist
                        />
                    );
                }
                actions.push(
                    <GridActionsCellItem
                        key="divider"
                        disabled
                        sx={{
                            '&.Mui-disabled': {
                                opacity: 1,
                                cursor: 'default',
                                '& .MuiSvgIcon-root': { display: 'none' }
                            }
                        }}
                        showInMenu
                        label=""
                        icon={<Divider />}
                    />
                )
                actions.push(
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Stornieren"
                        onClick={() => handleDelete(params.row.transport_id)}
                        showInMenu
                        disabled={params.row.status === 'finished' || params.row.status === 'canceled'}
                    />
                );
                if (params.row.is_recurring || params.row.is_recurring_return) {
                    actions.push(
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Serie regelmäßiger Transporte stornieren"
                            onClick={() => handleDeleteSeries(params.row.series_identifier)}
                            showInMenu
                            disabled={params.row.status === 'in_transit' || params.row.status === 'finished' || params.row.status === 'canceled'} // Deaktiviert, wenn der Status 'in_transit' ist
                        />
                    );
                }
                if (user?.role === 'admin' || user?.role === 'poweruser') {
                    actions.push(
                        <GridActionsCellItem
                            key="divider"
                            disabled
                            sx={{
                                '&.Mui-disabled': {
                                    opacity: 1,
                                    cursor: 'default',
                                    '& .MuiSvgIcon-root': { display: 'none' }
                                }
                            }}
                            showInMenu
                            label=""
                            icon={<Divider />}
                        />,
                        <GridActionsCellItem
                            icon={<HistoryIcon />}
                            label="Historie anzeigen"
                            onClick={() => handleShowHistory(params.row.transport_id)}
                            showInMenu
                        />
                    );
                }



                return actions;
            },
        },
        {
            field: 'create_date',
            headerName: 'Erstellt am/von',
            width: settings.transportListWidths?.create_date || 200,
            renderCell: (params) => {
                const createDate = params.row.create_date ? new Date(params.row.create_date) : null;

                // Datum und Uhrzeit formatieren
                const formattedDate = createDate
                    ? createDate.toLocaleDateString('de-DE', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    })
                    : 'Unbekanntes Datum';

                const formattedTime = createDate
                    ? createDate.toLocaleTimeString('de-DE', {
                        hour: '2-digit',
                        minute: '2-digit',
                    })
                    : '00:00';

                const formattedDateTime = `${formattedDate} ${formattedTime}`;

                return (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center', // Vertikale Zentrierung
                            alignItems: 'flex-start', // Inhalte links ausrichten
                            height: '100%',
                            marginTop: '4px', // Abstand von oben
                        }}
                    >
                        {/* Datum und Uhrzeit */}
                        <Typography variant="body2">{formattedDateTime}</Typography>

                        {/* Benutzername */}
                        {params.row.transport_id && (
                            <Typography variant="caption" style={{ fontStyle: 'italic', color: 'gray' }}>
                                Transport-Id: {params.row.transport_id}
                            </Typography>
                        )}

                        {/* Benutzername */}
                        {params.row.username && (
                            <Typography variant="caption" style={{ fontStyle: 'italic', color: 'gray' }}>
                                {params.row.username}
                            </Typography>
                        )}

                        {/* Institution */}
                        {params.row.object_institution && (
                            <Typography variant="caption" style={{ fontStyle: 'italic', color: 'gray' }}>
                                {params.row.object_institution}
                            </Typography>
                        )}

                        {/* Automatisch generiert */}
                        {params.row.auto_generated && (
                            <Typography variant="caption" style={{ fontStyle: 'italic', color: 'blue' }}>
                                Automatisch generiert
                            </Typography>
                        )}
                    </div>
                );
            },
        }
    ];

    const isAdmin = ['admin'].includes(user.role);
    const columnPlanning = {
        field: 'planning_overview',
        headerName: 'Planung KTW-DISPO',
        width: 240,
        renderCell: (params) => {
            // Nur Admin/Poweruser => sonst leer
            const isAdmin = ['admin'].includes(user.role);
            if (!isAdmin) return null;

            const row = params.row;
            // row.planning_id, row.vehicle_name, row.planned_start, row.planned_end, row.plan_status
            if (!row.planning_id) {
                // Fehler aus booking_log anzeigen (angenommen, Fehler kommt von API)
                const errorMessage = row.booking_error || 'ungeplant'; // Fallback, falls kein Fehler geladen
                return (
                    <Typography
                        variant="body2"
                        color="error"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => navigate(`/planning/transport/${row.transport_id}`)} // Link mit transport_id
                    >
                        {errorMessage}
                    </Typography>
                );
            }

            const startTime = row.planned_start ? new Date(row.planned_start) : null;
            const endTime = row.planned_end ? new Date(row.planned_end) : null;
            const startStr = startTime ? startTime.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' }) : '??';
            const endStr = endTime ? endTime.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' }) : '??';

            return (
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%', marginTop: '4px', marginBottom: '4px' }}>
                    {/* Station-Name als klickbarer Link */}
                    <Typography
                        variant="body2"
                        sx={{ cursor: 'pointer', color: 'primary.main' }}
                        onClick={() => navigate(`/planning/${row.planning_id}`)}
                    >
                        {row.station_name || 'Wache ?'}
                    </Typography>

                    {/* Fahrzeug-Name als klickbarer Link */}
                    <Typography
                        variant="body2"
                        sx={{ fontStyle: 'italic', cursor: 'pointer', color: 'primary.main' }}
                        onClick={() => navigate(`/planning/${row.planning_id}`)}
                    >
                        {row.vehicle_name || 'Fahrzeug ?'}
                    </Typography>
                    <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                        {startStr} - {endStr}
                    </Typography>
                    <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                        Status: {row.plan_status}
                    </Typography>

                </div>
            );
        }
    };
    if (isAdmin) {
        columns.push(columnPlanning);
    }


    const handleCreateTransport = () => {
        navigate('/transport');
    };

    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchInputValue(value); // Sofortige UI-Aktualisierung
        debouncedSearch(value); // Verzögerte Filteraktualisierung
    };

    const handleDateFilterChange = (event) => {
        // eslint-disable-next-line
        const { name, value } = event.target;
        setDateFilter(value);
    };

    const handleClearDateFilter = () => {
        setDateFilter(null);
    };

    const handleClearSearch = () => {
        setSearchInputValue('');
        setSearchQuery('');
    };


    const lastEntry = historyData.reduce((acc, cur) => {
        return new Date(cur.modified_date) > new Date(acc.modified_date) ? cur : acc;
    }, historyData[0]);


    const filteredTransports = useMemo(() => {
        // Erstelle eine stabile Kopie der zu filternden Daten
        return transports
            .filter((transport) =>
                (transport.transport_type && transport.transport_type.toLowerCase().includes(searchQuery)) ||
                (transport.from_institution && transport.from_institution.toLowerCase().includes(searchQuery)) ||
                (transport.from_street && transport.from_street.toLowerCase().includes(searchQuery)) ||
                (transport.from_postal_code && transport.from_postal_code.toLowerCase().includes(searchQuery)) ||
                (transport.from_city && transport.from_city.toLowerCase().includes(searchQuery)) ||
                (transport.to_institution && transport.to_institution.toLowerCase().includes(searchQuery)) ||
                (transport.to_street && transport.to_street.toLowerCase().includes(searchQuery)) ||
                (transport.to_postal_code && transport.to_postal_code.toLowerCase().includes(searchQuery)) ||
                (transport.to_city && transport.to_city.toLowerCase().includes(searchQuery)) ||
                (transport.first_name && transport.last_name && (`${transport.first_name} ${transport.last_name}`).toLowerCase().includes(searchQuery)) ||
                (transport.transport_reason && transport.transport_reason.toLowerCase().includes(searchQuery))
            )
            .filter((transport) =>
                (statusFilter.pending && transport.status === 'pending') ||
                (statusFilter.approved && transport.status === 'approved') ||
                (statusFilter.rejected && transport.status === 'rejected') ||
                (statusFilter.scheduled && transport.status === 'scheduled') ||
                (statusFilter.finished && transport.status === 'finished') ||
                (statusFilter.in_transit && transport.status === 'in_transit') ||
                (statusFilter.canceled && transport.status === 'canceled')
            )
            .filter((transport) => {
                if (!dateFilter) {
                    return true;
                }

                const transportDate = new Date(transport.departure_date);
                const selectedDate = new Date(dateFilter);

                return transportDate.toDateString() === selectedDate.toDateString();
            })
            // Stelle sicher, dass jede Zeile eine ordnungsgemäße ID hat
            .map(transport => ({
                ...transport,
                // Für DataGrid eine besondere ID verwenden, um Konflikte zu vermeiden
                _uniqueId: transport.transport_id < 0 ?
                    `break_${Math.abs(transport.transport_id)}` :
                    `transport_${transport.transport_id}`
            }));
    }, [transports, searchQuery, statusFilter, dateFilter]);

    return (
        <Box m={1} >
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                <Stack justifyItems="flex-start" alignItems="center" direction="row">
                    <Typography variant="h5" color="#E46450" component="h1" gutterBottom mt={1}>
                        Offene Bestellungen
                    </Typography>

                    {isLoading && !isAutoLoading && (
                        <CircularProgress color="inherit" />
                    )}
                    {(!isLoading) && (
                        <IconButton ml={2} size="large" aria-label="refresh" onClick={fetchTransports}>
                            <RefreshIcon fontSize="large" />
                        </IconButton>
                    )}
                </Stack>

                <TextField
                    id="search"
                    label="Schnellsuche"
                    variant="outlined"
                    size="small"
                    value={searchInputValue}
                    onChange={handleSearch}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {searchInputValue && (
                                    <IconButton
                                        aria-label="Clear search"
                                        size="small"
                                        onClick={handleClearSearch}
                                        edge="end"
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                )}
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        width: '300px',
                    }}
                />
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                    <Button variant="outlined" color="primary" onClick={handleCreateTransport} startIcon={<AddIcon />}>
                        Neuen Transport bestellen
                    </Button>
                    <SettingsMenu
                        isAutoRefreshEnabled={isAutoRefreshEnabled}
                        toggleAutoRefresh={toggleAutoRefresh}
                    />
                </Box>
            </Box>
            {user?.role !== 'user' && (
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={1} sx={{
                    border: '1px solid #ccc', // Leicht grauer Rahmen
                    borderRadius: '4px', // Runde Ecken (optional)
                    padding: '12px', // Innenabstand
                }}>
                    <Stack justifyItems="flex-start" alignItems="center" direction="row">
                        <FilterAltIcon sx={{ marginRight: 1 }} /> {/* Filter-Symbol */}
                        <Typography variant="body1" sx={{ marginRight: 8, fontWeight: 'bold' }}>
                            Filter
                        </Typography>

                        {/* Status Filter */}
                        <Box display="flex" alignItems="center" mr={4}>
                            <Typography variant="body2" sx={{ marginRight: 1 }}>
                                Status:
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={statusFilter.pending}
                                        onChange={() => handleStatusFilterChange('pending')}
                                        color="primary"
                                    />
                                }
                                label={
                                    <Typography variant="body2">in Prüfung</Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={statusFilter.approved}
                                        onChange={() => handleStatusFilterChange('approved')}
                                        color="primary"
                                    />
                                }
                                label={
                                    <Typography variant="body2">Akzeptiert</Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={statusFilter.scheduled}
                                        onChange={() => handleStatusFilterChange('scheduled')}
                                        color="primary"
                                    />
                                }
                                label={
                                    <Typography variant="body2">vorgeplant</Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={statusFilter.in_transit}
                                        onChange={() => handleStatusFilterChange('in_transit')}
                                        color="primary"
                                    />
                                }
                                label={
                                    <Typography variant="body2">unterwegs</Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={statusFilter.finished}
                                        onChange={() => handleStatusFilterChange('finished')}
                                        color="primary"
                                    />
                                }
                                label={
                                    <Typography variant="body2">beendet</Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={statusFilter.canceled}
                                        onChange={() => handleStatusFilterChange('canceled')}
                                        color="primary"
                                    />
                                }
                                label={
                                    <Typography variant="body2">Storniert</Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={statusFilter.rejected}
                                        onChange={() => handleStatusFilterChange('rejected')}
                                        color="primary"
                                    />
                                }
                                label={
                                    <Typography variant="body2">Abgelehnt</Typography>
                                }
                            />
                        </Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DatePicker
                                    label="Transport Datum"
                                    name="filterDate"
                                    value={dateFilter}
                                    onChange={(date) => handleDateFilterChange({ target: { name: 'filterDate', value: date } })}
                                    margin="normal"
                                    fullWidth
                                    format="DD.MM.YYYY"
                                />
                                {dateFilter && (
                                    <IconButton
                                        size="small"
                                        onClick={handleClearDateFilter}
                                        edge="end"
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                )}
                            </div>
                        </LocalizationProvider>

                    </Stack>
                    {isExpertRole && (
                        <Button
                            variant="text"
                            color="primary"
                            onClick={() => navigate('/transportseries')}
                            style={{ marginTop: '8px' }}
                        >
                            Zu den regelmäßigen Transporten
                        </Button>
                    )}
                </Box>
            )}
            {serverError && <Alert severity="error" sx={{ my: 2 }}>{serverError}</Alert>}

            <div style={{ width: '100%' }}>
                <DataGrid
                    rows={filteredTransports}
                    columns={columns}
                    getRowClassName={(params) =>
                        params.row.status === 'canceled' ? 'canceled-row' : ''
                    }
                    pageSize={5}
                    rowsPerPageOptions={[5, 10, 20]}
                    getRowId={(row) => row._uniqueId || `fallback_${Math.random().toString(36).substr(2)}`}
                    onError={(error) => console.warn('DataGrid Error:', error)} // Fehler loggen
                    loading={isLoading || isAutoLoading || isFiltering} // Ladezustand während Filterung anzeigen

                    getRowHeight={() => 'auto'}
                    onColumnWidthChange={handleColumnWidthChange}
                    // Weitere Optimierung für Stabilität
                    componentsProps={{
                        row: {
                            onMouseEnter: () => { }
                        }
                    }}
                    disableSelectionOnClick // Verhindert Auswahlprobleme
                    keepNonExistentRowsSelected={false}
                    disableColumnMenu={false}
                    disableColumnSelector={false}
                    disableVirtualization={false} // Evtl. auf true setzen, wenn weiterhin Probleme auftreten
                />
                {(selectedTransport || selectedSeriesIdentifier) && (
                    <ConfirmationDialog
                        open={dialogOpen}
                        onClose={handleConfirmation}
                        onConfirm={handleConfirmation}
                        title={dialogTitle}
                        description={dialogDescription}
                        readonly={selectedTransport?.status === 'in_transit' && user?.role !== 'admin' && user?.role !== 'poweruser'}
                        linkedTransportId={linkedTransportId}
                    />
                )}
            </div>

            {/* Confirmation dialog for actions (delete, approve, etc.) */}
            <Dialog open={editDialogOpen} onClose={handleEditDialogClose}>
                <DialogTitle>Bearbeitungsoptionen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Möchten Sie nur diese Bestellung oder die gesamte Serie bearbeiten?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditSingleTransport} color="primary">
                        Nur diese Bestellung bearbeiten
                    </Button>
                    <Button onClick={handleEditRecurringSeries} color="primary">
                        Ganze Serie bearbeiten
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openHistoryDialog}
                onClose={handleCloseHistory}
                maxWidth="md"
                fullWidth
            >
                {/* HEADER mit Titel und Info-Button */}
                <Box display="flex" alignItems="center" justifyContent="space-between" px={2} pt={2}>
                    <Box display="flex" alignItems="center">
                        <DialogTitle sx={{ p: 0, mr: 2 }}>
                            Historie für Transport
                        </DialogTitle>

                        {lastEntry && (
                            <Typography variant="caption" sx={{ color: 'gray' }}>
                                {/* Beispiel-Formatierung: DD.MM.YYYY, HH:mm:ss */}
                                Letzte Änderung: {lastEntry.changed_by_user} am{' '}
                                {new Date(lastEntry.modified_date).toLocaleString('de-DE')}
                            </Typography>
                        )}
                    </Box>
                    <IconButton onClick={handleToggleHelp} aria-label="Info anzeigen" sx={{ marginLeft: 'auto' }}>
                        <InfoIcon />
                    </IconButton>
                </Box>

                <DialogContent dividers>
                    {/* Hilfe-Text einblenden, falls showHelp = true */}
                    {showHelp && (
                        <Box mb={2} p={2} border="1px solid #eee" borderRadius="4px" bgcolor="#f5f5f5">
                            <Typography variant="subtitle2" gutterBottom>
                                Erfassung der Änderungen (Historisierung)
                            </Typography>
                            <Typography variant="body2" paragraph>
                                Wir speichern automatisch nur Änderungen an den folgenden Feldern:
                            </Typography>
                            <ul style={{ marginTop: 0, marginBottom: '8px' }}>
                                <li><strong>status</strong> : Status des Transports</li>
                                <li><strong>transport_type</strong> : Transport Art</li>
                                <li><strong>departure_date</strong> : Transport Datum</li>
                                <li><strong>departure_time</strong> : Transport Datum</li>
                                <li><strong>from_address</strong> : Von Adresse</li>
                                <li><strong>to_address</strong> : Nach Adresse</li>
                                <li><strong>transport_mode</strong> : Transport Art (liegend / sitzend)</li>
                                <li><strong>self_payer</strong> : Selbstzahler</li>
                                <li><strong>ambulatory</strong> : Ambulant</li>
                                <li><strong>export_status</strong> : Export Status, z.B. Opheo Export</li>
                            </ul>
                            <Typography variant="body2">
                                Andere Felder (z.B. patient_id, phone_number etc.) werden derzeit <em>nicht</em> historisiert.
                                Bei Fragen wenden Sie sich bitte an die Projektgruppe.
                            </Typography>
                        </Box>
                    )}

                    {/* Hier die eigentliche Anzeige der History-Einträge */}
                    {historyData.length === 0 ? (
                        <Typography variant="body2">Keine Historie-Einträge vorhanden.</Typography>
                    ) : (
                        historyData.map((entry) => (
                            /* render dein HistoryEntryCard o.Ä. */
                            <HistoryEntryCard key={entry.historie_id} entry={entry} />
                        ))
                    )}
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseHistory}>Schließen</Button>
                </DialogActions>
            </Dialog>
        </Box >
    );
};

export default TransportList;
