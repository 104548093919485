// PersistentDataGrid.js
import React, { useMemo } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useUser } from '../components/userContext'; // Pfad ggf. anpassen

/**
 * PersistentDataGrid ist ein Wrapper um den MUI DataGrid.
 *
 * Props:
 * - storageKey: String, der angibt, unter welchem Schlüssel in den Settings
 *   die Spaltenbreiten gespeichert werden (z. B. "transportListWidths" oder "shiftPlanListWidths").
 * - columns: Die Columns-Definitionen des DataGrid.
 * - onColumnWidthChange: Optionaler Callback, der zusätzlich aufgerufen wird, wenn sich
 *   die Spaltenbreite ändert.
 * - ...other: Alle weiteren Props, die an das DataGrid weitergereicht werden.
 */
const PersistentDataGrid = ({ storageKey, columns, onColumnWidthChange, ...other }) => {
    const { settings, updateSetting } = useUser();
    // Auslesen des persistierten Objekts, z. B. settings.transportListWidths
    // Initialisierung von storedWidths in einem eigenen useMemo-Hook
    const storedWidths = useMemo(() => settings[storageKey] || {}, [settings, storageKey]);


    // Mergen der übergebenen Spalten mit den persistierten Breiten, falls vorhanden
    const mergedColumns = useMemo(() => {
        return columns.map((col) => ({
            ...col,
            // Wenn für das Feld bereits eine persistierte Breite existiert, diese verwenden,
            // ansonsten den in der Column-Definition gesetzten Wert oder einen Default (z. B. 150)
            width: storedWidths[col.field] || col.width || 150,
        }));
    }, [columns, storedWidths]);

    // Handler, der bei einer Änderung der Spaltenbreite aufgerufen wird
    const handleColumnWidthChange = (params, event) => {
        const { colDef, width } = params;
        // Erstelle ein aktualisiertes Objekt, in dem die neue Breite gespeichert wird
        const updatedWidths = { ...storedWidths, [colDef.field]: width };
        // Persistiere das aktualisierte Objekt unter dem übergebenen storageKey
        updateSetting(storageKey, updatedWidths);

        // Falls ein externer onColumnWidthChange-Callback übergeben wurde, rufe diesen ebenfalls auf
        if (onColumnWidthChange) {
            onColumnWidthChange(params, event);
        }
    };

    return (
        <DataGrid
            {...other}
            columns={mergedColumns}
            onColumnWidthChange={handleColumnWidthChange}
        />
    );
};

export default PersistentDataGrid;
