import React, { useEffect, useState } from 'react';
import { useUser } from '../../components/userContext';
import {
    Typography,
    Box,
    CircularProgress,
} from '@mui/material';
import {
    Timeline,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
} from '@mui/lab';
import dayjs from 'dayjs';

const planningTypeLabels = {
    TO_START: "Anfahrt",
    MAIN: "Transport (mit Patient)",
    TO_GARAGE: "Rückfahrt zur Wache",
    BREAK: "Pause",
    SERVICE: "Wartung",
    LOADING: "Beladung",
    DELIVERY: "Entladung",
    CUSTOM: "Sonstiges",
    CLEANING: "Reinigung",
};

/**
 * Wiederverwendbare Timeline-Komponente für Planungsdetails
 * @param {Object} props - Component properties
 * @param {string} props.planning_id - ID der Planung, die angezeigt werden soll
 */
function PlanningDetailTimeline({ planning_id, showHeader = true }) {
    const { user } = useUser();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [planning, setPlanning] = useState(null);
    const [details, setDetails] = useState([]);

    useEffect(() => {
        if (planning_id) {
            fetchPlanning();
        }
        // eslint-disable-next-line
    }, [planning_id]);

    async function fetchPlanning() {
        setLoading(true);
        setError(null);
        try {
            const planningResponse = await fetch(`${process.env.REACT_APP_API_URI}/api/planning/${planning_id}`, {
                headers: { 'Authorization': user.api_key },
            });

            if (!planningResponse.ok) {
                throw new Error(`Fehler beim Laden der Planung: ${planningResponse.status}`);
            }

            const planningData = await planningResponse.json();
            setPlanning(planningData.planning);
            setDetails(planningData.details);
        } catch (err) {
            console.error(err);
            setError(err.message || 'Fehler beim Laden der Planungsdaten');
        } finally {
            setLoading(false);
        }
    }

    function translatePlanningType(type) {
        return planningTypeLabels[type] || type;
    }

    function formatDateTime(dateStr) {
        return dateStr ? dayjs(dateStr).format('DD.MM.YYYY HH:mm') : '';
    }

    function formatTime(dateStr) {
        return dateStr ? dayjs(dateStr).format('HH:mm') : '';
    }

    function formatRouteInfo(distance_meters, duration_seconds) {
        if (distance_meters == null || duration_seconds == null) return '';
        const km = (distance_meters / 1000).toFixed(1);
        const min = (duration_seconds / 60).toFixed(1);
        return `${km} km, ${min} Min`;
    }

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ textAlign: 'center', my: 4, color: 'error.main' }}>
                <Typography variant="h6">Fehler</Typography>
                <Typography variant="body1">{error}</Typography>
            </Box>
        );
    }

    if (!planning) {
        return (
            <Box sx={{ textAlign: 'center', my: 4 }}>
                <Typography variant="body1">Keine Planungsdaten verfügbar</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            mt: 1,
        }}>
            {showHeader && (
                <>
                    <Typography variant="h5" gutterBottom sx={{ textAlign: 'center' }}>
                        Planung {planning.transport_type}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2, textAlign: 'center' }}>
                        Planungszeitraum: <strong>{formatDateTime(planning.planned_start)}</strong> - <strong>{formatDateTime(planning.planned_end)}</strong> (Status: {planning.status})
                    </Typography>
                </>
            )}
            {!showHeader && (
                <>
                    <Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
                        <strong>{formatDateTime(planning.planned_start)}</strong> - <strong>{formatDateTime(planning.planned_end)}</strong>
                    </Typography>
                </>
            )}
            <Timeline position="alternate">
                {details.map((d, index) => {
                    const timeLabel = `${formatTime(d.planned_start)} - ${formatTime(d.planned_end)}`;
                    const routeInfo = formatRouteInfo(d.distance_meters, d.duration_seconds);

                    return (
                        <TimelineItem key={d.planning_detail_id}>
                            <TimelineOppositeContent sx={{ m: 'auto 0', fontWeight: 'bold' }} align="right" variant="body2" color="text.secondary">
                                {timeLabel}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot color="primary" />
                                {index < details.length - 1 && <TimelineConnector />}
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span" sx={{ fontWeight: 'bold' }}>
                                    {translatePlanningType(d.planning_type)}
                                </Typography>
                                <Typography variant="caption" color="gray" display="block">
                                    ID: #{d.planning_detail_id}
                                </Typography>
                                {d.start_latitude && d.start_longitude && (
                                    <Typography variant="caption" display="block">
                                        Von: {d.start_address_text || '???'}
                                    </Typography>
                                )}
                                {d.destination_latitude && d.destination_longitude && (
                                    <Typography variant="caption" display="block">
                                        Nach: {d.destination_address_text || '???'}
                                    </Typography>
                                )}
                                {routeInfo && (
                                    <Typography variant="caption" color="gray" display="block">
                                        Route: {routeInfo}
                                    </Typography>
                                )}
                            </TimelineContent>
                        </TimelineItem>
                    );
                })}
            </Timeline>
        </Box>
    );
}

export default PlanningDetailTimeline;