import React, { useState, useEffect } from 'react';
import { Alert, Box, Button, Checkbox, CircularProgress, DialogContentText, FormControlLabel, IconButton, Stack, Typography } from '@mui/material';
import { useUser } from '../components/userContext';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { renewToken } from '../misc/helperFunctions';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PauseIcon from '@mui/icons-material/Pause';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import RefreshIcon from '@mui/icons-material/Refresh';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useThemeContext, THEME_CLASSIC } from '../theme/ThemeContext';
import {
    DrkCard,
    DrkActionBar,
    DrkDataGrid,
    DrkDialog
} from '../components/DrkComponents';

const ConfirmationDialog = ({ open, onClose, onConfirm, title, description }) => {
    return (
        <DrkDialog
            open={open}
            onClose={() => onClose(false)}
            title={title}
            actions={
                <>
                    <Button onClick={() => onClose(false)}>Abbrechen</Button>
                    <Button onClick={() => onConfirm(true)} color="primary">Ja</Button>
                </>
            }
        >
            <DialogContentText sx={{ p: 2 }}>
                {description}
            </DialogContentText>
        </DrkDialog>
    );
};

const UsersList = () => {
    const [users, setUsers] = useState([]);
    const { user } = useUser();
    const [selectedUser, setSelectedUser] = useState(null);
    const [dialogAction, setDialogAction] = useState(null);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogDescription, setDialogDescription] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [serverError, setServerError] = useState(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true); // for loading Icon
    const [searchQuery, setSearchQuery] = useState('');
    const [statusFilter, setStatusFilter] = useState({
        pending: true,
        approved: true,
    });
    const { currentTheme } = useThemeContext();
    const isClassic = currentTheme === THEME_CLASSIC;

    useEffect(() => {
        if (user) {
            fetchUsers();
        }
        // eslint-disable-next-line
    }, [user]);

    const fetchUsers = async () => {
        try {
            // API aufrufen, um die Liste aller Benutzer abzurufen
            const response = await fetch(process.env.REACT_APP_API_URI + '/api/users', {
                headers: {
                    'Authorization': user.api_key,
                },
            });
            if (!response.ok) {
                throw new Error('Fehler beim Abrufen der Benutzer');
            }
            const data = await response.json();
            setUsers(data);
            await renewToken();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleConfirmation = async (confirm) => {
        setDialogOpen(false); // Close the dialog

        if (confirm) {
            try {
                let updatedUser = { ...selectedUser };

                switch (dialogAction) {
                    case 'freigeben':
                        updatedUser.registration_status = 'bestätigt';
                        updatedUser.account_activated = true;
                        await updateUser(updatedUser);
                        break;
                    case 'sperren':
                        updatedUser.account_activated = false;
                        await updateUser(updatedUser);
                        break;
                    case 'löschen':
                        await deleteUser(selectedUser.user_id);
                        break;
                    default:
                        break;
                }

                setServerError(null);
                // Refresh the user list after successful update
                await fetchUsers();
            } catch (error) {
                console.error('Fehler beim Aktualisieren des Benutzers:', error);
                setServerError(error.message);
            }
        }

        // Reset the selected user and action after the action is complete
        setSelectedUser(null);
        setDialogAction(null);
    };

    const updateUser = async (updatedUser) => {
        await fetch(`${process.env.REACT_APP_API_URI}/api/users/${updatedUser.user_id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': user.api_key,
            },
            body: JSON.stringify(updatedUser),
        });
    };

    const deleteUser = async (userId) => {
        const response = await fetch(`${process.env.REACT_APP_API_URI}/api/users/${userId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': user.api_key,
            },
        });

        if (!response.ok) {
            const errorData = await response.json(); // Nehmen Sie an, dass der Server ein JSON-Objekt mit Fehlerdetails zurückgibt
            throw new Error(errorData.message || 'Fehler beim Löschen des Benutzers. Der Benutzer wird noch in anderen Tabellen referenziert.');
        }
    };

    const handleEdit = (id) => {
        console.log('Bearbeiten:', id);
        const fullUser = findUserById(id);
        console.log("Found user " + fullUser.user_id + " " + fullUser.username + " " + fullUser);
        setSelectedUser(fullUser);
        navigate(`/edituser/${fullUser.username}`);
    };

    const handleDelete = (id) => {
        console.log('Löschen:', id);
        const fullUser = findUserById(id);
        console.log("Found user " + fullUser.user_id + " " + fullUser.username + " " + fullUser);
        setSelectedUser(fullUser);
        setDialogAction('löschen');
        setDialogTitle('Löschen');
        setDialogDescription(`Möchten Sie den Benutzer "${fullUser.username}" löschen?`);
        setDialogOpen(true);
    };

    const handleLock = (id) => {
        console.log('User sperren:', id);
        const fullUser = findUserById(id);
        console.log("Found user " + fullUser.user_id + " " + fullUser.username + " " + fullUser);
        setSelectedUser(fullUser);
        setDialogAction('sperren');
        setDialogTitle('Sperren');
        setDialogDescription(`Möchten Sie den Benutzer "${fullUser.username}" sperren?`);
        setDialogOpen(true);
    };

    const handleFreigeben = (id) => {
        console.log("Freigabe button clicked " + id);
        const fullUser = findUserById(id);
        console.log("Found user " + fullUser.user_id + " " + fullUser.username + " " + fullUser);
        setSelectedUser(fullUser);
        setDialogAction('freigeben');
        setDialogTitle('Freigeben');
        setDialogDescription(`Möchten Sie den Benutzer "${fullUser.username}" freigeben?`);
        setDialogOpen(true);
    };

    const handleStatusFilterChange = (status) => {
        setStatusFilter((prevStatusFilter) => ({
            ...prevStatusFilter,
            [status]: !prevStatusFilter[status],
        }));
    };

    function renderInstitutionCell(params) {
        // Überprüfen, ob standort_guid null ist
        return params.row.standort_guid == null ? (
            <Link to={`/institutionpage/${params.row.institution_id}`}>
                {params.value}
            </Link>
        ) : (
            <span>{params.value}</span> // Nur Text anzeigen, wenn standort_guid nicht null ist
        );
    }

    function findUserById(id) {
        const userId = Number(id); // Konvertieren Sie die ID in eine Zahl
        for (let i = 0; i < usersWithId.length; i++) {
            if (usersWithId[i].id === userId) {
                return users[i];
            }
        }
        return null;
    }

    const columns = [
        //    { field: 'user_id', headerName: 'ID', width: 1 },
        { field: 'username', headerName: 'Benutzername', width: 140 },
        {
            field: 'object_institution',
            headerName: 'Institution',
            width: 140,
            renderCell: renderInstitutionCell
        },
        // { field: 'postal_code', headerName: 'PLZ', width: 100 },
        //{ field: 'city', headerName: 'Stadt', width: 150 },
        { field: 'station', headerName: 'Station', width: 100 },
        // { field: 'phone_number', headerName: 'Telefonnummer', width: 150 },
        {
            field: 'registration_status',
            headerName: 'Registrierungsstatus',
            width: 150,
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {params.row.registration_status === 'in Bearbeitung' ? (
                        <PauseIcon style={{ color: '#E46450', marginRight: 5 }} />
                    ) : params.row.registration_status === 'bestätigt' ? (
                        <ThumbUpOffAltIcon style={{ color: 'green', marginRight: 5 }} />
                    ) : (
                        <DoDisturbIcon style={{ color: 'red', marginRight: 5 }} />
                    )}
                    <Typography variant="body2" style={{ color: params.row.registration_status === 'in Bearbeitung' ? '#E46450' : params.row.registration_status === 'bestätigt' ? 'green' : 'red' }}>
                        {params.row.registration_status}
                    </Typography>
                </div>
            ),
        },
        {
            field: 'account_activated',
            headerName: 'Acc. Aktiviert',
            width: 80,
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {params.row.account_activated === false ? (
                        <DoDisturbIcon style={{ color: '#E46450', marginRight: 5 }} />
                    ) : (
                        <ThumbUpOffAltIcon style={{ color: 'green', marginRight: 5 }} />
                    )}
                    <Typography variant="body2" style={{ color: params.row.account_activated === false ? 'red' : 'green' }}>
                        {params.row.account_activated}
                    </Typography>
                </div>
            ),
        },
        {
            field: 'role',
            headerName: 'Rolle',
            width: 90,
            renderCell: (params) => {
                if (params.value === 'poweruser') {
                    return 'Disponent';
                } else if (params.value === 'dialysis') {
                    return 'Dialyse';
                } else {
                    return params.value;
                }
            }
        },
        {
            field: 'create_date',
            headerName: 'Erstellt am',
            width: 200,
            renderCell: (params) => {
                const createDate = params.row.create_date ? new Date(params.row.create_date) : null;

                // Datum und Uhrzeit formatieren
                const formattedDate = createDate
                    ? createDate.toLocaleDateString('de-DE', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    })
                    : 'Unbekanntes Datum';

                const formattedTime = createDate
                    ? createDate.toLocaleTimeString('de-DE', {
                        hour: '2-digit',
                        minute: '2-digit',
                    })
                    : '00:00';

                const formattedDateTime = `${formattedDate} ${formattedTime}`;

                return (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center', // Vertikale Zentrierung
                            alignItems: 'flex-start', // Inhalte links ausrichten
                            height: '100%',
                            marginTop: '4px', // Abstand von oben
                        }}
                    >
                        {/* Datum und Uhrzeit */}
                        <Typography variant="body2">{formattedDateTime}</Typography>
                    </div>
                );
            },
        },
        {
            field: 'actions2',
            type: 'actions',
            headerName: 'Aktionen',
            width: 120,
            getActions: (params) => {
                return ([
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Löschen"
                        onClick={() => handleDelete(params.id)}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        icon={<CheckBoxIcon />}
                        label="Freigeben"
                        onClick={() => handleFreigeben(params.id)}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        icon={<BlockIcon />}
                        label="Sperren"
                        onClick={() => handleLock(params.id)}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Bearbeiten"
                        onClick={() => handleEdit(params.id)}
                    />,
                ]);
            },
        }
    ];

    // Modify the data to include the `id` property
    const usersWithId = users.map((user) => ({
        id: user.user_id, // Use the `user_id` as the `id` property
        ...user,
    }));

    const handleCreateUser = () => {
        navigate('/edituser');
    };

    const filteredUsers = usersWithId
        .filter((user) =>
            (user.username && user.username.toLowerCase().includes(searchQuery)) ||
            (user.object_institution && user.object_institution.toLowerCase().includes(searchQuery)) ||
            (user.role && user.role.toLowerCase().includes(searchQuery)) ||
            (user.station && user.station.toLowerCase().includes(searchQuery))
        )
        .filter((user) =>
            // Filterbedingungen für den Status-Filter hier einfügen
            (statusFilter.pending && user.registration_status === 'in Bearbeitung') ||
            (statusFilter.approved && user.registration_status === 'bestätigt')
        );

    return (
        <Box m={1}>
            <DrkCard
                elevation={isClassic ? 2 : 0}
                maxWidth="100%"
                sx={{ p: 0 }}
            >
                {/* Titel und Aktionsleiste */}
                <Box display="flex" alignItems="center" justifyContent="space-between" p={0}>
                    <Stack justifyItems="flex-start" alignItems="center" direction="row">
                        <Typography
                            variant="h5"
                            color="primary"
                            component="h1"
                            gutterBottom={false}
                            sx={{ mt: 0, mb: 0 }}
                        >
                            Benutzerliste
                        </Typography>

                        {isLoading ? (
                            <CircularProgress color="inherit" size={24} sx={{ ml: 2 }} />
                        ) : (
                            <IconButton
                                size="medium"
                                aria-label="refresh"
                                onClick={fetchUsers}
                                sx={{ ml: 1 }}
                            >
                                <RefreshIcon />
                            </IconButton>
                        )}
                    </Stack>

                    {/* Suchleiste mit DrkActionBar */}
                    <DrkActionBar
                        searchTerm={searchQuery}
                        onSearchChange={value => setSearchQuery(value)}
                        searchPlaceholder="Benutzer suchen..."
                        showSearch={true}
                        sx={{ width: '800px', mt: 1 }}
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCreateUser}
                        startIcon={<AddIcon />}
                    >
                        Neuen Benutzer hinzufügen
                    </Button>
                </Box>



                {/* Filter-Bereich */}
                <Box display="flex" alignItems="center" justifyContent="space-between" sx={{
                    border: '1px solid #ccc',
                    borderRadius: isClassic ? '4px' : '8px',
                    padding: '8px',
                }}>
                    <Stack justifyItems="flex-start" alignItems="center" direction="row">
                        <FilterAltIcon sx={{ marginRight: 1 }} />
                        <Typography variant="body1" sx={{ marginRight: 11 }}>
                            Filter
                        </Typography>

                        {/* Status Filter */}
                        <Box display="flex" alignItems="center">
                            <Typography variant="body2" sx={{ marginRight: 1 }}>
                                Registrierungsstatus:
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={statusFilter.pending}
                                        onChange={() => handleStatusFilterChange('pending')}
                                        color="primary"
                                    />
                                }
                                label={
                                    <Typography variant="body2">in Bearbeitung</Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={statusFilter.approved}
                                        onChange={() => handleStatusFilterChange('approved')}
                                        color="primary"
                                    />
                                }
                                label={
                                    <Typography variant="body2">bestätigt</Typography>
                                }
                            />
                        </Box>
                    </Stack>
                </Box>

                {/* Fehlermeldungen */}
                {serverError && <Alert severity="error">{serverError}</Alert>}

                {/* DataGrid für Benutzerliste */}
                <Box sx={{ mt: 2 }}>
                    <DrkDataGrid
                        storageKey="usersList"
                        rows={filteredUsers}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5, 10, 20]}
                        autoHeight
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'username', sort: 'asc' }],
                            },
                        }}
                    />
                </Box>

                {/* Confirmation Dialog */}
                {selectedUser && (
                    <ConfirmationDialog
                        open={dialogOpen}
                        onClose={handleConfirmation}
                        onConfirm={handleConfirmation}
                        title={dialogTitle}
                        description={dialogDescription}
                    />
                )}
            </DrkCard>
        </Box>
    );
}

export default UsersList;