import React, { useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import dayjs from 'dayjs';
import { calcPosition, ROW_HEIGHT } from './timelineUtils';
import { getTransportTypeStyle } from './transportTypeUtils';
import debounce from 'lodash/debounce';
import TransportBarContextMenu from './TransportBarContextMenu';
import { useDrag, useDrop } from 'react-dnd';

// Definieren wir die DnD-Typen
export const ItemTypes = {
    TRANSPORT: 'transport',
    BREAK: 'break'
};

// Hilfsfunktion zum Formatieren der Zeit
const formatTime = (timeString) => {
    const time = dayjs(timeString);
    return time.format('HH:mm');
};

// Komponente für einen einzelnen Transportbalken
const TransportBar = ({
    transport,
    left,
    width,
    style,
    onClick,
    onHover,
    onLeave,
    onDetailsTransport,
    onEditTransport,
    onDeleteTransport,
    onDuplicateTransport,
    onAddBreakAfter,
    onDrop,
    onShowRoundTrip,
    isBlinking
}) => {
    const typeKey = transport.transport_type || 'DEFAULT';
    const typeStyle = getTransportTypeStyle(typeKey);
    const ref = useRef(null);

    // State für Kontext-Menü
    const [contextMenu, setContextMenu] = useState({
        open: false,
        position: null
    });

    // Bestimme, ob das Element verschiebbar ist (standardmäßig nur Pausen)
    const isDraggable = typeKey === 'BREAK';

    // DnD: Drag-Konfiguration
    const [{ isDragging }, drag] = useDrag({
        type: isDraggable ? ItemTypes.BREAK : ItemTypes.TRANSPORT,
        item: () => ({
            id: transport.planning_detail_id,
            transport: transport,
            originalLeft: left,
            width: width
        }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: () => isDraggable // Nur Pausen können verschoben werden
    });

    // DnD: Drop-Konfiguration (falls wir auch Drops auf Transporte erlauben wollen)
    const [, drop] = useDrop({
        accept: [ItemTypes.BREAK],
        hover(item, monitor) {
            if (!ref.current) return;

            // Verhindere Hover auf sich selbst
            if (item.id === transport.planning_detail_id) return;
        }
    });

    // Kombiniere refs für Drag und Drop
    drag(drop(ref));

    const debouncedHover = useMemo(
        () =>
            debounce((target, transport) => {
                // Hier bauen wir ein simples Objekt mit currentTarget
                onHover({ currentTarget: target }, transport);
            }, 500),
        [onHover]
    );

    const handleMouseEnter = (e) => {
        // Erfasse das DOM-Element sofort, bevor der Debounce-Delay beginnt
        const target = e.currentTarget;
        debouncedHover(target, transport);
    };

    const handleMouseLeave = (e) => {
        // Bei Verlassen des Elements den Debounce abbrechen
        debouncedHover.cancel();
        onLeave(e);
    };

    // Handler für Rechtsklick auf den Balken
    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setContextMenu({
            open: true,
            position: { x: e.clientX, y: e.clientY }
        });
    };

    // Handler für Kontext-Menü schließen
    const handleCloseContextMenu = () => {
        setContextMenu({
            ...contextMenu,
            open: false
        });
    };

    // Handler für Action-Button Klick (drei Punkte)
    const handleActionClick = (e) => {
        e.stopPropagation();
        const buttonRect = e.currentTarget.getBoundingClientRect();

        setContextMenu({
            open: true,
            position: {
                x: buttonRect.right,
                y: buttonRect.top
            }
        });
    };

    // Textinhalt des Balkens
    const getBarContent = () => {
        // Berechne die verfügbare Breite für Text (in Pixel)
        // Wenn zu schmal, zeige nur das Icon an
        if (width < 50) {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    {typeStyle.icon}
                </Box>
            );
        }

        // Bei mittlerer Breite zeige Zeit an
        if (width < 120) {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    {typeStyle.icon}
                    <Typography
                        variant="caption"
                        sx={{
                            ml: 0.5,
                            fontWeight: 'bold',
                            fontSize: '0.7rem'
                        }}
                    >
                        {formatTime(transport.planned_start_full)}
                    </Typography>
                </Box>
            );
        }

        if (width < 200) {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', pr: 0.5 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
                        {typeStyle.icon}
                        <Typography
                            variant="caption"
                            sx={{
                                ml: 0.5,
                                fontWeight: 'bold',
                                fontSize: '0.7rem'
                            }}
                        >
                            {typeKey === 'BREAK' ? 'Pause' : typeKey}
                            {transport.return_trip && ' (R)'}
                        </Typography>
                    </Box>
                    {width > 150 && (
                        <IconButton
                            size="small"
                            sx={{
                                color: typeStyle.actionColor || 'inherit',
                                p: 0.3,
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.2)'
                                }
                            }}
                            onClick={handleActionClick}
                        >
                            <MoreVertIcon fontSize="small" />
                        </IconButton>
                    )}
                </Box>
            );
        }

        // Bei ausreichender Breite zeige mehr Informationen an
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                px: 1
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {typeStyle.icon}
                    <Typography
                        variant="caption"
                        sx={{
                            ml: 0.5,
                            fontWeight: 'bold',
                            fontSize: '0.7rem'
                        }}
                    >
                        {typeKey === 'BREAK' ? 'Pause' : typeKey}
                        {transport.return_trip && ' (R)'}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        variant="caption"
                        sx={{
                            fontWeight: 'medium',
                            fontSize: '0.7rem',
                            mr: 1
                        }}
                    >
                        {formatTime(transport.planned_start_full)} - {formatTime(transport.planned_end_full)}
                    </Typography>
                    <IconButton
                        size="small"
                        sx={{
                            color: typeStyle.actionColor || 'inherit',
                            p: 0.3,
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.2)'
                            }
                        }}
                        onClick={handleActionClick}
                    >
                        <MoreVertIcon fontSize="small" />
                    </IconButton>
                </Box>
            </Box>
        );
    };

    return (
        <>
            <Box
                ref={ref} // Kombinierte Drag & Drop-Referenz
                sx={{
                    position: 'absolute',
                    top: `calc(50% - ${ROW_HEIGHT * 0.35}px)`, // Halbe Höhe des Balkens (0.7 * ROW_HEIGHT / 2)
                    left,
                    width: Math.max(width, 20),
                    height: ROW_HEIGHT * 0.7,
                    borderRadius: '6px',
                    color: typeStyle.textColor,
                    background: typeStyle.gradient,
                    boxShadow: typeStyle.shadow,
                    border: typeStyle.border,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    zIndex: isDragging ? 10 : 2,
                    transition: 'box-shadow 0.15s ease-in-out',
                    cursor: isDraggable ? 'grab' : 'pointer',
                    opacity: isDragging ? 0 : 1, // Vollständig unsichtbar während des Draggens
                    '&:hover': {
                        background: typeStyle.hoverGradient,
                        boxShadow: typeStyle.hoverShadow,
                    },
                    // Spezielle Hintergrundmuster für Rückfahrten
                    ...(transport.return_trip && {
                        backgroundImage: `repeating-linear-gradient(
                    -45deg,
                    transparent,
                    transparent 8px,
                    rgba(255, 255, 255, 0.15) 8px,
                    rgba(255, 255, 255, 0.15) 16px
                ), ${typeStyle.gradient}`,
                    }),
                    // Blinkeffekt für ausgewählte Hin- und Rückfahrten
                    ...(isBlinking && {
                        animation: 'blink 0.5s ease-in-out infinite alternate',
                        '@keyframes blink': {
                            '0%': {
                                opacity: 1,
                                boxShadow: '0 0 5px 2px rgba(33, 150, 243, 0.8)'
                            },
                            '100%': {
                                opacity: 0.7,
                                boxShadow: '0 0 10px 4px rgba(33, 150, 243, 0.8)'
                            }
                        }
                    }),
                    ...style
                }}
                onClick={onClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onContextMenu={handleContextMenu}
            >
                {getBarContent()}
            </Box>

            {/* Context Menu */}
            <TransportBarContextMenu
                mode="forTimeLine"
                open={contextMenu.open}
                anchorPosition={contextMenu.position}
                onClose={handleCloseContextMenu}
                transport={transport}
                onDetails={onDetailsTransport}
                onEdit={onEditTransport}
                onDelete={onDeleteTransport}
                onDuplicate={onDuplicateTransport}
                onAddBreakAfter={onAddBreakAfter}
                onShowRoundTrip={onShowRoundTrip}
            />
        </>
    );
};

// Aktualisierte TransportBars-Komponente
function TransportBars({
    transports,
    vehicleId,
    selectedDate,
    hourWidth,
    onTransportClick,
    onHover,
    onLeave,
    onDetailsTransport,
    onEditTransport,
    onDeleteTransport,
    onDuplicateTransport,
    onAddBreakAfter,
    onTransportDrop, // Neuer Prop für Drag & Drop
    onShowRoundTrip, // Neuer Prop für Hin- und Rückfahrt anzeigen
    blinkingTransports = [] // Neue Prop für blinkende Transporte
}) {
    // console.time('TransportBars render');
    // Referenz für den Drag-Zielbereich
    const dropTargetRef = useRef(null);

    // Implementierung des Drop-Targets für die gesamte Transportfahrzeugzeile
    const [, vehicleRowDrop] = useDrop({
        accept: [ItemTypes.BREAK],
        drop(item, monitor) {
            if (!dropTargetRef.current) return;

            const dropBoundingRect = dropTargetRef.current.getBoundingClientRect();
            const clientOffset = monitor.getClientOffset();
            const initialClientOffset = monitor.getInitialClientOffset();
            const initialSourceOffset = monitor.getInitialSourceClientOffset();

            if (!clientOffset || !initialClientOffset || !initialSourceOffset) return;

            const dragOffsetX = initialClientOffset.x - initialSourceOffset.x;
            const timelineOffset = clientOffset.x - dropBoundingRect.left - dragOffsetX;
            const newHourPosition = timelineOffset / hourWidth;
            const clampedHourPosition = Math.max(0, newHourPosition);

            const transportDate = dayjs(item.transport.planned_start_full).format('YYYY-MM-DD');
            const dayStart = dayjs(transportDate).startOf('day');
            const newStartTime = dayStart.add(clampedHourPosition, 'hour');
            const originalStart = dayjs(item.transport.planned_start_full);
            const originalEnd = dayjs(item.transport.planned_end_full);
            const durationMinutes = originalEnd.diff(originalStart, 'minute');
            const newEndTime = newStartTime.add(durationMinutes, 'minute');

            if (onTransportDrop) {
                onTransportDrop(item.transport, newStartTime, newEndTime);
            }

            // Kein Return-Wert nötig, da der State lokal aktualisiert wird
        },
    });

    // Filtere und gruppiere Transporte nach vehicle_id und sortiere sie nach planned_start
    const groupTransports = transports
        .filter((t) => t.vehicle_id === vehicleId)
        .sort((a, b) => dayjs(a.planned_start).diff(dayjs(b.planned_start)))
        .reduce((acc, transport) => {
            const lastGroup = acc[acc.length - 1];
            if (lastGroup && lastGroup[lastGroup.length - 1].transport_id === transport.transport_id) {
                lastGroup.push(transport);
            } else {
                acc.push([transport]);
            }
            return acc;
        }, []);

    // Funktion zur Berechnung der Position und Breite eines zusammengeführten Balkens
    const calculateMergedBar = (transports) => {
        const firstTransport = transports[0];
        const lastTransport = transports[transports.length - 1];
        const { left: startLeft } = calcPosition(firstTransport.planned_start, firstTransport.planned_end, selectedDate, hourWidth);
        const { left: endLeft, width: endWidth } = calcPosition(lastTransport.planned_start, lastTransport.planned_end, selectedDate, hourWidth);
        const width = endLeft + endWidth - startLeft;
        return { left: startLeft, width };
    };

    // Funktion zum Erstellen des erweiterten Transport-Objekts
    const createExtendedTransport = (transportGroup) => {
        const firstTransport = transportGroup[0];
        return {
            ...firstTransport,
            transport_segments: transportGroup.map((transport) => ({
                planning_detail_id: transport.planning_detail_id,
                planning_type: transport.planning_type,
                planned_start: transport.planned_start,
                planned_end: transport.planned_end,
                route_id: transport.route_id,
            })),
        };
    };

    // Kombiniere das Drop-Target mit der Referenz für den gesamten Fahrzeugzeilenbereich
    vehicleRowDrop(dropTargetRef);

    //  console.timeEnd('TransportBars render');

    return (
        <Box
            ref={dropTargetRef}
            sx={{ position: 'relative', width: '100%', height: '100%' }}
        >
            {groupTransports.map((transportGroup, groupIndex) => {
                const { left, width } = calculateMergedBar(transportGroup);
                const transport = createExtendedTransport(transportGroup);
                const isBlinking = blinkingTransports.includes(transport.transport_id);

                return (
                    <TransportBar
                        key={`merged-bar-${groupIndex}`}
                        transport={transport}
                        left={left}
                        width={width}
                        onClick={(e) => {
                            e.stopPropagation();
                            onTransportClick(e, transport);
                        }}
                        onHover={(e) => onHover(e, transport)}
                        onLeave={onLeave}
                        onDetailsTransport={(e) => onDetailsTransport(e, transport)}
                        onEditTransport={onEditTransport}
                        onDeleteTransport={onDeleteTransport}
                        onDuplicateTransport={onDuplicateTransport}
                        onAddBreakAfter={onAddBreakAfter}
                        onDrop={onTransportDrop}
                        onShowRoundTrip={onShowRoundTrip}
                        isBlinking={isBlinking}
                    />
                );
            })}
        </Box>
    );
}

TransportBars.propTypes = {
    transports: PropTypes.arrayOf(
        PropTypes.shape({
            vehicle_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            transport_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            planning_type: PropTypes.string.isRequired,
            planned_start: PropTypes.string.isRequired,
            planned_end: PropTypes.string.isRequired,
        })
    ).isRequired,
    vehicleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    hourWidth: PropTypes.number.isRequired,
    onTransportClick: PropTypes.func.isRequired,
    onHover: PropTypes.func.isRequired,
    onLeave: PropTypes.func.isRequired,
    onDetailsTransport: PropTypes.func,
    onEditTransport: PropTypes.func,
    onDeleteTransport: PropTypes.func,
    onDuplicateTransport: PropTypes.func,
    onAddBreakAfter: PropTypes.func,
    onTransportDrop: PropTypes.func,
    onShowRoundTrip: PropTypes.func,
    blinkingTransports: PropTypes.array
};

export default TransportBars;