// src/components/StationSplitButton.js
import React, { useState } from 'react';
import {
    Box,
    Button,
    Menu,
    MenuItem
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

/**
 * StationSplitButton:
 * Zeigt eine Wache (stationName) als Button an. Beim Klick öffnet sich ein Kontext-Menü mit:
 * - "Wache bearbeiten"
 * - "Fahrzeug aus Wache entfernen"
 */
const StationSplitButton = ({ stationName, stationId, onRemoveStation, vehicle }) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);

    // Kontext-Menü öffnen
    const handleButtonClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    // Kontext-Menü schließen
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    // "Wache bearbeiten"
    const handleEditStation = () => {
        handleMenuClose();
        if (stationId) {
            navigate(`/stationpage/${stationId}`);
        }
    };

    // "Fahrzeug aus Wache entfernen"
    const handleRemoveStationClick = () => {
        handleMenuClose();
        onRemoveStation(vehicle);
    };

    return (
        <Box sx={{ mr: 1, mb: 1 }}>
            <Button
                variant="outlined"
                endIcon={<MoreVertIcon />}
                onClick={handleButtonClick}
                sx={{ textTransform: 'none' }}
            >
                {stationName || 'Keine Wache'}
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClick={handleMenuClose}
            >
                {/* Nur anzeigen, wenn stationId existiert */}
                {stationId && (
                    <MenuItem onClick={handleEditStation}>
                        <EditIcon fontSize="small" style={{ marginRight: 8 }} />
                        Wache bearbeiten
                    </MenuItem>
                )}
                {stationId && (
                    <MenuItem onClick={handleRemoveStationClick}>
                        <DeleteIcon fontSize="small" style={{ marginRight: 8 }} />
                        Fahrzeug aus Wache entfernen
                    </MenuItem>
                )}
            </Menu>
        </Box>
    );
};

export default StationSplitButton;