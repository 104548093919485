import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    Paper,
    Chip,
    Button,
    TextField,
    MenuItem,
    Alert,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    Card,
    CardContent,
    Collapse,
    Select,
    InputLabel,
    FormControl
} from '@mui/material';
import {
    Refresh as RefreshIcon,
    ErrorOutline as ErrorIcon,
    FilterList as FilterIcon,
    Clear as ClearIcon,
    Search as SearchIcon
} from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useUser } from '../../components/userContext';

const UnplannedTransportsPage = ({ onClose }) => {
    const { user } = useUser();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [transports, setTransports] = useState([]);
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [showFilters, setShowFilters] = useState(false);
    const [selectedTransportId, setSelectedTransportId] = useState(null);
    const [selectedTransport, setSelectedTransport] = useState(null);
    const [planDialogOpen, setPlanDialogOpen] = useState(false);

    // Filter-States
    const [searchQuery, setSearchQuery] = useState('');
    const [transportTypeFilter, setTransportTypeFilter] = useState('all');
    const [errorTypeFilter, setErrorTypeFilter] = useState('all');

    // Filter-Optionen
    const transportTypes = [
        { value: 'all', label: 'Alle Typen' },
        { value: 'ENTLASSUNG', label: 'Entlassung' },
        { value: 'TERMINFAHRT', label: 'Terminfahrt' },
        { value: 'DIALYSEFAHRT', label: 'Dialyse' },
        { value: 'VERLEGUNG', label: 'Verlegung' }
    ];

    const errorTypes = [
        { value: 'all', label: 'Alle Fehler' },
        { value: 'keine_fahrzeuge', label: 'Keine Fahrzeuge verfügbar' },
        { value: 'zeitkonflikt', label: 'Zeitkonflikte' },
        { value: 'andere', label: 'Andere Fehler' }
    ];

    useEffect(() => {
        fetchTransports();
        // eslint-disable-next-line
    }, [selectedDate]);

    const fetchTransports = async () => {
        setLoading(true);
        try {
            const dateStr = selectedDate.format('YYYY-MM-DD');
            const response = await fetch(
                `${process.env.REACT_APP_API_URI}/api/unplanned-failed-transports?date=${dateStr}`,
                { headers: { Authorization: user.api_key } }
            );

            if (!response.ok) {
                throw new Error('Fehler beim Laden der ungeplanten Transporte');
            }

            const data = await response.json();
            setTransports(data);
            setError(null);
        } catch (err) {
            console.error('Fehler beim Abrufen der Daten:', err);
            setError('Die Daten konnten nicht geladen werden. Bitte versuche es später erneut.');
        } finally {
            setLoading(false);
        }
    };

    // Filterfunktion
    const getFilteredTransports = () => {
        return transports.filter(transport => {
            const lowerCaseQuery = searchQuery.toLowerCase();
            // Kombinierter Patientenname in Kleinbuchstaben
            const fullName = `${transport.patient_first_name} ${transport.patient_last_name}`.toLowerCase();
            // Prüfen, ob der kombinierte Name den Suchbegriff enthält
            const matchesPatientName = fullName.includes(lowerCaseQuery);

            // Prüfung anderer relevanter Felder (z. B. Institutionen, Städte, Transport-ID)
            const matchesOtherFields = [
                transport.from_institution,
                transport.to_institution,
                transport.from_city,
                transport.to_city,
                transport.transport_id?.toString()
            ].some(field => field && field.toLowerCase().includes(lowerCaseQuery));

            const matchesSearchQuery = searchQuery === '' || matchesPatientName || matchesOtherFields;

            // Transporttyp-Filter
            const matchesTransportType = transportTypeFilter === 'all' ||
                transport.transport_type === transportTypeFilter;

            // Fehlertyp-Filter (vereinfachte Implementierung)
            let matchesErrorType = errorTypeFilter === 'all';
            if (errorTypeFilter === 'keine_fahrzeuge' && transport.logs) {
                matchesErrorType = transport.logs.some(log =>
                    log.log_message.includes('Keine der verfügbaren Fahrzeuge')
                );
            } else if (errorTypeFilter === 'zeitkonflikt' && transport.logs) {
                matchesErrorType = transport.logs.some(log =>
                    log.log_message.includes('Zeitkonflikt')
                );
            } else if (errorTypeFilter === 'andere' && transport.logs) {
                matchesErrorType = transport.logs.some(log =>
                    !log.log_message.includes('Keine der verfügbaren Fahrzeuge') &&
                    !log.log_message.includes('Zeitkonflikt')
                );
            }

            return matchesSearchQuery && matchesTransportType && matchesErrorType;
        });
    };

    const handleSelectRow = (transport_id, planning_id, transport) => {
        setSelectedTransportId(transport_id);
        setSelectedTransport(transport);
    };

    const handleManualPlanning = (transport) => {
        setSelectedTransport(transport);
        setPlanDialogOpen(true);
    };

    const handlePlanDialogClose = () => {
        setPlanDialogOpen(false);
    };

    const handleConfirmPlanning = () => {
        // Hier nur Alert anzeigen, später wird hier die Navigation implementiert
        alert(`Transport ${selectedTransport.transport_id} wird manuell geplant.`);
        setPlanDialogOpen(false);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{
                p: 2,
                height: 'calc(100vh - 130px)',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Paper
                    elevation={1}
                    sx={{
                        p: 2,
                        mb: 2,
                        borderRadius: '12px',
                        background: 'linear-gradient(to right, #f9f9f9, #ffffff)',
                        border: '1px solid #eeeeee'
                    }}
                >
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                        <Typography variant="h5" component="h1" sx={{ color: '#E46450', fontWeight: 600 }}>
                            Ungeplante / Fehlerhafte Transporte
                        </Typography>

                        <Box display="flex" alignItems="center">
                            <Tooltip title="Zurück zur Planungsübersicht">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={onClose}
                                    sx={{ mr: 1 }}
                                >
                                    Zurück zur Übersicht
                                </Button>
                            </Tooltip>

                            <Tooltip title="Aktualisieren">
                                <IconButton
                                    onClick={fetchTransports}
                                    sx={{ mr: 1 }}
                                >
                                    <RefreshIcon />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={showFilters ? "Filter ausblenden" : "Filter einblenden"}>
                                <IconButton
                                    onClick={() => setShowFilters(!showFilters)}
                                    sx={{
                                        bgcolor: showFilters ? '#e3f2fd' : 'transparent',
                                        '&:hover': { bgcolor: showFilters ? '#bbdefb' : '#e0e0e0' }
                                    }}
                                >
                                    <FilterIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>

                    <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                        <Box display="flex" alignItems="center">
                            <DatePicker
                                label="Datum"
                                value={selectedDate}
                                format="DD.MM.YYYY"
                                onChange={(newDate) => setSelectedDate(newDate)}
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        sx: { width: 150, mr: 2 }
                                    }
                                }}
                            />

                            <TextField
                                placeholder="Suche..."
                                variant="outlined"
                                size="small"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                InputProps={{
                                    startAdornment: <SearchIcon fontSize="small" sx={{ mr: 0.5, color: 'text.secondary' }} />,
                                    endAdornment: searchQuery && (
                                        <IconButton
                                            size="small"
                                            onClick={() => setSearchQuery('')}
                                            sx={{ p: 0.5 }}
                                        >
                                            <ClearIcon fontSize="small" />
                                        </IconButton>
                                    ),
                                }}
                                sx={{ width: 250, mr: 2 }}
                            />

                            <Chip
                                label={`${getFilteredTransports().length} Transporte`}
                                color="primary"
                                size="small"
                                sx={{ height: 30 }}
                            />
                        </Box>
                    </Box>

                    <Collapse in={showFilters}>
                        <Box mt={2} p={1.5} bgcolor="#f5f5f5" borderRadius={1}>
                            <Typography variant="subtitle2" fontWeight={600} mb={1}>
                                Erweiterte Filter
                            </Typography>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="transport-type-label">Transporttyp</InputLabel>
                                        <Select
                                            labelId="transport-type-label"
                                            value={transportTypeFilter}
                                            label="Transporttyp"
                                            onChange={(e) => setTransportTypeFilter(e.target.value)}
                                        >
                                            {transportTypes.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="error-type-label">Fehlertyp</InputLabel>
                                        <Select
                                            labelId="error-type-label"
                                            value={errorTypeFilter}
                                            label="Fehlertyp"
                                            onChange={(e) => setErrorTypeFilter(e.target.value)}
                                        >
                                            {errorTypes.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} display="flex" alignItems="center" justifyContent="flex-end">
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => {
                                            setSearchQuery('');
                                            setTransportTypeFilter('all');
                                            setErrorTypeFilter('all');
                                        }}
                                        sx={{ mr: 1 }}
                                    >
                                        Filter zurücksetzen
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Collapse>
                </Paper>

                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Alert
                        severity="error"
                        sx={{ mt: 2 }}
                        action={
                            <Button color="inherit" size="small" onClick={fetchTransports}>
                                Erneut versuchen
                            </Button>
                        }
                    >
                        {error}
                    </Alert>
                ) : getFilteredTransports().length === 0 ? (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        flexGrow={1}
                        p={3}
                    >
                        <Typography variant="h6" sx={{ mb: 1, color: 'text.secondary' }}>
                            Keine ungeplanten Transporte gefunden
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Für das ausgewählte Datum oder die gewählten Filter wurden keine Ergebnisse gefunden.
                        </Typography>
                    </Box>
                ) : (
                    <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                        {getFilteredTransports().map((transport) => (
                            <Card
                                key={transport.transport_id}
                                elevation={1}
                                sx={{
                                    mb: 2,
                                    borderRadius: '8px',
                                    border: selectedTransportId === transport.transport_id ? '2px solid #1976d2' : '1px solid #e0e0e0'
                                }}
                            >
                                <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={9}>
                                            <Box display="flex" alignItems="flex-start" mb={1}>
                                                <Box>
                                                    <Box display="flex" alignItems="center" mb={0.5}>
                                                        <ErrorIcon color="error" sx={{ mr: 1, fontSize: 20 }} />
                                                        <Typography variant="subtitle1" fontWeight={600}>
                                                            Transport #{transport.transport_id}
                                                        </Typography>
                                                        <Chip
                                                            label={transport.transport_type}
                                                            size="small"
                                                            sx={{ ml: 1, height: 24 }}
                                                        />
                                                    </Box>

                                                    <Typography variant="body2" gutterBottom>
                                                        <strong>Von:</strong> {transport.from_institution}, {transport.from_city}
                                                    </Typography>

                                                    <Typography variant="body2" gutterBottom>
                                                        <strong>Nach:</strong> {transport.to_institution}, {transport.to_city}
                                                    </Typography>

                                                    <Typography variant="body2" gutterBottom>
                                                        <strong>Patient:</strong> {transport.patient_first_name} {transport.patient_last_name}
                                                    </Typography>

                                                    <Typography variant="body2" gutterBottom>
                                                        <strong>Datum/Zeit:</strong> {new Date(transport.departure_date).toLocaleDateString('de-DE')} {transport.departure_time}
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            <Box
                                                mt={1}
                                                p={1}
                                                bgcolor="#ffebee"
                                                borderRadius={1}
                                            >
                                                <Typography variant="subtitle2" fontWeight={600} gutterBottom>
                                                    Letzte Fehlermeldungen:
                                                </Typography>

                                                {transport.logs && transport.logs.slice(0, 3).map((log, index) => (
                                                    <Typography
                                                        key={log.log_id}
                                                        variant="body2"
                                                        sx={{
                                                            mb: 0.5,
                                                            color: log.log_level === 'error' ? 'error.main' : 'text.primary',
                                                            fontSize: '0.825rem'
                                                        }}
                                                    >
                                                        {new Date(log.created_at).toLocaleString('de-DE')}: {log.log_message}
                                                    </Typography>
                                                ))}

                                                {transport.logs && transport.logs.length > 3 && (
                                                    <Typography variant="caption" color="text.secondary">
                                                        +{transport.logs.length - 3} weitere Fehlermeldungen
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            sm={3}
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleManualPlanning(transport)}
                                                sx={{ width: '100%', mb: 1 }}
                                            >
                                                Manuell planen
                                            </Button>

                                            <Button
                                                variant="outlined"
                                                onClick={() => handleSelectRow(transport.transport_id, null, transport)}
                                                sx={{ width: '100%' }}
                                            >
                                                Details anzeigen
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                )}

                {/* Dialog für manuelle Planung */}
                <Dialog
                    open={planDialogOpen}
                    onClose={handlePlanDialogClose}
                    aria-labelledby="plan-dialog-title"
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle id="plan-dialog-title">
                        Transport manuell planen
                    </DialogTitle>

                    <DialogContent>
                        {selectedTransport && (
                            <DialogContentText>
                                Möchten Sie den Transport #{selectedTransport.transport_id} ({selectedTransport.transport_type}) von {selectedTransport.patient_first_name} {selectedTransport.patient_last_name} manuell planen?
                            </DialogContentText>
                        )}
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handlePlanDialogClose} color="primary">
                            Abbrechen
                        </Button>
                        <Button onClick={handleConfirmPlanning} color="primary" variant="contained">
                            Manuell planen
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </LocalizationProvider>
    );
};

export default UnplannedTransportsPage;