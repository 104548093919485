// RestrictedTimePicker.js
import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';

/**
 * Dieser Komponente entstammt der Auslagerung aus dem transportWhenExpertStep.js.
 * Alle Kommentare und Logik wurden beibehalten.
 * 
 * @param {Object} props
 * @param {string} props.day - Wochentag oder Identifier
 * @param {string} props.time - Aktuell gesetzte Zeit (z.B. "12:30")
 * @param {function} props.onChange - Callback zum Setzen der Zeit
 * @param {string} props.transportType - Typ des Transports (z.B. 'DIALYSEFAHRT')
 * @param {boolean} [props.isReturn=false] - Gibt an, ob es sich um eine Rückfahrt handelt
 * @param {string} [props['data-testid']] - optionales DataTestID
 * @param {Object} props.timeErrors - Enthält Mapping { [errorKey]: errorText }
 * @param {function} props.setTimeErrors - State-Setter-Funktion zum Aktualisieren der Fehler
 * @param {function} props.getTimeConstraints - Funktion, die { minTime, maxTime } basierend auf transportType zurückgibt
 * @param {function} props.validateTime - Funktion zur Validierung einer Zeit (z.B. außerhalb allowed range)
 * @param {boolean} [props.disabled=false] - Gibt an, ob der TimePicker deaktiviert werden soll
 */
const RestrictedTimePicker = ({
    day,
    time,
    onChange,
    transportType,
    isReturn = false,
    'data-testid': dataTestId,
    timeErrors,
    setTimeErrors,
    getTimeConstraints,
    validateTime,
    disabled = false
}) => {
    const theme = useTheme();

    // Dieser Key bildet ab, ob Hin- oder Rückfahrt
    const errorKey = isReturn ? `rück-${day}` : `hin-${day}`;
    // Fehler aus dem state-Objekt (wurde im Original-Code über useState im Parent verwaltet)
    const error = timeErrors[errorKey] || '';

    // Hier werden minTime und maxTime mit Hilfe der an den Parent ausgelagerten Funktion abgerufen
    const { minTime, maxTime } = getTimeConstraints(transportType, isReturn);

    // RawValue zur internen Speicherung
    const [rawValue, setRawValue] = useState(time ? dayjs(time, 'HH:mm') : null);

    /**
     * handleAccept: Wird aufgerufen, sobald der Benutzer die Zeit bestätigt (onAccept).
     * Hier wird auch die Validierung angeworfen.
     */
    const handleAccept = (acceptedValue) => {
        const timeString = acceptedValue ? acceptedValue.format('HH:mm') : '';
        const validationError = validateTime(timeString, transportType, isReturn);

        // Fehler im übergeordneten State aktualisieren
        setTimeErrors(prev => ({ ...prev, [errorKey]: validationError || '' }));

        // Callback zum Setzen der Zeit in formData
        onChange(day, timeString);

        // Aktuellen Wert intern speichern
        setRawValue(acceptedValue);
    };

    // Effekt, um externe Updates zu verarbeiten (z.B. Übernahme der Zeit vom Vorgänger)
    React.useEffect(() => {
        if (time && (!rawValue || rawValue.format('HH:mm') !== time)) {
            setRawValue(dayjs(time, 'HH:mm'));
        }
        // eslint-disable-next-line
    }, [time]);

    /**
     * handleChange: Wird bei jedem Ändern der Zeit aufgerufen, speichert den Wert aber nur temporär,
     * bis der Benutzer wirklich akzeptiert (auf "OK" klickt oder onBlur auslöst).
     */
    const handleChange = (newValue) => {
        setRawValue(newValue);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <TimePicker
                desktopModeMediaQuery="@media (min-width: 0px)"
                label={isReturn ? "Rückfahrt" : "Hinfahrt"}
                views={['hours', 'minutes']}
                format="HH:mm"
                value={rawValue}
                onChange={handleChange}
                onAccept={handleAccept}
                minTime={minTime}
                maxTime={maxTime}
                disabled={disabled}
                slotProps={{
                    textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error,
                        size: "medium",
                        variant: "outlined",
                        onBlur: () => {
                            if (rawValue) handleAccept(rawValue);
                        },
                        'data-testid': dataTestId,
                        sx: {
                            bgcolor: theme.palette.background.paper,
                            '& .MuiOutlinedInput-root': {
                                '&:hover fieldset': {
                                    borderColor: isReturn ? theme.palette.secondary.main : theme.palette.primary.main,
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: isReturn ? theme.palette.secondary.main : theme.palette.primary.main,
                                },
                            },
                        }
                    },
                    inputAdornment: {
                        style: { marginRight: '8px' }
                    }
                }}
                sx={{
                    opacity: disabled ? 0.7 : 1,
                    transition: 'opacity 0.2s ease-in-out',
                }}
            />
        </LocalizationProvider>
    );
};

export default RestrictedTimePicker;