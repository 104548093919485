import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Typography,
    CircularProgress,
    Box,
    Alert,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Paper,
    List,
    ListItem,
    ListItemText,
    Chip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { useUser } from '../../components/userContext';

/**
 * Dialog-Komponente zur Umplanung aller Transporte eines Fahrzeugs
 * 
 * @param {Object} props
 * @param {boolean} props.open - Ob der Dialog geöffnet ist
 * @param {Function} props.onClose - Handler zum Schließen des Dialogs
 * @param {Object} props.vehicle - Das Fahrzeug, dessen Transporte umgeplant werden sollen
 * @param {Function} props.onSuccess - Callback bei erfolgreicher Umplanung
 */
const VehicleReplanDialog = ({ open, onClose, vehicle, onSuccess }) => {
    const { user } = useUser();
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(null);
    const [reason, setReason] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [report, setReport] = useState(null);

    useEffect(() => {
        if (open) {
            // Zurücksetzen des Formulars beim Öffnen
            setStartDate(dayjs());
            setEndDate(null);
            setReason('');
            setError(null);
            setReport(null);
        }
    }, [open]);

    const handleStartDateChange = (newDate) => {
        setStartDate(newDate);
    };

    const handleEndDateChange = (newDate) => {
        setEndDate(newDate);
    };

    const handleReasonChange = (event) => {
        setReason(event.target.value);
    };

    const resetForm = () => {
        setStartDate(dayjs());
        setEndDate(null);
        setReason('');
        setError(null);
        setReport(null);
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    const handleReplan = async () => {
        if (!reason.trim()) {
            setError('Bitte geben Sie einen Grund für die Außerbetriebnahme an.');
            return;
        }

        try {
            setLoading(true);
            setError(null);

            const payload = {
                vehicleId: vehicle.vehicle_id,
                startDate: startDate ? startDate.format('YYYY-MM-DD HH:mm:ss') : undefined,
                endDate: endDate ? endDate.format('YYYY-MM-DD HH:mm:ss') : undefined,
                reason: reason.trim()
            };

            const response = await fetch(
                `${process.env.REACT_APP_API_URI}/api/planning/replan-vehicle`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: user.api_key
                    },
                    body: JSON.stringify(payload)
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Fehler bei der Umplanung.');
            }

            const responseData = await response.json();
            setReport(responseData.report);

            // Benachrichtigung an Parent-Komponente, dass Umplanung erfolgreich war
            if (onSuccess) {
                onSuccess(responseData.report);
            }

        } catch (err) {
            console.error('Fehler bei der Fahrzeugumplanung:', err);
            setError(err.message || 'Ein unbekannter Fehler ist aufgetreten.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={loading ? null : handleClose}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>
                Fahrzeug außer Dienst stellen und Transporte umplanen
            </DialogTitle>

            <DialogContent>
                {!report ? (
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Sie sind dabei, das Fahrzeug <strong>{vehicle?.vehicle_name}</strong> außer Dienst zu stellen.
                            Alle Transporte im angegebenen Zeitraum werden storniert und auf andere verfügbare Fahrzeuge umgeplant.
                        </Typography>

                        <Box sx={{ mt: 3, mb: 3 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                                <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, flex: 1 }}>
                                        <Button
                                            data-testid="now-button"
                                            variant="text"
                                            onClick={() => setStartDate(dayjs())}
                                        >
                                            Jetzt
                                        </Button>
                                        <DateTimePicker
                                            desktopModeMediaQuery="@media (min-width: 0px)"
                                            data-testid="start-date-time-picker"
                                            label="Von (Start der Außerbetriebnahme)"
                                            value={startDate}
                                            onChange={handleStartDateChange}
                                            minDateTime={dayjs().startOf('minute')}
                                            format="DD.MM.YYYY HH:mm"
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    variant: 'outlined'
                                                }
                                            }}
                                            sx={{ mt: 2 }}
                                        />
                                    </Box>

                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, flex: 1 }}>
                                        <Button
                                            data-testid="add-one-day-button"
                                            variant="text"
                                            onClick={() => {
                                                if (startDate) {
                                                    setEndDate(startDate.add(1, 'day'));
                                                }
                                            }}
                                        >
                                            + 1 Tag
                                        </Button>
                                        <DateTimePicker
                                            desktopModeMediaQuery="@media (min-width: 0px)"
                                            data-testid="end-date-time-picker"
                                            label="Bis (Ende der Außerbetriebnahme, optional)"
                                            value={endDate}
                                            onChange={handleEndDateChange}
                                            minDateTime={startDate || dayjs().startOf('minute')}
                                            format="DD.MM.YYYY HH:mm"
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    variant: 'outlined',
                                                    helperText: 'Leer lassen für unbegrenzte Außerbetriebnahme'
                                                }
                                            }}
                                            sx={{ mt: 2 }}
                                        />
                                    </Box>
                                </Box>
                            </LocalizationProvider>

                            <TextField
                                name="reason-textfield"
                                label="Grund der Außerbetriebnahme"
                                value={reason}
                                onChange={handleReasonChange}
                                fullWidth
                                required
                                multiline
                                rows={2}
                                variant="outlined"
                                error={!reason.trim() && !!error}
                                helperText={!reason.trim() && error ? error : ""}
                            />
                        </Box>

                        {error && reason.trim() && (
                            <Alert severity="error" sx={{ mt: 2 }}>
                                {error}
                            </Alert>
                        )}
                    </Box>
                ) : (
                    <Box sx={{ mt: 2 }}>
                        <Alert
                            severity={report.summary.failed_replan > 0 ? "warning" : "success"}
                            sx={{ mb: 3 }}
                        >
                            <Typography variant="subtitle1">
                                Umplanung für Fahrzeug <strong>{vehicle?.vehicle_name}</strong> abgeschlossen
                            </Typography>
                            <Typography variant="body2">
                                {report.summary.successfully_replanned} von {report.summary.total_transports} Transporten wurden erfolgreich umgeplant.
                                {report.summary.failed_replan > 0 && ` ${report.summary.failed_replan} Transporte konnten nicht umgeplant werden.`}
                            </Typography>
                        </Alert>

                        <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
                            <Typography variant="subtitle2" gutterBottom>Zusammenfassung</Typography>
                            <List dense>
                                <ListItem>
                                    <ListItemText
                                        primary="Zeitraum"
                                        secondary={`${dayjs(report.date_range.start).format('DD.MM.YYYY HH:mm')} bis ${report.date_range.end ? dayjs(report.date_range.end).format('DD.MM.YYYY HH:mm') : 'unbegrenzt'}`}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary="Grund"
                                        secondary={report.reason}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary="Fahrzeugstatus"
                                        secondary="Auf 'maintenance' gesetzt"
                                    />
                                </ListItem>
                            </List>
                        </Paper>

                        {report.replanned_transports && report.replanned_transports.length > 0 && (
                            <Accordion defaultExpanded>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>
                                        Erfolgreich umgeplante Transporte ({report.replanned_transports.length})
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <List dense>
                                        {report.replanned_transports.map((transport) => (
                                            <ListItem key={transport.transport_id} divider>
                                                <ListItemText
                                                    primary={
                                                        <Box display="flex" alignItems="center">
                                                            <Typography variant="body2" component="span">
                                                                Transport #{transport.transport_id} ({transport.transport_type})
                                                            </Typography>
                                                            <Chip
                                                                label={`Neues Fahrzeug: ${transport.new_vehicle_id}`}
                                                                size="small"
                                                                color="success"
                                                                sx={{ ml: 1 }}
                                                            />
                                                        </Box>
                                                    }
                                                    secondary={
                                                        <>
                                                            <Typography variant="caption" display="block">
                                                                Startzeit: {dayjs(transport.planned_start).format('DD.MM.YYYY HH:mm')}
                                                            </Typography>
                                                            <Typography variant="caption" display="block">
                                                                Von: {transport.from_institution} · Nach: {transport.to_institution}
                                                            </Typography>
                                                        </>
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        )}

                        {report.failed_transports && report.failed_transports.length > 0 && (
                            <Accordion defaultExpanded sx={{ mt: 2 }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ bgcolor: 'error.light' }}
                                >
                                    <Typography>
                                        Fehlgeschlagene Umplanungen ({report.failed_transports.length})
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <List dense>
                                        {report.failed_transports.map((transport) => (
                                            <ListItem key={transport.transport_id} divider>
                                                <ListItemText
                                                    primary={
                                                        <Box display="flex" alignItems="center">
                                                            <Typography variant="body2" component="span">
                                                                Transport #{transport.transport_id} ({transport.transport_type})
                                                            </Typography>
                                                            <Chip
                                                                label="Fehler"
                                                                size="small"
                                                                color="error"
                                                                sx={{ ml: 1 }}
                                                            />
                                                        </Box>
                                                    }
                                                    secondary={
                                                        <>
                                                            <Typography variant="caption" display="block">
                                                                Startzeit: {dayjs(transport.planned_start).format('DD.MM.YYYY HH:mm')}
                                                            </Typography>
                                                            <Typography variant="caption" display="block">
                                                                Von: {transport.from_institution} · Nach: {transport.to_institution}
                                                            </Typography>
                                                            <Typography variant="caption" display="block" color="error.main">
                                                                Fehler: {transport.error}
                                                            </Typography>
                                                        </>
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </Box>
                )}
            </DialogContent>

            <DialogActions sx={{ justifyContent: 'space-between', px: 3, py: 2 }}>
                <Button onClick={handleClose} disabled={loading}>
                    Abbrechen
                </Button>
                {!report ? (
                    <Button
                        data-testid="replan-button"
                        onClick={handleReplan}
                        color="primary"
                        variant="contained"
                        disabled={loading}
                        startIcon={loading && <CircularProgress size={20} color="inherit" />}
                    >
                        {loading ? 'Wird umgeplant...' : 'Fahrzeug außer Dienst stellen und Transporte umplanen'}
                    </Button>
                ) : (
                    <Button onClick={handleClose} color="primary">
                        Schließen
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default VehicleReplanDialog;