// stammdatenPage.js

import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Tabs, Tab, Typography, TextField, IconButton, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

// Importiere unsere Tab-Views
import StationsList from './stationsList';
import VehiclesList from './vehiclesList';
import ShiftPlanList from './shiftPlanList';

const StammdatenPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const initialTab = parseInt(searchParams.get('tab')) || 0;
    const [tabIndex, setTabIndex] = useState(initialTab);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
        setSearchParams({ tab: newValue });
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleClearSearch = () => {
        setSearchTerm('');
    };

    const handleAddButton = () => {
        switch (tabIndex) {
            case 0:
                navigate('/stationpage');
                break;
            case 1:
                navigate('/vehiclepage');
                break;
            case 2:
                navigate('/shiftplanpage');
                break;
            default:
                // Handle unknown tab if needed
                break;
        }
    };

    return (
        <Box sx={{ p: 2 }}>
            {/* Überschrift + Schnellsuche */}
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h5" color="#E46450" component="h1" gutterBottom >
                    Stammdaten
                </Typography>

                {/* Centered Tabs */}
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Tabs value={tabIndex} onChange={handleTabChange} textColor="primary" indicatorColor="primary">
                        <Tab label="Wachenverwaltung" />
                        <Tab label="Fahrzeugverwaltung" />
                        <Tab label="Schichtplanverwaltung" />
                    </Tabs>
                </Box>

                {/* Schnellsuche-Feld */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        size="small"
                        placeholder="Schnellsuche"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        InputProps={{
                            endAdornment: (
                                <>
                                    {searchTerm && (
                                        <IconButton size="small" onClick={handleClearSearch}>
                                            <ClearIcon />
                                        </IconButton>
                                    )}
                                    <SearchIcon />
                                </>
                            )
                        }}
                    />
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleAddButton}
                >
                    {tabIndex === 0 && 'Neue Wache hinzufügen'}
                    {tabIndex === 1 && 'Neues Fahrzeug anlegen'}
                    {tabIndex === 2 && 'Neuen Schichtplan hinzufügen'}
                </Button>
            </Box>


            {/* Panels */}
            {tabIndex === 0 && <StationsList globalSearch={searchTerm} />}
            {tabIndex === 1 && <VehiclesList globalSearch={searchTerm} />}
            {tabIndex === 2 && <ShiftPlanList globalSearch={searchTerm} />}
        </Box>
    );
};

export default StammdatenPage;