import React from 'react';
import { MenuItem, ListItemIcon, Typography, Divider } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import HistoryIcon from '@mui/icons-material/History';
import { useThemeContext, THEME_MODERN } from '../theme/ThemeContext';

// Komponente zum Hinzufügen des Theme-Switchers im Avatar-Menü
const ThemeSwitcherMenuItem = () => {
    const { currentTheme, toggleTheme } = useThemeContext();

    return (
        <>
            <Divider />
            <MenuItem onClick={toggleTheme}>
                <ListItemIcon>
                    {currentTheme === THEME_MODERN ? (
                        <HistoryIcon fontSize="small" />
                    ) : (
                        <AutoAwesomeIcon fontSize="small" />
                    )}
                </ListItemIcon>
                <Typography variant="inherit">
                    {currentTheme === THEME_MODERN ? 'Klassisches Design' : 'Modernes Design'}
                </Typography>
            </MenuItem>
        </>
    );
};

export default ThemeSwitcherMenuItem;