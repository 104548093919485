// drkClassicTheme.js
import { createTheme } from '@mui/material/styles';

// Traditionelle MUI-Blautöne für das klassische Theme
const muiBlue = '#1976d2';
const muiLightBlue = '#42a5f5';
const muiDarkBlue = '#1565c0';

// DRK-Rot als Sekundärfarbe beibehalten
const drkRed = '#E46450';

const drkClassicTheme = createTheme({
    palette: {
        primary: {
            main: muiBlue,
            light: muiLightBlue,
            dark: muiDarkBlue,
            contrastText: '#fff',
        },
        secondary: {
            main: drkRed,
            light: '#F8D7D3',
            dark: '#C74236',
            contrastText: '#fff',
        },
        error: {
            main: '#F44336',
        },
        background: {
            default: '#fff',
            paper: '#fff',
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.6)',
        },
    },
    typography: {
        fontFamily: [
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
        ].join(','),
    },
    // Überschreibungen für Komponenten im klassischen MUI-Stil
    components: {
        /*
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'uppercase', // Im klassischen MUI sind Buttons üblicherweise in Großbuchstaben
                    borderRadius: 4,
                    padding: '6px 16px',
                    boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2)',
                    '&:hover': {
                        boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.3)',
                    },
                },
                containedPrimary: {
                    backgroundColor: muiBlue,
                    '&:hover': {
                        backgroundColor: muiDarkBlue,
                    },
                },
            },
        },*/
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        borderRadius: 4,
                        '&:-webkit-autofill': {
                            WebkitBoxShadow: '0 0 0 1000px white inset',
                            WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)',
                        },
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: 16,
                    '&:last-child': {
                        paddingBottom: 16,
                    },
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: muiBlue,
                    textDecoration: 'none',
                    '&:hover': {
                        textDecoration: 'underline',
                    },
                },
            },
        },
        // Im klassischen Design gibt es keinen farbigen Balken oben an der Karte
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: muiBlue,
                }
            }
        }
    },
});

export default drkClassicTheme;