// stationPage.js
import React, { useEffect, useState } from 'react';
import {
    Box, Button, TextField, Typography, Alert, Container, IconButton, Menu, MenuItem, Grid2
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useUser } from '../../components/userContext';
import { useNavigate, useParams } from 'react-router-dom';

const StationPage = () => {
    const { user } = useUser();
    const navigate = useNavigate();
    const { station_id } = useParams();
    const isEditMode = Boolean(station_id);

    const [formData, setFormData] = useState({
        lk: '',
        name: '',
        betreiber: '',
        street: '',
        house_number: '',
        postal_code: '',
        city: '',
        image_id: null
    });

    const [errors, setErrors] = useState({});
    const [serverError, setServerError] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        if (isEditMode) {
            fetchStation();
        }
        // eslint-disable-next-line
    }, [isEditMode]);

    useEffect(() => {
        if (formData.image_id) {
            fetch(`${process.env.REACT_APP_API_URI}/api/images/${formData.image_id}`)
                .then((res) => res.json())
                .then((data) => {
                    if (data.image) {
                        setFormData((prev) => ({ ...prev, imageSrc: data.image }));
                    }
                })
                .catch((err) => console.error('Fehler beim Laden des Bildes:', err));
        }
    }, [formData.image_id]);

    const fetchStation = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/stations`, {
                headers: { Authorization: user.api_key }
            });
            if (!response.ok) {
                throw new Error('Fehler beim Abrufen der Station-Daten');
            }
            const data = await response.json();

            const station = data.find(st => st.station_id === parseInt(station_id, 10));
            if (!station) throw new Error('Station nicht gefunden');

            setFormData({
                lk: station.lk || '',
                name: station.name || '',
                betreiber: station.betreiber || '',
                street: station.street || '',
                house_number: station.house_number || '',
                postal_code: station.postal_code || '',
                city: station.city || '',
                image_id: station.image_id || null
            });
        } catch (error) {
            setServerError(error.message);
        }
    };

    const handleChange = (e) => {
        setFormData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Wachenname ist erforderlich';
        if (!formData.lk) newErrors.lk = 'Landkreis ist erforderlich';
        if (!formData.betreiber) newErrors.betreiber = 'Betreiber ist erforderlich';
        if (!formData.street) newErrors.street = 'Straße ist erforderlich';
        if (!formData.house_number) newErrors.hn = 'Hausnummer ist erforderlich';
        if (!formData.postal_code) newErrors.postal_code = 'Postleitzahl ist erforderlich';
        if (!formData.city) newErrors.city = 'Ort ist erforderlich';
        setErrors(newErrors);

        // Kopie von formData erstellen und `imageSrc` entfernen
        const { imageSrc, ...filteredFormData } = formData;

        if (Object.keys(newErrors).length > 0) return;

        try {
            let url = `${process.env.REACT_APP_API_URI}/api/stations`;
            let method = 'POST';
            if (isEditMode) {
                url = `${url}/${station_id}`;
                method = 'PUT';
            }

            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user.api_key
                },
                body: JSON.stringify(filteredFormData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Fehler beim Speichern');
            }

            navigate(-1);
        } catch (error) {
            setServerError(error.message);
        }
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('image', file);

        const response = await fetch(`${process.env.REACT_APP_API_URI}/api/upload-image`, {
            method: 'POST',
            headers: { Authorization: user.api_key },
            body: formData
        });

        const data = await response.json();
        if (data.success) {
            await fetch(`${process.env.REACT_APP_API_URI}/api/stations/${station_id}/set-image`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user.api_key
                },
                body: JSON.stringify({ image_id: data.file_id })
            });

            window.location.reload();
        } else {
            alert('Fehler beim Hochladen des Bildes');
        }
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <Container maxWidth="md">
            <Box mt={2} p={2} sx={{ backgroundColor: '#fff', borderRadius: 3, boxShadow: 3 }}>
                {/* Bild + Header */}
                <Box display="flex" justifyContent="space-between" >
                    <Box display="flex">
                        <Box
                            component="img"
                            src={formData.imageSrc || "/default-station.png"}
                            alt="Wache"
                            sx={{
                                maxHeight: 160,
                                objectFit: 'cover',
                                borderRadius: 3,
                                border: '2px solid #ddd',
                                mb: 2
                            }}
                        />
                        <Typography variant="h5" fontWeight="bold" ml={2}>
                            {isEditMode ? 'Wache bearbeiten' : 'Neue Wache hinzufügen'}
                        </Typography>
                    </Box>

                    <Box>
                        <IconButton onClick={handleMenuOpen}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                            <MenuItem onClick={() => document.getElementById('fileInput').click()}>
                                Bild hochladen oder ändern
                            </MenuItem>
                        </Menu>
                    </Box>
                </Box>

                {serverError && <Alert severity="error">{serverError}</Alert>}

                {/* Formular */}
                <form onSubmit={handleSubmit}>
                    <TextField label="Landkreis" name="lk" value={formData.lk} onChange={handleChange} error={!!errors.lk} helperText={errors.lk} fullWidth margin="normal" />
                    <TextField label="Wachenname" name="name" value={formData.name} onChange={handleChange} error={!!errors.name} helperText={errors.name} fullWidth margin="normal" />
                    <TextField label="Betreiber" name="betreiber" value={formData.betreiber} onChange={handleChange} error={!!errors.betreiber} helperText={errors.betreiber} fullWidth margin="normal" />

                    {/* Straße + Hausnummer */}
                    <Grid2 container spacing={2}>
                        <Grid2 item size={8}>
                            <TextField fullWidth label="Straße" name="street" value={formData.street} onChange={handleChange} error={!!errors.street} helperText={errors.street} margin="normal" />
                        </Grid2>
                        <Grid2 item size={4}>
                            <TextField fullWidth label="Hausnummer" name="house_number" value={formData.house_number} onChange={handleChange} error={!!errors.hn} helperText={errors.hn} margin="normal" />
                        </Grid2>
                    </Grid2>

                    {/* PLZ + Ort */}
                    <Grid2 container spacing={2}>
                        <Grid2 item size={4}>
                            <TextField fullWidth label="PLZ" name="postal_code" value={formData.postal_code} onChange={handleChange} error={!!errors.postal_code} helperText={errors.postal_code} margin="normal" />
                        </Grid2>
                        <Grid2 item size={8}>
                            <TextField fullWidth label="Ort" name="city" value={formData.city} onChange={handleChange} error={!!errors.city} helperText={errors.city} margin="normal" />
                        </Grid2>
                    </Grid2>

                    <input type="file" id="fileInput" onChange={handleFileChange} hidden />

                    <Box display="flex" justifyContent="space-between">
                        <Button variant="text" onClick={() => navigate(-1)}>
                            Eingabe Abbrechen
                        </Button>
                        <Box mt={2}>
                            <Button variant="contained" color="primary" type="submit">Speichern</Button>
                        </Box>
                    </Box>
                </form>
            </Box>
        </Container>
    );
};

export default StationPage;