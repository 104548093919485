import React, { useState, useRef, useEffect } from 'react';
import {
    Typography,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Box,
    Switch,
    Checkbox
} from '@mui/material';
import {
    DateTimePicker,
    LocalizationProvider
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/de'; // Damit das deutsche Locale geladen wird
import { useUser } from '../components/userContext';
import { DrkTransportDesignBox } from '../components/DrkComponents';
import { useTheme } from '@mui/material/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { getValidationErrors } from '../utils/validationLogic';
import { getTimeRangeText, getTimeRangeInfoText } from '../utils/whenStepHelpers';

// Import des ausgelagerten Dialogs für Serientermine
import SerialAppointmentsDialog from './SerialAppointmentsDialog';
// Import des Verfügbarkeitsdialogs
import AvailabilityCheckDialog from './dialogs/AvailabilityCheckDialog';
// Import unserer neuen Komponente für die Verfügbarkeitsprüfung
import AvailabilityCheckButton from './AvailabilityCheckButton';

import AnimatedTextWrapper from '../components/AnimatedTextWrapper';

const WhenExpertStep = ({ formData, setFormData, isExpertMode, isEditMode, timeSettings, modBookingSystem = true }) => {
    const [dateError, setDateError] = useState(null);
    const { user } = useUser(); // Angemeldeter User
    const allowedRoles = ['user', 'dialysis'];

    const isTransportApproved = formData.status === "approved" && allowedRoles.includes(user?.role);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [timeErrors, setTimeErrors] = useState({});
    const theme = useTheme();

    // Zustand für den Verfügbarkeitsdialog
    const [availabilityDialogOpen, setAvailabilityDialogOpen] = useState(false);
    // Zustand zum Speichern, ob die Verfügbarkeit überprüft wurde
    const [availabilityChecked, setAvailabilityChecked] = useState(isEditMode ? true : false);
    const [availabilityStatus, setAvailabilityStatus] = useState(isEditMode ? 'green' : null); // 'green', 'yellow', 'red' oder null
    // Speichern des ursprünglichen Datums im Edit-Modus
    // eslint-disable-next-line
    const [originalDate, setOriginalDate] = useState(formData.departureDateTime);

    // Neue States für die Hintergrundverfügbarkeitsprüfung
    const [backgroundCheckInProgress, setBackgroundCheckInProgress] = useState(false);
    const availabilityResultsRef = useRef(null);

    // Für Adressänderungen von außen
    const previousAddressFieldsRef = useRef({});
    // Neuer Ref, um zu verfolgen, ob die initiale Daten bereits geladen wurden
    const initialLoadCompletedRef = useRef(false);

    // Funktion zur Überprüfung der Benutzerrolle
    const isExpertRole = (user) => {
        return user?.role === 'dialysis' || user?.role === 'poweruser' || user?.role === 'admin' || user?.role === 'superadmin';
    };

    //const now = dayjs();
    let minDate = dayjs().add(60, 'minutes');
    if (['poweruser', 'admin'].includes(user?.role)) {
        minDate = dayjs().add(-1, 'minutes');
    }
    const maxDate = dayjs().add(10, 'years');

    dayjs.extend(updateLocale);
    // Montag = Wochenstart
    dayjs.updateLocale('de', {
        weekStart: 1,
    });

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleSaveDialog = () => {
        setDialogOpen(false);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
            ...(name === 'transport_type' && { initial_trip: true }),
        }));

        // Bei Änderung der relevanten Daten Cache vollständig leeren
        if (
            name === 'departureDateTime' ||
            name === 'from_street' || name === 'from_house_number' ||
            name === 'from_postal_code' || name === 'from_city' ||
            name === 'to_street' || name === 'to_house_number' ||
            name === 'to_postal_code' || name === 'to_city' ||
            name === 'transport_type'
        ) {
            // Cache vollständig leeren
            setAvailabilityChecked(false);
            setAvailabilityStatus(null);
            availabilityResultsRef.current = null;
        }
    };


    // Füge diesen useEffect hinzu, um auf externe Adressänderungen zu reagieren
    useEffect(() => {
        const addressFields = [
            'from_street', 'from_house_number', 'from_postal_code', 'from_city',
            'to_street', 'to_house_number', 'to_postal_code', 'to_city'
        ];

        // Initialer Ladevorgang: Aktuelle Werte speichern, aber keinen Reset durchführen
        if (!initialLoadCompletedRef.current && isEditMode) {
            // Speichere die initialen Werte für späteren Vergleich
            for (const field of addressFields) {
                previousAddressFieldsRef.current[field] = formData[field];
            }
            // Markiere den initialen Ladevorgang als abgeschlossen
            initialLoadCompletedRef.current = true;
            return; // Früh zurückkehren, um keinen Reset beim Erstladen zu verursachen
        }

        // Nach dem initialen Laden prüfen wir, ob sich Werte geändert haben
        let hasAddressChanged = false;

        console.log('Checking for address changes...');
        // Prüfe, ob sich die relevanten Felder geändert haben
        for (const field of addressFields) {
            if (formData[field] !== previousAddressFieldsRef.current[field]) {
                hasAddressChanged = true;
                break;
            }
        }

        // Wenn sich ein relevantes Feld geändert hat, setze den Cache zurück und starte Hintergrundverfügbarkeitsprüfung
        if (hasAddressChanged) {
            setAvailabilityChecked(false);
            setAvailabilityStatus(null);
            availabilityResultsRef.current = null;

            console.log('Address has changed, resetting cache and performing background availability check...');
            // Aktualisiere die gespeicherten Werte
            for (const field of addressFields) {
                previousAddressFieldsRef.current[field] = formData[field];
            }

            // Führe die Hintergrundverfügbarkeitsprüfung ohne UI-Update durch
            performBackgroundAvailabilityCheck(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.from_street, formData.from_house_number, formData.from_postal_code, formData.from_city,
    formData.to_street, formData.to_house_number, formData.to_postal_code, formData.to_city, isEditMode]);

    // Funktion zur Durchführung der Hintergrundverfügbarkeitsprüfung
    const performBackgroundAvailabilityCheck = async (updateUI = true) => {
        if (backgroundCheckInProgress) return;
        if (!modBookingSystem) return;

        const requestData = getAvailabilityRequestData();
        if (!requestData) return;

        if (updateUI) {
            setBackgroundCheckInProgress(true);
        }

        try {
            console.log('Starte Hintergrundverfügbarkeitsprüfung...');
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/availability/check`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': user.api_key
                },
                body: JSON.stringify(requestData)
            });

            if (!response.ok) {
                throw new Error('Fehler bei der Hintergrundverfügbarkeitsprüfung');
            }

            const data = await response.json();
            availabilityResultsRef.current = data;
            console.log('Hintergrundverfügbarkeitsprüfung abgeschlossen');

            // Zentralen Slot finden und Status setzen - nur wenn updateUI = true
            if (updateUI) {
                const centralSlot = data.availabilityMatrix.find(s => s.isCentral);
                if (centralSlot) {
                    setAvailabilityStatus(centralSlot.status);
                    setAvailabilityChecked(true);
                }
            }
        } catch (err) {
            console.error('Fehler bei der Hintergrundverfügbarkeitsprüfung:', err);
            availabilityResultsRef.current = null;
            if (updateUI) {
                setAvailabilityStatus(null);
            }
        } finally {
            setBackgroundCheckInProgress(false);
        }
    };

    // Neuer Handler für den Verfügbarkeitscheck-Button
    const handleAvailabilityButtonClick = () => {
        // Erst die Verfügbarkeitsprüfung durchführen mit UI-Update
        performBackgroundAvailabilityCheck(true).then(() => {
            // Nach erfolgreicher Prüfung den Dialog öffnen
            setAvailabilityDialogOpen(true);
        });
    };

    useEffect(() => {
        // Aktualisiert unser zentrales formData, sobald sich availabilityChecked / availabilityStatus ändern
        setFormData(prev => ({
            ...prev,
            availabilityChecked,
            availabilityStatus
        }));
    }, [availabilityChecked, availabilityStatus, setFormData]);

    const errorMessage = React.useMemo(() => {
        switch (dateError) {
            case 'maxDate':
            case 'minDate':
            case 'minDateTime':
            case 'disablePast':
                return 'Das gewählte Datum/Uhrzeit ist ungültig (zu nah oder in der Vergangenheit).';
            case 'invalidDate':
                return 'Datum nicht korrekt.';
            default:
                return '';
        }
    }, [dateError]);

    // Standard-Rückfahrt
    const getDefaultReturnTime = () => {
        // SCRUM-93 (Rückfahrt Zeitvorschlag und Geburtstag Vorbelegung entfernen)
        //return formData.departureDateTime ? dayjs(formData.departureDateTime).add(4, 'hours') : null;
        return null;
    };
    const getMinReturnTime = () => {
        return minDate;
    };

    // Switches für Hin-/Rückfahrt
    const handleRecurringChange = (event) => {
        setFormData((prevData) => ({
            ...prevData,
            is_recurring: event.target.checked,
            departureDateTime: event.target.checked ? null : prevData.departureDateTime,
            returnDepartureTime: event.target.checked ? null : prevData.returnDepartureTime,
        }));
    };

    const handleInitalTripChange = (event) => {
        setFormData((prevData) => ({
            ...prevData,
            initial_trip: event.target.checked,
        }));
    };

    const handleReturnTripChange = (event) => {
        const checked = event.target.checked;
        setFormData((prevData) => ({
            ...prevData,
            return_trip: checked,
            returnDepartureTime: checked ? getDefaultReturnTime() : null,
        }));
    };

    // Zeitvalidierung
    const getTimeConstraints = (transportType, isReturn = false) => {
        let settingKey = transportType;
        if (transportType === 'DIALYSEFAHRT') {
            settingKey = isReturn ? 'DIALYSE_RUECKFART' : 'DIALYSE_HINFART';
        }
        const settings = timeSettings?.settings?.[settingKey] || {};
        const minTime = settings.startTime ? dayjs(settings.startTime, 'HH:mm') : null;
        const maxTime = settings.endTime ? dayjs(settings.endTime, 'HH:mm') : null;

        return { minTime, maxTime };
    };

    const validateTime = (time, transportType, isReturn = false) => {
        const { minTime, maxTime } = getTimeConstraints(transportType, isReturn);
        const timeObj = dayjs(time, 'HH:mm');

        if (minTime && timeObj.isBefore(minTime)) {
            return `Zeit muss nach ${minTime.format('HH:mm')} liegen`;
        }
        if (maxTime && timeObj.isAfter(maxTime)) {
            return `Zeit muss vor ${maxTime.format('HH:mm')} liegen`;
        }
        return null;
    };

    // Zusammenfassung der ausgewählten Tage
    const getRecurringSummary = (days, times) => {
        const daysAndTimes = [];

        // Extrahiere nur die Wochentage und Zeiten
        for (const [day, isSelected] of Object.entries(days || {})) {
            if (['valid_from', 'valid_to', 'break_from', 'break_to'].includes(day)) continue; // Felder ignorieren
            if (isSelected === true) {
                const time = times?.[day] || 'Keine Zeit angegeben';
                daysAndTimes.push(`${day}: ${time}`);
            }
        }

        return daysAndTimes.length > 0 ? daysAndTimes.join(', ') : 'Keine Tage oder Zeiten ausgewählt';
    };

    // Neue Funktion für die Gültigkeits- und Pauseninformationen
    const getValidityAndBreakInfo = (days) => {
        const validInfo = [];
        if (days?.valid_from) {
            validInfo.push(`Gültig ab: ${dayjs(days.valid_from).format('DD.MM.YYYY')}`);
        }
        if (days?.valid_to) {
            validInfo.push(`Gültig bis: ${dayjs(days.valid_to).format('DD.MM.YYYY')}`);
        }

        // Füge Pauseninformationen hinzu
        if (days?.break_from && days?.break_to) {
            validInfo.push(`Pause: ${dayjs(days.break_from).format('DD.MM.YYYY')} - ${dayjs(days.break_to).format('DD.MM.YYYY')}`);
        }

        return validInfo.length > 0 ? validInfo.join(', ') : '';
    };

    // Öffnen des Verfügbarkeitsdialogs
    // eslint-disable-next-line
    const handleOpenAvailabilityDialog = () => {
        setAvailabilityDialogOpen(true);
    };

    // Callback für die Auswahl eines Zeitslots
    const handleSelectTimeSlot = (slot) => {
        setFormData(prev => ({
            ...prev,
            departureDateTime: dayjs(`${slot.date}T${slot.time}`)
        }));
        setAvailabilityChecked(true);
    };

    // Prüfen, ob der Verfügbarkeitscheck-Button angezeigt werden soll
    const shouldShowAvailabilityCheck = () => {
        // Prüfen, ob alle erforderlichen Daten eingegeben wurden
        const requiredFields = {
            from_street: formData.from_street,
            from_postal_code: formData.from_postal_code,
            from_city: formData.from_city,
            to_street: formData.to_street,
            to_postal_code: formData.to_postal_code,
            to_city: formData.to_city,
            departureDateTime: formData.departureDateTime,
            transport_type: formData.transport_type
        };

        // Wenn ein Pflichtfeld fehlt, Button nicht anzeigen
        const missingRequiredField = Object.values(requiredFields).some(value => !value);
        if (missingRequiredField) return false;

        // Gilt nicht für Serien- und Rückserientransporte
        if (formData.isHeadOfSeries) {
            return false;
        }

        // Ansonsten immer anzeigen, wenn alle erforderlichen Daten vorhanden sind
        return true;
    };

    // Erstellen der Anfragedaten für den Verfügbarkeitsdialog
    const getAvailabilityRequestData = () => {
        // Erstellen einer temporären Kopie von departureDateTime
        let tempDepartureDateTime = formData.departureDateTime;

        // Wenn keine departureDateTime existiert, erstellen wir eine temporäre Zeit für die Verfügbarkeitsprüfung
        // WICHTIG: Wir verändern nicht mehr formData direkt
        if (!tempDepartureDateTime) {
            tempDepartureDateTime = dayjs().add(1, 'day').set('hour', 8).set('minute', 0).set('second', 0);
        }

        return {
            date: dayjs(tempDepartureDateTime).format('YYYY-MM-DD'),
            time: dayjs(tempDepartureDateTime).format('HH:mm:00'),
            from_street: formData.from_street || '',
            from_house_number: formData.from_house_number || '',
            from_postal_code: formData.from_postal_code || '',
            from_city: formData.from_city || '',
            to_street: formData.to_street || '',
            to_house_number: formData.to_house_number || '',
            to_postal_code: formData.to_postal_code || '',
            to_city: formData.to_city || '',
            transport_type: formData.transport_type || 'TERMINFAHRT'
        };
    };

    const noCheckboxChecked = !formData.initial_trip && !formData.return_trip;
    const recurringTimeErrors = getValidationErrors(3, formData, timeSettings, isEditMode, user, modBookingSystem);

    const isRecurring = formData.is_recurring || formData.is_recurring_return || false;
    const isSeriesTerm = formData.is_recurring || formData.is_recurring_return;

    // Hilfsfunktion für Markdown-Fehler
    const removeMarkdownFormatting = (text) => {
        return text.replace(/\*\*/g, '');
    };

    const availableTransportTypes = isExpertRole(user)
        ? ['TERMINFAHRT', 'ENTLASSUNG', 'EINWEISUNG', 'VERLEGUNG', 'DIALYSEFAHRT']
        : ['TERMINFAHRT', 'ENTLASSUNG', 'EINWEISUNG', 'VERLEGUNG'];

    return (
        <DrkTransportDesignBox isExpertMode={isExpertMode} minHeight={280}>
            <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                Wann soll der Transport durchgeführt werden?
            </Typography>

            <FormControl component="fieldset" margin="normal" fullWidth>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <FormLabel
                        component="legend"
                        style={{ color: formData.transport_type ? 'inherit' : '#E61F02' }}
                    >
                        Art des Transports
                    </FormLabel>
                    <RadioGroup
                        row
                        aria-label="transport_type"
                        name="transport_type"
                        value={formData.transport_type || ''}
                        onChange={handleChange}
                        disabled={isTransportApproved || formData.isHeadOfSeries || isSeriesTerm}
                    >
                        {availableTransportTypes.map((type) => (
                            <FormControlLabel
                                key={type}
                                value={type}
                                style={{ color: formData.hasOwnProperty('transport_type') ? 'inherit' : '#E61F02' }}
                                control={
                                    <Radio
                                        tabIndex={0}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter') {
                                                handleChange({
                                                    target: { name: 'transport_type', value: type }
                                                });
                                            }
                                        }}
                                        disabled={isTransportApproved || formData.isHeadOfSeries || isSeriesTerm}
                                    />
                                }
                                label={
                                    type === 'TERMINFAHRT' ? 'Terminfahrt' :
                                        type === 'ENTLASSUNG' ? 'Entlassung' :
                                            type === 'EINWEISUNG' ? 'Einweisung' :
                                                type === 'VERLEGUNG' ? 'Verlegung' : 'Dialysefahrt'
                                }
                                disabled={isTransportApproved || formData.isHeadOfSeries || isSeriesTerm}
                            />
                        ))}
                    </RadioGroup>
                </Box>
            </FormControl>

            {isSeriesTerm && (
                <Typography variant="body2" color="textSecondary" style={{ fontStyle: 'italic' }}>
                    Der Transporttyp kann für Serientermine nicht geändert werden.
                </Typography>
            )}

            {(formData.transport_type === 'TERMINFAHRT' || formData.transport_type === 'DIALYSEFAHRT')
                && (!isEditMode || formData.isHeadOfSeries) && (
                    <Box mt={2}>
                        <FormControl component="fieldset">
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <FormControlLabel
                                    control={<Switch
                                        checked={isRecurring}
                                        onChange={handleRecurringChange}
                                        disabled={isEditMode}
                                        inputProps={{ 'data-testid': 'recurring-transport-switch' }}
                                    />}
                                    label="Regelmäßiger Transport?"
                                    labelPlacement="start"
                                    sx={{ marginLeft: 0 }}
                                />
                                {isRecurring && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                            marginLeft: '34px',
                                        }}
                                        onClick={handleOpenDialog}
                                    >
                                        <CalendarMonthIcon sx={{ marginRight: '8px', color: theme.palette.primary.main }} />
                                        <Typography
                                            sx={{
                                                color: theme.palette.primary.main,
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            Einstellungen bearbeiten
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </FormControl>

                        {isRecurring && (
                            <Box mt={2}>
                                <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'gray' }}>
                                    Hinfahrten: {getRecurringSummary(formData.recurring_days, formData.recurring_times)}
                                </Typography>
                                <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'gray' }}>
                                    Rückfahrten: {getRecurringSummary(formData.recurring_return_days, formData.recurring_return_times)}
                                </Typography>
                                {getValidityAndBreakInfo(formData.recurring_days) && (
                                    <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'gray', mt: 1 }}>
                                        {getValidityAndBreakInfo(formData.recurring_days)}
                                    </Typography>
                                )}
                            </Box>
                        )}
                    </Box>
                )}

            <Box mt={isExpertMode ? 1 : 4} display="flex" alignItems="center">
                {!isRecurring && (formData.transport_type === 'TERMINFAHRT' || formData.transport_type === 'DIALYSEFAHRT') && (
                    <Box ml={0} mt={2}>
                        {!isEditMode && (
                            <Box display="flex" alignItems="center">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            data-testid="initial-trip-checkbox"
                                            checked={formData.initial_trip || false}
                                            onChange={handleInitalTripChange}
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter') {
                                                    event.preventDefault();
                                                    setFormData(prev => ({
                                                        ...prev,
                                                        initial_trip: !prev.initial_trip
                                                    }));
                                                }
                                            }}
                                            name="initialTrip"
                                            color="primary"
                                            tabIndex={0}
                                            sx={{ marginLeft: '10px' }}
                                        />
                                    }
                                    label="Hinfahrt (ja/nein)"
                                    labelPlacement="start"
                                    sx={{
                                        marginLeft: 0,
                                        color: noCheckboxChecked ? '#E61F02' : 'inherit',
                                    }}
                                />

                                <Box display="flex" alignItems="center">
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                                        <DateTimePicker
                                            desktopModeMediaQuery="@media (min-width: 0px)"
                                            label="Ankunft Zielort"
                                            name="departureDateTimeDia"
                                            value={formData.departureDateTime || null}
                                            onChange={(date) => handleChange({
                                                target: {
                                                    name: 'departureDateTime',
                                                    value: date
                                                }
                                            })}
                                            margin="normal"
                                            fullWidth
                                            ampm={false}
                                            format="DD.MM.YYYY HH:mm"
                                            disablePast
                                            maxDate={maxDate}
                                            minDateTime={minDate}
                                            disabled={!isEditMode && (isTransportApproved || !formData.initial_trip)}
                                            onError={(newError) => setDateError(newError)}
                                            slotProps={{
                                                textField: {
                                                    disabled: !isEditMode && (isTransportApproved || !formData.initial_trip),
                                                    error: !isEditMode && !isTransportApproved && formData.initial_trip && !!dateError,
                                                    helperText: (!isEditMode && !isTransportApproved && formData.initial_trip && errorMessage) || "",
                                                },
                                            }}
                                            sx={{ marginLeft: '16px' }}
                                        />
                                    </LocalizationProvider>

                                    {/* Verfügbarkeitscheck-Button - Verwendet jetzt die gemeinsame Komponente */}
                                    {shouldShowAvailabilityCheck() && modBookingSystem && (
                                        <AvailabilityCheckButton
                                            isChecking={backgroundCheckInProgress}
                                            status={availabilityStatus}
                                            isChecked={availabilityChecked}
                                            onCheckClick={handleAvailabilityButtonClick}
                                            onAlternativesClick={handleAvailabilityButtonClick}
                                            useExtendedButton={(!isExpertMode) || isEditMode || formData.transport_type === 'ENTLASSUNG' ||
                                                formData.transport_type === 'EINWEISUNG' ||
                                                formData.transport_type === 'VERLEGUNG'}
                                        />
                                    )}
                                </Box>
                            </Box>
                        )}
                        <br />
                        {!isEditMode && (
                            <>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formData.return_trip || false}
                                            onChange={handleReturnTripChange}
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter') {
                                                    event.preventDefault();
                                                    setFormData(prev => ({
                                                        ...prev,
                                                        return_trip: !prev.return_trip
                                                    }));
                                                }
                                            }}
                                            name="returnTrip"
                                            color="primary"
                                            tabIndex={0}
                                        />
                                    }
                                    label="Rückfahrt (ja/nein)"
                                    labelPlacement="start"
                                    sx={{
                                        marginLeft: 0,
                                        marginTop: 1,
                                        color: noCheckboxChecked ? '#E61F02' : 'inherit',
                                    }}
                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                                    <DateTimePicker
                                        desktopModeMediaQuery="@media (min-width: 0px)"
                                        label="Wunschzeit Abfahrt"
                                        name="returnDepartureTime"
                                        value={formData.returnDepartureTime || getDefaultReturnTime()}
                                        onChange={(date) => handleChange({
                                            target: {
                                                name: 'returnDepartureTime',
                                                value: date
                                            }
                                        })}
                                        margin="normal"
                                        fullWidth
                                        ampm={false}
                                        format="DD.MM.YYYY HH:mm"
                                        disablePast
                                        maxDate={maxDate}
                                        minDateTime={getMinReturnTime()}
                                        disabled={isTransportApproved || !formData.return_trip}
                                        onError={(newError) => setDateError(newError)}
                                        slotProps={{
                                            textField: {
                                                disabled: isTransportApproved || !formData.return_trip,
                                                error: !isTransportApproved && formData.return_trip && !!dateError,
                                                helperText: (!isTransportApproved && formData.return_trip && errorMessage) || "",
                                            },
                                        }}
                                        sx={{ marginLeft: '15px', marginTop: 1 }}
                                    />
                                </LocalizationProvider>
                            </>
                        )}
                    </Box>
                )}

                {(formData.transport_type === 'ENTLASSUNG'
                    || formData.transport_type === 'EINWEISUNG'
                    || formData.transport_type === 'VERLEGUNG'
                    || ((formData.transport_type === 'TERMINFAHRT' || formData.transport_type === 'DIALYSEFAHRT')
                        && isEditMode && !formData.isHeadOfSeries))
                    && (
                        <Box display="flex" alignItems="center" width="100%">
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                                <DateTimePicker
                                    desktopModeMediaQuery="@media (min-width: 0px)"
                                    label={formData.transport_type === 'TERMINFAHRT'
                                        ? 'Ankunft Zielort' : 'Wunschzeit Abfahrt'
                                    }
                                    name="departureDateTime"
                                    value={formData.departureDateTime || null}
                                    onChange={(date) => handleChange({
                                        target: {
                                            name: 'departureDateTime',
                                            value: date
                                        }
                                    })}
                                    margin="normal"
                                    fullWidth
                                    ampm={false}
                                    format="DD.MM.YYYY HH:mm"
                                    disablePast
                                    maxDate={maxDate}
                                    minDateTime={minDate}
                                    disabled={isTransportApproved}
                                    onError={(newError) => setDateError(newError)}
                                    slotProps={{
                                        textField: {
                                            disabled: isTransportApproved,
                                            error: !isTransportApproved && (recurringTimeErrors.length > 0),
                                            helperText: (!isTransportApproved && errorMessage) || "",
                                            inputProps: { 'data-testid': 'departure-date-time-picker' },
                                        },
                                    }}
                                    sx={{
                                        marginLeft: '0px',
                                        marginTop: isSeriesTerm ? '20px' : undefined
                                    }}
                                />
                            </LocalizationProvider>

                            {/* Verfügbarkeitscheck-Button für Edit-Modus - Auch hier verwenden wir die gemeinsame Komponente */}
                            {shouldShowAvailabilityCheck() && modBookingSystem && (
                                <AvailabilityCheckButton
                                    isChecking={backgroundCheckInProgress}
                                    status={availabilityStatus}
                                    isChecked={availabilityChecked}
                                    onCheckClick={handleAvailabilityButtonClick}
                                    onAlternativesClick={handleAvailabilityButtonClick}
                                    useExtendedButton={(!isExpertMode) || isEditMode || formData.transport_type === 'ENTLASSUNG' ||
                                        formData.transport_type === 'EINWEISUNG' ||
                                        formData.transport_type === 'VERLEGUNG'}
                                />
                            )}
                        </Box>
                    )}
            </Box>

            <>
                {/* Ausgelagerte Dialog-Komponente für Serientermin-Einstellungen */}
                <SerialAppointmentsDialog
                    open={dialogOpen}
                    onClose={handleCloseDialog}
                    onSave={handleSaveDialog}
                    formData={formData}
                    setFormData={setFormData}
                    timeErrors={timeErrors}
                    setTimeErrors={setTimeErrors}
                    getTimeConstraints={getTimeConstraints}
                    validateTime={validateTime}
                />

                {/* Verfügbarkeitsprüfung Dialog */}
                <AvailabilityCheckDialog
                    open={availabilityDialogOpen}
                    onClose={() => setAvailabilityDialogOpen(false)}
                    onSelectTimeSlot={handleSelectTimeSlot}
                    requestData={getAvailabilityRequestData()}
                    preloadedData={availabilityResultsRef.current}
                />

                {/* Hinweis: Expert Mode */}
                {!isExpertMode && formData.transport_type
                    && (formData.departureDateTime === null || formData.departureDateTime === undefined) && (
                        <Typography variant="body1" sx={{ mt: 2, color: 'text.secondary' }}>
                            {getTimeRangeInfoText(
                                formData.departureDateTime,
                                formData.transport_type,
                                timeSettings
                            )}
                        </Typography>
                    )}

                {/* Fehlermeldungen (aus validationLogic) */}
                {
                    recurringTimeErrors.length > 0 && formData.transport_type && (
                        <Box mt={1}>
                            {recurringTimeErrors.map((error, index) => (
                                <Typography key={index} variant="body2" color="#E61F02">
                                    {removeMarkdownFormatting(error)}
                                </Typography>
                            ))}
                        </Box>
                    )
                }
                {!isExpertMode && !formData.isHeadOfSeries
                    && getTimeRangeText(formData.departureDateTime, formData.transport_type, timeSettings)
                    && recurringTimeErrors.length === 0 && (
                        <Typography variant="body1" sx={{ mt: 2 }}>
                            {getTimeRangeText(formData.departureDateTime, formData.transport_type, timeSettings)}
                        </Typography>
                    )}
                {/* Hinzufügen des neuen Textes mit Animation wenn backgroundCheckInProgress aktiv ist */}
                <AnimatedTextWrapper
                    showCondition={backgroundCheckInProgress}
                    text="Wir suchen ein passendes Fahrzeug. Bitte warten..."
                    colorTheme="blue"
                    size="large"
                    minDuration={1500} // 3 seconds minimum display time
                />
            </>
        </DrkTransportDesignBox>
    );
};

export default WhenExpertStep;