import React from 'react';
import PropTypes from 'prop-types';
import {
    Popover,
    Box,
    Typography,
    Divider,
    IconButton,
    Paper
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

function VehiclePopover({ anchorEl, content, onClose }) {
    if (!content) return null;

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            disableRestoreFocus
        // Entferne pointerEvents: 'none', damit Benutzer mit dem Popover interagieren können
        >
            <Paper
                elevation={3}
                sx={{
                    maxWidth: 350,
                    bgcolor: '#333',
                    color: 'white',
                    borderRadius: 1,
                    overflow: 'hidden'
                }}
            >
                {/* Header mit Titel und Close-Button */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 1,
                        pl: 2,
                        bgcolor: 'rgba(255,255,255,0.1)'
                    }}
                >
                    <Typography variant="subtitle1" fontWeight="bold">
                        {content.vehicle_name || 'Unbekanntes Fahrzeug'}
                    </Typography>
                    <IconButton
                        size="small"
                        onClick={onClose}
                        sx={{
                            color: 'white',
                            '&:hover': {
                                bgcolor: 'rgba(255,255,255,0.2)'
                            }
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Box>

                <Box sx={{ p: 2 }}>
                    {content.license_plate && (
                        <Typography variant="body2" sx={{ display: 'flex' }}>
                            <Box component="span" sx={{ minWidth: 140 }}>Kennzeichen:</Box>
                            <Box component="span" sx={{ flex: 1 }}>{content.license_plate}</Box>
                        </Typography>
                    )}

                    {content.radio_call_sign && (
                        <Typography variant="body2" sx={{ display: 'flex' }}>
                            <Box component="span" sx={{ minWidth: 140 }}>Funkrufname:</Box>
                            <Box component="span" sx={{ flex: 1 }}>{content.radio_call_sign}</Box>
                        </Typography>
                    )}

                    <Divider sx={{ borderColor: 'rgba(255,255,255,0.1)', my: 1 }} />

                    {content.station_name && (
                        <Typography variant="body2" sx={{ display: 'flex' }}>
                            <Box component="span" sx={{ minWidth: 140 }}>Wache:</Box>
                            <Box component="span" sx={{ flex: 1 }}>{content.station_name}</Box>
                        </Typography>
                    )}

                    {content.lk && (
                        <Typography variant="body2" sx={{ display: 'flex' }}>
                            <Box component="span" sx={{ minWidth: 140 }}>Landkreis:</Box>
                            <Box component="span" sx={{ flex: 1 }}>{content.lk}</Box>
                        </Typography>
                    )}

                    {content.betreiber && (
                        <Typography variant="body2" sx={{ display: 'flex' }}>
                            <Box component="span" sx={{ minWidth: 140 }}>Betreiber:</Box>
                            <Box component="span" sx={{ flex: 1 }}>{content.betreiber}</Box>
                        </Typography>
                    )}

                    {/* Anzeige der Unverfügbarkeiten der nächsten 10 Tage */}
                    {content.unavailabilities && content.unavailabilities.length > 0 && (
                        <>
                            <Divider sx={{ borderColor: 'rgba(255,255,255,0.1)', my: 1 }} />
                            <Typography variant="caption" sx={{ fontStyle: 'italic', color: 'gray' }}>
                                Unverfügbarkeiten der nächsten 10 Tage:
                            </Typography>
                            {content.unavailabilities.map((unavail, index) => (
                                <Box key={index} sx={{ mt: 1 }}>
                                    <Typography variant="body2" sx={{ display: 'flex' }}>
                                        <Box component="span" sx={{ minWidth: 140 }}>Von:</Box>
                                        <Box component="span" sx={{ flex: 1 }}>
                                            {new Date(unavail.start_datetime).toLocaleString()}
                                        </Box>
                                    </Typography>
                                    <Typography variant="body2" sx={{ display: 'flex' }}>
                                        <Box component="span" sx={{ minWidth: 140 }}>Bis:</Box>
                                        <Box component="span" sx={{ flex: 1 }}>
                                            {new Date(unavail.end_datetime).toLocaleString()}
                                        </Box>
                                    </Typography>
                                    <Typography variant="body2" sx={{ display: 'flex' }}>
                                        <Box component="span" sx={{ minWidth: 140 }}>Grund:</Box>
                                        <Box component="span" sx={{ flex: 1 }}>
                                            {unavail.reason}
                                        </Box>
                                    </Typography>
                                </Box>
                            ))}
                        </>
                    )}

                    <Divider sx={{ borderColor: 'rgba(255,255,255,0.1)', my: 1 }} />

                    <Typography variant="caption" sx={{ display: 'flex', fontStyle: 'italic', color: 'gray' }}>
                        <Box component="span" sx={{ minWidth: 140 }}>Fahrzeug-ID:</Box>
                        <Box component="span" sx={{ flex: 1 }}>{content.vehicle_id}</Box>
                    </Typography>

                </Box>
            </Paper>
        </Popover>
    );
}

VehiclePopover.propTypes = {
    anchorEl: PropTypes.object,
    content: PropTypes.object,
    onClose: PropTypes.func.isRequired,
};

export default VehiclePopover;