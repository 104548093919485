// src/components/ConfigTabs.jsx
import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import { CATEGORY_LIST } from '../constants/categoryList';
import ConfigParamsList from './configParamsList';

export default function ConfigTabs() {
    const [tabValue, setTabValue] = useState('all');

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box sx={{ p: 2 }}>
            {/* Überschrift + Schnellsuche */}
            <Box display="flex" alignItems="center" justifyContent="left">
                <Typography variant="h5" color="#E46450" component="h1"  >
                    Konfigurationsparameter
                </Typography>
                {/* Tabs-Reihe */}
                <Box sx={{ justifyContent: 'center', marginLeft: '40px' }}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        textColor="primary"
                        indicatorColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {/* "Alle"-Tab */}
                        <Tab label="Alle" value="all" />

                        {/* Dynamisch aus der CATEGORY_LIST generieren */}
                        {CATEGORY_LIST.map(cat => (
                            <Tab key={cat.key} label={cat.label} value={cat.key} />
                        ))}
                    </Tabs>
                </Box>
            </Box>
            {/* Jetzt das ConfigParamsList je nach tabValue */}
            <ConfigParamsList category={tabValue} />
        </Box>
    );
}