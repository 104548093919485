import React, { useState, useEffect } from 'react';
import {
    Box,
    Tabs,
    Tab,
    Typography,
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Alert,
    FormControl,
    FormLabel,
    Grid,
} from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack'; // Snackbar
import { useUser } from '../../components/userContext'; // ggf. anpassen
import PersistentDataGrid from '../../components/PersistentDataGrid'; // analog zu deinem Infektionen-Beispiel

export default function SetupPage() {
    const [tabValue, setTabValue] = useState('tenants'); // "tenants" | "systemparams"
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useUser();

    // ---------------------------
    // TABS-Handling
    // ---------------------------
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // ---------------------------
    // Mandanten-STATE
    // ---------------------------
    const [tenants, setTenants] = useState([]);
    const [loadingTenants, setLoadingTenants] = useState(false);
    const [errorTenants, setErrorTenants] = useState(null);

    // Modal: Add / Edit Mandant
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState('add'); // 'add' | 'edit'
    const [tenantFormData, setTenantFormData] = useState({
        tenant_id: null,
        name: '',
        status: 'active',
        street: '',
        house_number: '',
        postal_code: '',
        city: '',
        licence_number: ''
    });

    // Zusätzliche Felder für den Admin-User (nur relevant im Add-Modus)
    const [adminEmail, setAdminEmail] = useState('');
    const [adminPw1, setAdminPw1] = useState('');
    const [adminPw2, setAdminPw2] = useState('');

    // ---------------------------
    // Laden der Mandanten
    // ---------------------------
    const fetchTenants = async () => {
        try {
            setLoadingTenants(true);
            setErrorTenants(null);

            const response = await fetch(
                `${process.env.REACT_APP_API_URI}/api/tenants?showAll=true`, // wir holen alle, auch 'inactive'
                {
                    headers: {
                        Authorization: user.api_key
                    }
                }
            );

            if (!response.ok) {
                throw new Error(`Fehler beim Abrufen der Tenants: ${response.statusText}`);
            }

            const data = await response.json();
            if (data.status !== 'success') {
                throw new Error(data.error || 'Unbekannter Fehler beim Laden der Mandanten.');
            }

            setTenants(data.data || []);
        } catch (err) {
            setErrorTenants(err.message);
        } finally {
            setLoadingTenants(false);
        }
    };

    useEffect(() => {
        if (user) {
            fetchTenants();
        }
        // eslint-disable-next-line
    }, [user]);

    // ---------------------------
    // Button: Add new Tenant
    // ---------------------------
    const handleOpenAddModal = () => {
        setModalMode('add');
        setTenantFormData({
            tenant_id: null,
            name: '',
            status: 'active',
            street: '',
            house_number: '',
            postal_code: '',
            city: '',
            licence_number: ''
        });
        setAdminEmail('');
        setAdminPw1('');
        setAdminPw2('');
        setModalOpen(true);
    };

    // ---------------------------
    // Button: Edit Tenant
    // ---------------------------
    const handleOpenEditModal = (tenantId) => {
        const t = tenants.find((x) => x.tenant_id === tenantId);
        if (t) {
            setModalMode('edit');
            setTenantFormData({ ...t });
            // Im Edit-Modus kein neues Admin-PW
            setAdminEmail('');
            setAdminPw1('');
            setAdminPw2('');
            setModalOpen(true);
        }
    };

    // ---------------------------
    // Button: Delete Tenant
    // => Setzt Status auf 'inactive'
    // ---------------------------
    const handleDeleteTenant = async (tenantId) => {
        if (!window.confirm(`Möchten Sie Mandant #${tenantId} wirklich deaktivieren?`)) {
            return;
        }
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URI}/api/tenants/${tenantId}`,
                {
                    method: 'DELETE',
                    headers: {
                        Authorization: user.api_key
                    }
                }
            );

            if (!response.ok) {
                const errData = await response.json();
                throw new Error(errData.error || 'Fehler beim Deaktivieren des Mandanten.');
            }

            enqueueSnackbar(`Mandant #${tenantId} wurde deaktiviert.`, { variant: 'success' });
            fetchTenants();
        } catch (err) {
            enqueueSnackbar(`Fehler: ${err.message}`, { variant: 'error' });
        }
    };

    // ---------------------------
    // Modal: Speichern
    // ---------------------------
    const handleSaveModal = async () => {
        try {
            // Validierung Mandant
            if (!tenantFormData.name) {
                throw new Error('Das Feld "Name" des Mandanten darf nicht leer sein.');
            }

            if (modalMode === 'add') {
                // Check Admin-Felder
                if (!adminEmail) {
                    throw new Error('E-Mail-Adresse des Admins darf nicht leer sein.');
                }
                if (!adminPw1 || !adminPw2) {
                    throw new Error('Passwörter dürfen nicht leer sein.');
                }
                if (adminPw1 !== adminPw2) {
                    throw new Error('Die Passwörter stimmen nicht überein.');
                }
            }

            let tenantId = tenantFormData.tenant_id;

            if (modalMode === 'add') {
                // Beispiel: handleSaveModal() im "Add Tenant" Fall
                const response = await fetch(
                    `${process.env.REACT_APP_API_URI}/api/tenants/create-with-admin`,
                    {
                        method: 'POST',
                        headers: {
                            Authorization: user.api_key,         // falls nötig
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            tenant: {
                                name: tenantFormData.name,
                                status: tenantFormData.status,
                                street: tenantFormData.street,
                                house_number: tenantFormData.house_number,
                                postal_code: tenantFormData.postal_code,
                                city: tenantFormData.city,
                                licence_number: tenantFormData.licence_number
                            },
                            adminUser: {
                                username: adminEmail,
                                password: adminPw1,
                                registration_status: 'bestätigt',
                                account_activated: true
                            }
                        })
                    }
                );
                if (!response.ok) {
                    const errData = await response.json();
                    throw new Error(errData.message || 'Fehler beim Anlegen');
                }
                const resultData = await response.json();
                console.log('Neuer Tenant + Admin:', resultData.data);

                enqueueSnackbar('Neuer Mandant + Admin wurden erfolgreich angelegt.', {
                    variant: 'success'
                });
            } else {
                // Edit-Modus: PUT /tenants/:id
                const updateRes = await fetch(
                    `${process.env.REACT_APP_API_URI}/api/tenants/${tenantId}`,
                    {
                        method: 'PUT',
                        headers: {
                            Authorization: user.api_key,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ ...tenantFormData })
                    }
                );
                if (!updateRes.ok) {
                    const errData = await updateRes.json();
                    throw new Error(errData.error || 'Fehler beim Bearbeiten des Mandanten.');
                }
                enqueueSnackbar('Mandant wurde erfolgreich bearbeitet.', { variant: 'success' });
            }

            // Nach erfolgreichem Speichern Modal schließen + Liste refresh
            setModalOpen(false);
            fetchTenants();
        } catch (err) {
            enqueueSnackbar(`Fehler: ${err.message}`, { variant: 'error' });
        }
    };

    // ---------------------------
    // DataGrid-Spalten
    // ---------------------------
    const columns = [
        { field: 'tenant_id', headerName: 'ID', width: 80 },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'status', headerName: 'Status', width: 120 },
        { field: 'street', headerName: 'Straße', width: 120 },
        { field: 'house_number', headerName: 'Nr.', width: 60 },
        { field: 'postal_code', headerName: 'PLZ', width: 80 },
        { field: 'city', headerName: 'Stadt', width: 120 },
        { field: 'licence_number', headerName: 'Lizenz-Nr.', width: 120 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Aktionen',
            width: 120,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Bearbeiten"
                    onClick={() => handleOpenEditModal(params.id)}
                    key="edit"
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Deaktivieren"
                    onClick={() => handleDeleteTenant(params.id)}
                    key="delete"
                />,
            ],
        },
    ];

    // ---------------------------
    // RENDER
    // ---------------------------
    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h4" sx={{ mb: 2 }}>
                Setup
            </Typography>

            <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label="Mandanten" value="tenants" />
                <Tab label="Systemparameter" value="systemparams" />
            </Tabs>

            {tabValue === 'tenants' && (
                <Box sx={{ mt: 3 }}>
                    <Typography variant="h6" gutterBottom>Mandanten-Verwaltung</Typography>
                    <Box sx={{ mb: 2 }}>
                        <Button
                            data-testid="add-tenant-button"
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={handleOpenAddModal}
                        >
                            Neuen Mandanten anlegen
                        </Button>
                    </Box>

                    {loadingTenants && <CircularProgress />}
                    {errorTenants && <Alert severity="error">{errorTenants}</Alert>}
                    {!loadingTenants && !errorTenants && (
                        <PersistentDataGrid
                            storageKey="tenantsList"
                            rows={tenants}
                            columns={columns}
                            pageSize={5}
                            getRowId={(row) => row.tenant_id}
                        />
                    )}

                    {/* Modal für Add/Edit */}
                    <Dialog
                        open={modalOpen}
                        onClose={() => setModalOpen(false)}
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogTitle>
                            {modalMode === 'add' ? 'Neuen Mandanten anlegen' : 'Mandant bearbeiten'}
                        </DialogTitle>
                        <DialogContent dividers>
                            <Box mt={1}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="tenant-name-input"
                                            label="Name"
                                            fullWidth
                                            value={tenantFormData.name || ''}
                                            onChange={(e) =>
                                                setTenantFormData({
                                                    ...tenantFormData,
                                                    name: e.target.value
                                                })
                                            }
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Status"
                                            fullWidth
                                            value={tenantFormData.status || ''}
                                            onChange={(e) =>
                                                setTenantFormData({
                                                    ...tenantFormData,
                                                    status: e.target.value
                                                })
                                            }
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="tenant-street-input"
                                            label="Straße"
                                            fullWidth
                                            value={tenantFormData.street || ''}
                                            onChange={(e) =>
                                                setTenantFormData({
                                                    ...tenantFormData,
                                                    street: e.target.value
                                                })
                                            }
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="tenant-house-number-input"
                                            label="Hausnummer"
                                            fullWidth
                                            value={tenantFormData.house_number || ''}
                                            onChange={(e) =>
                                                setTenantFormData({
                                                    ...tenantFormData,
                                                    house_number: e.target.value
                                                })
                                            }
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="tenant-postal-code-input"
                                            label="PLZ"
                                            fullWidth
                                            value={tenantFormData.postal_code || ''}
                                            onChange={(e) =>
                                                setTenantFormData({
                                                    ...tenantFormData,
                                                    postal_code: e.target.value
                                                })
                                            }
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="tenant-city-input"
                                            label="Stadt"
                                            fullWidth
                                            value={tenantFormData.city || ''}
                                            onChange={(e) =>
                                                setTenantFormData({
                                                    ...tenantFormData,
                                                    city: e.target.value
                                                })
                                            }
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="tenant-licence-number-input"
                                            label="Lizenz-Nr."
                                            fullWidth
                                            value={tenantFormData.licence_number || ''}
                                            onChange={(e) =>
                                                setTenantFormData({
                                                    ...tenantFormData,
                                                    licence_number: e.target.value
                                                })
                                            }
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            {modalMode === 'add' && (
                                <Box mt={3}>
                                    <FormControl fullWidth>
                                        <FormLabel>Administrator-Account</FormLabel>
                                        <TextField
                                            name="tenant-admin-email-input"
                                            label="E-Mail (Admin)"
                                            type="email"
                                            fullWidth
                                            value={adminEmail}
                                            onChange={(e) => setAdminEmail(e.target.value)}
                                            margin="normal"
                                        />
                                        <TextField
                                            name="tenant-admin-pw1-input"
                                            label="Passwort"
                                            type="password"
                                            fullWidth
                                            value={adminPw1}
                                            onChange={(e) => setAdminPw1(e.target.value)}
                                            margin="normal"
                                        />
                                        <TextField
                                            name="tenant-admin-pw2-input"
                                            label="Passwort (Wiederholung)"
                                            type="password"
                                            fullWidth
                                            value={adminPw2}
                                            onChange={(e) => setAdminPw2(e.target.value)}
                                            margin="normal"
                                        />
                                    </FormControl>
                                </Box>
                            )}
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: 'space-between', px: 3, pb: 2 }}>
                            <Button onClick={() => setModalOpen(false)}>
                                Abbrechen
                            </Button>
                            <Button variant="contained" onClick={handleSaveModal}>
                                Speichern
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            )}

            {tabValue === 'systemparams' && (
                <Box sx={{ mt: 3 }}>
                    <Typography variant="h6">Systemparameter</Typography>
                    <Typography variant="body1" sx={{ mt: 1 }}>
                        {/* Platzhalter-Inhalt */}
                        Hier können später globale Parameter gepflegt werden. (noch nicht implementiert)
                    </Typography>
                </Box>
            )}
        </Box>
    );
}