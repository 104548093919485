// src/constants/categoryList.js
import { ConfigCategories } from './configCategories';

export const CATEGORY_LIST = [
    {
        key: ConfigCategories.TIME_PARAMS,
        label: 'Zeitparameter',
    },
    {
        key: ConfigCategories.GEOCODING_ROUTING,
        label: 'Geocoding und Routing',
    },
    {
        key: ConfigCategories.INTERFACES,
        label: 'Schnittstellen',
    },
    {
        key: ConfigCategories.COMMUNICATOR,
        label: 'Kommunikator',
    },
    {
        key: ConfigCategories.GENERAL_MODULES,
        label: 'Allgemeine Module',
    },
    {
        key: ConfigCategories.SYSTEM_PARAMS,
        label: 'Systemparameter',
    },
];