import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Alert, Box, Button, Checkbox, FormControlLabel, IconButton, Tooltip, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../components/userContext';
import { isPasswordValid } from '../misc/helperFunctions';
import InstitutionAutocomplete from '../components/institutionAutocomplete';
import { useThemeContext, THEME_CLASSIC } from '../theme/ThemeContext';
import {
    DrkCard,
    DrkFormField,
    DrkPageContainer
} from '../components/DrkComponents';

const EditUserPage = () => {
    const { user } = useUser(); // Anbemeldeter User
    const [formData, setFormData] = useState({
        institution_id: '',
        password: '',
    });
    const [serverError, setServerError] = useState({});
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const { username } = useParams(); // Benutzername aus der URL holen. User zum Bearbeiten
    const [institutions, setInstitutions] = useState([]);
    // eslint-disable-next-line
    const [isEditMode, setIsEditMode] = useState(username != null);
    const [isPasswordChangeVisible, setIsPasswordChangeVisible] = useState(false);
    const { currentTheme } = useThemeContext();
    const isClassic = currentTheme === THEME_CLASSIC;

    useEffect(() => {
        // Benutzerdaten beim ersten Laden der Seite abrufen
        const fetchUserData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URI}/api/users/${username}`);
                if (response.ok) {
                    const data = await response.json();
                    setFormData({
                        ...data,
                        currentPassword: data.password,
                        password: '',
                    });
                } else {
                    const errorData = await response.json();
                    throw new Error(errorData.message);
                }
            } catch (error) {
                console.error('Fehler beim Abrufen des Benutzers:', error.message);
                setServerError(error.message);
            }
        };

        if (username) fetchUserData();
    }, [username]);

    useEffect(() => {
        fetchInstitutions()
            .then(gettedInstitutions => setInstitutions(gettedInstitutions))
            .catch(error => console.error('Fehler beim Abrufen der Institutionen:', error.message));
        // eslint-disable-next-line
    }, []);

    const fetchInstitutions = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/institutions`, {
                method: 'GET',
                headers: {
                    'Authorization': user.api_key,
                },
            });
            if (response.ok) {
                const data = await response.json();
                return data;
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message);
            }
        } catch (error) {
            console.error('Fehler beim Abrufen der Institutionen:', error.message);
            throw error; // Re-throw the error so that it can be caught in the .catch() block above
        }
    };

    const handleChange = (event) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        const { name } = event.target;

        const lowercaseValue = name === 'username' ? value.toLowerCase() : value;

        setFormData({
            ...formData,
            [event.target.name]: lowercaseValue
        });
    };

    const handleCancel = () => {
        navigate('/userspage');
    };

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        let newErrors = {};
        let newServerErrors = {};

        if (!validateEmail(formData.username)) {
            console.error('Ungültige E-Mail-Adresse');
            newErrors.username = "Ungültige E-Mail-Adresse";
        }

        if (!formData.institution_id) {
            // Display an error message or perform validation as needed
            console.error('Institution is required.');
            newErrors.institution = "Institution ist erforderlich";
        }

        if (!formData.role) {
            console.error('Role is required.');
            newErrors.role = "Rolle ist erforderlich";
        }

        if (!isEditMode || isPasswordChangeVisible) {
            // Überprüfen, ob die Passwortfelder ausgefüllt sind
            if (!formData.password) newErrors.password = 'Passwort ist erforderlich';
            if (!isPasswordValid(formData.password)) {
                newErrors.password = 'Passwort muss mindestens 6 Zeichen lang sein, einen Großbuchstaben und ein Sonderzeichen enthalten';
            }
            if (!formData.confirmPassword) newErrors.confirmPassword = 'Passwortbestätigung ist erforderlich';

            // Überprüfen, ob die Passwörter übereinstimmen
            if (formData.password && formData.confirmPassword && formData.password !== formData.confirmPassword) {
                newErrors.confirmPassword = 'Passwörter stimmen nicht überein';
            }
        }

        setErrors(newErrors);
        setServerError(newServerErrors);

        const isValid = Object.keys(newErrors).length === 0;

        if (isValid) {
            try {
                let response;
                if (isEditMode) {
                    response = await fetch(`${process.env.REACT_APP_API_URI}/api/users/${formData.user_id}`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': user.api_key,
                        },
                        body: JSON.stringify(formData),
                    });
                } else {
                    // Set registration_status to "bestätigt" for new user creation
                    const formDataWithRegistrationStatus = {
                        ...formData,
                        registration_status: "bestätigt"
                    };

                    response = await fetch(`${process.env.REACT_APP_API_URI}/api/users`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(formDataWithRegistrationStatus),
                    });
                }

                if (response.ok) {
                    const data = await response.json();
                    console.log('Benutzerdaten aktualisiert:', data);

                    // Call the password update function if needed
                    if (isPasswordChangeVisible) {
                        await resetPassword();
                    }

                    navigate('/userspage');
                } else {
                    const errorData = await response.json();
                    throw new Error(errorData.message);
                }
            } catch (error) {
                console.error('Fehler beim Aktualisieren des Benutzers:', error.message);
                setServerError(error.message);
            }
        }
    };

    const resetPassword = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/users/${formData.user_id}/passwordreset`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': user.api_key,
                },
                body: JSON.stringify({
                    currentPassword: formData.currentPassword,
                    newPassword: formData.password,
                }),
            });

            if (response.status === 200) {
                console.log('Password updated successfully');
            } else if (response.status === 403) {
                throw new Error('Current password incorrect');
            } else if (response.status === 404) {
                throw new Error('User not found');
            } else if (response.status === 500) {
                throw new Error('Server error');
            } else {
                throw new Error('Unknown error: ' + response.statusText);
            }
        } catch (error) {
            console.error('Error updating password:', error.message);
            setServerError('Fehler beim Aktualisieren des Passworts: ' + error.message);
        }
    };

    const handleCreateNewInstitution = async () => {
        navigate('/institutionpage');
        const updatedInstitutions = await fetchInstitutions();
        const newlyCreatedInstitution = updatedInstitutions.find(
            inst => !institutions.some(existingInst => existingInst.institution_id === inst.institution_id)
        );

        setInstitutions(updatedInstitutions);

        if (newlyCreatedInstitution) {
            console.log('Found new instituition: ' + newlyCreatedInstitution);
            setFormData({
                ...formData,
                institution_id: newlyCreatedInstitution.institution_id,
            });
        }
    };

    const selectedInstitution = institutions.find(inst => inst.institution_id === formData.institution_id);

    const handleTogglePasswordChange = () => {
        setIsPasswordChangeVisible(!isPasswordChangeVisible);
    };

    return (
        <DrkPageContainer>
            <DrkCard
                title={isEditMode ? 'Benutzer bearbeiten' : 'Benutzer anlegen'}
                elevation={isClassic ? 2 : 0}
                maxWidth="700px"
            >
                {errors && Object.values(errors).join(', ') &&
                    <Alert severity="error" sx={{ my: 2 }}>
                        {Object.values(errors).join(', ')}
                    </Alert>
                }

                {typeof serverError === 'string' && serverError &&
                    <Alert severity="error" sx={{ my: 2 }}>
                        {serverError}
                    </Alert>
                }

                <Box component="form" onSubmit={handleSubmit}>
                    {/* Institution Auswahl mit Autocomplete */}
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <Box sx={{ flex: 1 }}>
                            <InstitutionAutocomplete
                                value={selectedInstitution || null}
                                onChange={(event, newValue) => {
                                    if (newValue === null) {
                                        // Handle the case when the selection is cleared (x button)
                                        setFormData({
                                            ...formData,
                                            institution_id: ''
                                        });
                                    } else {
                                        setFormData({
                                            ...formData,
                                            institution_id: newValue ? newValue.institution_id : ''
                                        });
                                    }
                                }}
                                label="Institution eingeben (Klinik, Alten- oder Pflegeheim, Arztpraxis,...)"
                            />
                        </Box>
                        <Tooltip title="Hier klicken, um eine neue Institution hinzuzufügen" placement="top">
                            <IconButton
                                color="primary"
                                size="large"
                                sx={{ ml: 2 }}
                                onClick={handleCreateNewInstitution}
                            >
                                <AddCircleOutlineIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>

                    {/* Benutzername (E-Mail) */}
                    <DrkFormField
                        label="Benutzername (Mailadresse)"
                        name="username"
                        value={formData.username || ''}
                        onChange={handleChange}
                        error={errors.username}
                        helperText={errors.username}
                        fullWidth
                    />

                    {/* Aktuelles Passwort (nur im Bearbeitungsmodus) */}
                    {isEditMode && (
                        <>
                            <DrkFormField
                                label="Aktuelles Passwort"
                                name="currentPassword"
                                type="password"
                                value="thisIsTopSecret"
                                onChange={handleChange}
                                disabled
                                fullWidth
                            />
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleTogglePasswordChange}
                                sx={{ mb: 2 }}
                            >
                                {isPasswordChangeVisible ? 'Passwort nicht zurücksetzen' : 'Passwort zurücksetzen'}
                            </Button>
                        </>
                    )}

                    {/* Passwortfelder (nur bei Neuanlage oder Passwortrücksetzung) */}
                    {(!isEditMode || isPasswordChangeVisible) && (
                        <Box>
                            <DrkFormField
                                label="neues Passwort"
                                name="password"
                                type="password"
                                value={formData.password}
                                onChange={handleChange}
                                error={errors.password}
                                helperText={errors.password}
                                fullWidth
                            />
                            <DrkFormField
                                label="Passwort bestätigen"
                                name="confirmPassword"
                                type="password"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                error={errors.confirmPassword}
                                helperText={errors.confirmPassword}
                                fullWidth
                            />
                        </Box>
                    )}

                    {/* Rollenauswahl */}
                    <FormControl
                        fullWidth
                        margin="normal"
                        error={!!errors.role}
                        required
                    >
                        <InputLabel id="role-label">Rolle</InputLabel>
                        <Select
                            labelId="role-label"
                            id="role"
                            name="role"
                            value={formData.role || ''}
                            onChange={handleChange}
                            label="Rolle"
                            sx={{ borderRadius: isClassic ? '4px' : '8px' }}
                        >
                            <MenuItem value="admin">Admin</MenuItem>
                            <MenuItem value="poweruser">Disponent</MenuItem>
                            <MenuItem value="dialysis">Dialyse</MenuItem>
                            <MenuItem value="user">User</MenuItem>
                        </Select>
                        {errors.role && (
                            <FormHelperText>{errors.role}</FormHelperText>
                        )}
                    </FormControl>

                    {/* API-Key (nur wenn vorhanden) */}
                    {formData.api_key && (
                        <DrkFormField
                            label="API-Key"
                            name="api_key"
                            value={formData.api_key}
                            onChange={handleChange}
                            error={errors.api_key}
                            helperText={errors.api_key}
                            InputProps={{
                                readOnly: true,
                                style: { fontSize: '12px' }
                            }}
                            fullWidth
                        />
                    )}

                    {/* Account aktivieren Checkbox */}
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="account_activated"
                                checked={formData.account_activated || false}
                                onChange={handleChange}
                            />
                        }
                        label="Account aktiviert"
                    />

                    {/* Aktionsbuttons */}
                    <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            {isEditMode ? 'Änderungen speichern' : 'Speichern'}
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleCancel}
                        >
                            Abbrechen
                        </Button>
                    </Box>
                </Box>
            </DrkCard>
        </DrkPageContainer>
    );
};

export default EditUserPage;