import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    IconButton,
    Chip,
    Stack,
    CircularProgress,
    Alert,
    Snackbar,
    Button
} from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/de';  // Importiere die deutsche Lokalisierung
import { FreeBreakfast as BreakIcon } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { useUser } from '../../components/userContext';
import { deleteBreak } from './breakUtils';
import { useThemeContext, THEME_CLASSIC } from '../../theme/ThemeContext';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import { DrkDialogCard, DrkFormField } from '../../components/DrkComponents';

// Plugins für Zeitzonenfunktionalität registrieren
dayjs.extend(utc);
dayjs.extend(timezone);

// Setze die Standardzeitzone auf Berlin
dayjs.tz.setDefault('Europe/Berlin');

const BreakDialog = ({
    open,
    onClose,
    vehicleId,
    vehicleName,
    initialDate,
    initialTime,
    editMode = false,
    breakData = null
}) => {
    const { user } = useUser();
    const { currentTheme } = useThemeContext();
    const isClassic = currentTheme === THEME_CLASSIC;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    // Zustand für die Eingabefelder
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [notes, setNotes] = useState('');

    // Konvertiert DB-Zeitstempel nach Berliner Zeit für die Anzeige
    const convertToBerlinTime = (timestamp) => {
        if (!timestamp) return null;
        return dayjs(timestamp).tz('Europe/Berlin');
    };

    // Lade die Daten, wenn der Dialog im Bearbeitungsmodus geöffnet wird
    useEffect(() => {
        if (open) {
            setError(null);
            if (editMode && breakData) {
                // Im Bearbeitungsmodus: existierende Daten laden und nach Berliner Zeit konvertieren
                setStartTime(convertToBerlinTime(breakData.planned_start));
                setEndTime(convertToBerlinTime(breakData.planned_end));
                setNotes(breakData.notes || '');
            } else {
                // Im Erstellungsmodus: Initialwerte setzen
                const currentDate = initialDate ? dayjs(initialDate) : dayjs().tz('Europe/Berlin');
                let timeValue;

                if (initialTime) {
                    // Wenn eine initialTime übergeben wurde (z.B. vom Timeline-Klick)
                    timeValue = typeof initialTime === 'string'
                        ? dayjs(`${currentDate.format('YYYY-MM-DD')}T${initialTime}`).tz('Europe/Berlin')
                        : dayjs(initialTime).tz('Europe/Berlin');
                } else {
                    // Sonst die aktuelle Zeit nehmen und auf 5 Minuten runden
                    const now = dayjs().tz('Europe/Berlin');
                    const minutes = now.minute();
                    const roundedMinutes = Math.round(minutes / 5) * 5;
                    timeValue = now.minute(roundedMinutes).second(0);
                }

                setStartTime(timeValue);
                setEndTime(timeValue.add(30, 'minute')); // Standard: 30 Minuten Pause

                // Standardnotiz je nach Uhrzeit
                const hour = timeValue.hour();
                if (hour < 11) {
                    setNotes('Frühstückspause');
                } else {
                    setNotes('Mittagspause');
                }
            }
        }
    }, [open, editMode, breakData, initialDate, initialTime]);

    // Funktion zum Hinzufügen einer bestimmten Anzahl von Minuten zur Endzeit
    const addMinutesToEndTime = (minutes) => {
        if (startTime) {
            setEndTime(dayjs(startTime).add(minutes, 'minute'));
        }
    };

    // Validierungsfunktion
    const validateForm = () => {
        if (!startTime || !endTime) {
            setError('Start- und Endzeit müssen angegeben werden');
            return false;
        }

        if (!endTime.isAfter(startTime)) {
            setError('Die Endzeit muss nach der Startzeit liegen');
            return false;
        }

        return true;
    };

    // Funktion zum Speichern der Pause
    const handleSave = async () => {
        if (!validateForm()) return;

        setLoading(true);
        setError(null);

        try {
            const currentDate = initialDate ? dayjs(initialDate) : dayjs().tz('Europe/Berlin');
            const dateStr = currentDate.format('YYYY-MM-DD');

            // Erstelle Start- und Endzeit als Berliner Zeit und konvertiere in ISO-String ohne Zeitzone
            // indem das Datum und die Zeit kombiniert werden und dann als lokale Zeit behandelt werden
            const localStartDate = dayjs.tz(
                `${dateStr}T${startTime.format('HH:mm')}`,
                'Europe/Berlin'
            );

            const localEndDate = dayjs.tz(
                `${dateStr}T${endTime.format('HH:mm')}`,
                'Europe/Berlin'
            );

            // ISO-String ohne Zeitzone für die DB (nur Datum + Zeit)
            const startTimeISO = localStartDate.format('YYYY-MM-DDTHH:mm:ss');
            const endTimeISO = localEndDate.format('YYYY-MM-DDTHH:mm:ss');

            const payload = {
                vehicle_id: vehicleId,
                planned_start: startTimeISO,
                planned_end: endTimeISO,
                notes: notes
            };

            let url = `${process.env.REACT_APP_API_URI}/api/planning/break`;
            let method = 'POST';

            if (editMode && breakData) {
                // Bei Bearbeitung: PUT-Anfrage mit der ID
                const breakId = breakData.planning_detail_id;
                url = `${url}/${breakId}`;
                method = 'PUT';
            }

            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': user.api_key
                },
                body: JSON.stringify(payload)
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Ein Fehler ist aufgetreten');
            }

            setSuccess(true);

            // Nach kurzer Verzögerung schließen
            setTimeout(() => {
                onClose(true); // true signalisiert, dass Änderungen vorgenommen wurden
            }, 200);

        } catch (err) {
            console.error('Fehler beim Speichern der Pause:', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    // Funktion zum Löschen einer Pause (nur im Bearbeitungsmodus)
    const handleDelete = async () => {
        if (!editMode || !breakData) return;

        if (!window.confirm('Möchten Sie diese Pause wirklich löschen?')) {
            return;
        }

        setLoading(true);
        setError(null);

        try {
            await deleteBreak(breakData.planning_detail_id, user.api_key, process.env.REACT_APP_API_URI);
            setSuccess(true);

            // Nach kurzer Verzögerung schließen
            setTimeout(() => {
                onClose(true); // true signalisiert, dass Änderungen vorgenommen wurden
            }, 1500);

        } catch (err) {
            console.error('Fehler beim Löschen der Pause:', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    // Wir erstellen einen benutzerdefinierten Titel für den Dialog
    // Der Titel wird direkt in DrkDialog übergeben, daher brauchen wir keine separaten Variablen mehr

    // Footer-Aktionen (Speichern, Abbrechen, Löschen)
    const dialogActions = (
        <>
            {/* Linke Seite - Löschen-Button (nur im Edit-Modus) */}
            <Box sx={{ mr: 'auto' }}>
                {editMode && (
                    <Button
                        onClick={handleDelete}
                        color="error"
                        disabled={loading}
                    >
                        Löschen
                    </Button>
                )}
            </Box>

            {/* Rechte Seite - Abbrechen und Speichern */}
            <Button
                onClick={() => onClose(false)}
                disabled={loading}
                sx={{ color: 'text.primary' }}
            >
                Abbrechen
            </Button>

            <Button
                onClick={handleSave}
                variant="contained"
                disabled={loading}
                sx={{
                    bgcolor: '#e46450',
                    '&:hover': { bgcolor: '#d32f2f' }
                }}
                startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <BreakIcon />}
            >
                {editMode ? 'Aktualisieren' : 'Pause erstellen'}
            </Button>
        </>
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            {/* 
              Wir erstellen einen benutzerdefinierten Dialog mit einem eigenen Header im farbigen Balken 
            */}
            <Dialog
                open={open}
                onClose={() => onClose(false)}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: isClassic ? '4px' : '12px',
                        overflow: 'hidden'
                    }
                }}
            >
                {/* Farbiger Header-Balken mit Icon, Titel und Schließen-Button */}
                <Box
                    sx={{
                        bgcolor: '#e46450',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        p: 2
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <BreakIcon sx={{ color: 'white', mr: 1 }} />
                        <Typography variant="h6" component="div" fontWeight="500">
                            {editMode ? 'Pause bearbeiten' : 'Neue Pause erstellen'}
                        </Typography>
                    </Box>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => onClose(false)}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                {/* Roter Trennstreifen unterhalb des Headers - für das moderne Design */}
                {!isClassic && <Box sx={{ height: '4px', bgcolor: '#d32f2f' }} />}
                <DialogContent sx={{ backgroundColor: isClassic ? 'transparent' : '#f9fafb', pt: 2 }}>
                    <DrkDialogCard>
                        <Box sx={{ mt: 1 }}>
                            <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                Fahrzeug: {vehicleName || `ID: ${vehicleId}`}
                            </Typography>

                            <Typography variant="subtitle1" fontWeight="500" gutterBottom sx={{ mt: 2 }}>
                                Datum: {(initialDate ? dayjs(initialDate) : dayjs().tz('Europe/Berlin')).format('DD.MM.YYYY')}
                            </Typography>

                            <Box sx={{ mt: 3 }}>
                                <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                    Pausenzeit (Berliner Zeit):
                                </Typography>

                                <Box sx={{ display: 'flex', gap: 2, mb: 3, alignItems: 'center' }}>
                                    <TimePicker
                                        label="Startzeit"
                                        value={startTime}
                                        onChange={setStartTime}
                                        format="HH:mm"
                                        ampm={false}
                                        slotProps={{
                                            textField: {
                                                variant: 'outlined',
                                                fullWidth: true,
                                                required: true,
                                                sx: {
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: isClassic ? 1 : 2
                                                    }
                                                }
                                            }
                                        }}
                                    />

                                    <Typography variant="body1" sx={{ mx: 1 }}>bis</Typography>

                                    <TimePicker
                                        label="Endzeit"
                                        value={endTime}
                                        onChange={setEndTime}
                                        format="HH:mm"
                                        ampm={false}
                                        slotProps={{
                                            textField: {
                                                variant: 'outlined',
                                                fullWidth: true,
                                                required: true,
                                                sx: {
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: isClassic ? 1 : 2
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </Box>

                                <Typography variant="subtitle2" fontWeight="500" gutterBottom>
                                    Schnellauswahl Pausendauer:
                                </Typography>

                                <Stack direction="row" spacing={1} sx={{ mb: 3 }}>
                                    {[15, 30, 45, 60].map((minutes) => (
                                        <Chip
                                            key={minutes}
                                            label={`${minutes} Minuten`}
                                            onClick={() => addMinutesToEndTime(minutes)}
                                            color="primary"
                                            variant="outlined"
                                            sx={{
                                                borderColor: '#e46450',
                                                color: '#e46450',
                                                borderRadius: isClassic ? '4px' : '16px'
                                            }}
                                        />
                                    ))}
                                </Stack>

                                <DrkFormField
                                    label="Notizen"
                                    value={notes}
                                    onChange={(e) => setNotes(e.target.value)}
                                    fullWidth
                                    multiline
                                    rows={2}
                                    margin="normal"
                                />
                            </Box>
                        </Box>

                        {error && (
                            <Alert
                                severity="error"
                                sx={{
                                    mt: 2,
                                    borderRadius: isClassic ? 1 : 2
                                }}
                            >
                                {error}
                            </Alert>
                        )}
                    </DrkDialogCard>
                </DialogContent>

                <DialogActions sx={{ justifyContent: 'flex-end', p: 2, backgroundColor: isClassic ? 'transparent' : '#f9fafb' }}>
                    {dialogActions}
                </DialogActions>
            </Dialog>

            <Snackbar
                open={success}
                autoHideDuration={3000}
                onClose={() => setSuccess(false)}
                message={editMode ? "Pause erfolgreich aktualisiert" : "Pause erfolgreich erstellt"}
            />
        </LocalizationProvider>
    );
};

BreakDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    vehicleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    vehicleName: PropTypes.string,
    initialDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    initialTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    editMode: PropTypes.bool,
    breakData: PropTypes.object
};

export default BreakDialog;