import React, { useState, useEffect, useRef } from 'react';
import AnimatedText from './AnimatedText';
import { Fade } from '@mui/material';

const AnimatedTextWrapper = ({
    showCondition,
    text,
    minDuration = 3000,
    colorTheme = 'blue',
    size = 'medium',
    fadeOutDuration = 500, // Duration of fade-out effect in ms
    animated = true,
}) => {
    const [visible, setVisible] = useState(false);
    const [shouldRender, setShouldRender] = useState(false);
    const mountTimeRef = useRef(null);
    const timeoutRef = useRef(null);

    useEffect(() => {
        // When condition becomes true
        if (showCondition && !visible) {
            setVisible(true);
            setShouldRender(true);
            mountTimeRef.current = Date.now();
        }

        // When condition becomes false
        if (!showCondition && visible) {
            const elapsedTime = Date.now() - mountTimeRef.current;

            if (elapsedTime < minDuration) {
                // If minimum duration hasn't passed, set a timeout
                const remainingTime = minDuration - elapsedTime;

                // Clear any existing timeout
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }

                // Set new timeout to start fade-out after minimum duration
                timeoutRef.current = setTimeout(() => {
                    setVisible(false);

                    // Remove from DOM after fade completes
                    setTimeout(() => {
                        setShouldRender(false);
                    }, fadeOutDuration);
                }, remainingTime);
            } else {
                // Minimum duration has passed, start fade-out
                setVisible(false);

                // Remove from DOM after fade completes
                setTimeout(() => {
                    setShouldRender(false);
                }, fadeOutDuration);
            }
        }

        // Cleanup function
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [showCondition, visible, minDuration, fadeOutDuration]);

    // Render the AnimatedText component with Fade transition
    return shouldRender ? (
        <Fade in={visible} timeout={fadeOutDuration}>
            <div>
                <AnimatedText
                    text={text}
                    colorTheme={colorTheme}
                    size={size}
                    animated={animated}
                />
            </div>
        </Fade>
    ) : null;
};

export default AnimatedTextWrapper;