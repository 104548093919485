import React from 'react';
import {
    IconButton,
    Tooltip,
    CircularProgress,
    Box,
    Button,
    Badge,
    Zoom,
    Typography
} from '@mui/material';
import {
    CheckCircle as CheckCircleIcon,
    Warning as WarningIcon,
    Schedule as ScheduleIcon,
    Search as SearchIcon
} from '@mui/icons-material';

/**
 * Reusable component for the availability check button and status display
 * 
 * @param {Object} props - Component properties
 * @param {boolean} props.isChecking - Whether a check is currently in progress
 * @param {string} props.status - Current availability status ('green', 'yellow', 'red' or null)
 * @param {boolean} props.isChecked - Whether availability has been checked
 * @param {function} props.onCheckClick - Function to handle check button click
 * @param {function} props.onAlternativesClick - Function to handle alternatives button click (for 'red' status)
 * @param {boolean} props.useExtendedButton - Whether to use an extended button for alternatives (default: false)
 * @returns {JSX.Element} Rendered component
 */
const AvailabilityCheckButton = ({
    isChecking,
    status,
    isChecked,
    onCheckClick,
    onAlternativesClick,
    useExtendedButton = false
}) => {
    const pulseAnimation = `
        @keyframes pulse {
            0% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(25, 118, 210, 0.7);
            }
            
            70% {
                transform: scale(1);
                box-shadow: 0 0 0 8px rgba(25, 118, 210, 0);
            }
            
            100% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(25, 118, 210, 0);
            }
        }
    `;

    // Die Farbkonstanten für die verschiedenen Status
    const colors = {
        green: '#4caf50',
        yellow: '#ff9800',
        red: '#f44336',
        primary: '#1976d2'
    };

    // Get button styling and icon based on status
    const getButtonStyling = () => {
        if (isChecking) {
            return {
                color: 'primary',
                customColor: colors.primary,
                icon: <CircularProgress size={20} thickness={5} color="inherit" />,
                tooltip: "Verfügbarkeit wird geprüft..."
            };
        }

        if (status === 'red') {
            return {
                color: 'error',
                customColor: colors.red,
                icon: <WarningIcon />,
                tooltip: "Keine Fahrzeuge verfügbar! Bitte Zeit ändern oder Alternativen prüfen."
            };
        }

        if (isChecked && status === 'green') {
            return {
                color: 'success',
                customColor: colors.green,
                icon: <CheckCircleIcon sx={{ color: colors.green }} />,
                tooltip: "Mehrere Fahrzeuge verfügbar"
            };
        }

        if (isChecked && status === 'yellow') {
            return {
                color: 'warning',
                customColor: colors.yellow,
                icon: <CheckCircleIcon sx={{ color: colors.yellow }} />,
                tooltip: "Ein Fahrzeug verfügbar"
            };
        }

        return {
            color: 'primary',
            customColor: colors.primary,
            icon: <ScheduleIcon />,
            tooltip: "Verfügbarkeit prüfen"
        };
    };

    const buttonStyling = getButtonStyling();

    // Render search button when not checked or loading indicator when checking
    const renderSearchButton = () => {
        // Show loading indicator when checking
        if (isChecking) {
            return (
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    ml: 1,
                    px: 2,
                    py: 0.5,
                    borderRadius: '16px',
                    backgroundColor: 'rgba(25, 118, 210, 0.08)',
                    border: '1px solid rgba(25, 118, 210, 0.3)'
                }}>
                    <CircularProgress size={20} thickness={5} color="primary" />
                    {useExtendedButton && (
                        <Typography variant="caption" sx={{ ml: 1, color: colors.primary, fontWeight: 'medium' }}>
                            Prüfe Verfügbarkeit...
                        </Typography>
                    )}
                </Box>
            );
        }

        // Show search button when not checked
        if (!isChecked) {
            if (useExtendedButton) {
                return (
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        startIcon={<SearchIcon />}
                        onClick={onCheckClick}
                        sx={{
                            ml: 1,
                            animation: 'pulse 1.5s infinite',
                            fontSize: '0.75rem',
                            borderRadius: '16px',
                            height: '28px'
                        }}
                    >
                        Verfügbare Fahrzeuge suchen
                    </Button>
                );
            } else {
                return (
                    <Button
                        data-testid="availability-check-button"
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={onCheckClick}
                        sx={{
                            ml: 1,
                            animation: 'pulse 1.5s infinite',
                            borderRadius: '16px',
                            height: '28px',
                            minWidth: '80px'
                        }}
                    >
                        Suchen
                    </Button>
                );
            }
        }
        return null;
    };

    // Enhanced styling for green/yellow checked button when useExtendedButton is true
    const renderAvailabilityButton = () => {
        if (!isChecked || isChecking) {
            return null; // Don't show the icon button if not checked or is checking
        }

        if (useExtendedButton && isChecked && status === 'green') {
            return (
                <Zoom in={true}>
                    <Button
                        variant="outlined"
                        color="success"
                        size="small"
                        startIcon={<CheckCircleIcon sx={{ color: colors.green }} />}
                        onClick={onCheckClick}
                        sx={{
                            ml: 1,
                            fontSize: '0.75rem',
                            borderRadius: '16px',
                            height: '28px',
                            border: `1px solid rgba(76, 175, 80, 0.5)`,
                            color: colors.green,
                            boxShadow: `0 0 8px rgba(76, 175, 80, 0.4)`,
                            '&:hover': {
                                backgroundColor: 'rgba(76, 175, 80, 0.08)',
                                borderColor: colors.green,
                                boxShadow: '0 0 12px rgba(76, 175, 80, 0.6)'
                            }
                        }}
                    >
                        Verfügbar
                    </Button>
                </Zoom>
            );
        }

        if (useExtendedButton && isChecked && status === 'yellow') {
            return (
                <Zoom in={true}>
                    <Button
                        variant="outlined"
                        color="warning"
                        size="small"
                        startIcon={<WarningIcon sx={{ color: colors.yellow }} />}
                        onClick={onCheckClick}
                        sx={{
                            ml: 1,
                            fontSize: '0.75rem',
                            borderRadius: '16px',
                            height: '28px',
                            border: `1px solid rgba(255, 152, 0, 0.5)`,
                            color: colors.yellow,
                            boxShadow: `0 0 8px rgba(255, 152, 0, 0.4)`,
                            '&:hover': {
                                backgroundColor: 'rgba(255, 152, 0, 0.08)',
                                borderColor: colors.yellow,
                                boxShadow: '0 0 12px rgba(255, 152, 0, 0.6)'
                            }
                        }}
                    >
                        Geringe Verfügbarkeit
                    </Button>
                </Zoom>
            );
        }

        // Default button with custom color styling
        return (
            <Badge
                variant="dot"
                color={buttonStyling.color}
                invisible={!isChecked || status === 'red'}
                overlap="circular"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    "& .MuiBadge-badge": {
                        backgroundColor: status === 'yellow' ? colors.yellow : undefined
                    }
                }}
            >
                <Tooltip title={buttonStyling.tooltip}>
                    <IconButton
                        color={buttonStyling.color}
                        onClick={onCheckClick}
                        size="medium"
                        disabled={isChecking}
                        sx={{
                            animation: isChecking ? 'none' : (!isChecked ? 'pulse 1.5s infinite' : 'none'),
                            transition: 'all 0.3s ease',
                            color: status === 'yellow' && isChecked ? colors.yellow : undefined,
                            '&:hover': {
                                transform: 'scale(1.1)',
                                backgroundColor: status === 'red'
                                    ? 'rgba(244, 67, 54, 0.1)'
                                    : (status === 'yellow' && isChecked)
                                        ? 'rgba(255, 152, 0, 0.1)'
                                        : 'rgba(25, 118, 210, 0.1)'
                            }
                        }}
                    >
                        {buttonStyling.icon}
                    </IconButton>
                </Tooltip>
            </Badge>
        );
    };

    return (
        <>
            <style>{pulseAnimation}</style>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                ml: 1
            }}>
                {/* Search button when not checked */}
                {renderSearchButton()}

                {/* Availability status button when checked */}
                {renderAvailabilityButton()}

                {/* Zeige je nach useExtendedButton Prop die passende Version bei "red" Status */}
                {status === 'red' && (
                    useExtendedButton ? (
                        // Extended Button mit Text
                        <Zoom in={status === 'red'}>
                            <Button
                                variant="outlined"
                                color="error"
                                size="small"
                                onClick={onAlternativesClick}
                                sx={{
                                    ml: 1,
                                    fontSize: '0.75rem',
                                    borderRadius: '16px',
                                    height: '28px',
                                    animation: 'pulse 1.5s infinite',
                                    boxShadow: '0 0 8px rgba(244, 67, 54, 0.4)'
                                }}
                            >
                                Alternativen
                            </Button>
                        </Zoom>
                    ) : (
                        // Kompakter Icon-Button
                        <Zoom in={status === 'red'}>
                            <Tooltip title="Alternative Zeiten prüfen">
                                <IconButton
                                    color="error"
                                    size="small"
                                    onClick={onAlternativesClick}
                                    sx={{
                                        ml: 0.5,
                                        animation: 'pulse 1.5s infinite',
                                        boxShadow: '0 0 8px rgba(244, 67, 54, 0.4)',
                                        border: '1px solid rgba(244, 67, 54, 0.5)',
                                        p: 0.5
                                    }}
                                >
                                    <SearchIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Zoom>
                    )
                )}
            </Box>
        </>
    );
};

export default AvailabilityCheckButton;