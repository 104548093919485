import React, { useState, useEffect } from 'react';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    TextField,
    Checkbox,
    Typography,
} from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import { useUser } from '../components/userContext';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PersistentDataGrid from '../components/PersistentDataGrid';
import { useSnackbar } from 'notistack';

const InfektionenList = () => {
    const [infektionen, setInfektionen] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [serverError, setServerError] = useState(null);
    // eslint-disable-next-line
    const [openAlert, setOpenAlert] = useState(false);
    const { user } = useUser();

    // Modal-State für Hinzufügen/Bearbeiten
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState('add'); // 'add' oder 'edit'
    const [modalInfektion, setModalInfektion] = useState({
        infektion: '',
        disinfection_time: 60,
        in_main_station: false,
    });

    // Snackbar
    const { enqueueSnackbar } = useSnackbar();
    const showSnackbar = (message, variant) => {
        enqueueSnackbar(message, { variant: variant || 'default' });
    };

    useEffect(() => {
        if (user) {
            fetchInfektionen();
        }
        // eslint-disable-next-line
    }, [user]);

    const fetchInfektionen = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/infektionen`, {
                method: 'GET',
                headers: {
                    'Authorization': user.api_key,
                },
            });
            if (response.ok) {
                const data = await response.json();
                // Alphabetische Sortierung nach "infektion"
                data.sort((a, b) => a.infektion.localeCompare(b.infektion));
                setInfektionen(data);
                setServerError(null);
            } else {
                setServerError('Fehler beim Abrufen der Infektionen.');
            }
        } catch (error) {
            console.error('Fehler beim Abrufen der Infektionen:', error.message);
            setServerError('Fehler beim Abrufen der Infektionen.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteInfektion = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/infektionen/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': user.api_key,
                },
            });

            if (response.ok) {
                fetchInfektionen();
                setServerError(null);
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Fehler beim Löschen der Infektion.');
            }
        } catch (error) {
            console.error('Fehler beim Löschen der Infektion:', error.message);
            setServerError('Fehler beim Löschen der Infektion: ' + error.message);
        }
    };

    // Öffnet den Modal-Dialog im "Hinzufügen"-Modus
    const handleOpenAddModal = () => {
        setModalMode('add');
        setModalInfektion({
            infektion: '',
            disinfection_time: 60,
            in_main_station: false,
        });
        setModalOpen(true);
        setServerError(null);
    };

    // Öffnet den Modal-Dialog im "Bearbeiten"-Modus und lädt die Daten der zu bearbeitenden Infektion
    const handleOpenEditModal = (id) => {
        const infektionToEdit = findInfektionById(id);
        if (infektionToEdit) {
            setModalMode('edit');
            setModalInfektion({ ...infektionToEdit });
            setModalOpen(true);
            setServerError(null);
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    // Speichert den Datensatz (POST bei "add", PUT bei "edit")
    const handleSaveModal = async () => {
        try {
            if (!modalInfektion.infektion) {
                setServerError('Das Feld "Infektion" darf nicht leer sein.');
                return;
            }
            // Validierung für disinfection_time: muss >= 0 sein
            if (typeof modalInfektion.disinfection_time !== 'number' || modalInfektion.disinfection_time < 0) {
                setServerError('Die "Desinfektionszeit" muss eine Zahl >= 0 sein.');
                return;
            }
            let response;
            if (modalMode === 'add') {
                response = await fetch(`${process.env.REACT_APP_API_URI}/api/infektionen`, {
                    method: 'POST',
                    headers: {
                        'Authorization': user.api_key,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(modalInfektion),
                });
            } else if (modalMode === 'edit') {
                response = await fetch(`${process.env.REACT_APP_API_URI}/api/infektionen/${modalInfektion.infektion_id}`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': user.api_key,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(modalInfektion),
                });
            }

            if (response.ok) {
                setModalOpen(false);
                fetchInfektionen();
                setServerError(null);
                showSnackbar('Infektion wurde erfolgreich bearbeitet.', 'success');
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Fehler beim Speichern der Infektion.');
            }
        } catch (error) {
            console.error('Fehler beim Speichern der Infektion:', error.message);
            setServerError('Fehler beim Speichern der Infektion: ' + error.message);
        }
    };

    const findInfektionById = (id) => {
        const infektionId = Number(id);
        return infektionen.find((infektion) => infektion.infektion_id === infektionId);
    };

    const columns = [
        { field: 'infektion_id', headerName: 'ID', width: 100 },
        { field: 'infektion', headerName: 'Infektion', width: 300 },
        {
            field: 'disinfection_time',
            headerName: 'Desinfektionszeit (Minuten)',
            width: 200
        },
        {
            field: 'in_main_station',
            headerName: 'Desinfektion in Stammwache erforderlich',
            width: 250,
            renderCell: (params) => (
                <span>{params.value ? 'Ja' : 'Nein'}</span>
            )
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Aktionen',
            width: 120,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Löschen"
                    onClick={() => handleDeleteInfektion(params.id)}
                    showInMenu
                    key="delete"
                />,
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Bearbeiten"
                    onClick={() => handleOpenEditModal(params.id)}
                    key="edit"
                />,
            ],
        }
    ];

    return (
        <Box m={1}>
            <Typography variant="h5" color="#E46450" component="h1" gutterBottom mt={1}>
                Infektionenliste
            </Typography>
            {isLoading ? (
                <CircularProgress color="inherit" />
            ) : (
                <div>
                    <Box mb={2}>
                        <Button variant="contained" startIcon={<AddIcon />} onClick={handleOpenAddModal}>
                            Infektion hinzufügen
                        </Button>
                    </Box>

                    {serverError && <Alert severity="error" sx={{ mb: 2 }}>{serverError}</Alert>}
                    {openAlert && (
                        <Alert severity="success" sx={{ mb: 2 }}>
                            Infektion erfolgreich gespeichert.
                        </Alert>
                    )}

                    <div style={{ width: '100%' }}>
                        <PersistentDataGrid
                            storageKey="infektionenList"
                            rows={infektionen}
                            columns={columns}
                            pageSize={5}
                            getRowId={(row) => row.infektion_id}
                        />
                    </div>

                    {/* Modal-Dialog für Hinzufügen/Bearbeiten */}
                    <Dialog open={modalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
                        <DialogTitle>
                            {modalMode === 'add' ? 'Infektion hinzufügen' : 'Infektion bearbeiten'}
                        </DialogTitle>
                        <DialogContent dividers>
                            <TextField
                                margin="dense"
                                label="Infektion"
                                fullWidth
                                value={modalInfektion.infektion}
                                onChange={(e) =>
                                    setModalInfektion({ ...modalInfektion, infektion: e.target.value })
                                }
                            />
                            <TextField
                                margin="dense"
                                label="Desinfektionszeit (Minuten)"
                                type="number"
                                fullWidth
                                value={modalInfektion.disinfection_time}
                                onChange={(e) =>
                                    setModalInfektion({
                                        ...modalInfektion,
                                        disinfection_time: parseInt(e.target.value, 10)
                                    })
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={modalInfektion.in_main_station}
                                        onChange={(e) =>
                                            setModalInfektion({
                                                ...modalInfektion,
                                                in_main_station: e.target.checked
                                            })
                                        }
                                    />
                                }
                                label="Desinfektion in Stammwache erforderlich"
                            />
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: 'space-between', px: 3, pb: 2 }}>
                            <Button onClick={handleCloseModal}>Abbrechen</Button>
                            <Button onClick={handleSaveModal} variant="contained" disabled={!modalInfektion.infektion}>
                                Speichern
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )}
        </Box>
    );
};

export default InfektionenList;