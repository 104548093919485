// VehicleHistoryEntryCard.js
import React from 'react';
import { Box, Typography, Card, CardContent } from '@mui/material';
import dayjs from 'dayjs';

const formatDateTime = (dtStr) => {
    if (!dtStr) return '';
    return dayjs(dtStr).format('DD.MM.YYYY HH:mm');
};

const VehicleHistoryEntryCard = ({ entry }) => {
    const {
        historie_id,
        modified_date,
        changed_by_user,
        changed_action,
        changes_json
    } = entry;

    // Datum formatieren
    const dateStr = dayjs(modified_date).format('DD.MM.YYYY HH:mm:ss');

    // Hier unsere Funktion, die changes_json interpretiert
    const renderChanges = () => {
        if (!changes_json) return null;

        return Object.entries(changes_json).map(([key, change]) => {
            // z. B. key = "unavailability_updated", "unavailability_removed", "shift_assignment" etc.

            if (key === 'unavailability_updated') {
                // Hier haben wir old_value/new_value mit Feldern: start_datetime, end_datetime, reason, additional_info, ...
                const oldVal = change.old_value || {};
                const newVal = change.new_value || {};
                return (
                    <Typography variant="body2" sx={{ mb: 1 }} key={key}>
                        <strong>Unverfügbarkeit geändert</strong>:
                        <br />
                        <em>Alt:</em> {formatDateTime(oldVal.start_datetime)} - {formatDateTime(oldVal.end_datetime)},
                        Grund: {oldVal.reason || '(kein)'},
                        Info: {oldVal.additional_info || '(keine)'}
                        <br />
                        <em>Neu:</em> {formatDateTime(newVal.start_datetime)} - {formatDateTime(newVal.end_datetime)},
                        Grund: {newVal.reason || '(kein)'},
                        Info: {newVal.additional_info || '(keine)'}
                    </Typography>
                );
            }

            if (key === 'unavailability_added') {
                // old_value=null, new_value={ start_datetime, end_datetime, reason, ... }
                const newVal = change.new_value || {};
                return (
                    <Typography variant="body2" sx={{ mb: 1 }} key={key}>
                        <strong>Unverfügbarkeit hinzugefügt</strong>:
                        <br />
                        {formatDateTime(newVal.start_datetime)} - {formatDateTime(newVal.end_datetime)},
                        Grund: {newVal.reason || '(kein)'},
                        Info: {newVal.additional_info || '(keine)'}
                    </Typography>
                );
            }

            if (key === 'unavailability_removed') {
                // old_value={...}, new_value=null
                const oldVal = change.old_value || {};
                return (
                    <Typography variant="body2" sx={{ mb: 1 }} key={key}>
                        <strong>Unverfügbarkeit entfernt</strong>:
                        <br />
                        {formatDateTime(oldVal.start_datetime)} - {formatDateTime(oldVal.end_datetime)},
                        Grund: {oldVal.reason || '(kein)'},
                        Info: {oldVal.additional_info || '(keine)'}
                    </Typography>
                );
            }

            // ALLES, was NICHT unavailability_* ist:
            // z. B. shift_assignment, station_name, vehicle_name usw.
            if (key === 'shift_assignment') {
                if (change.old_value === null && change.new_value) {
                    return (
                        <Typography variant="body2" sx={{ mb: 1 }} key={key}>
                            <strong>Schichtzuordnung hinzugefügt</strong>: {change.new_value}
                        </Typography>
                    );
                } else if (change.old_value && change.new_value === null) {
                    return (
                        <Typography variant="body2" sx={{ mb: 1 }} key={key}>
                            <strong>Schichtzuordnung entfernt</strong>: {change.old_value}
                        </Typography>
                    );
                } else {
                    // Falls es alt + neu gibt (unwahrscheinlich in dem Fall)
                    return (
                        <Typography variant="body2" sx={{ mb: 1 }} key={key}>
                            <strong>Schichtzuordnung geändert</strong> von <em>{change.old_value}</em> auf <em>{change.new_value}</em>
                        </Typography>
                    );
                }
            }

            if (key === 'station_name') {
                // z. B. altValue= "Station A", newValue= "Station B"
                return (
                    <Typography variant="body2" sx={{ mb: 1 }} key={key}>
                        <strong>Wache gewechselt</strong> von <em>{change.old_value}</em> auf <em>{change.new_value}</em>
                    </Typography>
                );
            }

            if (key === 'default_station_name') {
                // z. B. altValue= "Station A", newValue= "Station B"
                return (
                    <Typography variant="body2" sx={{ mb: 1 }} key={key}>
                        <strong>Stammwache gewechselt</strong> von <em>{change.old_value}</em> auf <em>{change.new_value}</em>
                    </Typography>
                );
            }

            // Genereller Fallback – wir können z. B. anstatt JSON.stringify
            // etwas "schneller" interpretieren:
            return (
                <Typography variant="body2" sx={{ mb: 1 }} key={key}>
                    <strong>{key}</strong> geändert: alt={String(change.old_value)} / neu={String(change.new_value)}
                </Typography>
            );
        });
    };

    return (
        <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent>
                <Typography variant="caption" color="text.secondary">
                    #{historie_id} &bull; {dateStr} &bull; {changed_by_user} &bull; {changed_action}
                </Typography>
                <Box mt={1}>{renderChanges()}</Box>
            </CardContent>
        </Card>
    );
};

export default VehicleHistoryEntryCard;