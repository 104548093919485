import React, { useState } from 'react';
import {
    Box,
    Typography,
    TextField,
    InputAdornment,
    IconButton,
    Stack,
    Paper,
    Divider,
    Tooltip,
    Button,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import SettingsMenu from '../../components/transportSettingsMenu';
import {
    ArrowBack,
    ArrowForward,
    Refresh as RefreshIcon,
    Clear as ClearIcon,
    Search as SearchIcon,
    ViewDay as ViewDayIcon,
    ViewList as ViewListIcon,
    SwapHoriz as SwapHorizIcon,
    Cancel as CancelIcon,
    Assessment as AssessmentIcon,
    Close as CloseIcon,
    ErrorOutline as ErrorOutlineIcon
} from '@mui/icons-material';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import CountyFilter from './CountyFilter';
import StatistikDashboard from './StatistikDashboard';

const PlanningHeader = ({
    title = "Planungsübersicht",
    selectedDate,
    searchQuery,
    isAutoRefreshEnabled,
    viewMode,
    timeScale,
    handleRefresh,
    handleSearch,
    handleClearSearch,
    handleDateChange,
    changeDate,
    setTimeScale,
    toggleViewMode,
    toggleAutoRefresh,
    selectedCounties,
    handleCountyFilterChange,
    roundTripFilter,
    handleClearRoundTripFilter,
    onOpenUnplannedTransports
}) => {
    // Überprüfen, ob das ausgewählte Datum heute ist
    const isToday = selectedDate ? selectedDate.isSame(dayjs(), 'day') : false;

    // Das aktuelle Datum für Vergleiche
    const today = dayjs();

    // State für das Statistik-Dialog
    const [statisticsOpen, setStatisticsOpen] = useState(false);

    // Funktion zur Überprüfung, ob ein Datum in der Zukunft oder heute liegt
    const isDateValid = (date) => {
        return date.isSame(today, 'day') || date.isAfter(today, 'day');
    };

    // Erweiterte Funktion zum Ändern des Datums mit Plausibilitätsprüfung
    const handleDateChangeWithValidation = (newDate) => {
        if (isDateValid(newDate)) {
            handleDateChange(newDate);
        }
    };

    // Funktion zum Ändern des Datums mit dem Vor- oder Zurück-Button
    const handleChangeDateWithButton = (amount) => {
        const newDate = selectedDate.add(amount, 'day');
        if (isDateValid(newDate) || amount > 0) {
            changeDate(amount);
        }
    };

    // Öffnen und Schließen des Statistik-Dialogs
    const openStatistics = () => setStatisticsOpen(true);
    const closeStatistics = () => setStatisticsOpen(false);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Paper
                elevation={0}
                sx={{
                    p: 2,
                    mb: 2,
                    borderRadius: '12px',
                    background: 'linear-gradient(to right, #f9f9f9, #ffffff)',
                    border: '1px solid #eeeeee'
                }}
            >
                {/* Hauptzeile mit Titel und Such/View-Controls */}
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={2}
                >
                    {/* Linke Seite mit Titel und Refresh */}
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography
                            variant="h5"
                            component="h1"
                            sx={{
                                color: '#E46450',
                                fontWeight: 600,
                                letterSpacing: '-0.5px'
                            }}
                        >
                            {title}
                        </Typography>
                        <Tooltip title="Aktualisieren">
                            <IconButton
                                onClick={handleRefresh}
                                sx={{
                                    backgroundColor: '#f0f0f0',
                                    '&:hover': { backgroundColor: '#e0e0e0' }
                                }}
                            >
                                <RefreshIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>

                    {/* Mitte - Suchfeld */}
                    <Box>
                        <TextField
                            placeholder="Schnellsuche"
                            variant="outlined"
                            size="small"
                            value={searchQuery}
                            onChange={handleSearch}
                            sx={{
                                width: '300px',
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '20px',
                                    backgroundColor: '#ffffff'
                                }
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="action" />
                                    </InputAdornment>
                                ),
                                endAdornment: searchQuery && (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            onClick={handleClearSearch}
                                            edge="end"
                                        >
                                            <ClearIcon fontSize="small" />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        {roundTripFilter && (
                            <>
                                <Chip
                                    icon={<SwapHorizIcon />}
                                    label={`Hin-Rück: ${roundTripFilter.first_name} ${roundTripFilter.last_name}`}
                                    color="primary"
                                    variant="outlined"
                                    sx={{ borderRadius: '16px' }}
                                />
                                <Tooltip title="Hin- und Rückfahrtfilter zurücksetzen">
                                    <IconButton
                                        onClick={handleClearRoundTripFilter}
                                        color="primary"
                                        size="small"
                                        sx={{
                                            backgroundColor: '#e3f2fd',
                                            '&:hover': { backgroundColor: '#bbdefb' }
                                        }}
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                    </Box>

                    {/* Rechte Seite: Statistik, Ansichtsumschalter und Einstellungen */}
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Tooltip title={viewMode === 'timeline' ? 'Zur Tabellenansicht wechseln' : 'Zur Zeitleiste wechseln'}>
                            <IconButton
                                onClick={toggleViewMode}
                                sx={{
                                    backgroundColor: '#f0f0f0',
                                    '&:hover': { backgroundColor: '#e0e0e0' }
                                }}
                            >
                                {viewMode === 'timeline' ? <ViewListIcon /> : <ViewDayIcon />}
                            </IconButton>
                        </Tooltip>

                        {/* Neuer Statistik-Button */}
                        <Tooltip title="Statistiken anzeigen">
                            <IconButton
                                onClick={openStatistics}
                                sx={{
                                    backgroundColor: '#e3f2fd',
                                    '&:hover': { backgroundColor: '#bbdefb' }
                                }}
                            >
                                <AssessmentIcon color="primary" />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Ungeplante Transporte anzeigen">
                            <IconButton
                                onClick={onOpenUnplannedTransports}
                                sx={{
                                    backgroundColor: '#ffebee', // Helles Rot
                                    '&:hover': { backgroundColor: '#ffcdd2' } // Etwas dunkleres Rot beim Hover
                                }}
                            >
                                <ErrorOutlineIcon />
                            </IconButton>
                        </Tooltip>

                        <SettingsMenu
                            isAutoRefreshEnabled={isAutoRefreshEnabled}
                            toggleAutoRefresh={toggleAutoRefresh}
                        />
                    </Stack>
                </Box>

                <Divider sx={{ my: 1.5 }} />

                {/* Zweite Zeile: Filter, Datum und Zeitskala */}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    {/* Linke Seite: Landkreis-Filter */}
                    <CountyFilter
                        selectedCounties={selectedCounties}
                        onChange={handleCountyFilterChange}
                    />

                    {/* Mitte: Datumsauswahl */}
                    <Box display="flex" alignItems="center" ml={8}>
                        <Tooltip title={isToday ? "Zurück-Navigation nicht möglich für heutiges Datum" : "Zum vorherigen Tag"}>
                            <span>
                                <IconButton
                                    onClick={() => handleChangeDateWithButton(-1)}
                                    disabled={isToday}
                                    sx={{
                                        backgroundColor: '#f0f0f0',
                                        '&:hover': { backgroundColor: '#e0e0e0' },
                                        '&.Mui-disabled': {
                                            backgroundColor: '#f5f5f5',
                                            opacity: 0.5
                                        }
                                    }}
                                >
                                    <ArrowBack />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <DatePicker
                            value={selectedDate}
                            onChange={handleDateChangeWithValidation}
                            format="DD.MM.YYYY"
                            disablePast={true}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    sx: { mx: 1, width: '160px' }
                                }
                            }}
                        />
                        <IconButton
                            onClick={() => handleChangeDateWithButton(1)}
                            sx={{
                                backgroundColor: '#f0f0f0',
                                '&:hover': { backgroundColor: '#e0e0e0' }
                            }}
                        >
                            <ArrowForward />
                        </IconButton>
                        {!isToday && (
                            <Tooltip title="Zum heutigen Tag springen">
                                <IconButton
                                    onClick={() => handleDateChangeWithValidation(dayjs())}
                                    sx={{
                                        ml: 1,
                                        backgroundColor: '#e0e0e0',
                                        '&:hover': { backgroundColor: '#d0d0d0' }
                                    }}
                                >
                                    <KeyboardReturnIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>

                    {/* Rechte Seite: Zeitskala-Buttons */}
                    <Stack direction="row" spacing={1}>
                        <Button
                            variant={timeScale === 4 ? 'contained' : 'outlined'}
                            size="small"
                            onClick={() => setTimeScale(4)}
                            sx={{
                                borderRadius: '20px',
                                minWidth: '40px',
                                backgroundColor: timeScale === 4 ? '#2196f3' : 'transparent'
                            }}
                        >
                            4h
                        </Button>
                        <Button
                            variant={timeScale === 12 ? 'contained' : 'outlined'}
                            size="small"
                            onClick={() => setTimeScale(12)}
                            sx={{
                                borderRadius: '20px',
                                minWidth: '40px',
                                backgroundColor: timeScale === 12 ? '#2196f3' : 'transparent'
                            }}
                        >
                            12h
                        </Button>
                        <Button
                            variant={timeScale === 24 ? 'contained' : 'outlined'}
                            size="small"
                            onClick={() => setTimeScale(24)}
                            sx={{
                                borderRadius: '20px',
                                minWidth: '40px',
                                backgroundColor: timeScale === 24 ? '#2196f3' : 'transparent'
                            }}
                        >
                            24h
                        </Button>
                    </Stack>
                </Box>
            </Paper>

            {/* Statistik-Dialog */}
            <Dialog
                open={statisticsOpen}
                onClose={closeStatistics}
                maxWidth="lg"
                fullWidth
                aria-labelledby="statistics-dialog-title"
            >
                <DialogTitle id="statistics-dialog-title" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" component="div" sx={{ color: '#E46450', fontWeight: 600 }}>
                        Statistiken
                    </Typography>
                    <IconButton edge="end" color="inherit" onClick={closeStatistics} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <StatistikDashboard selectedDate={selectedDate} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeStatistics} color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
        </LocalizationProvider>
    );
};

export default PlanningHeader;