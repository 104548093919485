import React, { useState, useEffect } from 'react';
import { Box, Stack, TextField, Typography, Button, Checkbox, IconButton, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment, Alert, Tooltip, FormControlLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';
//import ConfirmationDialog from './ConfirmationDialog'; // Reuse the confirmation dialog component
import { useUser } from '../components/userContext';
import PersistentDataGrid from '../components/PersistentDataGrid';
import { renewToken } from '../misc/helperFunctions';
import { GridActionsCellItem } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import GradeIcon from '@mui/icons-material/Grade';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const ConfirmationDialog = ({ open, onClose, onConfirm, title, description }) => {
    return (
        <Dialog open={open} onClose={() => onClose(false)}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(false)}>Abbrechen</Button>
                <Button onClick={() => onConfirm(true)} color="primary">Ja</Button>
            </DialogActions>
        </Dialog>
    );
};

const InstitutionsList = () => {
    const [institutions, setInstitutions] = useState([]);
    // Other state variables
    const [selectedInstitution, setSelectedInstitution] = useState(null);
    const { user } = useUser();
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(true); // for loading Icon
    const [dialogAction, setDialogAction] = useState(null);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogDescription, setDialogDescription] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [serverError, setServerError] = useState(null);
    const [statusFilter, setStatusFilter] = useState({
        ils: true,
        manuell: true,
    });


    useEffect(() => {
        if (user) {
            fetchInstitutions();
        }
        // eslint-disable-next-line
    }, [user]);

    useEffect(() => {
        fetchInstitutions(searchQuery);
        // eslint-disable-next-line
    }, [searchQuery]);


    const fetchInstitutions = async (searchQuery = '', start = 0, limit = 5000) => {
        try {
            setServerError(null);
            searchQuery = searchQuery || '';

            // Entscheidet, welcher Endpunkt und welche Parameter basierend auf dem Vorhandensein von searchQuery verwendet werden
            let url;
            if (searchQuery.trim()) {
                // Wenn searchQuery vorhanden ist, benutzen Sie die Suche
                const params = new URLSearchParams({
                    searchTerm: searchQuery,
                    start,
                    limit
                }).toString();
                url = `${process.env.REACT_APP_API_URI}/api/lazyinstitutions?${params}`;
            } else {
                // Wenn kein searchQuery vorhanden ist, laden Sie alle Institutionen
                url = `${process.env.REACT_APP_API_URI}/api/institutions`;
            }

            const response = await fetch(url, {
                headers: {
                    'Authorization': user.api_key,
                },
            });

            if (!response.ok) {
                throw new Error('Fehler beim Abrufen der Institutionen');
            }

            const data = await response.json();
            setInstitutions(data);
            // Erneuern Sie das Token nur, wenn keine Suchanfrage vorliegt
            if (!searchQuery.trim()) {
                await renewToken();
            }
        } catch (error) {
            console.error(error);
            setServerError("Fehler beim Laden der Institutionen: " + error.message);
        } finally {
            setIsLoading(false);
        }
    };



    const handleConfirmation = async (confirm) => {
        setDialogOpen(false); // Close the dialog

        if (confirm) {
            try {
                switch (dialogAction) {
                    case 'delete':
                        await deleteInstitution(selectedInstitution.institution_id);
                        break;
                    default:
                        break;
                }

                setServerError(null);
                // Refresh the user list after successful update
                await fetchInstitutions();
            } catch (error) {
                console.error('Fehler beim Aktualisieren des Benutzers:', error);
                setServerError(error.message);
            }
        }

        // Reset the selected user and action after the action is complete
        setSelectedInstitution(null);
        setDialogAction(null);
    };

    const deleteInstitution = async (id) => {
        const response = await fetch(`${process.env.REACT_APP_API_URI}/api/institutions/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': user.api_key,
            },
        });

        if (!response.ok) {
            const errorData = await response.json(); // Nehmen Sie an, dass der Server ein JSON-Objekt mit Fehlerdetails zurückgibt
            throw new Error(errorData.message || 'Fehler beim Löschen der Institution. Die Institution wird vermutlich noch in anderen Tabellen referenziert.');
        }
    };


    // Define functions for handling actions like edit, delete, lock, etc.
    const handleEdit = (id) => {
        // Find the institution by ID
        const selectedInstitution = findInstitutionById(id);

        // Navigate to the edit institution page
        navigate(`/institutionpage/${selectedInstitution.institution_id}`);
    };

    const handleDelete = (id) => {
        // Find the institution by ID
        const selectedInstitution = findInstitutionById(id);
        setSelectedInstitution(selectedInstitution);

        // Open the delete confirmation dialog
        setDialogAction('delete');
        setDialogTitle('Löschen');
        setDialogDescription(`Möchten Sie die Institution "${selectedInstitution.object_institution}" löschen?`);
        setDialogOpen(true);
    };

    const findInstitutionById = (id) => {
        const institutionId = id;
        const selectedInstitution = institutionsWithId.find(inst => inst.institution_id === institutionId);
        return selectedInstitution || null;
    };

    const columns = [
        // { field: 'institution_id', headerName: 'ID', width: 80 },
        {
            field: 'ILS',
            headerName: 'ILS',
            width: 50,
            renderCell: (params) => {
                const showIcon = params.row.standort_guid !== null;
                if (showIcon) {
                    return (
                        <Tooltip title="Datensatz wurde von der ILS importiert und ist nicht veränderbar.">
                            <GradeIcon />
                        </Tooltip>
                    );
                } else {
                    return null; // Statt leerem String, geben Sie null zurück
                }
            },
        },
        { field: 'object_institution', headerName: 'Institution', width: 400 },
        { field: 'station', headerName: 'Station', width: 150 },
        { field: 'street_and_number', headerName: 'Straße und Nummer', width: 200 },
        { field: 'postal_code', headerName: 'PLZ', width: 100 },
        { field: 'city', headerName: 'Stadt', width: 150 },
        { field: 'phone_number', headerName: 'Telefonnummer', width: 150 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Aktionen',
            width: 150,
            getActions: (params) => {
                // Prüfen, ob standort_guid null ist
                if (params.row.standort_guid === null) {
                    return ([
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label="Bearbeiten"
                            onClick={() => handleEdit(params.id)}
                        />,
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Löschen"
                            onClick={() => handleDelete(params.id)}
                            showInMenu
                        />,
                    ]);
                } else {
                    return ([
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label="Bearbeiten"
                            onClick={() => handleEdit(params.id)}
                            disabled={true}  // Deaktiviere die Bearbeiten-Aktion
                        />,
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Löschen"
                            onClick={() => handleDelete(params.id)}
                            disabled={true}  // Deaktiviere die Löschen-Aktion
                            showInMenu
                        />,
                    ]);
                }
            },
        },
    ];



    // Modify the data to include the `id` property
    const institutionsWithId = institutions.map((institution) => ({
        id: institution.institution_id, // Use the `institution_id` as the `id` property
        ...institution,
    }));

    // Update the filteredInstitutions calculation
    const filteredInstitutions = institutionsWithId
        // .filter((institution) =>
        //     (institution.object_institution && institution.object_institution.toLowerCase().includes(searchQuery)) ||
        //     (institution.street_and_number && institution.street_and_number.toLowerCase().includes(searchQuery)) ||
        //     (institution.postal_code && institution.postal_code.toLowerCase().includes(searchQuery)) ||
        //     (institution.city && institution.city.toLowerCase().includes(searchQuery)) ||
        //     (institution.station && institution.station.toLowerCase().includes(searchQuery))
        // )
        .filter((institution) =>
            // Filterbedingungen für den Status-Filter hier einfügen
            (statusFilter.ils && institution.standort_guid) ||
            (statusFilter.manuell & !institution.standort_guid)
        );;

    const handleStatusFilterChange = (status) => {
        setStatusFilter((prevStatusFilter) => ({
            ...prevStatusFilter,
            [status]: !prevStatusFilter[status],
        }));
    };

    const handleCreateNewInstitution = () => {
        navigate('/institutionpage');
    };

    const handleSearch = (event) => {
        const newSearchQuery = event.target.value.toLowerCase();
        setSearchQuery(newSearchQuery);
    };

    const handleReload = (event) => {
        setSearchQuery(searchQuery);
    };

    const handleClearSearch = (event) => {
        const newSearchQuery = '';
        setSearchQuery(newSearchQuery);
    };
    return (
        <Box m={1}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Stack justifyItems="flex-start" alignItems="center" direction="row">
                    <Typography variant="h5" color="#E46450" component="h1" gutterBottom mt={1}>
                        Institutionen
                    </Typography>

                    {isLoading && (
                        <CircularProgress color="inherit" />
                    )}
                    {(!isLoading) && (
                        <IconButton ml={2} size="large" aria-label="refresh" onClick={handleReload}>
                            <RefreshIcon fontSize="large" />
                        </IconButton>
                    )}
                </Stack>
                <TextField
                    id="search"
                    label="Schnellsuche"
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={handleSearch}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {searchQuery && (
                                    <IconButton
                                        aria-label="Clear search"
                                        size="small"
                                        onClick={handleClearSearch}
                                        edge="end"
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                )}
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        width: '300px',
                    }}
                />
                <Button variant="outlined" color="primary" onClick={handleCreateNewInstitution} startIcon={<AddIcon />}>
                    Neue Institution hinzufügen
                </Button>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1} sx={{
                border: '1px solid #ccc', // Leicht grauer Rahmen
                borderRadius: '4px', // Runde Ecken (optional)
                padding: '8px', // Innenabstand
            }}>
                <Stack justifyItems="flex-start" alignItems="center" direction="row">
                    <FilterAltIcon sx={{ marginRight: 1 }} /> {/* Filter-Symbol */}
                    <Typography variant="body1" sx={{ marginRight: 11 }}>
                        Filter
                    </Typography>

                    {/* Status Filter */}
                    <Box display="flex" alignItems="center">
                        <Typography variant="body2" sx={{ marginRight: 1 }}>
                            Herkunft:
                        </Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={statusFilter.ils}
                                    onChange={() => handleStatusFilterChange('ils')}
                                    color="primary"
                                />
                            }
                            label={
                                <Typography variant="body2">von ILS importiert</Typography>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={statusFilter.manuell}
                                    onChange={() => handleStatusFilterChange('manuell')}
                                    color="primary"
                                />
                            }
                            label={
                                <Typography variant="body2">manuell angelegt</Typography>
                            }
                        />
                    </Box>
                </Stack>
            </Box>
            {serverError && <Alert severity="error" sx={{ my: 2 }}>{serverError}</Alert>}

            <div style={{ width: '100%' }}>
                <PersistentDataGrid
                    storageKey="institutionsList"
                    rows={filteredInstitutions}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5, 10, 20]}
                    autoHeight
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'object_institution', sort: 'asc' }],
                        },
                    }}
                />
                {/* Pass handleDeleteConfirmation as the onConfirm prop */}
                {selectedInstitution && (
                    <ConfirmationDialog
                        open={dialogOpen}
                        onClose={handleConfirmation}
                        onConfirm={handleConfirmation}
                        title={dialogTitle}
                        description={dialogDescription}
                    />
                )}
            </div>
        </Box>
    );


};

export default InstitutionsList;
