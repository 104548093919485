import React, { useState, useRef, useEffect, forwardRef } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import PropTypes from 'prop-types';
import { Paper, Box, Typography } from '@mui/material';
import { List } from 'react-virtualized';
import debounce from 'lodash/debounce';
import { BASE_HOUR_WIDTH, ROW_HEIGHT, TIME_LABELS_HEIGHT, formatHourLabel } from './timelineUtils';
import VehicleColumn from './VehicleColumn';
import NonWorkingBackground from './NonWorkingBackground';
import TransportBars from './TransportBars';
import TransportPopover from './TransportPopover';
import TimelineHeader from './TimelineHeader';

// Memoized NonWorkingBackground
const MemoizedNonWorkingBackground = ({ vehicle, selectedDate, hourWidth }) => (
    <NonWorkingBackground
        vehicle={vehicle}
        selectedDate={selectedDate}
        hourWidth={hourWidth}
    />
);

// Memoized TransportBars
const MemoizedTransportBars = React.memo(
    ({ transports, vehicleId, selectedDate, hourWidth, onTransportClick, onHover, onLeave, onDetailsTransport, onEditTransport,
        onDeleteTransport, onDuplicateTransport, onAddBreakAfter, onTransportDrop, onShowRoundTrip, blinkingTransports = [] }) => (
        <TransportBars
            transports={transports}
            vehicleId={vehicleId}
            selectedDate={selectedDate}
            hourWidth={hourWidth}
            onTransportClick={onTransportClick}
            onHover={onHover}
            onLeave={onLeave}
            onDetailsTransport={onDetailsTransport}
            onEditTransport={onEditTransport}
            onDeleteTransport={onDeleteTransport}
            onDuplicateTransport={onDuplicateTransport}
            onAddBreakAfter={onAddBreakAfter}
            onTransportDrop={onTransportDrop}
            onShowRoundTrip={onShowRoundTrip}
            blinkingTransports={blinkingTransports}
        />
    ),
    (prevProps, nextProps) =>
        prevProps.vehicleId === nextProps.vehicleId &&
        prevProps.selectedDate === nextProps.selectedDate &&
        prevProps.hourWidth === nextProps.hourWidth &&
        prevProps.transports === nextProps.transports &&
        prevProps.onTransportClick === nextProps.onTransportClick &&
        prevProps.onHover === nextProps.onHover &&
        prevProps.onLeave === nextProps.onLeave &&
        prevProps.blinkingTransports === nextProps.blinkingTransports
);

// Convert to forwardRef to allow parent to access scrollable container
const PlanningTimeline = forwardRef(({
    vehicles,
    transports,
    timeScale,
    selectedDate,
    onTimelineClick,
    onTransportClick,
    detailsPanelOpen,
    countyColors,
    onCreateBreak,
    onDeleteBreak,
    onTransportDrop,
    onShowRoundTrip,
    blinkingTransports = [],
    onSuccess,
}, ref) => {
    const innerContainerRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const minTimelineWidth = 24 * ((24 / timeScale) * BASE_HOUR_WIDTH);
    const VEHICLE_COLUMN_WIDTH = 340;

    useEffect(() => {
        if (!innerContainerRef.current) return;

        const updateWidth = () => {
            if (detailsPanelOpen) {
                // Drawer ist offen: Debounced ResizeObserver verwenden
                const handleResize = debounce((entries) => {
                    if (!entries || entries.length === 0) return;
                    const { width } = entries[0].contentRect;
                    setContainerWidth(width);
                }, 100);

                const resizeObserver = new ResizeObserver(handleResize);
                resizeObserver.observe(innerContainerRef.current);

                // Initiale Breite setzen
                setContainerWidth(innerContainerRef.current.getBoundingClientRect().width);

                return () => {
                    resizeObserver.disconnect();
                    handleResize.cancel();
                };
            } else {
                // Drawer ist geschlossen: Sofort volle Breite setzen
                const fullWidth = window.innerWidth - VEHICLE_COLUMN_WIDTH; // Gesamtbreite minus VehicleColumn
                setContainerWidth(fullWidth);
                return () => { }; // Kein Cleanup nötig
            }
        };

        const cleanup = updateWidth();
        return cleanup;
    }, [detailsPanelOpen]); // Abhängigkeit von detailsPanelOpen

    const totalTimelineWidth = Math.max(containerWidth, minTimelineWidth);
    const hourWidth = timeScale === 24 ? totalTimelineWidth / 24 : (24 / timeScale) * BASE_HOUR_WIDTH;
    const totalTimelineHeight = TIME_LABELS_HEIGHT + vehicles.length * ROW_HEIGHT + TIME_LABELS_HEIGHT;

    const [popoverAnchor, setPopoverAnchor] = useState(null);
    const [popoverContent, setPopoverContent] = useState(null);

    const handleTransportHover = (event, transport) => {
        //setPopoverAnchor(event.currentTarget);
        //setPopoverContent(transport);
    };


    const handlePopoverClose = () => {
        setPopoverAnchor(null);
        setPopoverContent(null);
    };

    const handleDetailsTransport = (event, transport) => {
        console.log('Details Transport:', event, transport);
        // Now we set the anchor and content here to open the popover
        setPopoverAnchor(event.currentTarget);
        setPopoverContent(transport);
    }

    const handleEditTransport = (event, transport) => {
        console.log('Edit Transport:', event, transport);
        if (onTransportClick) {
            onTransportClick(event, transport);
        }
        // Hier kommt dann deine Logik für das Bearbeiten des Transports
    }

    const handleDeleteTransport = (event, transport) => {
        if (onDeleteBreak) {
            onDeleteBreak(event, transport);
        }
    }
    const handleDuplicateTransport = (event, transport) => {
        console.log('Duplicate Transport:', transport);
        // Hier können Sie die Logik für das Duplizieren des Transports hinzufügen
    }
    const handleAddBreakAfter = (event, transport) => {
        if (onCreateBreak) {
            onCreateBreak(event, transport);
        }
    };
    // Handle für onTransportDrop(item.transport, newStartTime, newEndTime);
    const handleTransportDrop = (transport, newStartTime, newEndTime) => {
        //console.log('Transport dropped:', transport, newStartTime, newEndTime);
        if (onTransportDrop) {
            onTransportDrop(transport, newStartTime, newEndTime);
        }
    }

    // Handle für das Anzeigen von Hin- und Rückfahrt
    const handleShowRoundTrip = (event, transport) => {
        console.log('Show Round Trip (handleShowRoundTrip):', transport);
        if (onShowRoundTrip) {
            onShowRoundTrip(event, transport);
        }
    };

    const renderTextTimeAxis = () => {
        const labels = [];
        for (let hour = 0; hour <= 24; hour += 2) {
            const xPos = hour * hourWidth;
            labels.push(
                <Typography
                    key={`axis-${hour}`}
                    variant="caption"
                    sx={{
                        position: 'absolute',
                        left: hour === 0 ? xPos + 8 : hour === 24 ? xPos - 48 : xPos - 20,
                        width: 30,
                        textAlign: 'center',
                        userSelect: 'none',
                        mt: 1,
                        fontSize: '1rem',
                        fontWeight: 'bold',
                    }}
                >
                    {formatHourLabel(hour)}
                </Typography>
            );
        }
        return labels;
    };

    const renderGridLines = () => {
        const gridLines = [];
        for (let hour = 0; hour <= 24; hour += 2) {
            const xPos = hour * hourWidth;
            gridLines.push(
                <Box
                    key={`grid-${hour}`}
                    sx={{
                        position: 'absolute',
                        left: xPos,
                        top: 0,
                        height: '100%',
                        width: '1px',
                        backgroundColor: hour % 2 === 0 ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.05)',
                        zIndex: 0,
                    }}
                />
            );
        }
        return gridLines;
    };

    // Modified handleClick to show context menu on right-click
    const handleClick = (e, vehicleId, selectedDate) => {
        // For left-click, we now use a double-click approach instead of immediate dialog
        if (e.detail === 2) {
            onTimelineClick(e, vehicleId, selectedDate, hourWidth);
        }
    };

    const renderRow = ({ index, key, style }) => {
        const vehicle = vehicles[index];
        const topY = TIME_LABELS_HEIGHT + index * ROW_HEIGHT;
        return (
            <Box
                key={key}
                style={style}
                sx={{
                    position: 'absolute',
                    top: topY,
                    left: 0,
                    width: '100%',
                    height: ROW_HEIGHT,
                    borderBottom: '1px solid #ddd',
                }}
                onClick={(e) => handleClick(e, vehicle.vehicle_id, selectedDate)}
            >
                <MemoizedNonWorkingBackground
                    key={`${vehicle.vehicle_id}-${selectedDate.format('YYYY-MM-DD')}`}
                    vehicle={vehicle}
                    selectedDate={selectedDate}
                    hourWidth={hourWidth}
                />
                <MemoizedTransportBars
                    transports={transports}
                    vehicleId={vehicle.vehicle_id}
                    selectedDate={selectedDate}
                    hourWidth={hourWidth}
                    onTransportClick={onTransportClick}
                    onHover={handleTransportHover}
                    onLeave={handlePopoverClose}
                    onDetailsTransport={handleDetailsTransport}
                    onEditTransport={handleEditTransport}
                    onDeleteTransport={handleDeleteTransport}
                    onDuplicateTransport={handleDuplicateTransport}
                    onAddBreakAfter={handleAddBreakAfter}
                    onTransportDrop={handleTransportDrop}
                    onShowRoundTrip={handleShowRoundTrip}
                    blinkingTransports={blinkingTransports}
                />
            </Box>
        );
    };

    return (
        <Paper elevation={2} sx={{ height: '100%', display: 'flex', flexDirection: 'row' }}>
            <DndProvider backend={HTML5Backend}>
                <Box
                    ref={ref} // Use the forwarded ref here from parent component
                    sx={{ display: 'flex', overflowY: 'auto', overflowX: 'auto', height: '100%' }}
                >
                    <VehicleColumn
                        vehicles={vehicles}
                        countyColors={countyColors}
                        onSuccess={() => {
                            onSuccess();
                        }} />
                    <Box
                        ref={innerContainerRef} // Keep the inner ref for width calculations
                        sx={{ position: 'relative', width: '100%' }}
                    >
                        <Box
                            sx={{
                                position: 'relative',
                                width: totalTimelineWidth,
                                height: totalTimelineHeight,
                            }}
                        >
                            {renderGridLines()}
                            <TimelineHeader
                                totalWidth={totalTimelineWidth}
                                hourWidth={hourWidth}
                            />
                            <List
                                width={totalTimelineWidth}
                                height={vehicles.length * ROW_HEIGHT}
                                rowCount={vehicles.length}
                                rowHeight={ROW_HEIGHT}
                                rowRenderer={renderRow}
                                style={{ position: 'absolute', top: TIME_LABELS_HEIGHT, left: 0 }}
                            />
                            <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    width: totalTimelineWidth,
                                    height: TIME_LABELS_HEIGHT,
                                    borderTop: '1px solid #ddd',
                                    zIndex: 1,
                                }}
                            >
                                {renderTextTimeAxis()}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </DndProvider>
            <TransportPopover anchorEl={popoverAnchor} content={popoverContent} onClose={handlePopoverClose} />
        </Paper>
    );
});

PlanningTimeline.propTypes = {
    vehicles: PropTypes.array.isRequired,
    transports: PropTypes.array.isRequired,
    timeScale: PropTypes.oneOf([4, 12, 24]).isRequired,
    selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    onTimelineClick: PropTypes.func.isRequired,
    onTransportClick: PropTypes.func.isRequired,
    detailsPanelOpen: PropTypes.bool.isRequired,
    countyColors: PropTypes.object,
    onCreateBreak: PropTypes.func,
    onDeleteBreak: PropTypes.func,
    onTransportDrop: PropTypes.func,
    onShowRoundTrip: PropTypes.func,
    blinkingTransports: PropTypes.array,
};

export default PlanningTimeline;