import React, { useEffect, useState } from 'react';
import { useUser } from '../../components/userContext';
import {
    Typography,
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    CircularProgress,
} from '@mui/material';
import {
    Error as ErrorIcon,
    Info as InfoIcon,
    Warning as WarningIcon,
} from '@mui/icons-material';
import dayjs from 'dayjs';

/**
 * Wiederverwendbare Komponente für die Anzeige von Transport-Logeinträgen
 * @param {Object} props - Component properties
 * @param {string} props.transport_id - ID des Transports, dessen Logs angezeigt werden sollen
 * @param {string} props.title - Optionaler Titel für die Komponente
 */
function TransportLogs({ transport_id, title = "Transport Logs" }) {
    const { user } = useUser();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        if (transport_id) {
            fetchLogs();
        }
        // eslint-disable-next-line
    }, [transport_id]);

    async function fetchLogs() {
        setLoading(true);
        setError(null);
        try {
            const logsResponse = await fetch(`${process.env.REACT_APP_API_URI}/api/booking-logs/${transport_id}`, {
                headers: { 'Authorization': user.api_key },
            });
            if (!logsResponse.ok) {
                throw new Error(`Fehler beim Laden der Logs: ${logsResponse.status}`);
            }
            const logsData = await logsResponse.json();
            setLogs(logsData);
        } catch (err) {
            console.error(err);
            setError(err.message || 'Fehler beim Laden der Log-Daten');
        } finally {
            setLoading(false);
        }
    }

    // Funktion zum Rendern des passenden Icons basierend auf dem Log-Level
    const renderLogLevelIcon = (logLevel) => {
        switch (logLevel) {
            case 'error':
                return <ErrorIcon color="error" />;
            case 'info':
                return <InfoIcon color="info" />;
            case 'warning':
                return <WarningIcon color="warning" />;
            default:
                return <InfoIcon />;
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ textAlign: 'center', my: 4, color: 'error.main' }}>
                <Typography variant="h6">Fehler</Typography>
                <Typography variant="body1">{error}</Typography>
            </Box>
        );
    }

    return (
        <>
            <Typography variant="h5" gutterBottom>
                {title} #{transport_id}
            </Typography>
            {logs.length > 0 ? (
                <List>
                    {logs.map((log, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>
                                {renderLogLevelIcon(log.log_level)}
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <>
                                        {log.log_message}
                                        {log.log_message.startsWith('Fehler bei Planung: Keine MAIN-Route für Transport') && (
                                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                                                Prüfen Sie die Start- oder Zieladresse!
                                            </Typography>
                                        )}
                                    </>
                                }
                                secondary={dayjs(log.created_at).format('DD.MM.YYYY HH:mm')}
                            />
                        </ListItem>
                    ))}
                </List>
            ) : (
                <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', my: 2 }}>
                    Keine Logeinträge verfügbar.
                </Typography>
            )}
        </>
    );
}

export default TransportLogs;