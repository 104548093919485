import React from 'react';
import PropTypes from 'prop-types';
import { Popover, Box, Typography, Divider } from '@mui/material';
import { FreeBreakfast as BreakIcon } from '@mui/icons-material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

// Configure dayjs with timezone plugin
dayjs.extend(utc);
dayjs.extend(timezone);

// Helper function to format time to Berlin timezone
const formatBerlinTime = (dateTime) => {
    if (!dateTime) return '-';
    return dayjs(dateTime).tz('Europe/Berlin').format('HH:mm');
};

// Helper function to calculate duration in minutes
const calculateDuration = (startTime, endTime) => {
    if (!startTime || !endTime) return 0;
    const start = dayjs(startTime);
    const end = dayjs(endTime);
    return end.diff(start, 'minute');
};

function BreakPopover({ anchorEl, content, onClose }) {
    if (!content) return null;

    // Get start and end times from the break
    const startTime = content.planned_start || content.actual_start;
    const endTime = content.planned_end || content.actual_end;

    // Calculate duration
    const durationMinutes = calculateDuration(startTime, endTime);

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            disableRestoreFocus
            sx={{ pointerEvents: 'none' }}
        >
            <Box sx={{ p: 2, maxWidth: 300, bgcolor: '#333', color: 'white', borderRadius: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <BreakIcon sx={{ mr: 1, color: '#d32f2f' }} />
                    <Typography variant="subtitle1" fontWeight="bold">
                        Pause
                    </Typography>
                </Box>

                <Divider sx={{ borderColor: 'rgba(255,255,255,0.1)', my: 1 }} />

                <Typography variant="body2" sx={{ display: 'flex' }}>
                    <Box component="span" sx={{ minWidth: 120 }}>Beginn:</Box>
                    <Box component="span" sx={{ flex: 1 }}>{formatBerlinTime(startTime)}</Box>
                </Typography>

                <Typography variant="body2" sx={{ display: 'flex' }}>
                    <Box component="span" sx={{ minWidth: 120 }}>Ende:</Box>
                    <Box component="span" sx={{ flex: 1 }}>{formatBerlinTime(endTime)}</Box>
                </Typography>

                <Typography variant="body2" sx={{ display: 'flex' }}>
                    <Box component="span" sx={{ minWidth: 120 }}>Dauer:</Box>
                    <Box component="span" sx={{ flex: 1 }}>{durationMinutes} Minuten</Box>
                </Typography>

                {content.description && (
                    <>
                        <Divider sx={{ borderColor: 'rgba(255,255,255,0.1)', my: 1 }} />
                        <Typography variant="body2">
                            {content.description}
                        </Typography>
                    </>
                )}
            </Box>
        </Popover>
    );
}

BreakPopover.propTypes = {
    anchorEl: PropTypes.object,
    content: PropTypes.object,
    onClose: PropTypes.func.isRequired,
};

export default BreakPopover;