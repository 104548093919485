import React, { useState, useEffect } from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    OutlinedInput,
    CircularProgress,
    Box,
    IconButton,
    Tooltip
} from '@mui/material';
import { FilterAltOff as FilterAltOffIcon } from '@mui/icons-material';
import { useUser } from '../../components/userContext';

// Landkreis-Filter-Komponente mit dynamischer Ladung der Landkreise und Reset-Button
const CountyFilter = ({ selectedCounties, onChange }) => {
    const { user } = useUser();
    const [counties, setCounties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);

    // Bestimme, ob der Reset-Button angezeigt werden soll
    // Er wird angezeigt, wenn nicht "Alle" ausgewählt ist
    const showResetButton = !selectedCounties.includes('Alle');

    // Lade die verfügbaren Landkreise aus der API
    useEffect(() => {
        async function fetchCounties() {
            try {
                setLoading(true);
                const response = await fetch(
                    `${process.env.REACT_APP_API_URI}/api/stations/counties`,
                    { headers: { Authorization: user.api_key } }
                );

                if (!response.ok) {
                    throw new Error('Fehler beim Laden der Landkreise');
                }

                const data = await response.json();
                setCounties(['Alle', ...data]);
            } catch (error) {
                console.error('Fehler beim Laden der Landkreise:', error);
                setCounties(['Alle']); // Fallback, mindestens "Alle" anzeigen
            } finally {
                setLoading(false);
            }
        }

        if (user?.api_key) {
            fetchCounties();
        }
    }, [user]);

    const handleCountyChange = (event) => {
        const value = event.target.value;

        if (value.includes('Alle')) {
            // Wenn "Alle" ausgewählt oder abgewählt wird
            if (selectedCounties.includes('Alle')) {
                // Wenn "Alle" bereits ausgewählt war, deselektiere alles
                onChange([]);
            } else {
                // Wenn "Alle" neu ausgewählt wurde, wähle alle aus
                onChange(['Alle', ...counties.filter(county => county !== 'Alle')]);
            }
        } else {
            const newSelectedCounties = value.filter(county => county !== 'Alle');

            // Prüfe, ob alle spezifischen Landkreise ausgewählt sind
            if (newSelectedCounties.length === counties.length - 1) { // -1 für "Alle"
                onChange(['Alle', ...newSelectedCounties]);
            } else {
                onChange(newSelectedCounties);
            }
        }
    };

    // Funktion zum Zurücksetzen des Filters auf "Alle"
    const handleResetFilter = () => {
        onChange(['Alle']);
    };

    // Prüft, ob ein Landkreis ausgewählt ist
    const isCountySelected = (county) => {
        if (county === 'Alle') {
            return selectedCounties.includes('Alle');
        }
        return selectedCounties.includes(county);
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormControl sx={{ minWidth: 180 }} size="small">
                <InputLabel id="county-filter-label">Landkreis</InputLabel>
                <Select
                    labelId="county-filter-label"
                    id="county-filter"
                    multiple
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    value={selectedCounties}
                    onChange={handleCountyChange}
                    input={
                        <OutlinedInput
                            label="Landkreis"
                            sx={{
                                backgroundColor: showResetButton ? '#ffebee' : 'inherit',
                            }}
                        />
                    }
                    renderValue={(selected) => {
                        if (selected.includes('Alle')) {
                            return 'Alle Landkreise';
                        }
                        return selected.join(', ');
                    }}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 224,
                                width: 250,
                            },
                        },
                    }}
                    disabled={loading}
                    startAdornment={loading && (
                        <CircularProgress size={20} sx={{ mr: 1 }} />
                    )}
                >
                    {counties.map((county) => (
                        <MenuItem key={county} value={county}>
                            <Checkbox checked={isCountySelected(county)} />
                            <ListItemText primary={county === 'Alle' ? 'Alle Landkreise' : county} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* Reset-Button, der nur angezeigt wird, wenn Filter aktiv sind */}
            {showResetButton && (
                <Tooltip title="Filter zurücksetzen">
                    <IconButton
                        onClick={handleResetFilter}
                        size="small"
                        sx={{ ml: 1 }}
                        aria-label="Filter zurücksetzen"
                    >
                        <FilterAltOffIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Box>
    );
};

export default CountyFilter;