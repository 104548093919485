// FlottenverwaltungList.js
import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    TextField,
    IconButton,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Menu,
    MenuItem,
    CircularProgress,
    Alert,
    Tooltip,
    Stack
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Icon für "aktiv"
import EventBusyIcon from '@mui/icons-material/EventBusy';
import RefreshIcon from '@mui/icons-material/Refresh';
import WarningIcon from '@mui/icons-material/Warning';
import HolidayIcon from '@mui/icons-material/Celebration';
import EventIcon from '@mui/icons-material/Event';
//import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate } from 'react-router-dom';
import PersistentDataGrid from '../../components/PersistentDataGrid';
import { useUser } from '../../components/userContext';
import { useSnackbar } from 'notistack';
import VehicleUnavailabilityDialog from '../dialogs/VehicleUnavailabilityDialog';
import AssignStationDialog from '../dialogs/AssignStationDialog';
import { getVehicleStatusInfo } from './vehicleStatusUtils';

// Hilfsfunktion: Wochentagsbezeichnungen
const WEEKDAY_LABELS = {
    0: 'So',
    1: 'Mo',
    2: 'Di',
    3: 'Mi',
    4: 'Do',
    5: 'Fr',
    6: 'Sa'
};

// Hilfsfunktion: Zeit formatieren (HH:MM)
function formatTime(timeStr) {
    if (!timeStr) return '';
    // Falls timeStr im Format "HH:MM:SS" kommt, nehmen wir HH:MM
    const parts = timeStr.split(':');
    return `${parts[0]}:${parts[1]}`;
}

// Hilfsfunktion: Datum/Zeit formatieren, z. B. "Mo, 13.02. 07:00"
function formatDateTime(dtStr) {
    const dt = new Date(dtStr);
    if (isNaN(dt)) return '';
    const weekday = WEEKDAY_LABELS[dt.getDay()] || '';
    const day = dt.getDate().toString().padStart(2, '0');
    const month = (dt.getMonth() + 1).toString().padStart(2, '0');
    const hours = dt.getHours().toString().padStart(2, '0');
    const minutes = dt.getMinutes().toString().padStart(2, '0');
    return `${weekday}, ${day}.${month}. ${hours}:${minutes}`;
}

// Hilfsfunktion: Schichtdetails aus allen ShiftAssignments eines Fahrzeugs mergen
// Für jeden Wochentag: wir ermitteln den minimalen Start und maximalen Endzeitpunkt
// TODO: vehicleAvailabilityService.js -> mergeShiftDetails verwenden
function mergeShiftDetails(shiftAssignments = [], shiftTemplatesMap = {}) {
    // Trennen von regulären und Feiertagsschichtplänen
    const regularShifts = {};
    const holidayShifts = {};

    shiftAssignments.forEach(assignment => {
        const template = shiftTemplatesMap[assignment.template_id];
        if (!template) return;

        // Prüfen, ob es ein Feiertagsschichtplan ist (neues Flag)
        const isHolidayTemplate = template.holiday || false;
        const targetStore = isHolidayTemplate ? holidayShifts : regularShifts;

        if (Array.isArray(template.details)) {
            template.details.forEach(detail => {
                const day = detail.day_of_week;
                const start = detail.start_time;
                const end = detail.end_time;

                if (!targetStore[day]) {
                    targetStore[day] = {
                        start,
                        end,
                        breakDuration: detail.break_duration,
                        recurrence: detail.recurrence_pattern
                    };
                } else {
                    // Vergleich: min(start), max(end)
                    if (start < targetStore[day].start) {
                        targetStore[day].start = start;
                    }
                    if (end > targetStore[day].end) {
                        targetStore[day].end = end;
                    }

                    // Für breakDuration und recurrence - falls unterschiedliche Werte, einfach zusammenfassen
                    targetStore[day].breakDuration = targetStore[day].breakDuration || detail.break_duration;
                    targetStore[day].recurrence = targetStore[day].recurrence
                        ? targetStore[day].recurrence + ', ' + detail.recurrence_pattern
                        : detail.recurrence_pattern;
                }
            });
        }
    });

    // Ergebnis-Strings vorbereiten
    let result = '';

    // 1. Reguläre Schichtpläne formatieren
    if (Object.keys(regularShifts).length > 0) {
        result += '[[WEEKDAY_HEADER]]';
        result += formatShiftGroup(regularShifts);
    }

    // 2. Feiertagsschichtpläne formatieren
    if (Object.keys(holidayShifts).length > 0) {
        if (result) result += '\n\n';
        // Der Text "Feiertage" wird im renderInformationenCell mit dem Icon ergänzt
        result += 'Feiertage:';
        result += formatShiftGroup(holidayShifts);
    }

    return result;
}

// Hilfsfunktion zum Formatieren einer Schichtgruppe
function formatShiftGroup(shifts) {
    // Formatieren: Wir sortieren nach Wochentag (1 bis 7 – wobei Sonntag 0 ist)
    const days = Object.keys(shifts)
        .map(day => parseInt(day, 10))
        .sort((a, b) => a - b);

    let lines = days.map(day => {
        const info = shifts[day];
        return `${WEEKDAY_LABELS[day]} (${formatTime(info.start)} - ${formatTime(info.end)})`;
    });

    // Zusätzliche Info (falls vorhanden): Break, Recurrence
    let extra = [];

    // Falls mindestens ein Break vorhanden:
    const breaks = Object.values(shifts)
        .map(info => info.breakDuration)
        .filter(b => b);

    if (breaks.length > 0) {
        const breakStr = String(breaks[0]); // explizite Umwandlung in einen String
        const formattedBreak = breakStr.split(':').slice(0, 2).join(':');
        extra.push(`täglich ${formattedBreak} Pause`);
    }

    const recurrences = Object.values(shifts)
        .map(info => info.recurrence)
        .filter(r => r);

    if (recurrences.length > 0) {
        const uniqueRecurrences = new Set();
        recurrences.forEach(r => {
            r.split(',').forEach(val => uniqueRecurrences.add(val.trim()));
        });
        extra.push(`Rhythmus: ${[...uniqueRecurrences].join(', ')}`);
    }

    return lines.join(' - ') + (extra.length > 0 ? '\n' + extra.join('\n') : '');
}

// Bestätigungsdialog (wird auch für Aktionen verwendet)
// eslint-disable-next-line 
const ConfirmationDialog = ({ open, onClose, title, description }) => (
    <Dialog open={open} onClose={() => onClose(false)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <DialogContentText>{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => onClose(false)}>Abbrechen</Button>
            <Button onClick={() => onClose(true)} color="primary">Ja</Button>
        </DialogActions>
    </Dialog>
);

const Flottenverwaltung = () => {
    const [vehicles, setVehicles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [serverError, setServerError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [shiftTemplatesMap, setShiftTemplatesMap] = useState({});
    const [unavailabilityMap, setUnavailabilityMap] = useState({}); // { vehicle_id: [ ... ] }
    const [imageCache, setImageCache] = useState({});

    // eslint-disable-next-line 
    const navigate = useNavigate();
    const { user } = useUser();
    const { enqueueSnackbar } = useSnackbar();
    // eslint-disable-next-line 
    const showSnackbar = (msg, variant = 'default') => enqueueSnackbar(msg, { variant });

    // Neue States für den Dialog "Fahrzeug außer Betrieb setzen"
    const [unavailabilityDialogOpen, setUnavailabilityDialogOpen] = useState(false);
    const [unavailabilityDialogData, setUnavailabilityDialogData] = useState(null);
    const [currentVehicleForUnavailability, setCurrentVehicleForUnavailability] = useState(null);

    // State für "Wache zuordnen" Dialog
    const [assignDialogOpen, setAssignDialogOpen] = useState(false);
    const [vehicleForAssign, setVehicleForAssign] = useState(null);

    // Für Aktionen (Alert-Demos)
    const handleActionAlert = (action, vehicle) => {
        alert(`${action} für Fahrzeug ${vehicle.vehicle_name}`);
    };

    // Suchfeld
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const handleClearSearch = () => {
        setSearchTerm('');
    };

    // Daten holen
    const fetchVehicles = async () => {
        try {
            setIsLoading(true);
            setServerError(null);
            const resp = await fetch(`${process.env.REACT_APP_API_URI}/api/vehicles`, {
                headers: { Authorization: user.api_key }
            });
            if (!resp.ok) throw new Error('Fehler beim Laden der Fahrzeuge');
            const data = await resp.json();
            setVehicles(data);
        } catch (error) {
            setServerError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchShiftTemplates = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_API_URI}/api/shift-templates`, {
                headers: { Authorization: user.api_key }
            });
            if (!resp.ok) throw new Error('Fehler beim Laden der Schichtpläne');
            const data = await resp.json();
            // Erzeuge Mapping: template_id => template
            const map = {};
            data.forEach(t => { map[t.template_id] = t; });
            setShiftTemplatesMap(map);
        } catch (error) {
            console.error(error);
        }
    };

    // Für jedes Fahrzeug holen wir zukünftige Unavailability-Datensätze
    const fetchUnavailabilityForVehicle = async (vehicleId) => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_API_URI}/api/vehicles/${vehicleId}/unavailability`, {
                headers: { Authorization: user.api_key }
            });
            if (!resp.ok) return [];
            const data = await resp.json();
            return data;
        } catch (error) {
            console.error(error);
            return [];
        }
    };

    // Bilddaten cachen
    const fetchImage = async (imageId) => {
        if (!imageId) return;
        if (imageCache[imageId]) return; // schon im Cache
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/images/${imageId}`);
            const data = await response.json();
            if (data.image) {
                setImageCache(prev => ({ ...prev, [imageId]: data.image }));
            }
        } catch (error) {
            console.error('Fehler beim Laden des Bildes:', error);
        }
    };

    // Unavailability für alle Fahrzeuge holen
    const loadUnavailability = async (vehiclesList) => {
        const map = {};
        await Promise.all(vehiclesList.map(async vehicle => {
            const unavail = await fetchUnavailabilityForVehicle(vehicle.vehicle_id);
            map[vehicle.vehicle_id] = unavail;
        }));
        setUnavailabilityMap(map);
    };

    useEffect(() => {
        fetchVehicles();
        fetchShiftTemplates();
        // eslint-disable-next-line
    }, []);

    // Sobald Fahrzeuge geladen sind, laden wir Unavailability
    useEffect(() => {
        if (vehicles.length > 0) {
            loadUnavailability(vehicles);
        }
        // Bilder nachladen
        vehicles.forEach(v => {
            if (v.image_id) {
                fetchImage(v.image_id);
            }
        });
        // eslint-disable-next-line 
    }, [vehicles]);

    // Funktion zum Öffnen
    const openAssignStationDialog = (vehicle) => {
        setVehicleForAssign(vehicle);
        setAssignDialogOpen(true);
    };

    // Spalte 1: Fahrzeuge
    const renderFahrzeugeCell = (params) => {
        const vehicle = params.row;
        const imageSrc = vehicle.image_id ? imageCache[vehicle.image_id] : '/default-vehicle.jpg';

        // 1) Symbol-Logik
        const isActive = vehicle.is_currently_active;
        const icon = isActive ? (
            <CheckCircleIcon sx={{ color: '#81c784', fontSize: '20px' }} /> // Pastellgrün
        ) : (
            <EventBusyIcon sx={{ color: '#e57373', fontSize: '20px' }} /> // Pastellrot
        );
        const titleText = isActive
            ? 'Fahrzeug ist im Dienst'
            : vehicle.currently_unavailability_reason === 'Status: inactive'
                ? 'Außer Dienst'
                : vehicle.currently_unavailability_reason || 'Fahrzeug nicht einsatzbereit';

        // Status-Info für die Hintergrundfarbe basierend auf vehicle_status
        const statusInfo = getVehicleStatusInfo(vehicle.status);

        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'stretch',
                    p: 2,
                    m: 1,
                    maxWidth: 360,
                    minHeight: 100,
                    border: '1px solid',
                    borderColor: 'grey.300',
                    borderRadius: 2,
                    boxShadow: 2,
                    backgroundColor: statusInfo.bg, // Status-Hintergrundfarbe anwenden
                    transition: 'box-shadow 0.3s',
                    position: 'relative',
                    '&:hover': {
                        boxShadow: 4,
                    },
                }}
            >
                {/* Linker Bereich: Bild als Hintergrund mit Gradient */}
                <Box
                    sx={{
                        flex: '0 0 120px',
                        position: 'relative',
                        borderRadius: 1,
                        overflow: 'hidden',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        component="img"
                        src={imageSrc}
                        alt="Fahrzeug"
                        sx={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain', // Behält die Dimensionen des Bildes bei
                            borderRadius: 1
                        }}
                    />
                </Box>

                {/* Icon oben rechts */}
                <Tooltip title={titleText} arrow>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            cursor: 'default',
                        }}
                    >
                        {icon}
                    </Box>
                </Tooltip>

                {/* Rechter Bereich: Fahrzeugdaten */}
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', pl: 2 }}>
                    <Typography variant="subtitle1" fontWeight="bold" color="primary">
                        {vehicle.vehicle_name}
                    </Typography>
                    {vehicle.radio_call_sign && (
                        <Typography variant="caption" color="text.secondary">
                            Funkrufname: {vehicle.radio_call_sign}
                        </Typography>
                    )}
                    {vehicle.license_plate && (
                        <Typography variant="caption" color="text.secondary">
                            Kennzeichen: {vehicle.license_plate}
                        </Typography>
                    )}
                    <Typography variant="caption" color="text.secondary">
                        Wache: {vehicle.station_name ? vehicle.station_name : 'keine Wache zugeordnet'}
                    </Typography>
                    {vehicle.station_id !== vehicle.default_station_id && (
                        <Stack>
                            <Typography variant="caption" color="#e57373">
                                Stammwache:
                            </Typography>
                            <Typography variant="caption" color="#e57373">
                                {vehicle.default_station_name}
                            </Typography>
                        </Stack>
                    )}
                </Box>
            </Box>
        );
    };
    // Spalte 2: Informationen (zusammengefasste Schichtpläne)
    // In der renderInformationenCell-Funktion die Marker entsprechend formatieren
    // Spalte 2: Informationen (reguläre Schichtpläne ohne Feiertage)
    const renderInformationenCell = (params) => {
        const vehicle = params.row;
        const mergedShiftInfo = mergeShiftDetails(vehicle.shift_assignments, shiftTemplatesMap);

        // Ersetze den Marker durch eine spezielle Formatierung
        const processedInfo = mergedShiftInfo.replace('[[WEEKDAY_HEADER]]', '');

        // Split den Text bei "Feiertage:" um das Icon einzufügen
        const parts = processedInfo.split('Feiertage:');

        // Nur reguläre Schichtpläne in dieser Zelle anzeigen
        return (
            <Box sx={{ marginTop: 2, marginBottom: 2, maxWidth: 380 }}>
                {parts[0].trim() && (
                    <Box>
                        {/* Werktags-Header im Card-Design wie bei Feiertagen */}
                        <Box
                            sx={{
                                border: '1px solid #bbdefb',
                                borderRadius: 2,
                                overflow: 'hidden',
                                mb: 2
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: '#e3f2fd',
                                    px: 2,
                                    py: 1
                                }}
                            >
                                <EventIcon sx={{ color: '#1976d2', mr: 1 }} />
                                <Typography variant="body2" sx={{ fontWeight: 'medium', color: '#1976d2' }}>
                                    Werktags-Schichtplan
                                </Typography>
                            </Box>
                            <Box sx={{ p: 1.5, backgroundColor: '#ffffff' }}>
                                <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                                    {parts[0].trim()}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
        );
    };

    // Neue Spalte 3: Feiertags-Schichtpläne
    const renderFeiertagsSchichtplanCell = (params) => {
        const vehicle = params.row;
        const mergedShiftInfo = mergeShiftDetails(vehicle.shift_assignments, shiftTemplatesMap);

        // Split den Text bei "Feiertage:" um nur den Feiertagsteil zu bekommen
        const parts = mergedShiftInfo.replace('[[WEEKDAY_HEADER]]', '').split('Feiertage:');

        // Falls keine Feiertagsinformationen vorhanden sind
        if (parts.length <= 1 || !parts[1].trim()) {
            return (
                <Box sx={{ p: 1, whiteSpace: 'pre-wrap', marginTop: 1 }}>
                    <Typography variant="body2">keine</Typography>
                </Box>
            );
        }

        // Feiertagsinformationen vorhanden
        return (
            <Box sx={{ marginTop: 2, marginBottom: 2, maxWidth: 380 }}>
                <Box
                    sx={{
                        border: '1px solid #ffcdd2',
                        borderRadius: 2,
                        overflow: 'hidden'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: '#ffebee',
                            px: 2,
                            py: 1
                        }}
                    >
                        <HolidayIcon sx={{ color: '#d32f2f', mr: 1 }} />
                        <Typography variant="body2" sx={{ fontWeight: 'medium', color: '#d32f2f' }}>
                            Feiertags-Schichtplan
                        </Typography>
                    </Box>
                    <Box sx={{ p: 1.5, backgroundColor: '#fff8f8' }}>
                        <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                            {parts[1].trim()}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        );
    };


    // Spalte 3: Sonderregelungen (vehicle_unavailability)
    const renderSonderregelungenCell = (params) => {
        const vehicle = params.row;
        const unavailEntries = unavailabilityMap[vehicle.vehicle_id] || [];
        // Filtern nur zukünftige Einträge (sollte bereits vom API-Endpoint gefiltert sein)
        const futureEntries = unavailEntries;

        if (futureEntries.length === 0) {
            return (
                <Box sx={{ p: 1, whiteSpace: 'pre-wrap', marginTop: 1 }}>
                    <Typography variant="body2">keine</Typography>
                </Box>
            );
        }

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 1, maxWidth: 340 }}>
                {futureEntries.map((entry) => (
                    <Box
                        key={entry.unavailability_id}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative',
                            p: 2,
                            border: '1px solid',
                            borderColor: 'grey.300',
                            borderRadius: 2,
                            boxShadow: 2,
                            backgroundColor: 'background.paper',
                            transition: 'box-shadow 0.3s',
                            '&:hover': {
                                boxShadow: 4,
                            },
                        }}
                    >
                        <Typography variant="body2" fontWeight="bold">
                            Außer Betrieb wegen {entry.reason || '…'}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                            {formatDateTime(entry.start_datetime)} - {formatDateTime(entry.end_datetime)}
                        </Typography>

                        {/* Edit-Button (Icon) oben rechts */}
                        <IconButton
                            sx={{ position: 'absolute', top: 8, right: 8 }}
                            size="small"
                            onClick={() => openEditUnavailability(entry, vehicle)}
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </Box>
                ))}
            </Box>
        );
    };

    // Funktionen zum Öffnen des Dialogs

    // Für einen neuen Unavailability-Eintrag
    const openNewUnavailability = (vehicle) => {
        setCurrentVehicleForUnavailability(vehicle);
        setUnavailabilityDialogData(null);
        setUnavailabilityDialogOpen(true);
    };

    // Für das Editieren eines bestehenden Eintrags
    const openEditUnavailability = (entry, vehicle) => {
        setCurrentVehicleForUnavailability(vehicle);
        setUnavailabilityDialogData(entry);
        setUnavailabilityDialogOpen(true);
    };

    // Spalte 4: Aktionen (Kontextmenü)
    const ActionMenuCell = ({ vehicle }) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const handleClick = (e) => {
            e.stopPropagation();
            setAnchorEl(e.currentTarget);
        };
        const handleClose = () => setAnchorEl(null);
        return (
            <>
                <IconButton data-testid="action-menu-button" onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            openAssignStationDialog(vehicle);
                        }}
                    >
                        Wache wechseln
                    </MenuItem>  <MenuItem disabled onClick={() => { handleClose(); handleActionAlert('Schichtwechsel', vehicle); }}>Schichtwechsel</MenuItem>
                    <MenuItem
                        data-testid="menu-item-out-of-service"
                        onClick={() => {
                            handleClose();
                            // Hier den Dialog im Insert-Modus öffnen, z. B. über einen zustandsbasierten Dialog-Controller
                            handleClose();
                            // Öffne den Dialog für neuen "außer Betrieb"-Eintrag
                            openNewUnavailability(vehicle);
                        }}>
                        außer Betrieb
                    </MenuItem>
                </Menu>
            </>
        );
    };

    const renderActionsCell = (params) => {
        const vehicle = params.row;
        return <ActionMenuCell vehicle={vehicle} />;
    };

    // Spalten-Definition für die DataGrid
    const columns = [
        {
            field: 'fahrzeuge',
            headerName: 'Fahrzeuge',
            width: 500,
            renderCell: renderFahrzeugeCell
        },
        {
            field: 'warning',
            headerName: '⚠',
            width: 50,
            renderCell: (params) => {
                const { station_id, default_station_id } = params.row;
                if (station_id !== default_station_id) {
                    return (
                        <Box sx={{
                            display: 'flex', flexWrap: 'wrap', gap: 1,
                            width: '100%', height: '100%',
                            alignItems: 'center', justifyContent: 'flex-start'
                        }}>
                            <Tooltip title="Zugeordnete Wache weicht von der Stammwache ab!" arrow>
                                <WarningIcon color="error" />
                            </Tooltip>
                        </Box>
                    );
                }
                return null;
            }
        },
        {
            field: 'informationen',
            headerName: 'Werktags-Schichtplan',
            width: 380,
            renderCell: renderInformationenCell
        },
        {
            field: 'feiertagsschichtplan',
            headerName: 'Feiertags-Schichtplan',
            width: 380,
            renderCell: renderFeiertagsSchichtplanCell
        },
        {
            field: 'sonderregelungen',
            headerName: 'Sonderregelungen',
            width: 421,
            renderCell: renderSonderregelungenCell
        },
        {
            field: 'actions',
            headerName: 'Aktionen',
            width: 140,
            renderCell: renderActionsCell,
            sortable: false,
            filterable: false
        }
    ];

    // Filterung der Fahrzeuge anhand der Schnellsuche (Name oder Kennzeichen)
    const filteredRows = vehicles.filter(v => {
        const text = searchTerm.toLowerCase();
        return (
            v.vehicle_name?.toLowerCase().includes(text) ||
            v.license_plate?.toLowerCase().includes(text)
        );
    }).map(v => ({ id: v.vehicle_id, ...v }));

    return (
        <Box sx={{ p: 2 }}>
            {/* Header */}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                <Stack justifyItems="flex-start" alignItems="center" direction="row">
                    <Typography variant="h5" color="#E46450" component="h1">
                        Flottenverwaltung
                    </Typography>
                    {isLoading && (
                        <CircularProgress color="inherit" />
                    )}
                    {(!isLoading) && (
                        <IconButton ml={2} size="large" aria-label="refresh" onClick={fetchVehicles}>
                            <RefreshIcon fontSize="large" />
                        </IconButton>
                    )}
                </Stack>

                <TextField
                    size="small"
                    placeholder="Schnellsuche"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                        endAdornment: (
                            <>
                                {searchTerm && (
                                    <IconButton size="small" onClick={handleClearSearch}>
                                        <ClearIcon />
                                    </IconButton>
                                )}
                                <SearchIcon />
                            </>
                        )
                    }}
                />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>

                </Box>
            </Box>

            {/* DataGrid */}
            {serverError && <Alert severity="error">{serverError}</Alert>}
            {isLoading ? (
                <CircularProgress />
            ) : (
                <PersistentDataGrid
                    storageKey="flottenverwaltung"
                    rows={filteredRows}
                    columns={columns}
                    getRowHeight={() => 'auto'}
                    pageSize={5}
                    rowsPerPageOptions={[5, 10, 20]}
                />
            )}

            {/* Integration des Unavailability-Dialogs */}
            {unavailabilityDialogOpen && (
                <VehicleUnavailabilityDialog
                    open={unavailabilityDialogOpen}
                    onClose={(saved) => {
                        setUnavailabilityDialogOpen(false);
                        if (saved) {
                            // Nach dem Speichern: Daten neu laden (z. B. nur die Unavailability-Daten oder die gesamte Fahrzeugliste)
                            fetchVehicles();
                        }
                    }}
                    initialData={unavailabilityDialogData}
                    vehicleId={currentVehicleForUnavailability ? currentVehicleForUnavailability.vehicle_id : null}
                    onSaved={() => {
                        fetchVehicles();
                    }}
                />
            )}

            {/* Unser Dialog */}
            {assignDialogOpen && (
                <AssignStationDialog
                    open={assignDialogOpen}
                    onClose={(success) => {
                        setAssignDialogOpen(false);
                        if (success) {
                            // Refresh Fahrzeugliste
                            fetchVehicles();
                        }
                    }}
                    vehicle={vehicleForAssign}
                    onAssigned={() => {
                        // optional: fetchVehicles();
                    }}
                />
            )}
        </Box>
    );
};

export default Flottenverwaltung;