import React, { useState, useEffect } from 'react';
import {
    Box, Container, Link, Typography,
    Card, CardContent, CircularProgress, Divider
} from '@mui/material';
import { useParams } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EmailIcon from '@mui/icons-material/Email';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';

const ConfirmationPage = () => {
    const { token } = useParams();
    const [confirmationStatus, setConfirmationStatus] = useState('pending');
    const [isLoading, setIsLoading] = useState(true);

    const updateUser = async (updatedUser) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/users/${updatedUser.user_id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': updatedUser.api_key,
                },
                body: JSON.stringify(updatedUser),
            });

            setIsLoading(false);
            if (response.ok) {
                setConfirmationStatus('confirmed');
            } else {
                console.error('Fehler bei der API-Anfrage:', response.statusText);
                setConfirmationStatus('error');
            }
        } catch (error) {
            console.error('Fehler bei der API-Anfrage:', error);
            setIsLoading(false);
            setConfirmationStatus('error');
        }
    };

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const urlToken = urlSearchParams.get('token');
        const actualToken = token || urlToken;

        if (actualToken) {
            fetch(`${process.env.REACT_APP_API_URI}/api/users/token/${actualToken}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Benutzer nicht gefunden');
                    }
                    return response.json();
                })
                .then(data => {
                    if (data && data.user_id) {
                        const updatedUser = { ...data, registration_status: 'bestätigt', reg_token: null };
                        updateUser(updatedUser);
                    } else {
                        setIsLoading(false);
                        setConfirmationStatus('error');
                    }
                })
                .catch(error => {
                    console.error('Fehler bei der API-Anfrage:', error);
                    setIsLoading(false);
                    setConfirmationStatus('error');
                });
        } else {
            setIsLoading(false);
            console.log('Kein Token in der URL gefunden. Zeige reguläre Bestätigungsseite.');
        }
    }, [token]);

    return (
        <Container maxWidth="lg" sx={{ py: 5 }}>
            <Card
                elevation={2}
                sx={{
                    maxWidth: '650px',
                    mx: 'auto',
                    borderRadius: '16px',
                    overflow: 'hidden'
                }}
            >
                {/* Farbiger Balken am oberen Rand */}
                <Box sx={{ height: '8px', bgcolor: '#E46450' }} />

                <CardContent sx={{ p: 4 }}>
                    {isLoading ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 4 }}>
                            <CircularProgress sx={{ color: '#E46450', mb: 2 }} />
                            <Typography variant="h6" textAlign="center">
                                Verarbeitung läuft...
                            </Typography>
                        </Box>
                    ) : (
                        <>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                mb: 3
                            }}>
                                {confirmationStatus === 'confirmed' ? (
                                    <Box sx={{
                                        width: '80px',
                                        height: '80px',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        bgcolor: 'rgba(76, 175, 80, 0.1)',
                                        mb: 2
                                    }}>
                                        <CheckCircleOutlineIcon sx={{ fontSize: 45, color: '#4CAF50' }} />
                                    </Box>
                                ) : confirmationStatus === 'error' ? (
                                    <Box sx={{
                                        width: '80px',
                                        height: '80px',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        bgcolor: 'rgba(228, 100, 80, 0.1)',
                                        mb: 2
                                    }}>
                                        <EmailIcon sx={{ fontSize: 45, color: '#E46450' }} />
                                    </Box>
                                ) : (
                                    <Box sx={{
                                        width: '80px',
                                        height: '80px',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        bgcolor: 'rgba(228, 100, 80, 0.1)',
                                        mb: 2
                                    }}>
                                        <HourglassTopIcon sx={{ fontSize: 45, color: '#E46450' }} />
                                    </Box>
                                )}

                                <Typography
                                    variant="h4"
                                    component="h1"
                                    gutterBottom
                                    textAlign="center"
                                    color="#E46450"
                                    fontWeight="bold"
                                >
                                    {confirmationStatus === 'confirmed'
                                        ? 'E-Mail bestätigt!'
                                        : confirmationStatus === 'error'
                                            ? 'Vorgang nicht abgeschlossen'
                                            : 'Registrierung erfolgreich!'}
                                </Typography>
                            </Box>

                            <Divider sx={{ my: 2 }} />

                            <Box
                                sx={{
                                    py: 3,
                                    px: 2,
                                    bgcolor: 'rgba(228, 100, 80, 0.05)',
                                    borderRadius: '12px',
                                    textAlign: 'center',
                                    mb: 3
                                }}
                            >
                                {confirmationStatus === 'confirmed' ? (
                                    <Typography variant="body1" paragraph>
                                        Vielen Dank! Ihre E-Mail wurde bestätigt.<br />
                                        Die Leitstelle wird Ihren Zugang in Kürze freischalten.
                                    </Typography>
                                ) : confirmationStatus === 'error' ? (
                                    <Typography variant="body1" paragraph>
                                        Leider ist bei der Bestätigung ein Problem aufgetreten.
                                        Bitte versuchen Sie es später erneut oder kontaktieren Sie die Leitstelle.
                                    </Typography>
                                ) : (
                                    <Typography variant="body1" paragraph>
                                        Vielen Dank für Ihre Registrierung.<br /><br />
                                        Sie erhalten in Kürze eine Bestätigungs-Mail.<br />
                                        Bitte klicken Sie auf den Link in der E-Mail,
                                        um Ihre Registrierung abzuschließen.
                                    </Typography>
                                )}
                            </Box>

                            <Box sx={{ textAlign: 'center', mt: 4 }}>
                                <Link
                                    href="/login"
                                    sx={{
                                        color: '#E46450',
                                        textDecoration: 'none',
                                        fontWeight: 'medium',
                                        '&:hover': {
                                            textDecoration: 'underline'
                                        },
                                        display: 'inline-flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    Zurück zur Anmeldung
                                </Link>
                            </Box>
                        </>
                    )}
                </CardContent>
            </Card>

        </Container>
    );
};

export default ConfirmationPage;