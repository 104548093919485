import React, { createContext, useState, useContext, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import drkTheme from './drkTheme'; // Modernes Theme
import drkClassicTheme from './drkClassicTheme'; // Klassisches Theme
import { useUser } from '../components/userContext';

// Theme-Typen
export const THEME_MODERN = 'modern';
export const THEME_CLASSIC = 'classic';

// Context für Theme-Einstellungen
const ThemeContext = createContext({
    currentTheme: THEME_MODERN,
    setTheme: () => { },
    toggleTheme: () => { },
});

// Hook zum einfachen Zugriff auf das Theme
export const useThemeContext = () => useContext(ThemeContext);

// Provider-Komponente
export const ThemeContextProvider = ({ children }) => {
    const { user, settings, updateSetting } = useUser();

    // Lese Theme-Einstellung aus den Benutzereinstellungen oder fallback auf localStorage
    // Default ist jetzt THEME_CLASSIC
    const [currentTheme, setCurrentTheme] = useState(() => {
        // Prüfe zuerst, ob das Theme in den Benutzereinstellungen gespeichert ist
        if (settings && settings.theme) {
            return settings.theme;
        }

        // Fallback: Lese aus localStorage für nicht eingeloggte Benutzer oder alte Einstellungen
        const savedTheme = localStorage.getItem('drkTheme');
        return savedTheme === THEME_MODERN ? THEME_MODERN : THEME_CLASSIC; // Default ist CLASSIC
    });

    // Aktualisiere das Theme, wenn sich die Benutzereinstellungen ändern
    useEffect(() => {
        if (settings) {
            // Wenn der Benutzer eingeloggt ist und noch keine Theme-Einstellung hat,
            // setze die Standardeinstellung (THEME_CLASSIC)
            if (!settings.theme && user) {
                updateSetting('theme', THEME_CLASSIC);
            }
            // Wenn es eine Theme-Einstellung gibt und sie vom aktuellen Theme abweicht
            else if (settings.theme && settings.theme !== currentTheme) {
                setCurrentTheme(settings.theme);
            }
        }
    }, [settings, currentTheme, user, updateSetting]);

    // Setze Theme
    const setTheme = (theme) => {
        setCurrentTheme(theme);

        // Speichere in Benutzereinstellungen, wenn Benutzer eingeloggt ist
        if (user) {
            updateSetting('theme', theme);
        } else {
            // Fallback für nicht eingeloggte Benutzer
            localStorage.setItem('drkTheme', theme);
        }
    };

    // Toggle zwischen Themes
    const toggleTheme = () => {
        const newTheme = currentTheme === THEME_MODERN ? THEME_CLASSIC : THEME_MODERN;
        setTheme(newTheme);
    };

    // Aktuelles Theme-Objekt basierend auf der Einstellung
    const theme = currentTheme === THEME_CLASSIC ? drkClassicTheme : drkTheme;

    // Stelle Context-Werte bereit
    const contextValue = {
        currentTheme,
        setTheme,
        toggleTheme,
    };

    return (
        <ThemeContext.Provider value={contextValue}>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};

export default ThemeContextProvider;