import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack';

// Holen Sie sich das Wurzelelement
const rootElement = document.getElementById('root');

// Setzen Sie die 'notranslate'-Eigenschaft
if (rootElement) {
  rootElement.setAttribute('notranslate', 'true');
}

// Erstellen Sie die Wurzel und rendern Sie die Anwendung
const root = ReactDOM.createRoot(rootElement);
root.render(
  // <React.StrictMode>
  <SnackbarProvider maxSnack={3}>
    <App />
  </SnackbarProvider>
  // </React.StrictMode>
);

reportWebVitals();
