import React, { useState, useEffect } from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Grid,
    CircularProgress,
    Chip,
    IconButton,
    Paper,
    Button
} from '@mui/material';
import {
    DirectionsCar as DirectionsCarIcon,
    AccessTime as AccessTimeIcon,
    TrendingUp as TrendingUpIcon,
    Error as ErrorIcon,
    Refresh as RefreshIcon
} from '@mui/icons-material';
import { useUser } from '../../components/userContext';

const formatTime = (hours, minutes) => {
    return `${hours}h ${minutes}m`;
};

const StatistikDashboard = ({ selectedDate }) => {
    const [statistics, setStatistics] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user } = useUser();

    useEffect(() => {
        fetchStatistics();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate]);

    const fetchStatistics = async () => {
        setLoading(true);
        try {
            // Formatiere das Datum für die API-Anfrage
            const dateString = selectedDate ? selectedDate.format('YYYY-MM-DD') : '';

            const response = await fetch(
                `${process.env.REACT_APP_API_URI}/api/statistics${dateString ? `/${dateString}` : ''}`,
                { headers: { Authorization: user.api_key } }
            );

            if (!response.ok) throw new Error('Fehler beim Laden der Statistiken');

            const data = await response.json();
            setStatistics(data);
            setError(null);
        } catch (err) {
            console.error('Fehler beim Abrufen der Statistikdaten:', err);
            setError('Die Statistikdaten konnten nicht geladen werden. Bitte versuchen Sie es später erneut.');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="400px">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="400px">
                <Paper elevation={3} sx={{ p: 3, backgroundColor: '#fff9f9' }}>
                    <Typography color="error" gutterBottom>
                        {error}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<RefreshIcon />}
                        onClick={fetchStatistics}
                    >
                        Erneut versuchen
                    </Button>
                </Paper>
            </Box>
        );
    }

    // Wenn keine Daten vorhanden sind, zeige eine Mitteilung an
    if (!statistics) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="400px">
                <Typography>Keine Statistikdaten verfügbar</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3, backgroundColor: '#f9fafb' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <Typography variant="h5" fontWeight="600" color="#E46450">
                    Statistik für {selectedDate ? selectedDate.format('DD.MM.YYYY') : 'Heute'}
                </Typography>
                <IconButton
                    onClick={fetchStatistics}
                    sx={{ backgroundColor: '#f0f0f0', '&:hover': { backgroundColor: '#e0e0e0' } }}
                >
                    <RefreshIcon />
                </IconButton>
            </Box>

            <Grid container spacing={3}>
                {/* Transporte-Karte */}
                <Grid item xs={12} md={6} lg={4}>
                    <Card elevation={2} sx={{
                        height: '100%',
                        borderRadius: '12px',
                        transition: 'transform 0.2s',
                        '&:hover': { transform: 'translateY(-4px)', boxShadow: '0 8px 16px rgba(0,0,0,0.1)' }
                    }}>
                        <CardContent>
                            <Box display="flex" alignItems="center" mb={2}>
                                <DirectionsCarIcon sx={{ color: '#2196f3', mr: 1 }} />
                                <Typography variant="h6" fontWeight="600">
                                    Transporte
                                </Typography>
                            </Box>

                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Box textAlign="center" p={1} sx={{ backgroundColor: '#e3f2fd', borderRadius: '8px' }}>
                                        <Typography variant="body2" color="textSecondary">
                                            Geplant
                                        </Typography>
                                        <Typography variant="h4" fontWeight="bold" color="#1976d2">
                                            {statistics.transports.planned}
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={6}>
                                    <Box textAlign="center" p={1} sx={{ backgroundColor: '#fff8e1', borderRadius: '8px' }}>
                                        <Typography variant="body2" color="textSecondary">
                                            Ungeplant
                                        </Typography>
                                        <Typography variant="h4" fontWeight="bold" color="#ed6c02">
                                            {statistics.transports.unplanned}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Box mt={2} p={1.5} sx={{ backgroundColor: '#ffebee', borderRadius: '8px' }}>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Box display="flex" alignItems="center">
                                        <ErrorIcon sx={{ color: '#d32f2f', mr: 1 }} />
                                        <Typography variant="body2">
                                            Fehlerhafte Transporte
                                        </Typography>
                                    </Box>
                                    <Chip
                                        label={`${statistics.transports.errors.count}`}
                                        color="error"
                                        size="small"
                                    />
                                </Box>
                                <Box display="flex" justifyContent="flex-end" mt={1}>
                                    <Typography variant="body2" fontWeight="medium">
                                        {statistics.transports.errors.percentage.toFixed(1)}% aller Transporte
                                    </Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Auslastung-Karte */}
                <Grid item xs={12} md={6} lg={4}>
                    <Card elevation={2} sx={{
                        height: '100%',
                        borderRadius: '12px',
                        transition: 'transform 0.2s',
                        '&:hover': { transform: 'translateY(-4px)', boxShadow: '0 8px 16px rgba(0,0,0,0.1)' }
                    }}>
                        <CardContent>
                            <Box display="flex" alignItems="center" mb={2}>
                                <TrendingUpIcon sx={{ color: '#4caf50', mr: 1 }} />
                                <Typography variant="h6" fontWeight="600">
                                    Auslastung
                                </Typography>
                            </Box>

                            <Box display="flex" justifyContent="center" alignItems="center" position="relative" my={2}>
                                <Box
                                    sx={{
                                        position: 'relative',
                                        display: 'inline-flex',
                                        width: '180px',
                                        height: '180px',
                                    }}
                                >
                                    <CircularProgress
                                        variant="determinate"
                                        value={100}
                                        size={180}
                                        thickness={4}
                                        sx={{ color: '#e0e0e0', position: 'absolute' }}
                                    />
                                    <CircularProgress
                                        variant="determinate"
                                        value={Math.min(statistics.utilization.percentage, 100)}
                                        size={180}
                                        thickness={4}
                                        sx={{
                                            color: statistics.utilization.percentage < 50 ? '#f44336' :
                                                statistics.utilization.percentage < 75 ? '#ff9800' :
                                                    '#4caf50',
                                            position: 'absolute'
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexDirection: 'column'
                                        }}
                                    >
                                        <Typography variant="h3" fontWeight="bold">
                                            {statistics.utilization.percentage.toFixed(1)}%
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Auslastung
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>

                            <Box p={1.5} sx={{ backgroundColor: '#f0f5ff', borderRadius: '8px' }}>
                                <Typography variant="body2" color="textSecondary" gutterBottom>
                                    Theoretische Arbeitsstunden vs. Tatsächliche Arbeit
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" color="textSecondary">
                                            Geplant
                                        </Typography>
                                        <Typography variant="body1" fontWeight="medium">
                                            {formatTime(statistics.theoreticalWorkingHours.shiftPlanWork.hours, statistics.theoreticalWorkingHours.shiftPlanWork.minutes)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" color="textSecondary">
                                            Tatsächlich
                                        </Typography>
                                        <Typography variant="body1" fontWeight="medium">
                                            {formatTime(statistics.workTime.hours, statistics.workTime.minutes)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Fahrt- und Pausenzeiten */}
                <Grid item xs={12} md={6} lg={4}>
                    <Card elevation={2} sx={{
                        height: '100%',
                        borderRadius: '12px',
                        transition: 'transform 0.2s',
                        '&:hover': { transform: 'translateY(-4px)', boxShadow: '0 8px 16px rgba(0,0,0,0.1)' }
                    }}>
                        <CardContent>
                            <Box display="flex" alignItems="center" mb={2}>
                                <AccessTimeIcon sx={{ color: '#9c27b0', mr: 1 }} />
                                <Typography variant="h6" fontWeight="600">
                                    Zeit & Distanz
                                </Typography>
                            </Box>

                            <Box p={1.5} sx={{ backgroundColor: '#f3e5f5', borderRadius: '8px', mb: 2 }}>
                                <Typography variant="body2" fontWeight="medium" gutterBottom>
                                    Fahrten Heute
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" color="textSecondary">
                                            Gefahrene Strecke
                                        </Typography>
                                        <Typography variant="h6" fontWeight="bold" color="#7b1fa2">
                                            {parseInt(statistics.drive.distance.km).toLocaleString()} km
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" color="textSecondary">
                                            Fahrzeit
                                        </Typography>
                                        <Typography variant="h6" fontWeight="bold" color="#7b1fa2">
                                            {formatTime(statistics.drive.duration.hours, statistics.drive.duration.minutes)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box p={1.5} sx={{ backgroundColor: '#e8f5e9', borderRadius: '8px' }}>
                                <Typography variant="body2" fontWeight="medium" gutterBottom>
                                    Pausen
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" color="textSecondary">
                                            Geplante Pausen
                                        </Typography>
                                        <Typography variant="h6" fontWeight="bold" color="#2e7d32">
                                            {formatTime(statistics.scheduledBreaks.hours, statistics.scheduledBreaks.minutes)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" color="textSecondary">
                                            Pausenplan
                                        </Typography>
                                        <Typography variant="h6" fontWeight="bold" color="#2e7d32">
                                            {formatTime(statistics.theoreticalWorkingHours.shiftPlanBreaks.hours, statistics.theoreticalWorkingHours.shiftPlanBreaks.minutes)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default StatistikDashboard;