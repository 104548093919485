// HistoryEntryCard.js (oder inline)
import React from 'react';
import { Box, Typography, Divider } from '@mui/material';

const HistoryEntryCard = ({ entry }) => {
    // entry.changes_json => {"status": {"old_value": ..., "new_value": ...}, ...}
    const { historie_id, changed_by_user, changed_action, modified_date, changes_json } = entry;

    // Formatierung Datum
    const dateObj = new Date(modified_date);
    const formattedDate = dateObj.toLocaleDateString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
    const formattedTime = dateObj.toLocaleTimeString('de-DE', {
        hour: '2-digit',
        minute: '2-digit'
    });

    function renderValue(value) {
        if (typeof value === 'object' && value !== null) {
            // Objekt als Liste von Keys und Values darstellen
            return (
                <ul>
                    {Object.entries(value).map(([k, v]) => (
                        <li key={k}><strong>{k}:</strong> {String(v)}</li>
                    ))}
                </ul>
            );
        }
        return String(value);
    }

    // changes_json parsen: z.B. in <ul> Felder
    return (
        <Box mb={2} p={2} border="1px solid #ccc" borderRadius="4px">
            <Typography variant="subtitle2">
                <strong>Änderung # {historie_id}</strong> am {formattedDate} um {formattedTime}
            </Typography>
            <Typography variant="body2" sx={{ color: 'gray', fontStyle: 'italic' }}>
                Geändert von: {changed_by_user}
            </Typography>
            {changed_action && (
                <Typography variant="body2" sx={{ color: 'gray', fontStyle: 'italic' }}>
                    Aktion: {changed_action}
                </Typography>
            )}
            <Divider sx={{ my: 1 }} />
            {/* changes_json: Felder alt->neu */}
            {Object.entries(changes_json).map(([fieldName, values]) => (
                <Box key={fieldName} mb={0.5}>
                    <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                        <strong>{fieldName}:</strong>
                        von {renderValue(values.old_value)} auf {renderValue(values.new_value)}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
};

export default HistoryEntryCard;