import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { parseWorkingIntervals } from './timelineUtils';

function NonWorkingBackground({ vehicle, selectedDate, hourWidth }) {
    const intervals = parseWorkingIntervals(vehicle, selectedDate);

    const blocks = [];
    for (let hour = 0; hour < 24; hour++) {
        const blockStart = dayjs(selectedDate).startOf('day').hour(hour).minute(0).second(0);
        const blockEnd = blockStart.add(1, 'hour').subtract(1, 'second');

        let isWorking = false;
        for (const [s, e] of intervals) {
            if (blockStart.isBefore(e) && blockEnd.isAfter(s)) {
                isWorking = true;
                break;
            }
        }

        if (!isWorking) {
            blocks.push(
                <Box
                    key={`nonworking-${hour}-${vehicle.vehicle_id}`}
                    sx={{
                        position: 'absolute',
                        left: hour * hourWidth,
                        top: 0,
                        width: hourWidth,
                        height: '100%',
                        backgroundColor: 'rgba(128,128,128,0.1)',
                        pointerEvents: 'none',
                        zIndex: 1,
                    }}
                />
            );
        }
    }
    return <>{blocks}</>;
}

NonWorkingBackground.propTypes = {
    vehicle: PropTypes.shape({
        vehicle_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        free_time_json: PropTypes.shape({
            workingIntervals: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
        }),
    }).isRequired,
    selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    hourWidth: PropTypes.number.isRequired,
};

export default NonWorkingBackground;