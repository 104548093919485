import React, { useState, useEffect } from 'react';
import {
    Container,
    Box,
    TextField,
    Button,
    Typography,
    Grid2,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Switch,
    Alert,
    Card,
    CardContent
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../../components/userContext';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// Import von Icons für die verbesserte UI
import EventIcon from '@mui/icons-material/Event';
import HolidayIcon from '@mui/icons-material/Celebration';

// Definition der Wochentage: Wir legen fest, dass Montag = 1, Dienstag = 2, …, Samstag = 6 und Sonntag = 0 ist.
const days = [
    { label: 'Mo', value: 1 },
    { label: 'Di', value: 2 },
    { label: 'Mi', value: 3 },
    { label: 'Do', value: 4 },
    { label: 'Fr', value: 5 },
    { label: 'Sa', value: 6 },
    { label: 'So', value: 0 }
];

// Initialzustand für die Arbeitstage
const initialWorkingDays = {
    1: { active: false, start_time: null, end_time: null },
    2: { active: false, start_time: null, end_time: null },
    3: { active: false, start_time: null, end_time: null },
    4: { active: false, start_time: null, end_time: null },
    5: { active: false, start_time: null, end_time: null },
    6: { active: false, start_time: null, end_time: null },
    0: { active: false, start_time: null, end_time: null }
};


const ShiftPlanPage = () => {
    const { id } = useParams(); // id wird als Template-ID verwendet (Edit Mode, falls vorhanden)
    const isEditMode = Boolean(id);
    const navigate = useNavigate();
    const { user } = useUser();

    // Formulardaten für shift_template und globale Felder, die in jeden Detail-Datensatz übernommen werden
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        additional_information: '',
        recurrence_pattern: 'gerade, ungerade', // Standard: beide Wochen
        holiday: false, // Boolean-Flag für Feiertags-Schichtpläne
        break_duration: '00:00', // Globaler Wert für break_duration, im Format HH:mm
        workingDays: initialWorkingDays // Enthält für jeden Wochentag, ob aktiv und ggf. Start-/Endzeit
    });

    // Für die Anzeige der Fahrzeuge, die diesen Schichtplan verwenden (nur im Edit Mode)
    const [assignedVehicles, setAssignedVehicles] = useState([]);
    // Für Validierungs- und Server-Fehlermeldungen
    const [errors, setErrors] = useState({});
    const [serverError, setServerError] = useState('');

    // Beim Bearbeiten: bestehende Daten laden
    useEffect(() => {
        if (isEditMode) {
            fetchShiftTemplate();
            fetchAssignedVehicles();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    // Falls kein einzelner GET-Endpunkt existiert, laden wir alle shift-templates und filtern
    const fetchShiftTemplate = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/shift-templates`, {
                headers: { Authorization: user.api_key }
            });
            if (!response.ok) throw new Error('Fehler beim Laden des Schichtplans');
            const data = await response.json();
            const template = data.find(t => t.template_id === parseInt(id, 10));
            if (!template) throw new Error('Schichtplan nicht gefunden');

            // Setze globale Felder aus dem ersten vorhandenen Detail-Datensatz (Annahme: alle Details haben dieselben Werte)
            setFormData(prev => ({
                ...prev,
                name: template.name || '',
                description: template.description || '',
                additional_information: template.additional_information || '',
                recurrence_pattern: (template.details && template.details.length > 0)
                    ? template.details[0].recurrence_pattern
                    : 'gerade, ungerade',
                // Umwandlung von day_type zu holiday Boolean-Flag
                holiday: template.holiday || false, // Lese das holiday-Flag aus der Datenbank
                break_duration: (template.details && template.details.length > 0 && template.details[0].break_duration)
                    ? template.details[0].break_duration.substr(0, 5) // "HH:mm:ss" → "HH:mm"
                    : '00:00'
            }));

            // Für jeden aktiven Tag setzen wir die entsprechenden Arbeitszeiten
            if (template.details && template.details.length > 0) {
                const newWorkingDays = { ...initialWorkingDays };
                template.details.forEach(detail => {
                    newWorkingDays[detail.day_of_week] = {
                        active: true,
                        start_time: detail.start_time ? dayjs(detail.start_time, 'HH:mm:ss') : null,
                        end_time: detail.end_time ? dayjs(detail.end_time, 'HH:mm:ss') : null
                    };
                });
                setFormData(prev => ({
                    ...prev,
                    workingDays: newWorkingDays
                }));
            }
        } catch (error) {
            setServerError(error.message);
        }
    };

    // Abfrage der Fahrzeuge, die diesen Schichtplan nutzen (über den Endpoint /shift-templates/:id/vehicles)
    const fetchAssignedVehicles = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/shift-templates/${id}/vehicles`, {
                headers: { Authorization: user.api_key }
            });
            if (!response.ok) throw new Error('Fehler beim Laden der zugeordneten Fahrzeuge');
            const data = await response.json();
            setAssignedVehicles(data);
        } catch (error) {
            console.error(error);
        }
    };

    // Handler für Textfelder (Name, Description, additional_information)
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    // Handler für die Select-Box der recurrence_pattern
    const handleRecurrenceChange = (e) => {
        setFormData(prev => ({ ...prev, recurrence_pattern: e.target.value }));
    };

    // Handler für den Feiertag-Switch/Toggle (holiday)
    const handleHolidayChange = (e) => {
        setFormData(prev => ({ ...prev, holiday: e.target.checked }));
    };

    // Handler für break_duration (Zeit im Format HH:mm)
    const handleBreakDurationChange = (e) => {
        setFormData(prev => ({ ...prev, break_duration: e.target.value }));
    };

    // Handler, um den Switch eines Tages (aktiv/inaktiv) zu toggeln
    const handleDayToggle = (dayValue) => (e) => {
        setFormData(prev => ({
            ...prev,
            workingDays: {
                ...prev.workingDays,
                [dayValue]: {
                    ...prev.workingDays[dayValue],
                    active: e.target.checked
                }
            }
        }));
    };

    // Handler für Änderungen der Start- und Endzeiten pro Tag
    const handleTimeChange = (dayValue, field, time) => {
        // "time" ist ein String im Format "HH:mm"
        setFormData(prev => ({
            ...prev,
            workingDays: {
                ...prev.workingDays,
                [dayValue]: {
                    ...prev.workingDays[dayValue],
                    [field]: time
                }
            }
        }));
    };

    // Validierung: Pflichtfeld Name und mindestens ein aktiver Tag mit Arbeitszeiten
    const validateForm = () => {
        const newErrors = {};
        if (!formData.name.trim()) {
            newErrors.name = 'Name ist erforderlich';
        }
        const activeDays = Object.values(formData.workingDays).filter(day => day.active);
        if (activeDays.length === 0) {
            newErrors.workingDays = 'Mindestens ein Arbeitstag muss ausgewählt sein';
        } else {
            Object.entries(formData.workingDays).forEach(([day, data]) => {
                if (data.active) {
                    if (!data.start_time) {
                        newErrors[`start_time_${day}`] = 'Startzeit erforderlich';
                    }
                    if (!data.end_time) {
                        newErrors[`end_time_${day}`] = 'Endzeit erforderlich';
                    }
                }
            });
        }
        return newErrors;
    };

    // Absenden des Formulars: Erstellung des Payloads und Aufruf von POST (Anlegen) bzw. PUT (Edit)
    const handleSubmit = async (e) => {
        e.preventDefault();
        setServerError('');
        const validationErrors = validateForm();
        setErrors(validationErrors);
        if (Object.keys(validationErrors).length > 0) return;

        // Für jeden aktivierten Tag wird ein Detail-Datensatz erstellt
        const details = [];
        for (const [day, info] of Object.entries(formData.workingDays)) {
            if (info.active) {
                details.push({
                    day_of_week: parseInt(day, 10),
                    start_time: info.start_time.format('HH:mm') + ':00',
                    end_time: info.end_time.format('HH:mm') + ':00',
                    break_duration: formData.break_duration + ':00',
                    day_type: 'weekday', // NEU: holiday wird nicht mehr in day_type gespeichert
                    recurrence_pattern: formData.recurrence_pattern
                });
            }
        }
        if (details.length === 0) {
            setErrors(prev => ({ ...prev, workingDays: 'Mindestens ein Arbeitstag muss ausgewählt sein' }));
            return;
        }

        const payload = {
            name: formData.name,
            description: formData.description,
            additional_information: formData.additional_information,
            holiday: formData.holiday, // NEU: holiday-Flag als eigenständiges Feld übernehmen
            details: details
        };

        try {
            let url = `${process.env.REACT_APP_API_URI}/api/shift-templates`;
            let method = 'POST';
            if (isEditMode) {
                url += `/${id}`;
                method = 'PUT';
            }
            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user.api_key
                },
                body: JSON.stringify(payload)
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Fehler beim Speichern');
            }
            // Bei Erfolg zur vorherigen Seite zurück navigieren
            navigate(-1);
        } catch (error) {
            setServerError(error.message);
        }
    };

    /**
 * Findet den letzten aktiven Tag (dayValue) VOR dem aktuell übergebenen Tag
 * (ggf. zyklisch) und gibt dessen dayValue zurück, falls vorhanden.
 * Sonst null.
 */
    function findPreviousActiveDay(currentDayValue, workingDays) {
        // Wir durchsuchen rückwärts bis zu 7 Mal (alle Wochentage),
        // indem wir currentDayValue - 1, -2, etc. modulo 7 rechnen:
        for (let i = 1; i <= 7; i++) {
            // z.B. bei currentDayValue=1 => 1 - i = 0 => mod 7 => 0
            const candidate = (currentDayValue - i + 7) % 7;

            if (workingDays[candidate].active
                && workingDays[candidate].start_time
                && workingDays[candidate].end_time) {
                return candidate;
            }
        }
        return null;
    }

    const copyTimesFromDayToDay = (sourceDayValue, targetDayValue) => {
        setFormData(prev => ({
            ...prev,
            workingDays: {
                ...prev.workingDays,
                [targetDayValue]: {
                    ...prev.workingDays[targetDayValue],
                    start_time: prev.workingDays[sourceDayValue].start_time,
                    end_time: prev.workingDays[sourceDayValue].end_time
                }
            }
        }));
    };

    // Farben für die UI basierend auf dem Feiertagsmodus
    const themeColors = formData.holiday
        ? {
            headerBg: '#ff5252',
            headerText: '#ffffff',
            iconColor: '#f44336',
            switchColor: '#f44336',
            cardBg: '#fff8f8',
            cardBorder: '#ffcdd2',
            highlight: '#ffebee'
        }
        : {
            headerBg: '#1976d2',
            headerText: '#ffffff',
            iconColor: '#1976d2',
            switchColor: '#1976d2',
            cardBg: '#f5f8ff',
            cardBorder: '#bbdefb',
            highlight: '#e3f2fd'
        };

    return (
        <Container maxWidth="lg">
            <Box mt={2} pb={4}>
                {/* Header Card mit Schichtplantyp-Umschalter */}
                <Card
                    elevation={3}
                    sx={{
                        mb: 3,
                        overflow: 'hidden',
                        borderRadius: 3,
                        boxShadow: formData.holiday ? '0 4px 12px rgba(244, 67, 54, 0.2)' : '0 4px 12px rgba(25, 118, 210, 0.2)'
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: themeColors.headerBg,
                            color: themeColors.headerText,
                            p: 2
                        }}
                    >
                        <Typography variant="colorHeader" fontWeight="bold">
                            {isEditMode ? 'Schichtplan bearbeiten' : 'Neuen Schichtplan anlegen'}
                        </Typography>
                    </Box>

                    <CardContent>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            p={2}
                            sx={{
                                backgroundColor: formData.holiday ? '#fff5f5' : '#f8f9fa',
                                borderRadius: 2,
                                border: `1px solid ${formData.holiday ? '#ffcccc' : '#e9ecef'}`
                            }}
                        >
                            <Box display="flex" alignItems="center">
                                {formData.holiday ? (
                                    <HolidayIcon sx={{ mr: 2, color: themeColors.iconColor, fontSize: 30 }} />
                                ) : (
                                    <EventIcon sx={{ mr: 2, color: themeColors.iconColor, fontSize: 30 }} />
                                )}
                                <Typography variant="h6" fontWeight="medium">
                                    {formData.holiday ? 'Feiertagsschichtplan' : 'Regulärer Schichtplan'}
                                </Typography>
                            </Box>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formData.holiday}
                                        onChange={handleHolidayChange}
                                        color={formData.holiday ? "error" : "primary"}
                                        sx={{
                                            '& .MuiSwitch-switchBase.Mui-checked': {
                                                color: themeColors.switchColor,
                                            },
                                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                backgroundColor: themeColors.switchColor,
                                            },
                                        }}
                                    />
                                }
                                label={
                                    <Typography variant="body1" fontWeight="medium">
                                        Schichtplan für Feiertage
                                    </Typography>
                                }
                            />
                        </Box>
                    </CardContent>
                </Card>

                {/* Hauptinhalt-Box mit Farbhinterlegung basierend auf Schichtplantyp */}
                <Box
                    p={2}
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: 3,
                        boxShadow: 3,
                        border: formData.holiday ? `1px solid ${themeColors.cardBorder}` : 'none',
                    }}
                >
                    {serverError && <Alert severity="error" sx={{ mb: 2 }}>{serverError}</Alert>}
                    <form onSubmit={handleSubmit}>
                        {/* Erste Zeile: Name und Anzeige der zugeordneten Fahrzeuge */}
                        <Grid2 container spacing={2}>
                            <Grid2 item size={6}>
                                <TextField
                                    label="Name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    error={!!errors.name}
                                    helperText={errors.name}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: themeColors.iconColor,
                                            },
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: themeColors.iconColor,
                                        },
                                    }}
                                />
                            </Grid2>
                            <Grid2 item size={4}>
                                {isEditMode && (
                                    <Box>
                                        {assignedVehicles.length > 0 ? (
                                            <Typography variant="body2">
                                                Verwendete Fahrzeuge: {assignedVehicles.map(v => v.vehicle_name).join(', ')}
                                            </Typography>
                                        ) : (
                                            <Typography variant="body2">
                                                Verwendete Fahrzeuge: Keine Fahrzeuge zugeordnet
                                            </Typography>
                                        )}
                                    </Box>
                                )}
                            </Grid2>
                        </Grid2>

                        {/* Zweite Zeile: Beschreibung, recurrence_pattern (Select) */}
                        <Grid2 container spacing={2} mt={2}>
                            <Grid2 item size={6}>
                                <TextField
                                    label="Beschreibung"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    fullWidth
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: themeColors.iconColor,
                                            },
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: themeColors.iconColor,
                                        },
                                    }}
                                />
                            </Grid2>

                            <Grid2 item size={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="recurrence-label">Wochen</InputLabel>
                                    <Select
                                        labelId="recurrence-label"
                                        value={formData.recurrence_pattern}
                                        label="Wochen"
                                        onChange={handleRecurrenceChange}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '&.Mui-focused fieldset': {
                                                    borderColor: themeColors.iconColor,
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="gerade">gerade</MenuItem>
                                        <MenuItem value="ungerade">ungerade</MenuItem>
                                        <MenuItem value="gerade, ungerade">gerade, ungerade</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid2>
                        </Grid2>

                        {/* Dritte Zeile: Additional Information und break_duration */}
                        <Grid2 container spacing={2} mt={2} >
                            <Grid2 item size={6} >
                                <TextField
                                    label="Zusätzliche Informationen"
                                    name="additional_information"
                                    value={formData.additional_information}
                                    onChange={handleChange}
                                    fullWidth
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: themeColors.iconColor,
                                            },
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: themeColors.iconColor,
                                        },
                                    }}
                                />
                            </Grid2>

                            <Grid2 item size={4} >
                                <TextField
                                    label="Pausendauer (HH:mm)"
                                    name="break_duration"
                                    type="time"
                                    value={formData.break_duration}
                                    onChange={handleBreakDurationChange}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: themeColors.iconColor,
                                            },
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: themeColors.iconColor,
                                        },
                                    }}
                                />
                            </Grid2>
                        </Grid2>

                        {/* Hauptbereich: Arbeitszeiten pro Tag */}
                        <Box mt={3}>
                            <Card
                                sx={{
                                    p: 2,
                                    backgroundColor: formData.holiday ? themeColors.highlight : 'inherit',
                                    border: formData.holiday ? `1px solid ${themeColors.cardBorder}` : 'none',
                                    borderRadius: 2,
                                    mb: 2
                                }}
                            >
                                <Typography variant="h6" mb={2} sx={{ color: themeColors.iconColor, fontWeight: 'bold' }}>
                                    Arbeitszeiten pro Tag
                                    {formData.holiday && (
                                        <Typography component="span" variant="body2" sx={{ ml: 2, fontStyle: 'italic' }}>
                                            (Feiertagsschichtplan)
                                        </Typography>
                                    )}
                                </Typography>
                                {errors.workingDays && <Alert severity="error">{errors.workingDays}</Alert>}
                            </Card>

                            <Grid2 container spacing={2}>
                                {days.map(day => {
                                    const { active, start_time, end_time } = formData.workingDays[day.value];
                                    let previousDayLink = null;

                                    // Nur anzeigen, wenn Tag aktiv ist UND noch mindestens eine Zeit leer ist
                                    if (active && (!start_time || !end_time)) {
                                        const prevDayValue = findPreviousActiveDay(day.value, formData.workingDays);
                                        if (prevDayValue !== null) {
                                            // Vorgänger hat gültige Zeiten:
                                            const prevStart = formData.workingDays[prevDayValue].start_time.format('HH:mm');
                                            const prevEnd = formData.workingDays[prevDayValue].end_time.format('HH:mm');
                                            const linkText = `Zeiten übernehmen (${prevStart} - ${prevEnd})?`;

                                            previousDayLink = (
                                                <Button
                                                    variant="text"
                                                    size="small"
                                                    onClick={() => copyTimesFromDayToDay(prevDayValue, day.value)}
                                                    sx={{ ml: 1, color: themeColors.iconColor }}
                                                >
                                                    {linkText}
                                                </Button>
                                            );
                                        }
                                    }

                                    return (
                                        <Grid2 item xs={12} sm={6} md={4} key={day.value}>
                                            <Box
                                                border={1}
                                                borderRadius={2}
                                                p={1}
                                                borderColor={formData.holiday ? themeColors.cardBorder : "grey.300"}
                                                sx={{
                                                    backgroundColor: active ?
                                                        (formData.holiday ? 'rgba(255, 205, 210, 0.2)' : 'rgba(187, 222, 251, 0.2)')
                                                        : 'inherit',
                                                    transition: 'all 0.3s ease'
                                                }}
                                            >
                                                <Box display="flex" alignItems="center">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={active}
                                                                onChange={handleDayToggle(day.value)}
                                                                color={formData.holiday ? "error" : "primary"}
                                                                sx={{
                                                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                                                        color: themeColors.switchColor,
                                                                    },
                                                                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                                        backgroundColor: themeColors.switchColor,
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        label={day.label}
                                                        sx={{ mr: 1 }}
                                                    />
                                                    {/* Zeige den Link nur an, falls previousDayLink !== null */}
                                                    {previousDayLink}
                                                </Box>

                                                <Box mt={1}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                                                        <TimePicker
                                                            label="Von"
                                                            views={['hours', 'minutes']}
                                                            format="HH:mm"
                                                            disabled={!active}
                                                            value={start_time}
                                                            onChange={(newTime) => handleTimeChange(day.value, 'start_time', newTime)}
                                                            slotProps={{
                                                                textField: {
                                                                    error: Boolean(errors[`start_time_${day.value}`]),
                                                                    helperText: errors[`start_time_${day.value}`] || '',
                                                                    sx: {
                                                                        '& .MuiOutlinedInput-root': {
                                                                            '&.Mui-focused fieldset': {
                                                                                borderColor: themeColors.iconColor,
                                                                            },
                                                                        },
                                                                    }
                                                                },
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                                                        <TimePicker
                                                            label="Bis"
                                                            views={['hours', 'minutes']}
                                                            format="HH:mm"
                                                            disabled={!active}
                                                            value={end_time}
                                                            onChange={(newTime) => handleTimeChange(day.value, 'end_time', newTime)}
                                                            slotProps={{
                                                                textField: {
                                                                    error: Boolean(errors[`end_time_${day.value}`]),
                                                                    helperText: errors[`end_time_${day.value}`] || '',
                                                                    sx: {
                                                                        '& .MuiOutlinedInput-root': {
                                                                            '&.Mui-focused fieldset': {
                                                                                borderColor: themeColors.iconColor,
                                                                            },
                                                                        },
                                                                    }
                                                                },
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                </Box>
                                            </Box>
                                        </Grid2>
                                    );
                                })}
                            </Grid2>
                        </Box>

                        {/* Aktionen: Abbrechen und Speichern */}
                        <Box mt={3} display="flex" justifyContent="space-between">
                            <Button
                                variant="text"
                                onClick={() => navigate(-1)}
                            >
                                Abbrechen
                            </Button>
                            <Button
                                variant="contained"
                                color={formData.holiday ? "error" : "primary"}
                                type="submit"
                            >
                                Speichern
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Box>
        </Container>
    );
};

export default ShiftPlanPage;