// vehicleStatusUtils.js
// Utility-Funktionen für die Anzeige von Fahrzeugstatus

/**
 * Ermittelt Farbe und Bezeichnung für einen gegebenen Fahrzeugstatus
 * @param {string} status - Der Status des Fahrzeugs (active, available, inactive, maintenance)
 * @returns {Object} Ein Objekt mit color (Textfarbe), bg (Hintergrundfarbe) und text (Bezeichnung)
 */
export const getVehicleStatusInfo = (status) => {
    switch (status) {
        case 'active':
        case 'available':
            return {
                color: '#4caf50', // Grün
                bg: 'transparent',    // Hellgrau
                text: 'Verfügbar'
            };
        case 'inactive':
            return {
                color: '#f44336', // Rot
                bg: '#ffebee',    // Helles Rosa
                text: 'Außer Dienst'
            };
        case 'maintenance':
            return {
                color: '#ff9800', // Orange
                bg: '#fff8e1',    // Helles Gelb
                text: 'Wartung'
            };
        default:
            return {
                color: '#9e9e9e', // Grau
                bg: 'transparent', // Transparent statt Hintergrundfarbe
                text: 'Unbekannt'
            };
    }
};

// Exportiere weitere Fahrzeug-bezogene Utility-Funktionen bei Bedarf