import React, { useState, useEffect } from 'react';
import {
    Typography, Button, Alert, Box, TextField, InputAdornment, IconButton, CircularProgress,
    Stack, DialogContent, DialogContentText, Tooltip,
    DialogTitle, Dialog, DialogActions, FormControlLabel, Switch
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useUser } from '../components/userContext';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AddressCell from '../components/AddressCell';
import StatusCell from '../components/StatusCell';
import HistoryIcon from '@mui/icons-material/History';
import PersistentDataGrid from '../components/PersistentDataGrid';
import HistoryEntryCard from '../components/HistoryEntryCard'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { renewToken } from '../misc/helperFunctions';
const DateTimeHelper = require('ktw-shared');

dayjs.extend(utc);

const ConfirmationDialog = ({ open, onClose, onConfirm, title, description, readonly, linkedTransportId }) => {
    const [reason, setReason] = useState('');
    const [doDeleteLinkedItem, setDoDeleteLinkedItem] = useState('');

    const handleReasonChange = (event) => {
        setReason(event.target.value);
    };

    const handleLinkedItemChange = (event) => {
        setDoDeleteLinkedItem(event.target.checked);
    };

    const handleConfirm = () => {
        onConfirm(true, reason, doDeleteLinkedItem);
        setReason('');
    };

    const isStorno = title === 'Stornieren';

    return (
        <Dialog open={open} onClose={() => onClose(false)}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{description}</DialogContentText>
                {linkedTransportId && (
                    <FormControlLabel
                        control={<Switch
                            checked={doDeleteLinkedItem}
                            onChange={handleLinkedItemChange}
                        />}
                        label="Zugehörige Hin- oder Rückfahrt ebenfalls stornieren?"
                        labelPlacement="start"
                        sx={{ marginLeft: 0 }}
                    />
                )}
                {isStorno && (
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Storno-Grund"
                        type="text"
                        fullWidth
                        value={reason}
                        onChange={handleReasonChange}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(false)}>Abbrechen</Button>
                <Button onClick={handleConfirm} color="primary" disabled={readonly || (isStorno && (reason.trim() === ''))} >Ja</Button>
            </DialogActions>
        </Dialog>
    );
};

const HeadOfSeriesList = () => {
    const { user } = useUser();
    const navigate = useNavigate();

    const [transports, setTransports] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [serverError, setServerError] = useState(null);

    const [selectedTransport, setSelectedTransport] = useState(null);
    const [selectedSeriesIdentifier, setSelectedSeriesIdentifier] = useState(null);
    const [dialogAction, setDialogAction] = useState(null);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogDescription, setDialogDescription] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);

    const [openSeriesDialog, setOpenSeriesDialog] = useState(false);
    const [seriesData, setSeriesData] = useState([]);

    const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    const [showHelp, setShowHelp] = useState(false);
    const handleToggleHelp = () => {
        setShowHelp((prev) => !prev);
    };

    useEffect(() => {
        fetchHeadOfSeries();
        // eslint-disable-next-line
    }, []);

    const fetchHeadOfSeries = async () => {
        try {
            setIsLoading(true);
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/transports/hos`, {
                headers: {
                    'Authorization': user.api_key,
                },
            });

            if (!response.ok) {
                throw new Error('Error fetching HeadOfSeries');
            }

            const data = await response.json();
            setTransports(data);
            await renewToken();
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching HeadOfSeries:', error);
            setServerError(error.message);
            setIsLoading(false);
        }
    };

    const handleSearch = (event) => {
        const newSearchQuery = event.target.value.toLowerCase();
        setSearchQuery(newSearchQuery);
    };

    const handleClearSearch = () => {
        setSearchQuery('');
    };

    const filteredHeadOfSeries = transports.filter((hos) =>
        (hos.transport_type && hos.transport_type.toLowerCase().includes(searchQuery)) ||
        (hos.from_institution && hos.from_institution.toLowerCase().includes(searchQuery)) ||
        (hos.from_street && hos.from_street.toLowerCase().includes(searchQuery)) ||
        (hos.from_postal_code && hos.from_postal_code.toLowerCase().includes(searchQuery)) ||
        (hos.from_city && hos.from_city.toLowerCase().includes(searchQuery)) ||
        (hos.to_institution && hos.to_institution.toLowerCase().includes(searchQuery)) ||
        (hos.to_street && hos.to_street.toLowerCase().includes(searchQuery)) ||
        (hos.to_postal_code && hos.to_postal_code.toLowerCase().includes(searchQuery)) ||
        (hos.to_city && hos.to_city.toLowerCase().includes(searchQuery)) ||
        (hos.first_name && hos.last_name && (`${hos.first_name} ${hos.last_name}`).toLowerCase().includes(searchQuery))
    );

    const handleConfirmation = async (confirm, reason, doDeleteLinkedItem) => {
        setDialogOpen(false);

        if (confirm) {
            try {
                switch (dialogAction) {
                    case 'löschen_serie':
                        await deleteTransportSeries(selectedSeriesIdentifier, reason);
                        break;

                    case 'löschen':
                        await deleteTransport(selectedTransport, reason);
                        break;

                    default:
                        break;
                }

                setServerError(null);
                fetchHeadOfSeries();

            } catch (error) {
                console.error('Error performing action:', error);
                setServerError(error.message);
            }
        }

        setSelectedSeriesIdentifier(null);
        setDialogAction(null);
    };

    const deleteTransport = async (transportId, reason) => {
        const response = await fetch(`${process.env.REACT_APP_API_URI}/api/transports/${transportId}/cancel`, {
            method: 'PUT',
            headers: {
                'Authorization': user.api_key,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ cancellation_reason: reason }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Fehler beim Löschen des Transports.');
        }

        await handleShowSeries(selectedSeriesIdentifier);
    };

    const deleteTransportSeries = async (seriesIdentifier, reason) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/transports/cancel_series/${seriesIdentifier}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': user.api_key,
                },
                body: JSON.stringify({ reason }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Fehler beim Stornieren der Transport-Serie.');
            }

            console.log('Transport-Serie erfolgreich storniert.');
        } catch (error) {
            console.error('Error deleting transport series:', error.message);
        }
    };

    // Ruft die Historie vom Server ab und öffnet das Modal
    const handleShowHistory = async (transportId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/transports/${transportId}/history`, {
                headers: {
                    'Authorization': user.api_key,
                }
            });
            if (!response.ok) {
                throw new Error('Fehler beim Abrufen der Historie');
            }
            const data = await response.json();
            setHistoryData(data);
            setOpenHistoryDialog(true);
        } catch (error) {
            console.error('Fehler bei handleShowHistory:', error);
            setServerError(error.message);
        }
    };

    const handleCloseHistory = () => {
        setOpenHistoryDialog(false);
    };

    const handleEdit = (id) => {
        navigate(`/transport/${id}`);
    };

    const handleEditClick = (transport) => {
        setServerError(null);
        handleEdit(transport.transport_id);
    };

    const handleDelete = (transport_id) => {
        setSelectedTransport(transport_id);
        setDialogAction('löschen');
        setDialogTitle('Stornieren');
        setDialogDescription(`Möchten Sie diesen Transport stornieren?`);
        setDialogOpen(true);
    };

    const handleDeleteSeries = (seriesIdentifier) => {
        setSelectedSeriesIdentifier(seriesIdentifier);
        setDialogAction('löschen_serie');
        setDialogTitle('Stornieren');
        setDialogDescription(`Möchten Sie die gesamte Serie stornieren?`);
        setDialogOpen(true);
    };

    const handleShowSeries = async (seriesIdentifier) => {
        setServerError(null);
        setSelectedSeriesIdentifier(seriesIdentifier);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/transports/series/${seriesIdentifier}`, {
                headers: {
                    'Authorization': user.api_key,
                },
            });

            // Überprüfen, ob die Antwort nicht OK ist
            if (!response.ok) {
                // Wenn der Status 404 ist, setzen Sie die Fehlermeldung
                if (response.status === 404) {
                    throw new Error('Keine Transporte zu dieser Serie gefunden. Das automatische Generieren von Transporten kann einige Minuten dauern...');
                } else {
                    // Für andere Fehler eine allgemeine Fehlermeldung werfen
                    throw new Error('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
                }
            }

            const data = await response.json();
            setSeriesData(data);
            setOpenSeriesDialog(true);
        } catch (error) {
            console.error('Error fetching series transports:', error);
            setServerError(error.message);
        }
    };

    const lastEntry = historyData.reduce((acc, cur) => {
        return new Date(cur.modified_date) > new Date(acc.modified_date) ? cur : acc;
    }, historyData[0]);

    const columns = [
        {
            field: 'transport_type',
            headerName: 'Transport Art',
            width: 130,
            minWidth: 100,
            maxWidth: 200,
            renderCell: (params) => (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body2">
                        {params.row.transport_type}
                    </Typography>
                    {params.row.ambulatory && (
                        <Typography variant="caption" style={{ fontStyle: 'italic', color: 'gray' }}>
                            {params.row.return_trip ? 'Ambulant Rückfahrt' : 'Ambulant Hinfahrt'}
                        </Typography>
                    )}
                </div>
            ),
        },
        {
            field: 'recurring_days',
            headerName: 'Regelmäßige Fahrten',
            width: 350,
            minWidth: 100,
            maxWidth: 600,
            renderCell: (params) => {
                const {
                    recurring_days = {},
                    recurring_times = {},
                    recurring_return_days = {},
                    recurring_return_times = {}
                } = params.row;

                // Falls valid_from / valid_to im ISO-Format "YYYY-MM-DD" abgespeichert sind:
                const validFrom = recurring_days?.valid_from;
                const validTo = recurring_days?.valid_to;
                // Neue Felder für Pausen
                const breakFrom = recurring_days?.break_from;
                const breakTo = recurring_days?.break_to;

                const validFromDe = DateTimeHelper.formatDateOnly(validFrom);
                const validToDe = DateTimeHelper.formatDateOnly(validTo);
                const breakFromDe = DateTimeHelper.formatDateOnly(breakFrom);
                const breakToDe = DateTimeHelper.formatDateOnly(breakTo);

                const weekdaysOrder = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];

                // Nur Wochentage herausfiltern; 'valid_from'/'valid_to' und 'break_from'/'break_to' ignorieren!
                const formatDaysAndTimes = (days, times) => {
                    if (!days || typeof days !== 'object') return '';

                    // Nur Keys mit true und ohne Gültigkeits-/Pausenfelder
                    const sortedDays = Object.keys(days)
                        .filter((day) =>
                            day !== 'valid_from' &&
                            day !== 'valid_to' &&
                            day !== 'break_from' &&
                            day !== 'break_to' &&
                            days[day]
                        )
                        .sort(
                            (a, b) => weekdaysOrder.indexOf(a) - weekdaysOrder.indexOf(b)
                        );

                    // Jede dritte Eintragung bekommt einen Zeilenumbruch
                    return sortedDays
                        .map((day, index) => {
                            if (index > 0 && index % 3 === 0) {
                                return `<br />${day}: ${times[day] || 'Keine Zeit'}`;
                            }
                            return `${day}: ${times[day] || 'Keine Zeit'}`;
                        })
                        .join(', ');
                };

                const hinFahrt = formatDaysAndTimes(recurring_days, recurring_times);
                const rueckFahrt = formatDaysAndTimes(recurring_return_days, recurring_return_times);

                return (
                    <div>
                        {/* Gültigkeitszeitraum anzeigen */}
                        {(validFrom || validTo || breakFrom || breakTo) && (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                {validFrom && (
                                    <div>Gültig von: {validFromDe}</div>
                                )}
                                {validTo && (
                                    <div>Gültig bis: {validToDe}</div>
                                )}
                                {breakFrom && breakTo && (
                                    <div>Pause: {breakFromDe} - {breakToDe}</div>
                                )}
                            </Typography>
                        )}

                        {/* Hinfahrten */}
                        {hinFahrt && (
                            <Typography variant="body2">
                                <strong>Hinfahrt:</strong>{' '}
                                <span dangerouslySetInnerHTML={{ __html: hinFahrt }} />
                            </Typography>
                        )}

                        {/* Rückfahrten */}
                        {rueckFahrt && (
                            <Typography variant="body2">
                                <strong>Rückfahrt:</strong>{' '}
                                <span dangerouslySetInnerHTML={{ __html: rueckFahrt }} />
                            </Typography>
                        )}

                        {/* Nichts gesetzt? */}
                        {!hinFahrt && !rueckFahrt && !validFrom && !validTo && !breakFrom && !breakTo && (
                            <Typography
                                variant="body2"
                                style={{ fontStyle: 'italic', color: 'gray' }}
                            >
                                Keine regelmäßigen Tage
                            </Typography>
                        )}
                    </div>
                );
            },
        },
        {
            field: 'from_address',
            headerName: 'Von',
            width: 260,
            renderCell: (params) => (
                <AddressCell
                    address={{
                        institution: params.row.from_institution,
                        station: params.row.from_station,
                        street: params.row.from_street,
                        houseNumber: params.row.from_house_number,
                        postalCode: params.row.from_postal_code,
                        city: params.row.from_city,
                    }}
                />
            ),
        },
        {
            field: 'to_address',
            headerName: 'Nach',
            width: 260,
            renderCell: (params) => (
                <AddressCell
                    address={{
                        institution: params.row.to_institution,
                        station: params.row.to_station,
                        street: params.row.to_street,
                        houseNumber: params.row.to_house_number,
                        postalCode: params.row.to_postal_code,
                        city: params.row.to_city,
                    }}
                />
            ),
        },
        {
            field: 'patient_name',
            headerName: 'Patient',
            width: 140,
            renderCell: (params) => (
                <Typography variant="body2">
                    {`${params.row.first_name} ${params.row.last_name}`}
                </Typography>
            ),
        },
        {
            field: 'actions2',
            type: 'actions',
            headerName: 'Aktionen',
            flex: 1,
            //width: 300,
            getActions: (params) => {
                const actions = [
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Bearbeiten" arrow>
                                <Box
                                    component="div" // Verwenden Sie ein div anstelle eines Buttons
                                    onClick={() => handleEditClick(params.row)}
                                    disabled={params.row.status === 'finished' || params.row.status === 'canceled'}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '4px 8px',
                                        cursor: 'pointer',
                                        opacity: params.row.status === 'finished' || params.row.status === 'canceled' ? 0.5 : 1,
                                        pointerEvents: params.row.status === 'finished' || params.row.status === 'canceled' ? 'none' : 'auto',
                                    }}
                                >
                                    <EditIcon sx={{ marginRight: '8px' }} />
                                    <Typography variant="body2" sx={{ fontSize: '0.775rem' }}>
                                        Bearbeiten
                                    </Typography>
                                </Box>
                            </Tooltip>
                        }
                        label="Bearbeiten"
                    />
                ];
                if (params.row.is_recurring || params.row.is_recurring_return) {
                    actions.push(
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Serie regelmäßiger Transporte stornieren"
                            onClick={() => handleDeleteSeries(params.row.series_identifier)}
                            showInMenu
                            disabled={params.row.status === 'in_transit' || params.row.status === 'finished' || params.row.status === 'canceled'}
                        />,
                        <GridActionsCellItem
                            icon={
                                <Tooltip title="Fahrten anzeigen" arrow>
                                    <Box
                                        component="div"
                                        onClick={() => handleShowSeries(params.row.transport_id)}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '4px 8px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <CalendarMonthIcon sx={{ marginRight: '8px' }} />
                                        <Typography variant="body2" sx={{ fontSize: '0.775rem' }}>
                                            Fahrten anzeigen
                                        </Typography>
                                    </Box>
                                </Tooltip>
                            }
                            label="Alle Transporte der Serie anzeigen"
                        />,
                        <GridActionsCellItem
                            icon={<HistoryIcon />}
                            label="Historie anzeigen"
                            onClick={() => handleShowHistory(params.row.transport_id)}
                            showInMenu
                        />
                    );
                }
                return actions;
            },
        },
        {
            field: 'create_date',
            headerName: 'Erstellt am/von',
            width: 180,
            renderCell: (params) => {
                const createDate = new Date(params.row.create_date);

                const formattedDate = createDate.toLocaleDateString('de-DE', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                });

                const formattedTime = createDate.toLocaleTimeString('de-DE', {
                    hour: '2-digit',
                    minute: '2-digit',
                });

                const formattedDateTime = `${formattedDate} ${formattedTime}`;

                return (
                    <div>
                        {formattedDateTime}
                        <div style={{ fontStyle: 'italic', fontSize: '12px', color: 'gray' }}>
                            {params.row.username}
                        </div>
                        <div style={{ fontStyle: 'italic', fontSize: '12px', color: 'gray' }}>
                            {params.row.object_institution}
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <Box m={1}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                <Stack justifyItems="flex-start" alignItems="center" direction="row">
                    <Typography variant="h5" color="#E46450" component="h1" gutterBottom mt={1}>
                        Regelmäßige Transporte
                    </Typography>

                    {isLoading && (
                        <CircularProgress color="inherit" />
                    )}
                    {!isLoading && (
                        <IconButton ml={2} size="large" aria-label="refresh" onClick={fetchHeadOfSeries}>
                            <RefreshIcon fontSize="large" />
                        </IconButton>
                    )}
                </Stack>

                <TextField
                    id="search"
                    label="Schnellsuche"
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={handleSearch}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {searchQuery && (
                                    <IconButton
                                        aria-label="Clear search"
                                        size="small"
                                        onClick={handleClearSearch}
                                        edge="end"
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                )}
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        width: '300px',
                    }}
                />
                <Button variant="outlined" color="primary" onClick={() => navigate('/transport')} startIcon={<AddIcon />}>
                    Neuen Transport bestellen
                </Button>
            </Box>
            {serverError && <Alert severity="error" sx={{ my: 2 }}>{serverError}</Alert>}

            <div style={{ width: '100%' }}>
                <PersistentDataGrid
                    storageKey="transportListForSeries"
                    rows={filteredHeadOfSeries}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5, 10, 20]}
                    getRowId={(row) => row.transport_id}
                    getRowHeight={() => 'auto'}
                    sx={{
                        '& .MuiDataGrid-cell': {
                            whiteSpace: 'normal',
                            lineHeight: '1.5',
                        },
                    }}
                />
            </div>
            {(selectedSeriesIdentifier) && (
                <ConfirmationDialog
                    open={dialogOpen}
                    onClose={handleConfirmation}
                    onConfirm={handleConfirmation}
                    title={dialogTitle}
                    description={dialogDescription}
                />
            )}
            <Dialog
                open={openSeriesDialog}
                onClose={() => setOpenSeriesDialog(false)}
                aria-labelledby="series-dialog-title"
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle id="series-dialog-title">Anstehende Transporte dieser Serie</DialogTitle>
                <DialogContent>
                    {seriesData.length === 0 ? (
                        <Typography variant="body2">Keine Transporte gefunden.</Typography>
                    ) : (
                        <Box>
                            <Typography variant="h6">
                                Patient: {seriesData[0].patient_name || `${seriesData[0].first_name} ${seriesData[0].last_name}`}
                            </Typography>
                            <div style={{ width: '100%', marginTop: '16px' }}>
                                <DataGrid
                                    rows={seriesData.map((row, index) => ({
                                        ...row,
                                        id: row.transport_id,
                                        departure_date: DateTimeHelper.formatDateOnly(row.departure_date),
                                        departure_time: row.departure_time.substring(0, 5), // Nur die ersten 5 Zeichen (HH:mm)
                                    }))}
                                    columns={[
                                        { field: 'transport_type', headerName: 'Transport Art', width: 120 },
                                        {
                                            field: 'status',
                                            headerName: 'Status',
                                            width: 150,
                                            renderCell: (params) => (
                                                <StatusCell status={params.row.status} />
                                            ),
                                        },
                                        { field: 'departure_date', headerName: 'Datum', width: 120 },
                                        { field: 'departure_time', headerName: 'Zeit', width: 120 },
                                        {
                                            field: 'from_address',
                                            headerName: 'Von',
                                            width: 240,
                                            renderCell: (params) => (
                                                <AddressCell
                                                    address={{
                                                        institution: params.row.from_institution,
                                                        station: params.row.from_station,
                                                        street: params.row.from_street,
                                                        houseNumber: params.row.from_house_number,
                                                        postalCode: params.row.from_postal_code,
                                                        city: params.row.from_city,
                                                    }}
                                                />
                                            ),
                                        },
                                        {
                                            field: 'to_address',
                                            headerName: 'Nach',
                                            width: 240,
                                            renderCell: (params) => (
                                                <AddressCell
                                                    address={{
                                                        institution: params.row.to_institution,
                                                        station: params.row.to_station,
                                                        street: params.row.to_street,
                                                        houseNumber: params.row.to_house_number,
                                                        postalCode: params.row.to_postal_code,
                                                        city: params.row.to_city,
                                                    }}
                                                />
                                            ),
                                        },
                                        {
                                            field: 'return_trip',
                                            headerName: 'Rück.',
                                            width: 40,
                                            renderCell: (params) => (
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                    {params.row.return_trip ? <KeyboardReturnIcon /> : null}
                                                </div>
                                            ),
                                        },
                                        {
                                            field: 'actions2',
                                            type: 'actions',
                                            headerName: 'Aktionen',
                                            flex: 1,
                                            //width: 300,
                                            getActions: (params) => {
                                                const actions = [
                                                    <GridActionsCellItem
                                                        icon={<EditIcon />}
                                                        label="Bearbeiten"
                                                        onClick={() => handleEditClick(params.row)}
                                                        disabled={params.row.status === 'finished' || params.row.status === 'canceled'}
                                                    />,
                                                    <GridActionsCellItem
                                                        icon={<DeleteIcon />}
                                                        label="Stornieren"
                                                        onClick={() => handleDelete(params.row.transport_id)}
                                                        showInMenu
                                                        disabled={params.row.status === 'finished' || params.row.status === 'canceled'}
                                                    />,
                                                ];

                                                return actions;
                                            },
                                        },
                                    ]}
                                    pageSize={5}
                                    rowsPerPageOptions={[5, 10, 20]}
                                    getRowHeight={() => 'auto'}
                                />
                            </div>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between" // Verteilt den Platz zwischen den Elementen
                        width="100%"
                    >
                        {seriesData.length > 0 && (
                            <Typography variant="caption" sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                                Series Id: {seriesData[0].series_identifier}
                            </Typography>
                        )}
                        <Button onClick={() => setOpenSeriesDialog(false)} color="primary">
                            Schließen
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openHistoryDialog}
                onClose={handleCloseHistory}
                maxWidth="md"
                fullWidth
            >
                {/* HEADER mit Titel und Info-Button */}
                <Box display="flex" alignItems="center" justifyContent="space-between" px={2} pt={2}>
                    <Box display="flex" alignItems="center">
                        <DialogTitle sx={{ p: 0, mr: 2 }}>
                            Historie für regelmäßigen Transport
                        </DialogTitle>

                        {lastEntry && (
                            <Typography variant="caption" sx={{ color: 'gray' }}>
                                {/* Beispiel-Formatierung: DD.MM.YYYY, HH:mm:ss */}
                                Letzte Änderung: {lastEntry.changed_by_user} am{' '}
                                {new Date(lastEntry.modified_date).toLocaleString('de-DE')}
                            </Typography>
                        )}
                    </Box>
                    <IconButton onClick={handleToggleHelp} aria-label="Info anzeigen" sx={{ marginLeft: 'auto' }}>
                        <InfoIcon />
                    </IconButton>
                </Box>

                <DialogContent dividers>
                    {/* Hilfe-Text einblenden, falls showHelp = true */}
                    {showHelp && (
                        <Box mb={2} p={2} border="1px solid #eee" borderRadius="4px" bgcolor="#f5f5f5">
                            <Typography variant="subtitle2" gutterBottom>
                                Erfassung der Änderungen (Historisierung)
                            </Typography>
                            <Typography variant="body2" paragraph>
                                Wir speichern automatisch nur Änderungen an den folgenden Feldern:
                            </Typography>
                            <ul style={{ marginTop: 0, marginBottom: '8px' }}>
                                <li><strong>status</strong> : Status des Transports</li>
                                <li><strong>transport_type</strong> : Transport Art</li>
                                <li><strong>departure_date</strong> : Transport Datum</li>
                                <li><strong>departure_time</strong> : Transport Datum</li>
                                <li><strong>from_address</strong> : Von Adresse</li>
                                <li><strong>to_address</strong> : Nach Adresse</li>
                                <li><strong>transport_mode</strong> : Transport Art (liegend / sitzend)</li>
                                <li><strong>self_payer</strong> : Selbstzahler</li>
                                <li><strong>ambulatory</strong> : Ambulant</li>
                                <li><strong>export_status</strong> : Export Status, z.B. Opheo Export</li>
                            </ul>
                            <Typography variant="body2">
                                Andere Felder (z.B. patient_id, phone_number etc.) werden derzeit <em>nicht</em> historisiert.
                                Bei Fragen wenden Sie sich bitte an die Projektgruppe.
                            </Typography>
                        </Box>
                    )}

                    {/* Hier die eigentliche Anzeige der History-Einträge */}
                    {historyData.length === 0 ? (
                        <Typography variant="body2">Keine Historie-Einträge vorhanden.</Typography>
                    ) : (
                        historyData.map((entry) => (
                            /* render dein HistoryEntryCard o.Ä. */
                            <HistoryEntryCard key={entry.historie_id} entry={entry} />
                        ))
                    )}
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseHistory}>Schließen</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default HeadOfSeriesList;