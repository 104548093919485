import React, { useState } from 'react';
import { Menu, MenuItem, IconButton, Switch, ListItemText, ListItemIcon, Tooltip } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

const SettingsMenu = ({ isAutoRefreshEnabled, toggleAutoRefresh }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleToggleAutoRefresh = () => {
        toggleAutoRefresh();
        handleMenuClose();
    };

    return (
        <>
            <Tooltip title="Einstellungen">
                <IconButton
                    onClick={handleMenuOpen}
                    aria-label="Einstellungen"
                    sx={{
                        backgroundColor: '#f0f0f0',
                        '&:hover': { backgroundColor: '#e0e0e0' }
                    }}
                >
                    <SettingsIcon />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleToggleAutoRefresh}>
                    <ListItemIcon>
                        <Switch
                            checked={isAutoRefreshEnabled}
                            edge="end"
                            size="small"
                            color="primary"
                        />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{ style: { marginLeft: '16px' } }}
                    >
                        Autorefresh {isAutoRefreshEnabled ? 'aktiv' : 'inaktiv'}
                    </ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
};

export default SettingsMenu;