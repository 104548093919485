import React, { useEffect, useState } from 'react';
import {
    Container,
    Typography,
    Stepper,
    Step,
    StepLabel,
    Button,
    Divider,
    Box,
    Grid,
    Alert,
    List,
    ListItem,
    Modal,
    CircularProgress,
} from '@mui/material';

import ReactMarkdown from 'react-markdown';
//import remarkGfm from 'remark-gfm'; // Optional: Für erweiterte Markdown-Syntax
import { getValidationErrors } from '../utils/validationLogic';

import 'dayjs/locale/de'; // Deutsch für Datepicker
import PatientStep from './transportPatientStep';
import UserInformationStep from './transportUserStep';
import WhenExpertStep from './transportWhenExpertStep';
import FromToStep from './transportFromToStep';
import TransportDetailsStep from './transportDetailsStep';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../components/userContext';
import { useConfig } from '../components/configContext';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import utc from 'dayjs/plugin/utc'; // import plugin
import ToStep from './transportToStep';
import SeriesChangeDialog from './SeriesChangeDialog';
dayjs.extend(utc); // use plugin



const ErrorModal = ({ open, onClose, stepErrors }) => {
    const stepTitles = [
        'Angaben zum Besteller',
        'Angaben zum Patienten',
        'Wann soll der Transport durchgeführt werden?',
        'Start- und Zieladresse',
        'Details zum Transport',
    ];

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="error-modal-title">
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '900px',
                    maxWidth: '90vw',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}
            >
                <Typography
                    id="error-modal-title"
                    variant="h6"
                    gutterBottom
                    sx={{
                        color: '#E46450',
                        fontWeight: 'bold',
                    }}
                >
                    Validierungsmeldungen
                </Typography>
                {stepErrors.map((errors, stepIndex) => (
                    errors.length > 0 && (
                        <Box key={stepIndex} sx={{ marginBottom: 2 }}>
                            <Typography
                                variant="subtitle1"
                                gutterBottom
                                sx={{
                                    color: '#E46450',
                                    fontWeight: 'bold',
                                }}
                            >
                                {stepTitles[stepIndex]}:
                            </Typography>
                            <List
                                dense
                                sx={{
                                    padding: 0,
                                    margin: 0, // Entfernt Abstände der Liste selbst
                                }}
                            >
                                {errors.map((error, index) => (
                                    <ListItem
                                        key={index}
                                        sx={{
                                            padding: '4px 0', // Minimale vertikale Abstände
                                            margin: 0, // Kein zusätzlicher Rand
                                        }}
                                    >
                                        <ReactMarkdown
                                            // remarkPlugins={[remarkGfm]}
                                            components={{
                                                p: ({ node, children }) => (
                                                    <span style={{ margin: 0 }}>{children}</span>
                                                ),
                                            }}
                                        >
                                            {error}
                                        </ReactMarkdown>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    )
                ))}
                <Box textAlign="right">
                    <Button onClick={onClose} variant="contained" color="primary">
                        Schließen
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

const SummaryStep = ({ formData }) => {
    return (
        <Box mt={4} mb={2} pt={12} pl={4} pr={4} pb={4} style={{
            background: 'linear-gradient(to bottom, #FFFFFF, #F5F5F5)', borderRadius: '10px', width: '100%', border: '1px solid #F5F5F5', position: 'relative', zIndex: 1,
        }}>
            <Typography variant="h6">Zusammenfassung</Typography>
            <Typography variant="body1">
                <span style={{ fontWeight: 'bold' }}>Bestellung eines Krankentransportwagens</span>
                <br />
                <br />
                Sie bestellen einen KTW für den <span style={{ fontWeight: 'bold' }}>{formData.departureDateTime?.format('DD.MM.YYYY')}</span> um <span style={{ fontWeight: 'bold' }}>{formData.departureDateTime?.format('HH:mm')} Uhr </span> für folgenden Patienten:
                <br />
                <br />
                <span style={{ fontWeight: 'bold' }}>
                    {formData.firstName} {formData.lastName}, Geburtsdatum: {formData.dateOfBirth?.format('DD.MM.YYYY') || ''}
                </span>
                <br />
                <br />
                Der Transport startet in{' '}
                <span style={{ fontWeight: 'bold' }}>
                    {`${formData.from_street} ${formData.from_house_number}, ${formData.from_postal_code} ${formData.from_city}`}
                </span>{' '}
                und fährt{' '}
                <span style={{ fontWeight: 'bold' }}>
                    {`${formData.to_street} ${formData.to_house_number}, ${formData.to_postal_code} ${formData.to_city}`}
                </span>.
                <br />
                <br />

                Folgende Details wurden zum Transport eingegeben: <span style={{ fontWeight: 'bold' }}>{formData.transportDetails}</span>
                <br />
            </Typography>
            {/* Liste außerhalb des <p>-Elements */}
            <ul style={{ marginTop: '8px', marginBottom: '16px', paddingLeft: '24px' }}>
                {formData.transport_mode && <li>Transportart: {formData.transport_mode === 'sitzend' ? 'Sitzend' : 'Liegend'}</li>}
                {formData.own_wheelchair !== undefined && <li>Eigener Rollstuhl vorhanden: {formData.own_wheelchair ? 'Ja' : 'Nein'}</li>}
                {formData.own_wheelchair === true && formData.folding !== undefined && <li>Rollstuhl klappbar: {formData.folding ? 'Ja' : 'Nein'}</li>}
                {formData.luggage !== undefined && <li>Gepäck vorhanden: {formData.luggage ? 'Ja' : 'Nein'}</li>}
                {formData.companion !== undefined && <li>Begleitperson: {formData.companion ? 'Ja' : 'Nein'}</li>}
                {formData.oxygen_required !== undefined && <li>Sauerstoffpflichtig: {formData.oxygen_required ? 'Ja' : 'Nein'}</li>}
                {formData.oxygen_required === true && formData.oxygen_source !== undefined && <li>Sauerstoff: {formData.oxygen_source === 'ab_patient' ? 'Ab Patient' : 'Ab Fahrzeug'}</li>}
                {formData.prescription_present !== undefined && <li>Liegt eine gültige Verordnung vor: {formData.prescription_present ? 'Ja' : 'Nein'}</li>}
                {formData.transport_mode === 'sitzend' && formData.insurance_approved !== undefined && <li>Krankenkassengenehmigung: {formData.insurance_approved ? 'Ja' : 'Nein'}</li>}
                {formData.transport_mode === 'sitzend' && formData.insurance_approved === false && formData.confirm_insurance_approved !== undefined && <li>Kostenübernahme akzeptiert (Selbstzahler): {formData.confirm_insurance_approved ? 'Ja' : 'Nein'}</li>}
            </ul>
            <Typography variant="body1">
                Bitte bestellen Sie den Transport mit dem Klick auf "Absenden". Sie erhalten dann umgehend eine Bestätigung.
            </Typography>
        </Box>
    );
};

const TransportFormPage = () => {
    const { user } = useUser(); // Anbemeldeter User
    // eslint-disable-next-line
    const { getConfigValue, isLoading: isConfigLoading } = useConfig();
    // FIXME: modBookingSystem nur für admins -> für Testphase BOS
    // nach Freischaltung für alle User entfernen
    const modBookingSystem = getConfigValue('mod_booking_system', false) && user?.role === 'admin';
    const steps = ['Besteller', 'Patient', 'Von/Nach', 'Wann', 'Transport'];
    const [activeStep, setActiveStep] = useState(0);
    const [fromToActiveTab, setFromToActiveTab] = useState(0);
    const [formData, setFormData] = useState({
        initial_trip: true,
        mod_booking_system: modBookingSystem,
        // is_recurring: true,
        ...{},
    });
    const [stepErrors, setStepErrors] = useState(Array(steps.length).fill(false));
    const navigate = useNavigate();
    const [showSummary, setShowSummary] = useState(false);
    const { id, copy } = useParams(); // ID aus der URL holen. Transport zum Bearbeiten

    // Logik für den "copy"-Parameter
    const isCopyMode = copy === 'copy'; // Prüft, ob der copy-Parameter auf 'copy' gesetzt ist

    // eslint-disable-next-line
    const [isEditMode, setIsEditMode] = useState(id != null && !isCopyMode);
    const allowedRoles = ['user', 'dialysis'];
    const [isExpertMode, setIsExpertMode] = useState(false);
    const [showResolutionWarning, setShowResolutionWarning] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openErrorModal = () => setIsModalOpen(true);
    const closeErrorModal = () => setIsModalOpen(false);

    const hasErrors = stepErrors.some((errors) => errors.length > 0);

    const [isTransportLoaded, setIsTransportLoaded] = useState(false);
    const [isUserSettingsLoaded, setIsUserSettingsLoaded] = useState(false);

    // Ladezustand für die gesamte Seite
    const isLoading = !isUserSettingsLoaded || (id != null && !isTransportLoaded);

    const [timeSettings, setTimeSettings] = useState(null);

    const [seriesChangeDialogOpen, setSeriesChangeDialogOpen] = useState(false);
    const [preparedApiData, setPreparedApiData] = useState(null);

    useEffect(() => {
        const fetchUsersInstitution = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URI}/api/institutions/${user?.institutionId}`, {
                    headers: {
                        'Authorization': user.api_key,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log('useEffect user');
                    console.log(data);

                    // Straße und Hausnummer aufteilen
                    const streetAndNumber = data.street_and_number;
                    const lastSpaceIndex = streetAndNumber.lastIndexOf(' ');

                    const street = streetAndNumber.substring(0, lastSpaceIndex).trim();
                    const houseNumber = streetAndNumber.substring(lastSpaceIndex + 1).trim();

                    // Hier baust du dein JSON analog zur Backend-Logik zusammen
                    const institution_json = {
                        institution_id: data.institution_id,
                        object_institution: data.object_institution,
                        station: data.station,
                        street_and_number: data.street_and_number,
                        postal_code: data.postal_code,
                        city: data.city,
                        phone_number: data.phone_number,
                    };


                    const transformedFormData = {
                        // Füge die API-Daten in das formData-Objekt ein
                        user_id: data.user_id,
                        institution_id: data.institution_id,
                        institution: institution_json,
                        institution_json: institution_json,
                        objectInstitution: data.object_institution,
                        stationName: data.station,
                        phoneNumber: data.phone_number,
                        org_from_institution: data,
                        org_from_station: data.station,
                        org_from_street: street,
                        org_from_house_number: houseNumber,
                        org_from_postal_code: data.postal_code,
                        org_from_city: data.city
                    };
                    setFormData(transformedFormData);
                    //console.log(transformedFormData);
                    //setActiveStep(1);
                } else {
                    const errorData = await response.json();
                    throw new Error(errorData.message);
                }
            } catch (error) {
                console.error('Fehler beim Abrufen des Transports:', error.message);
            }
        };

        if (user && isEditMode === false) {
            // Übernahme von Daten nur für normale User
            if (allowedRoles.includes(user.role)) {
                fetchUsersInstitution();
            }
        }
        // eslint-disable-next-line
    }, [user]);

    const checkIfHeadOfSeries = (date) => {
        const DEFAULT_THRESHOLD_DATE = dayjs('2090-01-01');
        const departureDate = dayjs(date);
        return departureDate.isAfter(DEFAULT_THRESHOLD_DATE, 'day');
    };

    useEffect(() => {
        const fetchTransport = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URI}/api/transport/${id}`, {
                    headers: {
                        'Authorization': user.api_key,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log('useEffect id');
                    console.log(data);

                    // Wenn wir im Copy-Modus sind, setzen wir bestimmte Felder zurück
                    if (isCopyMode) {
                        data.transport_id = null; // Transport-ID zurücksetzen
                        data.departure_date = null; // Abfahrtsdatum zurücksetzen
                        data.departure_time = null; // Abfahrtszeit zurücksetzen
                        data.status = 'pending'; // Status auf "pending" setzen
                        data.is_recurring = null;
                        data.recurring_days = null;
                        data.recurring_times = null;
                        data.is_recurring_return = null;
                        data.recurring_return_days = null;
                        data.recurring_return_times = null;
                        data.user_id = null;
                    }

                    const isHead = checkIfHeadOfSeries(data.departure_date);
                    console.log('TransportFormPage: isHead', isHead);
                    data.isHeadOfSeries = isHead;

                    const datePart = dayjs(data.departure_date).add(0, 'day').format('YYYY-MM-DD');
                    const timePart = data.departure_time;
                    const dateTimeString = `${datePart}T${timePart}`;
                    const parsedDate = dayjs(dateTimeString);

                    const transformedFormData = {
                        // Füge die API-Daten in das formData-Objekt ein
                        user_id: data.user_id,
                        status: data.status,
                        seriesIdentifier: data.series_identifier,
                        institution_id: data.institution_id,
                        institution: data.institution_json,
                        institution_json: data.institution_json,
                        objectInstitution: data.object_institution,
                        stationName: data.station,
                        phoneNumber: data.phone_number,
                        is_private: data.is_private,
                        private_first_name: data.private_first_name,
                        private_last_name: data.private_last_name,
                        // ...
                        patient_id: data.patient_id,
                        firstName: data.first_name,
                        lastName: data.last_name,
                        dateOfBirth: data.birthdate ? dayjs(data.birthdate) : null,
                        insuranceNumber: data.insurance_number,
                        reasonForTransport: data.transport_reason,
                        infectious: data.is_infectious,
                        infektion_id: data.infektion_id,
                        // ...
                        transport_type: data.transport_type,
                        departureDateTime: parsedDate.isValid() ? parsedDate : null,
                        isHeadOfSeries: data.isHeadOfSeries,

                        // Mappen der Von-Adresse
                        from_institution: data.from_institution_json,
                        from_institution_json: data.from_institution_json,
                        from_station: data.from_station,
                        from_room: data.from_room,
                        from_street: data.from_street,
                        from_house_number: data.from_house_number,
                        from_postal_code: data.from_postal_code,
                        from_city: data.from_city,
                        from_additional_info: data.from_additional_info,
                        // Mappen der Nach-Adresse
                        to_institution: data.to_institution_json,
                        to_institution_json: data.to_institution_json,
                        to_station: data.to_station,
                        to_room: data.to_room,
                        to_street: data.to_street,
                        to_house_number: data.to_house_number,
                        to_postal_code: data.to_postal_code,
                        to_city: data.to_city,
                        to_additional_info: data.to_additional_info,

                        transport_mode: data.transport_mode,
                        insurance_approved: data.insurance_approved,
                        self_payer: data.self_payer,
                        ambulatory: data.ambulatory,
                        return_trip: data.return_trip,
                        initial_trip: data.initial_trip,
                        is_recurring: data.is_recurring,
                        recurring_days: data.recurring_days,
                        recurring_times: data.recurring_times,
                        is_recurring_return: data.is_recurring_return,
                        recurring_return_days: data.recurring_return_days,
                        recurring_return_times: data.recurring_return_times,
                        own_wheelchair: data.own_wheelchair,
                        folding: data.folding,
                        luggage: data.luggage,
                        companion: data.companion,
                        oxygen_required: data.oxygen_required,
                        oxygen_source: data.oxygen_source,
                        prescription_present: data.prescription_present,

                        weightChecked: true,
                        monitorRequired: false,
                        suctionRequired: false,
                        confirm_infectious: true,
                        confirm_weightChecked: true,
                        confirm_monitorRequired: true,
                        confirm_suctionRequired: true,
                        confirm_folding: true,
                        confirm_own_wheelchair: true,
                        confirm_luggage: true,
                        confirm_companion: true,
                        confirm_prescription_present: true,
                        confirm_insurance_approved: true,

                        availabilityChecked: true,
                        availabilityStatus: 'green',

                    };
                    setFormData(transformedFormData);

                    // Wenn wir im Copy-Modus sind, setzen wir isEditMode auf false
                    if (isCopyMode) {
                        setIsEditMode(false);
                    }

                } else {
                    const errorData = await response.json();
                    throw new Error(errorData.message);
                }
            } catch (error) {
                console.error('Fehler beim Abrufen des Transports:', error.message);
            } finally {
                setIsTransportLoaded(true);
            }
        };

        if (id) {
            fetchTransport();
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        // Validierung nach jeder Änderung in den FormDatas
        validateAllSteps();
        // eslint-disable-next-line
    }, [formData]);

    useEffect(() => {
        const fetchUserSettings = async () => {

            if (!user || !user.api_key) {
                console.warn('Benutzer oder API-Schlüssel nicht verfügbar.');
                return;
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URI}/api/user-settings`, {
                    headers: { Authorization: user.api_key }
                });
                if (response.ok) {
                    const data = await response.json();
                    if (['poweruser', 'admin', 'superadmin'].includes(user?.role)) {
                        setIsExpertMode(data.settings.is_expert_mode || false);
                    } else {
                        setIsExpertMode(false);
                    }
                }
            } catch (error) {
                console.error('Fehler beim Abrufen der Benutzereinstellungen:', error.message);
            } finally {
                setIsUserSettingsLoaded(true);
            }
        };
        fetchUserSettings();
    }, [user]);

    useEffect(() => {
        if (isExpertMode) {
            // Überprüfen, ob die Bildschirmgröße kleiner als 1920x1080 ist
            const checkResolution = () => {
                const width = window.innerWidth;
                const height = window.innerHeight;
                setShowResolutionWarning(width < 1920 || height < 1080);
            };

            // Initiale Überprüfung und Event Listener für Änderungen in der Bildschirmgröße
            checkResolution();
            window.addEventListener('resize', checkResolution);

            // Event Listener bereinigen, wenn die Komponente unmountet wird oder der Expertenmodus deaktiviert wird
            return () => window.removeEventListener('resize', checkResolution);
        } else {
            setShowResolutionWarning(false); // Warnung ausblenden, wenn der Expertenmodus deaktiviert ist
        }
    }, [isExpertMode]);

    // Laden der Zeiteinstellungen
    useEffect(() => {
        const loadSettings = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URI}/api/time-slot-settings`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: user.api_key,
                    },
                });

                if (!response.ok) throw new Error('Fehler beim Laden der Zeiteinstellungen');

                const data = await response.json();
                if (data.settings) {
                    console.log('Zeiteinstellungen geladen:', data.settings);
                    setTimeSettings(data.settings);
                }
            } catch (err) {
                console.error('Fehler:', err);
            } finally {
            }
        };

        if (user?.api_key) {
            loadSettings();
        }
    }, [user]);


    const toggleExpertMode = async () => {
        const newMode = !isExpertMode;
        setIsExpertMode(newMode);
        try {
            await fetch(`${process.env.REACT_APP_API_URI}/api/user-settings`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: user.api_key },
                body: JSON.stringify({ settings: { is_expert_mode: newMode } })
            });
        } catch (error) {
            console.error('Fehler beim Speichern der Benutzereinstellungen:', error.message);
        }
    };

    const handleNext = () => {
        const isValid = validateStep(activeStep);
        if (isValid) {
            setActiveStep((prevStep) => prevStep + 1);
        } else {
            if (activeStep === 3) {
                const isFromAddressComplete = !!formData.from_street &&
                    !!formData.from_house_number &&
                    !!formData.from_postal_code &&
                    !!formData.from_city;

                const isToAddressComplete = !!formData.to_street &&
                    !!formData.to_house_number &&
                    !!formData.to_postal_code &&
                    !!formData.to_city;

                // Überprüfe, ob die Abfahrtsadresse vorhanden ist, aber die Zieladresse nicht.
                if (isFromAddressComplete && !isToAddressComplete && fromToActiveTab === 0) {
                    setFromToActiveTab(1);
                }
            }
        }
    };


    const handleBack = () => {
        setShowSummary(false);
        setActiveStep((prevStep) => prevStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const validateAllSteps = () => {
        let allValid = true;
        const updatedErrors = [];

        for (let step = 0; step < steps.length; step++) {
            const errors = getValidationErrors(step, formData, timeSettings, isEditMode, user, modBookingSystem); // Fehlermeldungen für den Schritt holen
            updatedErrors[step] = errors; // Fehlermeldungen in die Liste einfügen
            if (errors.length > 0) {
                allValid = false; // Wenn Fehler vorhanden sind, ist der Schritt ungültig
            }
        }

        setStepErrors(updatedErrors); // Aktualisiere die stepErrors-Liste
        return allValid; // Gib zurück, ob alle Schritte gültig sind
    };

    const areAllStepsValid = () => {
        return stepErrors.every((errors) => errors.length === 0); // Keine Fehler in allen Schritten
    };



    const validateStep = (step) => {
        const errors = getValidationErrors(step, formData, timeSettings, isEditMode, user, modBookingSystem);
        console.log(`Validation errors for step ${step}:`, errors);
        setStepErrors((prevErrors) => {
            const updatedErrors = [...prevErrors];
            updatedErrors[step] = errors;
            return updatedErrors;
        });

        return errors.length === 0; // Schritt ist gültig, wenn keine Fehler vorhanden sind
    };

    const handleSubmit = () => {
        if (validateAllSteps()) {
            setShowSummary(true);
        }
    };

    const ensureRecurringTimes = (recurringTimes) => {
        const defaultTime = "08:00";

        // Überprüfen und setzen der Standardzeit, wenn keine Zeit angegeben ist
        for (let day in recurringTimes) {
            if (!recurringTimes[day] || recurringTimes[day].trim() === "") {
                recurringTimes[day] = defaultTime;
            }
        }
    };

    const handleFinalSubmit = async () => {
        if (!validateAllSteps()) {
            return;
        }

        // Überprüfen und Sicherstellen, dass keine leeren Zeiten in recurring_times existieren
        if (formData.is_recurring && formData.recurring_times) {
            ensureRecurringTimes(formData.recurring_times);
        }

        // Setze self_payer auf true, wenn insurance_approved false ist und confirm_insurance_approved true ist
        // d.h. Krankenkasse hat abgelehnt, aber der Nutzer hat bestätigt, dass er selbst zahlen möchte
        let self_payer_helper = formData.self_payer;
        if (formData.insurance_approved === false && formData.confirm_insurance_approved) {
            self_payer_helper = true;
        }

        // Setze initial_trip und return_trip, falls isEditMode = false
        const modifiedFormData = {
            ...formData,
            initial_trip: isEditMode ? formData.initial_trip : true,
            return_trip: isEditMode ? formData.return_trip : false,
            self_payer: self_payer_helper,
        };

        // Generiere oder verwende den bestehenden seriesIdentifier
        const seriesIdentifier = formData.seriesIdentifier || uuidv4();

        console.log(formData);
        // Konvertiere die UI-Feldnamen in API-Feldnamen
        const apiData = {
            user_id: formData.user_id ? formData.user_id : user.user_id,
            status: formData.status ? formData.status : 'pending',  // FIXME for admins
            institution_id: formData.institution?.institution_id,
            seriesIdentifier: seriesIdentifier,
            patient_institution_id: user?.institutionId,
            object_institution: formData.objectInstitution,
            station: formData.stationName,
            phone_number: formData.phoneNumber,
            is_private: formData.is_private,
            private_first_name: formData.private_first_name,
            private_last_name: formData.private_last_name,
            // ...
            patient_id: formData.patient_id,
            first_name: formData.firstName,
            last_name: formData.lastName,
            birthdate: formData.dateOfBirth ? formData.dateOfBirth.format('YYYY-MM-DD') : '',
            insurance_number: formData.insuranceNumber,
            transport_reason: formData.reasonForTransport,
            is_infectious: formData.infectious,
            infektion_id: formData.infektion_id,
            // ...
            transport_type: formData.transport_type,
            departure_date: formData.departureDateTime ? formData.departureDateTime.format('YYYY-MM-DD') : '',
            departure_time: formData.departureDateTime ? formData.departureDateTime.format('HH:mm:ss') : '',
            // Mappen der Von-Adresse
            from_institution_id: formData.from_institution?.institution_id,
            from_institution: formData.from_institution?.object_institution,
            from_station: formData.from_station,
            from_room: formData.from_room,
            from_street: formData.from_street,
            from_house_number: formData.from_house_number,
            from_postal_code: formData.from_postal_code,
            from_city: formData.from_city,
            from_additional_info: formData.from_additional_info,
            // Mappen der Nach-Adresse
            to_institution_id: formData.to_institution?.institution_id,
            to_institution: formData.to_institution?.object_institution,
            to_station: formData.to_station,
            to_room: formData.to_room,
            to_street: formData.to_street,
            to_house_number: formData.to_house_number,
            to_postal_code: formData.to_postal_code,
            to_city: formData.to_city,
            to_additional_info: formData.to_additional_info,

            transport_mode: formData.transport_mode,
            insurance_approved: formData.insurance_approved,
            self_payer: modifiedFormData.self_payer,
            ambulatory: formData.ambulatory,
            return_trip: modifiedFormData.return_trip,
            initial_trip: modifiedFormData.initial_trip,
            is_recurring: formData.is_recurring,
            recurring_days: formData.recurring_days,
            recurring_times: formData.recurring_times,
            is_recurring_return: formData.is_recurring_return,
            recurring_return_days: formData.recurring_return_days,
            recurring_return_times: formData.recurring_return_times,
            own_wheelchair: formData.own_wheelchair,
            folding: formData.folding,
            luggage: formData.luggage,
            companion: formData.companion,
            oxygen_required: formData.oxygen_required,
            oxygen_source: formData.oxygen_source,
            prescription_present: formData.prescription_present,
            isHeadOfSeries: formData.isHeadOfSeries,
        };

        // Wenn es sich um eine Serienbearbeitung handelt, zeigen wir den SeriesChangeDialog an.
        // Von dort aus wird über eine separate Route in die API geschrieben!
        if (isEditMode && formData.isHeadOfSeries) {
            setPreparedApiData(apiData);
            setSeriesChangeDialogOpen(true);
            return;
        }

        try {
            const apiUrl = isEditMode
                ? `${process.env.REACT_APP_API_URI}/api/transports/${id}`
                : `${process.env.REACT_APP_API_URI}/api/transports`;

            const method = isEditMode ? 'PUT' : 'POST';

            // Hinfahrt erstellen
            let response;

            if (isEditMode || (apiData.initial_trip && (apiData.departure_date || apiData.is_recurring || apiData.is_recurring_return))) {
                console.log('Creating main trip...');
                response = await fetch(apiUrl, {
                    method: method,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: user.api_key,
                    },
                    body: JSON.stringify(apiData),
                });
                //console.log('Response:', response);
                if (!response.ok) throw new Error(await response.json());
            }
            // Prüfen, ob eine Rückfahrt ausgewählt wurde
            // dann Rückfahrt anlegen (gilt für Dialysefahrten)
            if (!isEditMode && formData.return_trip && (!formData.is_recurring && !formData.is_recurring_return)) {
                let transportType = apiData.transport_type;
                if (apiData.transport_type === 'TERMINFAHRT') {
                    transportType = 'ENTLASSUNG';
                }
                const returnTripData = {
                    ...apiData,
                    // Rückfahrt spezifisch: Start- und Zieladresse tauschen, Abfahrtszeit setzen
                    from_institution_id: apiData.to_institution_id,
                    from_institution: apiData.to_institution,
                    from_station: apiData.to_station,
                    from_room: apiData.to_room,
                    from_street: apiData.to_street,
                    from_house_number: apiData.to_house_number,
                    from_postal_code: apiData.to_postal_code,
                    from_city: apiData.to_city,
                    from_additional_info: apiData.to_additional_info,
                    to_institution_id: apiData.from_institution_id,
                    to_institution: apiData.from_institution,
                    to_station: apiData.from_station,
                    to_room: apiData.from_room,
                    to_street: apiData.from_street,
                    to_house_number: apiData.from_house_number,
                    to_postal_code: apiData.from_postal_code,
                    to_city: apiData.from_city,
                    to_additional_info: apiData.from_additional_info,
                    departure_date: formData.returnDepartureTime ? formData.returnDepartureTime.format('YYYY-MM-DD') : '',
                    departure_time: formData.returnDepartureTime ? formData.returnDepartureTime.format('HH:mm:ss') : '',
                    initial_trip: false,
                    return_trip: true,
                    transport_type: transportType,
                };

                // Rückfahrt erstellen
                response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: user.api_key,
                    },
                    body: JSON.stringify(returnTripData),
                });

            }

            if (response.ok) {
                const newTransport = await response.json();
                console.log('New transport created:', newTransport);
                // Handle successful creation, navigation, etc.

                if (isEditMode) {
                    navigate(-1);
                } else {
                    if (formData.isHeadOfSeries || (formData.is_recurring || formData.is_recurring_return)) {
                        navigate('/transportseries');
                    } else {
                        navigate('/transportlist');
                    }
                }

            } else {
                const errorData = await response.json();
                throw new Error(errorData.message);
            }

        } catch (error) {
            console.error('Error creating transport:', error.message);
            // Handle error case
        }
    };


    const handleAbbort = () => {
        // Handle form submission to create or edit a transport record
        navigate(-1);
    };




    const renderStepContent = (step) => {

        if (isLoading) {
            return (
                <Box display="block" width="100%">
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="30vh"
                        style={{
                            background: 'linear-gradient(to bottom, #FFFFFF, #F5F5F5)',
                            borderRadius: '10px',
                            width: isExpertMode ? 'auto' : '100%',
                            border: '1px solid #F5F5F5',
                            position: 'relative',
                            zIndex: 1,
                        }}>
                        <CircularProgress />
                    </Box>
                </Box>);
        }

        if (isExpertMode) {
            // Expertenmodus: Alle Schritte untereinander anzeigen
            return (
                <>
                    <Box display="block" width="100%">
                        {/* UserInformationStep und WhenStep nebeneinander mit Grid */}
                        <Grid container spacing={2} width="100%" mb={1}>
                            <Grid item xs={12} md={6} lg={4} style={{ minWidth: 300, maxWidth: 600 }}>
                                <UserInformationStep formData={formData} setFormData={setFormData} isExpertMode={isExpertMode} />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4} style={{ minWidth: 300, maxWidth: 600 }}>
                                <WhenExpertStep formData={formData} setFormData={setFormData} isExpertMode={isExpertMode} isEditMode={isEditMode} timeSettings={timeSettings} modBookingSystem={modBookingSystem} />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4} style={{ minWidth: 300, maxWidth: 600 }}>
                                <TransportDetailsStep formData={formData} setFormData={setFormData} isExpertMode={isExpertMode} />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} width="100%" mb={1}>
                            <Grid item xs={12} md={6} lg={4} style={{ minWidth: 400, maxWidth: 600 }}>
                                <FromToStep formData={formData} setFormData={setFormData} activeTab={fromToActiveTab} isExpertMode={isExpertMode} />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4} style={{ minWidth: 400, maxWidth: 600 }}>
                                <ToStep formData={formData} setFormData={setFormData} isExpertMode={isExpertMode} />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4} style={{ minWidth: 400, maxWidth: 600 }}>
                                <PatientStep formData={formData} setFormData={setFormData} isExpertMode={isExpertMode} />
                            </Grid>
                        </Grid>

                        {/*
                        <Box width="100%" mt={1} mb={1}>
                            <PatientStep formData={formData} setFormData={setFormData} isExpertMode={isExpertMode} />
                        </Box>
                        <Divider variant="middle" />

                        <Box width="100%" mt={1}>
                            <TransportDetailsStep formData={formData} setFormData={setFormData} isExpertMode={isExpertMode} />
                        </Box>

                        */}

                        {/* Zusammenfassung am Ende der Expertenansicht */}
                        {showSummary && <SummaryStep formData={formData} />}
                    </Box>
                </>
            );
        } else {
            // Normaler Modus: Schrittweise Darstellung
            if (showSummary) {
                return <SummaryStep formData={formData} />;
            }

            switch (step) {
                case 0:
                    return <UserInformationStep formData={formData} setFormData={setFormData} />;
                case 1:
                    return <PatientStep formData={formData} setFormData={setFormData} />;
                case 2:
                    return (
                        <Grid container spacing={2} width="100%" mb={1}>
                            <Grid item xs={12} style={{ minWidth: 800, maxWidth: 800 }}>
                                <FromToStep formData={formData} setFormData={setFormData} activeTab={fromToActiveTab} isExpertMode={isExpertMode} />
                            </Grid>
                            <Grid item xs={12} style={{ minWidth: 800, maxWidth: 800, marginTop: 0, paddingTop: 0 }}>
                                <ToStep formData={formData} setFormData={setFormData} isExpertMode={isExpertMode} />
                            </Grid>
                        </Grid>
                    );
                case 3:
                    return (
                        <WhenExpertStep formData={formData} setFormData={setFormData} isExpertMode={isExpertMode} isEditMode={isEditMode} timeSettings={timeSettings} modBookingSystem={modBookingSystem} />
                    );;
                case 4:
                    return <TransportDetailsStep formData={formData} setFormData={setFormData} />;
                default:
                    return null;
            }
        }
    };


    const getAdditionalText = (formData) => {
        let text = '';
        if (formData.return_trip) {
            text += 'Rückfahrt';
        }
        if (formData.is_recurring || formData.is_recurring_return) {
            text += text ? ' einer Serie' : 'Fahrt einer Serie';
        }
        return text || null; // Kein zusätzlicher Text, wenn beide Bedingungen falsch sind
    };

    return (
        <Container
            maxWidth={isExpertMode ? false : "md"}
            sx={{
                maxWidth: isExpertMode ? '100vw' : 'auto',  // Setzt die maximale Breite im ExpertMode auf die Bildschirmbreite
                overflowX: 'hidden',                         // Verhindert horizontalen Scrollen
                padding: isExpertMode ? 0 : undefined        // Entfernt padding im ExpertMode, wenn nötig
            }}
        >
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={isExpertMode ? 0 : 2}>
                <Box display="flex" alignItems="center">
                    <Typography variant="h5" color="#E46450">
                        {isEditMode ? 'Transport bearbeiten' : isCopyMode ? 'Transport eingeben (Duplikat)' : 'Transport eingeben'}
                    </Typography>
                    {/* Zusätzlicher Text im Editmodus */}
                    {isEditMode && (
                        <Typography variant="body2" sx={{ ml: 2, color: 'text.secondary' }}>
                            {getAdditionalText(formData)}
                        </Typography>
                    )}
                </Box>
                {/* Warnung nur anzeigen, wenn showResolutionWarning true ist */}
                {showResolutionWarning && !formData.isHeadOfSeries && (
                    <Alert severity="warning" sx={{ ml: 2, p: 0.5, fontSize: '0.875rem', alignItems: 'center' }}>
                        Der Expertenmodus ist für eine Bildschirmauflösung von mindestens 1920x1080 optimiert.
                    </Alert>
                )}
                {formData.isHeadOfSeries && (
                    <Alert severity="error" sx={{ ml: 2, p: 1, fontSize: '0.875rem', alignItems: 'center' }}>
                        Sie Bearbeiten eine Transport-Serie für regelmäßige Fahrten. Änderungen werden auf alle Transporte der Serie angewendet!
                    </Alert>
                )}
                {['poweruser', 'admin', 'superadmin'].includes(user?.role) && (
                    <Button
                        variant="text"
                        onClick={toggleExpertMode}
                        sx={{ fontSize: '0.875rem', textTransform: 'none' }} // Kleinere Schriftgröße und keine Großbuchstaben
                    >
                        {isExpertMode ? "Zur Schritt-für-Schritt-Ansicht wechseln" : "Zur Expertenansicht wechseln"}
                    </Button>
                )}
            </Box>
            <Box sx={{ display: 'flex', position: 'relative', }} justifyContent="center" >
                {!isExpertMode && (
                    <Box mt={4}
                        style={{ background: 'linear-gradient(to bottom, #FFFFFF, #F5F5F5)', borderRadius: '10px', padding: '20px', margin: '0 auto', width: '80%', border: '1px solid #F5F5F5', position: 'absolute', zIndex: 2, }}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel error={stepErrors[index].length > 0} onClick={() => handleStepChange(index)}>
                                        {label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                )}
                {renderStepContent(activeStep)}
            </Box>
            <Divider />
            <Box mt={2} mb={4}>
                <div>
                    {(formData.weightChecked === false || formData.monitorRequired === true || formData.suctionRequired === true) ? (
                        <span>
                            {activeStep !== 1 &&
                                <Typography variant="body1" color="error">
                                    Der Transport kann nicht für Patienten mit einem Gewicht von 150 kg oder mehr angefordert werden.
                                </Typography>
                            }
                            <Button onClick={handleAbbort} variant="outlined" sx={{ mt: 2 }}>
                                Eingabe abbrechen
                            </Button>
                        </span>
                    ) : (
                        <Box display="flex" justifyContent="space-between">
                            <div>
                                {activeStep > 0 && !isExpertMode && <Button variant="outlined" onClick={handleBack}>Zurück</Button>}
                                <Button onClick={handleAbbort} variant="text" sx={{ ml: 1 }} tabIndex={-1}>
                                    Eingabe abbrechen
                                </Button>
                            </div>

                            {/* Fehler anzeigen Button */}
                            {hasErrors && (
                                <Button
                                    variant="text"
                                    color="error"
                                    onClick={openErrorModal}
                                    sx={{ mx: 2 }}
                                >
                                    Meldungen anzeigen
                                </Button>
                            )}

                            <div>
                                {!isExpertMode && activeStep < steps.length - 1 && (
                                    <>
                                        <Button data-testid="next-button" variant="outlined" onClick={handleNext}>Weiter</Button>
                                        {isEditMode && (
                                            <Button variant="outlined" onClick={handleFinalSubmit} disabled={!areAllStepsValid()} sx={{ ml: 1 }}>
                                                Speichern
                                            </Button>
                                        )}
                                    </>
                                )}

                                {(activeStep === steps.length - 1 || isExpertMode) && (
                                    <>
                                        {!areAllStepsValid() && (
                                            <Typography variant="caption" color="error" sx={{ mt: 1 }}>
                                                Es fehlen noch Pflichtangaben. Bitte prüfen.
                                            </Typography>
                                        )}
                                        <Button
                                            data-testid="submit-button"
                                            variant="outlined"
                                            onClick={() => {
                                                if (isExpertMode || showSummary) {
                                                    handleFinalSubmit();
                                                } else {
                                                    handleSubmit();
                                                }
                                            }}
                                            disabled={!areAllStepsValid()}
                                        >
                                            {showSummary || isExpertMode ? 'Speichern' : 'Absenden'}
                                        </Button>
                                    </>
                                )}
                            </div>
                        </Box>
                    )}
                </div>
            </Box>

            {/* Error Modal */}
            <ErrorModal open={isModalOpen} onClose={closeErrorModal} stepErrors={stepErrors} />

            <SeriesChangeDialog
                open={seriesChangeDialogOpen}
                onClose={() => {
                    // Schließe das Dialog-Fenster
                    setSeriesChangeDialogOpen(false);
                    // Falls du hier noch Aktionen brauchst, kannst du sie
                    // vor oder nach dem setSeriesChangeDialogOpen(false) machen.
                }}
                user={user}
                id={id}
                preparedApiData={preparedApiData}
            />

        </Container>
    );
};

export default TransportFormPage;
