// src/dialogs/VehicleUnavailabilityDialog.js
import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Box
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/de';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useUser } from '../../components/userContext';
dayjs.extend(customParseFormat);

const VehicleUnavailabilityDialog = ({ open, onClose, initialData, vehicleId, onSaved }) => {
    const { user } = useUser();
    const isEditMode = Boolean(initialData && initialData.unavailability_id);

    // Hier speichern wir zwei Dayjs-Objekte im Array: [start, end]
    const [dateTimeRange, setDateTimeRange] = useState([null, null]);
    const [reason, setReason] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [deleteConfirm, setDeleteConfirm] = useState(false); // Für die Löschbestätigung
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (isEditMode && initialData) {
            // Konvertiere vorhandene Datumswerte in Dayjs-Objekte
            console.log('initialData:', initialData);
            const startVal = dayjs(initialData.start_datetime).tz('Europe/Berlin');
            const endVal = dayjs(initialData.end_datetime).tz('Europe/Berlin');

            setDateTimeRange([startVal, endVal]);
            setReason(initialData.reason || '');
            setAdditionalInfo(initialData.additional_info || '');
        } else {
            // Neuer Eintrag (POST): leere Felder
            setDateTimeRange([null, null]);
            setReason('');
            setAdditionalInfo('');
        }
    }, [initialData, isEditMode, open]);

    // ---------------------------
    //   SPEICHERN (POST/PUT)
    // ---------------------------
    const handleSave = async () => {
        const newErrors = {};
        if (!reason) {
            newErrors.reason = 'Grund ist ein Pflichtfeld';
        }
        if (!dateTimeRange[0] || !dateTimeRange[1]) {
            newErrors.dateTimeRange = 'Bitte Start- und Endzeit angeben.';
        } else if (dateTimeRange[1].isBefore(dateTimeRange[0])) {
            newErrors.dateTimeRange = 'Enddatum darf nicht vor dem Startdatum liegen.';
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        // Dayjs -> String im lokalen Format, weil wir "timestamp without time zone" speichern
        const payload = {
            vehicle_id: vehicleId,
            start_datetime: dateTimeRange[0].format('YYYY-MM-DD HH:mm:ss'),
            end_datetime: dateTimeRange[1].format('YYYY-MM-DD HH:mm:ss'),
            reason,
            additional_info: additionalInfo,
        };

        try {
            let response;
            if (isEditMode) {
                response = await fetch(
                    `${process.env.REACT_APP_API_URI}/api/vehicles/unavailability/${initialData.unavailability_id}`,
                    {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: user.api_key
                        },
                        body: JSON.stringify(payload)
                    }
                );
            } else {
                response = await fetch(
                    `${process.env.REACT_APP_API_URI}/api/vehicles/unavailability`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: user.api_key
                        },
                        body: JSON.stringify(payload)
                    }
                );
            }
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Fehler beim Speichern.');
            }
            onSaved && onSaved();
            onClose(true);
        } catch (err) {
            alert(`Fehler: ${err.message}`);
        }
    };

    // ---------------------------
    //   LÖSCHEN (DELETE)
    // ---------------------------
    const handleDeleteClick = () => {
        // Bestätigungsdialog auslösen
        setDeleteConfirm(true);
    };

    const confirmDelete = async (yes) => {
        setDeleteConfirm(false);
        if (!yes || !initialData) return;

        try {
            const resp = await fetch(`${process.env.REACT_APP_API_URI}/api/vehicles/unavailability/${initialData.unavailability_id}`, {
                method: 'DELETE',
                headers: {
                    Authorization: user.api_key
                }
            });
            if (!resp.ok) {
                const err = await resp.json();
                throw new Error(err.error || 'Löschen fehlgeschlagen.');
            }
            onSaved && onSaved();
            onClose(true);
        } catch (err) {
            alert(`Fehler beim Löschen: ${err.message}`);
        }
    };

    return (
        <Dialog open={open} onClose={() => onClose(false)} maxWidth="sm" fullWidth>
            <DialogTitle>
                {isEditMode ? 'Unverfügbarkeit bearbeiten' : 'Fahrzeug außer Betrieb setzen'}
            </DialogTitle>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                    <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            {!isEditMode && (
                                <Button
                                    data-testid="now-button"
                                    variant="text"
                                    onClick={() => setDateTimeRange([dayjs(), dateTimeRange[1]])}
                                >
                                    Jetzt
                                </Button>
                            )}
                            <DateTimePicker
                                desktopModeMediaQuery="@media (min-width: 0px)"
                                data-testid="start-date-time-picker"
                                label="Start"
                                value={dateTimeRange[0]}
                                onChange={(newValue) => {
                                    setDateTimeRange([newValue, dateTimeRange[1]]);
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                // Validierung: keine Zeit in Vergangenheit
                                minDateTime={dayjs().startOf('minute')}
                                mask="__.__.____ __:__"
                                inputFormat="DD.MM.YYYY HH:mm"
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            {!isEditMode && (
                                <Button
                                    data-testid="add-one-day-button"
                                    variant="text"
                                    onClick={() => {
                                        if (dateTimeRange[0]) {
                                            setDateTimeRange([dateTimeRange[0], dateTimeRange[0].add(1, 'day')]);
                                        }
                                    }}
                                >
                                    + 1 Tag
                                </Button>
                            )}
                            <DateTimePicker
                                desktopModeMediaQuery="@media (min-width: 0px)"
                                data-testid="end-date-time-picker"
                                label="Ende"
                                value={dateTimeRange[1]}
                                onChange={(newValue) => {
                                    setDateTimeRange([dateTimeRange[0], newValue]);
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                // Validierung: keine Zeit vor Start und keine Zeit in Vergangenheit
                                minDateTime={dateTimeRange[0] || dayjs().startOf('minute')}
                                mask="__.__.____ __:__"
                                inputFormat="DD.MM.YYYY HH:mm"
                            />
                        </Box>
                    </Box>
                </LocalizationProvider>
                <TextField
                    name="reason-textfield"
                    label="Grund"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    fullWidth
                    margin="normal"
                    error={!!errors.reason}
                    helperText={errors.reason}
                    required
                />
                <TextField
                    data-testid="additional-info"
                    label="Zusatzinformationen"
                    value={additionalInfo}
                    onChange={(e) => setAdditionalInfo(e.target.value)}
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                />
                {errors.dateTimeRange && (
                    <Box sx={{ color: 'error.main', mt: 1 }}>{errors.dateTimeRange}</Box>
                )}
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between', px: 3, py: 2 }}>
                <Button onClick={() => onClose(false)}>Abbrechen</Button>
                {isEditMode && (
                    <Button
                        color="error"
                        variant="text"
                        onClick={handleDeleteClick}
                    >
                        Eintrag Löschen
                    </Button>
                )}
                <Box>
                    <Button data-testid="save-button" onClick={handleSave} variant="contained" color="primary">
                        Speichern
                    </Button>
                </Box>
            </DialogActions>

            {/* Löschbestätigung */}
            {deleteConfirm && (
                <Dialog open={deleteConfirm} onClose={() => confirmDelete(false)}>
                    <DialogTitle>Wirklich löschen?</DialogTitle>
                    <DialogContent>
                        Dieser Eintrag wird unwiderruflich entfernt.
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => confirmDelete(false)}>Abbrechen</Button>
                        <Button onClick={() => confirmDelete(true)} color="error">
                            Ja, löschen
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Dialog>
    );
};

export default VehicleUnavailabilityDialog;