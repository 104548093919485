import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../components/userContext';
import {
    Box,
    Container,
    Button,
    Grid,
    Divider,
} from '@mui/material';
import {
    Close as CloseIcon,
} from '@mui/icons-material';

import PlanningDetailTimeline from './planning/PlanningDetailTimeline';
import TransportInfoPanel from './planning/TransportInfoPanel';
import TransportLogs from './planning/TransportLogs';

function PlanningPage() {
    const { user } = useUser();
    const { planning_id, transport_id } = useParams();
    const navigate = useNavigate();
    // eslint-disable-next-line
    const [loading, setLoading] = useState(true);
    const [planning, setPlanning] = useState(null);
    // eslint-disable-next-line
    const [details, setDetails] = useState([]);
    // eslint-disable-next-line
    const [transport, setTransport] = useState(null);
    // eslint-disable-next-line
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (planning_id) {
            fetchPlanning();
        } else if (transport_id) {
            fetchTransportAndErrors();
        }
        // eslint-disable-next-line
    }, [planning_id, transport_id]);

    async function fetchPlanning() {
        setLoading(true);
        try {
            const planningResponse = await fetch(`${process.env.REACT_APP_API_URI}/api/planning/${planning_id}`, {
                headers: { 'Authorization': user.api_key },
            });
            if (!planningResponse.ok) throw new Error('Fehler beim Laden der Planung');
            const planningData = await planningResponse.json();
            setPlanning(planningData.planning);
            setDetails(planningData.details);

            if (planningData.planning.transport_id) {
                const transportResponse = await fetch(`${process.env.REACT_APP_API_URI}/api/transport/${planningData.planning.transport_id}`, {
                    headers: { 'Authorization': user.api_key },
                });
                if (!transportResponse.ok) throw new Error('Fehler beim Laden des Transports');
                const transportData = await transportResponse.json();
                setTransport(transportData);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    async function fetchTransportAndErrors() {
        setLoading(true);
        try {
            const transportResponse = await fetch(`${process.env.REACT_APP_API_URI}/api/transport/${transport_id}`, {
                headers: { 'Authorization': user.api_key },
            });
            if (!transportResponse.ok) throw new Error('Fehler beim Laden des Transports');
            const transportData = await transportResponse.json();
            setTransport(transportData);

            const errorsResponse = await fetch(`${process.env.REACT_APP_API_URI}/api/booking-logs/${transport_id}`, {
                headers: { 'Authorization': user.api_key },
            });
            if (!errorsResponse.ok) throw new Error('Fehler beim Laden der Fehler');
            const errorsData = await errorsResponse.json();
            setErrors(errorsData);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }


    return (
        <Container maxWidth="lg">
            <Grid container spacing={3}>
                {/* Linke Seite: Timeline oder Fehler */}
                <Grid item xs={12} md={8}>
                    {planning_id ? (
                        <>
                            <PlanningDetailTimeline planning_id={planning_id} />
                        </>
                    ) : (
                        <TransportLogs
                            transport_id={transport_id}
                            title="Transport - Ungeplant"
                        />
                    )}
                </Grid>

                {/* Rechte Seite: Infopanel */}
                <Grid item xs={12} md={4}>
                    <TransportInfoPanel transport_id={transport_id || (planning_id && planning?.transport_id)} />
                </Grid>
            </Grid>

            <Divider sx={{ my: 2, mt: 6 }} />
            <Box display="flex" justifyContent="flex-end" mt={4}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CloseIcon />}
                    onClick={() => navigate(-1)}
                >
                    Schließen
                </Button>
            </Box>
        </Container>
    );
}

export default PlanningPage;