import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, ListItemIcon, Typography, Divider } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ChatIcon from '@mui/icons-material/Chat';  // Neuer Import
import dayjs from 'dayjs';

const TransportBarContextMenu = ({
    open,
    anchorPosition,
    onClose,
    transport,
    onDetails,
    onEdit,
    onDelete,
    onDuplicate,
    onAddBreakAfter,
    onShowRoundTrip,
    mode // Prop: 'forTimeLine' oder 'forTableView'
}) => {

    const isTableView = mode === 'forTableView';
    const breakStartTime = transport ? dayjs(transport.planned_end_full).format('HH:mm') : '';
    const isBreak = transport && transport.transport_type === 'BREAK';

    return (
        <Menu
            open={open}
            onClose={onClose}
            anchorReference="anchorPosition"
            anchorPosition={anchorPosition ? { top: anchorPosition.y, left: anchorPosition.x } : undefined}
        >
            {/* Falls kein Break und im Timeline-Modus: Details und Hin- Rückfahrt anzeigen */}
            {!isBreak && !isTableView && (
                <>
                    <MenuItem
                        onClick={(e) => {
                            onDetails(e, transport);
                            onClose();
                        }}
                        dense
                    >
                        <ListItemIcon>
                            <InfoIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit">
                            Details anzeigen
                        </Typography>
                    </MenuItem>
                    {transport?.linked_transport_id && (
                        <MenuItem
                            onClick={(e) => {
                                if (onShowRoundTrip) {
                                    onShowRoundTrip(e, transport);
                                }
                                onClose();
                            }}
                            dense
                        >
                            <ListItemIcon>
                                <SwapHorizIcon fontSize="small" sx={{ color: '#1976d2' }} />
                            </ListItemIcon>
                            <Typography variant="inherit">
                                Hin- Rückfahrt anzeigen
                            </Typography>
                        </MenuItem>
                    )}
                    <Divider />
                </>
            )}

            <MenuItem
                onClick={(e) => {
                    onEdit(e, transport);
                    onClose();
                }}
                dense
                disabled={!isBreak}
            >
                <ListItemIcon>
                    <EditIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">
                    {isBreak ? 'Pause bearbeiten' : 'Transport bearbeiten'}
                </Typography>
            </MenuItem>

            {!isBreak && (
                <MenuItem
                    onClick={(e) => {
                        onDuplicate(e, transport);
                        onClose();
                    }}
                    dense
                    disabled
                >
                    <ListItemIcon>
                        <ContentCopyIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        Transport duplizieren
                    </Typography>
                </MenuItem>
            )}

            {isBreak && (
                <MenuItem
                    onClick={(e) => {
                        onDelete(e, transport);
                        onClose();
                    }}
                    dense
                    sx={{ color: 'error.main' }}
                >
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {isBreak ? 'Pause löschen' : 'Transport löschen'}
                    </Typography>
                </MenuItem>
            )}

            {!isBreak && (
                <>
                    <Divider />
                    <MenuItem
                        onClick={(e) => {
                            onAddBreakAfter(e, transport);
                            onClose();
                        }}
                        dense
                    >
                        <ListItemIcon>
                            <FreeBreakfastIcon fontSize="small" sx={{ color: '#d32f2f' }} />
                        </ListItemIcon>
                        <Typography variant="inherit">
                            Pause nach diesem Transport ({breakStartTime} Uhr)
                        </Typography>
                    </MenuItem>
                </>
            )}

            {/* Neue deaktivierte Fahrzeug-Einträge */}
            <Divider />
            <MenuItem disabled dense>
                <ListItemIcon>
                    <InfoIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">
                    Fahrzeug - Rückfahrt Wache
                </Typography>
            </MenuItem>
            <MenuItem disabled dense>
                <ListItemIcon>
                    <InfoIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">
                    Fahrzeug - Telemetrie auslösen
                </Typography>
            </MenuItem>
            <MenuItem disabled dense>
                <ListItemIcon>
                    <EditIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">
                    Fahrzeug - Sonderauftrag anlegen
                </Typography>
            </MenuItem>
            <MenuItem disabled dense>
                <ListItemIcon>
                    <ChatIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">
                    Fahrzeug - Chat (Kommunikator)
                </Typography>
            </MenuItem>
        </Menu>
    );
};

TransportBarContextMenu.propTypes = {
    open: PropTypes.bool.isRequired,
    anchorPosition: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number
    }),
    onClose: PropTypes.func.isRequired,
    transport: PropTypes.object,
    onDetails: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onDuplicate: PropTypes.func.isRequired,
    onAddBreakAfter: PropTypes.func.isRequired,
    onShowRoundTrip: PropTypes.func,
    mode: PropTypes.oneOf(['forTimeLine', 'forTableView'])
};

export default TransportBarContextMenu;