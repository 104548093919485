import React, { useRef, useState, useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';
import {
    Box,
    Typography,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Paper
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { useUser } from '../../components/userContext';
import PlanningTimeline from './PlanningTimeline';
import VehicleTransportTable from './PlanningTransportTable';
import VehicleTimelineDrawer from './VehicleTimelineDrawer';
import PlanningHeader from './PlanningHeader';
import BreakDialog from './BreakDialog';
import UnplannedTransportsPage from './UnplannedTransportsPage';
import { deleteBreak, updateBreak } from './breakUtils';

function getTimeString(date) {
    return dayjs(date).format('HH:mm');
}

export default function VehicleTimeline() {
    const { user, settings, updateSetting } = useUser();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [vehicles, setVehicles] = useState([]);
    const [transports, setTransports] = useState([]);
    const [tableTransports, setTableTransports] = useState([]);
    const [filteredVehicles, setFilteredVehicles] = useState([]);
    const [filteredTransports, setFilteredTransports] = useState([]);
    const [filteredTableTransports, setFilteredTableTransports] = useState([]);
    const [timeScale, setTimeScale] = useState(24);
    const [viewMode, setViewMode] = useState('timeline');
    const [dialogOpen, setDialogOpen] = useState(false);
    // eslint-disable-next-line
    const [selectedTransport, setSelectedTransport] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [isAutoRefreshEnabled, setIsAutoRefreshEnabled] = useState(
        settings?.is_auto_refresh_vehicle_timeline_enabled ?? true
    );
    const [selectedTransportId, setSelectedTransportId] = useState(null);
    const [selectedPlanningId, setSelectedPlanningId] = useState(null);
    const [detailsPanelOpen, setDetailsPanelOpen] = useState(false);
    const [countyColors, setCountyColors] = useState({});
    // eslint-disable-next-line
    const [clickedTransport, setClickedTransport] = useState(null);
    // Neuer State für den Landkreis-Filter
    const [selectedCounties, setSelectedCounties] = useState(
        settings?.selected_counties ?? ['Alle']
    );

    const [roundTripFilter, setRoundTripFilter] = useState(null);
    const [blinkingTransports, setBlinkingTransports] = useState([]);

    // State für Anzeige von ungeplanten Transporten
    const [showUnplannedTransports, setShowUnplannedTransports] = useState(false);

    // Snackbar-Hilfsfunktion
    const showSnackbar = (message, variant = 'default') => {
        enqueueSnackbar(message, { variant });
    };

    const [breakDialogOpen, setBreakDialogOpen] = useState(false);
    const [breakDialogData, setBreakDialogData] = useState(null);

    const [scrollPosition, setScrollPosition] = useState({ top: 0, left: 0 });
    const timelineContainerRef = useRef(null);

    const [refreshKey, setRefreshKey] = useState(0);

    // Function to save scroll position before data fetching
    const saveScrollPosition = useCallback(() => {
        if (timelineContainerRef.current) {
            setScrollPosition({
                top: timelineContainerRef.current.scrollTop,
                left: timelineContainerRef.current.scrollLeft
            });
        }
    }, []);


    useEffect(() => {
        fetchData().then(() => {
            // Erzwinge ein Neurendern aller Komponenten nach dem Datenabruf
            setRefreshKey(prevKey => prevKey + 1);
        });
        // eslint-disable-next-line
    }, [selectedDate]);

    // Auto-Refresh einrichten
    useEffect(() => {
        let interval;
        if (isAutoRefreshEnabled) {
            interval = setInterval(() => fetchData(true), 300000); // 5 Minuten
        }
        return () => {
            if (interval) clearInterval(interval);
        };
        // eslint-disable-next-line
    }, [isAutoRefreshEnabled, selectedDate]);

    // Initiale Einstellung aus User-Settings laden
    useEffect(() => {
        if (settings) {
            if (typeof settings.is_auto_refresh_vehicle_timeline_enabled !== 'undefined') {
                setIsAutoRefreshEnabled(settings.is_auto_refresh_vehicle_timeline_enabled);
            }

            if (Array.isArray(settings.selected_counties)) {
                setSelectedCounties(settings.selected_counties);
            } else {
                setSelectedCounties(['Alle']);
            }
        }
        // eslint-disable-next-line
    }, [settings]);

    // Detailsbereich schließen, wenn viewMode geändert wird
    useEffect(() => {
        setDetailsPanelOpen(false);
    }, [viewMode]);

    // Detailsbereich schließen, wenn die Daten neu geladen werden
    useEffect(() => {
        if (!loading) {
            setDetailsPanelOpen(false);
            setSelectedTransportId(null);
            setSelectedPlanningId(null);
        }
    }, [filteredTableTransports, loading]);

    // Ungebouncete Filterfunktion
    const filterDataImmediate = useCallback((query, vehicles, tableTransports, transports, counties, roundTrip = null) => {
        let filteredVeh = [...vehicles];

        if (!counties.includes('Alle') && counties.length > 0) {
            filteredVeh = vehicles.filter((vehicle) =>
                counties.some((county) => vehicle.lk === county)
            );
        }

        const searchTerms = query.toLowerCase().split(' ').filter(Boolean);

        // Filter für Hin- und Rückfahrt
        let filteredTrans = [];

        if (roundTrip) {
            // Wenn Rundfahrt-Filter aktiv ist, zeige nur die bestimmte Transport-ID und die zugehörige linked_transport_id
            filteredTrans = transports.filter(t =>
                t.transport_id === roundTrip.transport_id ||
                t.transport_id === roundTrip.linked_transport_id
            );

            // Lade nur die betroffenen Fahrzeuge
            const relevantVehicleNames = new Set(filteredTrans.map(t => t.vehicle_name));
            filteredVeh = filteredVeh.filter(v => relevantVehicleNames.has(v.vehicle_name));

            // Filtern der Tabellen-Transporte
            const transportIds = new Set(filteredTrans.map(t => t.transport_id));
            const filteredTableTrans = tableTransports.filter(t =>
                transportIds.has(t.transport_id)
            );

            setFilteredVehicles(filteredVeh);
            setFilteredTransports(filteredTrans);
            setFilteredTableTransports(filteredTableTrans);
            return;
        } else if (searchTerms.length === 0) {
            // Normaler Filter ohne Suche
            const filteredVehicleNames = new Set(filteredVeh.map(v => v.vehicle_name));
            filteredTrans = transports.filter(t =>
                filteredVehicleNames.has(t.vehicle_name)
            );

            const transportIds = new Set(filteredTrans.map(t => t.transport_id));
            const filteredTableTrans = tableTransports.filter(t =>
                transportIds.has(t.transport_id)
            );

            setFilteredVehicles(filteredVeh);
            setFilteredTransports(filteredTrans);
            setFilteredTableTransports(filteredTableTrans);
            return;
        }

        // Filter mit Suchbegriffen
        const filteredVehicleNames = new Set(filteredVeh.map(v => v.vehicle_name));
        filteredTrans = transports.filter(t =>
            filteredVehicleNames.has(t.vehicle_name) &&
            searchTerms.every((term) =>
                [
                    t.vehicle_name,
                    t.transport_type,
                    t.from_institution,
                    t.from_street,
                    t.from_postal_code,
                    t.from_city,
                    t.to_institution,
                    t.to_street,
                    t.to_postal_code,
                    t.to_city,
                    t.first_name,
                    t.last_name,
                    t.transport_reason,
                    t.transport_id,
                    t.linked_transport_id
                ].some((field) => field !== null && field !== undefined && String(field).toLowerCase().includes(term))
            )
        );

        const vehicleNamesInFilteredTransports = new Set(filteredTrans.map(t => t.vehicle_name));
        const finalFilteredVeh = filteredVeh.filter(vehicle =>
            vehicleNamesInFilteredTransports.has(vehicle.vehicle_name)
        );

        const transportIds = new Set(filteredTrans.map(t => t.transport_id));
        const filteredTableTrans = tableTransports.filter(t =>
            transportIds.has(t.transport_id)
        );

        setFilteredTableTransports(filteredTableTrans);
        setFilteredTransports(filteredTrans);
        setFilteredVehicles(finalFilteredVeh);
    }, []);

    // Debounced-Version für Suchanfragen
    // eslint-disable-next-line
    const filterDataDebounced = useCallback(
        debounce((query, vehicles, tableTransports, transports, counties, roundTrip) =>
            filterDataImmediate(query, vehicles, tableTransports, transports, counties, roundTrip),
            300),
        [filterDataImmediate]
    );

    // Debounced-Version nur für Suchanfragen
    // eslint-disable-next-line
    /*
    const filterDataDebounced = useCallback(
        debounce(filterDataImmediate, 300),
        [filterDataImmediate]
    );*/

    // useEffect für Datenänderungen ohne Debounce
    useEffect(() => {
        if (searchQuery) {
            filterDataDebounced(searchQuery, vehicles, tableTransports, transports, selectedCounties, roundTripFilter);
        } else {
            filterDataImmediate(searchQuery, vehicles, tableTransports, transports, selectedCounties, roundTripFilter);
        }
    }, [transports, vehicles, tableTransports, selectedCounties, searchQuery, roundTripFilter, filterDataImmediate, filterDataDebounced]);



    async function fetchData(autoRefresh = false) {
        // Save scroll position before loading
        saveScrollPosition();
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URI}/api/vehicles/planning?date=${selectedDate.format('YYYY-MM-DD')}`,
                { headers: { Authorization: user.api_key } }
            );
            if (!response.ok) throw new Error('Fehler beim Laden der Fahrzeugplanung');
            const data = await response.json();

            const dateStr = selectedDate.format('YYYY-MM-DD');
            const whResp = await fetch(
                `${process.env.REACT_APP_API_URI}/api/vehicles/working-hours?date=${dateStr}`,
                { headers: { Authorization: user.api_key } }
            );
            if (!whResp.ok) throw new Error('Fehler beim Laden der Arbeitszeiten');
            const whData = await whResp.json();

            // Extrahiere Fahrzeuge und Landkreisfarben aus der Antwort
            let vehicles = whData;
            let countyColors = {};

            // Prüfe, ob das neue API-Format verwendet wird (mit vehicles und countyColors)
            if (whData && whData.vehicles && whData.countyColors) {
                vehicles = whData.vehicles;
                countyColors = whData.countyColors;
            }

            const plTransResp = await fetch(
                `${process.env.REACT_APP_API_URI}/api/transports/withPlanning?date=${selectedDate.format('YYYY-MM-DD')}`,
                { headers: { Authorization: user.api_key } }
            );
            if (!plTransResp.ok) {
                throw new Error('Error fetching transports');
            }
            const plTransDate = await plTransResp.json();

            // Setze Fahrzeuge und Landkreisfarben im State
            setVehicles(vehicles);
            setCountyColors(countyColors); // Neuer State für die Landkreisfarben
            setTransports(data);
            setTableTransports(plTransDate);
        } catch (error) {
            console.error(error);
            //setError(error.message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!loading && timelineContainerRef.current && (scrollPosition.top > 0 || scrollPosition.left > 0)) {
            // Sofort scrollen, ohne setTimeout
            timelineContainerRef.current.scrollTop = scrollPosition.top;
            timelineContainerRef.current.scrollLeft = scrollPosition.left;

            // Falls nötig, ein zweites Mal scrollen nach kurzer Verzögerung (für Edge-Fälle)
            const timeoutId = setTimeout(() => {
                if (timelineContainerRef.current) {
                    timelineContainerRef.current.scrollTop = scrollPosition.top;
                    timelineContainerRef.current.scrollLeft = scrollPosition.left;
                }
            }, 50); // Reduziert von 100ms auf 50ms

            return () => clearTimeout(timeoutId);
        }
    }, [loading, scrollPosition]);


    const changeDate = (days) => {
        setSelectedDate((prev) => prev.add(days, 'day'));
    };

    const handleTimelineClick = (event, vehicleId, selectedDate, hourWidth) => {
        const rect = event.currentTarget.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const clickedHour = x / hourWidth;
        const dayStart = dayjs(selectedDate).startOf('day');
        const clickedTime = dayStart.add(clickedHour, 'hour');

        // Suche das Fahrzeug anhand der ID
        const vehicle = vehicles.find(v => v.vehicle_id === vehicleId);

        // Dialogdaten vorbereiten
        setBreakDialogData({
            vehicleId: vehicleId,
            vehicleName: vehicle ? vehicle.vehicle_name : `Fahrzeug ${vehicleId}`,
            initialDate: selectedDate,
            initialTime: clickedTime
        });

        // Dialog öffnen
        setBreakDialogOpen(true);
    };

    const handleCreateBreak = (event, transport) => {

        const breakStartTime = transport ? dayjs(transport.planned_end_full).add(1, 'minute').format('HH:mm') : '';


        // Dialogdaten vorbereiten
        setBreakDialogData({
            vehicleId: transport.vehicle_id,
            vehicleName: transport.vehicle_name,
            initialDate: selectedDate,
            initialTime: breakStartTime,
        });

        // Dialog öffnen
        setBreakDialogOpen(true);

    }

    const handleDeleteBreak = async (event, transport) => {
        event.stopPropagation();

        // Prüfen, ob es sich um eine Pause handelt
        if (transport.transport_type === 'BREAK') {
            if (!window.confirm('Möchten Sie diese Pause wirklich löschen?')) return;

            try {
                await deleteBreak(transport.planning_detail_id, user.api_key, process.env.REACT_APP_API_URI);
                fetchData();
            } catch (error) {
                console.error('Fehler beim Löschen der Pause:', error);
            }
        } else {
            // Andere Transporte löschen – hier ggf. andere Logik verwenden
            console.log('Löschen anderer Transporte');
        }
    }

    // Handler für den Break-Dialog
    const handleBreakDialogClose = (hasChanges) => {
        setBreakDialogOpen(false);

        // Wenn Änderungen vorgenommen wurden, Daten neu laden
        if (hasChanges) {
            fetchData();
        }
    };

    // Funktion zum lokalen Aktualisieren eines Transportes
    const updateTransportLocally = (transportId, newStartTime, newEndTime) => {
        setTransports((prevTransports) =>
            prevTransports.map((t) =>
                t.planning_detail_id === transportId
                    ? {
                        ...t,
                        planned_start: newStartTime.format('YYYY-MM-DDTHH:mm:ss'),
                        planned_end: newEndTime.format('YYYY-MM-DDTHH:mm:ss'),
                        planned_start_full: newStartTime.format('YYYY-MM-DDTHH:mm:ss'),
                        planned_end_full: newEndTime.format('YYYY-MM-DDTHH:mm:ss'),
                    }
                    : t
            )
        );
    };

    // Update BREAK via DragDrop
    const handleTransportDrop = async (transport, newStartTime, newEndTime) => {
        if (transport.transport_type === 'BREAK') {
            try {

                // Lokale Aktualisierung für sofortige UI-Reaktion
                updateTransportLocally(transport.planning_detail_id, newStartTime, newEndTime);
                // Formatiere die Zeiten für die API-Anfrage

                const formatted = {
                    planning_detail_id: transport.planning_detail_id,
                    planned_start: newStartTime.format('YYYY-MM-DDTHH:mm:ss'),
                    planned_end: newEndTime.format('YYYY-MM-DDTHH:mm:ss')
                };

                // Anzeigen eines Lade-Indikators, falls gewünscht
                //setLoading(true);

                // Aufruf der API zum Aktualisieren der Pause
                await updateBreak(formatted, user.api_key, process.env.REACT_APP_API_URI);

                // Optional: Vollständige Daten neu laden nach erfolgreichem Update
                debounce(fetchData, 500)(); // Debounced Reload falls nötig

                console.log('Pause erfolgreich verschoben');
            } catch (error) {
                console.error('Fehler beim Verschieben der Pause:', error);

                showSnackbar(`Verschieben fehlgeschlagen: ${error.message}`, 'error');
                // Rollback durch erneutes Laden der Daten
                fetchData();

            } finally {
                //setLoading(false);
            }
        } else {
            // Hier können wir die Logik für andere Transport-Typen hinzufügen,
            // falls diese in Zukunft auch verschiebbar sein sollen
            console.log('Verschieben anderer Transport-Typen ist derzeit nicht unterstützt');
        }
    };

    // Vereinheitlichte Transportauswahl
    // Klick auf Balken -> bei BREAK BreakDialog, sonst Drawer
    const handleTransportSelection = useCallback((transport) => {
        if (!transport) return;

        // Prüfen, ob es sich um eine Pause handelt
        if (transport.transport_type === 'BREAK') {
            // Für eine Pause den BreakDialog öffnen
            setBreakDialogData({
                vehicleId: transport.vehicle_id,
                vehicleName: transport.vehicle_name,
                initialDate: dayjs(transport.planned_start),
                editMode: true,  // Im Edit-Modus öffnen
                breakData: transport  // Die kompletten Transport-Daten übergeben
            });
            setBreakDialogOpen(true);
        } else {
            // Für alle anderen Transport-Typen den Drawer öffnen
            // Finde die zugehörigen IDs für die Tabellenansicht
            const tableTransport = tableTransports.find(t =>
                t.transport_id === transport.transport_id ||
                t.transports?.some(tt => tt.transport_id === transport.transport_id)
            );

            setSelectedTransportId(tableTransport?.transport_id || transport.transport_id);
            setSelectedTransport(tableTransport || transport)
            setSelectedPlanningId(tableTransport?.planning_id);
            setDetailsPanelOpen(true);
        }
    }, [tableTransports]);

    // Timeline-Transportklick-Handler
    const handleTimelineTransportClick = useCallback((e, transport) => {
        e.stopPropagation();
        setClickedTransport(transport);
        handleTransportSelection(transport);
    }, [handleTransportSelection]);

    // Handler für Suchanfragen
    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        filterDataDebounced(event.target.value, vehicles, tableTransports, transports, selectedCounties);
    };

    const handleClearSearch = () => {
        setSearchQuery('');
    };

    const toggleAutoRefresh = () => {
        const newValue = !isAutoRefreshEnabled;
        setIsAutoRefreshEnabled(newValue);
        updateSetting('is_auto_refresh_vehicle_timeline_enabled', newValue);
    };

    // Handler für den Landkreis-Filter
    const handleCountyFilterChange = (counties) => {
        setSelectedCounties(counties);
        updateSetting('selected_counties', counties);
    };

    // Callback-Funktion für die Tabellenzeilen-Auswahl
    const handleSelectRow = (transport_id, planning_id, transport) => {
        console.log(`Transport ID: ${transport_id}, Planning ID: ${planning_id}`);
        setSelectedTransportId(transport_id);
        setSelectedPlanningId(planning_id);
        setSelectedTransport(transport);
        setDetailsPanelOpen(true);
    };

    // Funktion zum Schließen des Detail-Panels
    const handleClosePanel = () => {
        setDetailsPanelOpen(false);
    };

    // Funktion zum Anzeigen der Hin- und Rückfahrt
    const handleShowRoundTrip = (event, transport) => {
        console.log('Show round trip for transport:', transport);
        event.stopPropagation();
        // Setze Rundfahrt-Filter
        setRoundTripFilter(transport);

        // Setze Blinken für 3 Sekunden
        const blinkTransports = [transport.transport_id];
        if (transport.linked_transport_id) {
            blinkTransports.push(transport.linked_transport_id);
        }
        setBlinkingTransports(blinkTransports);

        // Stoppe Blinken nach 3 Sekunden
        setTimeout(() => {
            setBlinkingTransports([]);
        }, 3000);

        // Zeige Snackbar-Meldung
        showSnackbar(`Hin- und Rückfahrt wird angezeigt: Transport ${transport.transport_id} - ${transport.linked_transport_id}`, 'info');
    };

    // Funktion zum Zurücksetzen des Rundfahrt-Filters
    const handleClearRoundTripFilter = () => {
        setRoundTripFilter(null);
        showSnackbar('Filter für Hin- und Rückfahrt zurückgesetzt', 'info');
    };


    if (loading) {
        return (
            <Box sx={{ height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
            </Box>
        );
    }

    const handleOpenUnplannedTransports = () => {
        setShowUnplannedTransports(true);
    };


    // Handler zum Schließen der ungeplanten Transporte-Ansicht
    const handleCloseUnplannedTransports = () => {
        setShowUnplannedTransports(false);
    };

    // Wenn ungeplante Transporte angezeigt werden sollen, zeige die entsprechende Seite
    if (showUnplannedTransports) {
        return (
            <UnplannedTransportsPage onClose={handleCloseUnplannedTransports} />
        );
    }

    const drawerWidth = 514;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box m={1} sx={{
                height: 'calc(100vh - 200px)',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden'
            }}>
                <PlanningHeader
                    title="Planungsübersicht"
                    selectedDate={selectedDate}
                    searchQuery={searchQuery}
                    isAutoRefreshEnabled={isAutoRefreshEnabled}
                    viewMode={viewMode}
                    timeScale={timeScale}
                    handleRefresh={() => fetchData()}
                    handleSearch={handleSearch}
                    handleClearSearch={handleClearSearch}
                    handleDateChange={(newValue) => setSelectedDate(newValue)}
                    changeDate={changeDate}
                    setTimeScale={setTimeScale}
                    toggleViewMode={() => setViewMode(viewMode === 'timeline' ? 'table' : 'timeline')}
                    toggleAutoRefresh={toggleAutoRefresh}
                    selectedCounties={selectedCounties}
                    handleCountyFilterChange={handleCountyFilterChange}
                    roundTripFilter={roundTripFilter}
                    handleClearRoundTripFilter={handleClearRoundTripFilter}
                    onOpenUnplannedTransports={handleOpenUnplannedTransports}
                />

                {/* Haupt-Inhaltsbereich mit flexibler Größe */}
                <Box sx={{
                    flexGrow: 1,
                    display: 'flex',
                    position: 'relative',
                    overflow: 'hidden',
                    width: '100%'
                }}>
                    {/* Gemeinsamer Content-Bereich */}
                    <Box sx={{
                        width: detailsPanelOpen ? `calc(100% - ${drawerWidth}px)` : '100%',
                        height: '100%',
                        transition: 'width 0.3s ease',
                        position: 'relative',
                        overflow: 'hidden',
                        zIndex: 1,
                    }}>
                        {viewMode === 'timeline' ? (
                            <Box sx={{ width: '100%', height: '100%' }}>
                                <PlanningTimeline
                                    key={refreshKey}
                                    ref={timelineContainerRef}
                                    vehicles={filteredVehicles}
                                    transports={filteredTransports}
                                    timeScale={timeScale}
                                    selectedDate={selectedDate}
                                    onTimelineClick={handleTimelineClick}
                                    onTransportClick={handleTimelineTransportClick}
                                    detailsPanelOpen={detailsPanelOpen}
                                    countyColors={countyColors}
                                    onCreateBreak={handleCreateBreak}
                                    onDeleteBreak={handleDeleteBreak}
                                    onTransportDrop={handleTransportDrop}
                                    onShowRoundTrip={handleShowRoundTrip}
                                    blinkingTransports={blinkingTransports}
                                    onSuccess={() => {
                                        fetchData();
                                    }}
                                />
                            </Box>
                        ) : (
                            <Paper elevation={2} sx={{
                                height: '100%',
                                overflow: 'auto',
                                width: '100%',
                                '& .MuiDataGrid-root': {
                                    height: '80vh !important' // Feste Höhe analog zur Timeline
                                },
                                '& table': {  // Tabellenstil anpassen
                                    width: '100%',
                                    tableLayout: 'fixed'  // Verbessert das Verhalten breiter Tabellen
                                }
                            }}>
                                <VehicleTransportTable
                                    transports={filteredTableTransports}
                                    onSelectRow={handleSelectRow}
                                    selectedId={selectedTransportId}
                                    // Aktionsfunktionen hinzufügen
                                    onDetails={(e, transport) => {
                                        e.stopPropagation();
                                        handleSelectRow(transport.transport_id, transport.planning_id, transport);
                                    }}
                                    onEdit={(e, transport) => handleTimelineTransportClick(e, transport)}
                                    onDelete={(e, transport) => handleDeleteBreak(e, transport)}
                                    onDuplicate={(e, transport) => {
                                        e.stopPropagation();
                                        // Hier müsste die Duplizierfunktion aufgerufen werden, die vermutlich in PlanningTimeline existiert
                                        // Funktionsaufruf je nach Implementierung in PlanningTimeline anpassen
                                    }}
                                    onAddBreakAfter={(e, transport) => handleCreateBreak(e, transport)}
                                    onShowRoundTrip={(e, transport) => handleShowRoundTrip(e, transport)}
                                />
                            </Paper>
                        )}
                    </Box>

                    {/* Using the new drawer component */}
                    <VehicleTimelineDrawer
                        open={detailsPanelOpen}
                        onClose={handleClosePanel}
                        selectedTransport={selectedTransport}
                        selectedTransportId={selectedTransportId}
                        selectedPlanningId={selectedPlanningId}
                    />
                </Box>

                {/* Dialog für Transport-Details (nur für Timeline-Ansicht) */}
                <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="sm" fullWidth>
                    <DialogTitle>Transport-Details</DialogTitle>
                    <DialogContent>
                        {selectedTransport && (
                            <Box>
                                <Typography variant="body1">
                                    <strong>Transport-ID:</strong> {selectedTransport.transport_id || 'Keine ID'}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Fahrzeug:</strong> {selectedTransport.vehicle_name || selectedTransport.vehicle_id}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Typ:</strong> {selectedTransport.planning_type}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Startzeit:</strong> {getTimeString(selectedTransport.planned_start)}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Endzeit:</strong> {getTimeString(selectedTransport.planned_end)}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Dauer:</strong>{' '}
                                    {dayjs(selectedTransport.planned_end).diff(dayjs(selectedTransport.planned_start), 'minute')} Minuten
                                </Typography>
                            </Box>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDialogOpen(false)}>Schließen</Button>
                    </DialogActions>
                </Dialog>

                {breakDialogData && (
                    <BreakDialog
                        open={breakDialogOpen}
                        onClose={handleBreakDialogClose}
                        vehicleId={breakDialogData.vehicleId}
                        vehicleName={breakDialogData.vehicleName}
                        initialDate={breakDialogData.initialDate}
                        initialTime={breakDialogData.initialTime}
                        editMode={breakDialogData.editMode || false}
                        breakData={breakDialogData.breakData || null}
                    />
                )}
            </Box>
        </LocalizationProvider >
    );
}