import React from 'react';
import { Typography, Box, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { DrkCard, DrkInfoBox } from './DrkComponents'; // Import DRK-Komponenten

const Content = () => {

    const navigate = useNavigate();

    const handleCreateTransport = () => {
        navigate('/transport');
    };

    const backgroundImageUrl = `${process.env.REACT_APP_BASE_URL}/login_back.jpg`;


    return (
        <Box>
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: '300px',
                    backgroundImage: `url(${backgroundImageUrl})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    marginBottom: '20px',
                    borderRadius: '16px', // Anpassung an Theme-Standards
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                }}
            />
            <Grid container spacing={4} columns={{ xs: 4, md: 12 }}>
                <Grid item xs={6}>
                    {/* Ersetzen von Paper mit DrkCard für konsistentes Theme-basiertes Styling */}
                    <DrkCard
                        title="Willkommen bei KTW-Anmeldung.de!"
                        elevation={3}
                        sx={{ minHeight: '400px', maxWidth: '100%' }}
                    >
                        <Typography variant="body1" align="center" sx={{ marginTop: 2 }}>
                            Hier einige wichtige Informationen für Sie:
                        </Typography>
                        <br />
                        <DrkInfoBox sx={{ textAlign: 'left' }}>
                            <Box component="ul" sx={{ paddingInlineStart: '20px', margin: '0', listStyleType: 'disc' }}>
                                <Typography variant="body1">
                                    <li>Unser regulärer Fahrdienst steht Ihnen von <strong>07:00 bis 17:00 Uhr</strong> zur Verfügung.</li>
                                    <br />
                                    <li>Bitte beachten Sie, dass unser Service ausschließlich für Patienten mit einem <strong>Gewicht unter 150 Kg</strong> vorgesehen ist.</li>
                                    <br />
                                    <li>Sie haben die Möglichkeit, Ihre eigenen Bestellungen anzulegen. Nachdem Sie eine Bestellung erstellt haben, wird diese von unserer Leitstelle geprüft. Wir setzen uns bei eventuellen Rückfragen oder zur Bestätigung mit Ihnen in Verbindung.</li>
                                    <br />
                                    <li>In <strong>akuten medizinischen Notfällen</strong> wenden sie sich an die <strong>112</strong>.</li>
                                    <br />
                                    <li>Der <strong>Ärztliche Bereitschaftsdienst</strong> unter der 116117, hilft Ihnen außerhalb der Sprechstundenzeiten bei Erkrankungen, mit denen Sie sonst in die Praxis gehen würden und deren Behandlung nicht bis zum nächsten Tag warten kann.</li>
                                </Typography>
                            </Box>
                        </DrkInfoBox>
                        <Typography variant="body1" align="center" sx={{ marginTop: 2, fontStyle: 'italic' }}>
                            Vielen Dank für Ihr Verständnis und Ihre Kooperation!
                        </Typography>
                    </DrkCard>
                </Grid>
                <Grid item xs={6}>
                    {/* Ersetzen von Paper mit DrkCard für konsistentes Theme-basiertes Styling */}
                    <DrkCard
                        title="Bestellen Sie einen Krankentransportwagen (KTW) - online über dieses Portal!"
                        elevation={3}
                        sx={{ minHeight: '400px', maxWidth: '100%', textAlign: 'center' }}
                    >
                        <DrkInfoBox>
                            <Box component="ul" sx={{ paddingInlineStart: '20px', margin: '0 auto', listStyleType: 'disc', textAlign: 'left' }}>
                                <Typography variant="body1" align="center" sx={{ marginTop: 2 }}>
                                    Beginnen Sie, indem Sie uns einige grundlegende Informationen über den <strong>Patienten</strong> mitteilen.
                                </Typography><br />
                                <Typography variant="body1" align="center">
                                    Anschließend benötigen wir Details zum gewünschten <strong>Zeitpunkt</strong> und den <strong>Abfahrts- und Zielorten</strong> des Transports.
                                    Ein paar zusätzliche Angaben, und schon sind Sie fertig!
                                </Typography>
                                <Typography variant="body1" align="center" sx={{ fontStyle: 'italic', marginTop: 2 }}>
                                    Ihre Bestellung wird umgehend in der Leitstelle geprüft, und Sie erhalten eine Bestätigung für den Krankentransport. <br />
                                </Typography>
                                <Typography variant="body1" align="center" sx={{ marginTop: 2 }}>
                                    Legen Sie gleich los, und wir werden den Transport so reibungslos und komfortabel wie möglich für Sie gestalten!
                                </Typography>
                            </Box>
                        </DrkInfoBox>
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <Button variant="contained" color="primary" onClick={handleCreateTransport} startIcon={<ArrowForwardIcon />}>
                                Neuen Transport bestellen
                            </Button>
                        </Box>
                    </DrkCard>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Content;