// DrkComponents.js
import React, { useState, forwardRef, useMemo } from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Container,
    Link,
    TextField,
    FormControl,
    FormHelperText,
    InputLabel,
    Button,
    Paper,
    InputAdornment,
    IconButton,
    CircularProgress,
    Select,
    FormControlLabel,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Autocomplete as MuiAutocomplete
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useThemeContext, THEME_CLASSIC } from '../theme/ThemeContext';
import { useUser } from '../components/userContext';
import MenuItem from '@mui/material/MenuItem';

/**
 * DRK-PageContainer - Hauptcontainer für alle Seiten
 */
export const DrkPageContainer = ({ children, maxWidth = "lg" }) => (
    <Container maxWidth={maxWidth} sx={{ py: 5 }}>
        {children}
    </Container>
);

/**
 * DrkCard - Basiskarte mit DRK-Styling
 */
export const DrkCard = ({ children, title, subtitle, maxWidth, elevation = 2, ...props }) => {
    const { currentTheme } = useThemeContext();

    // Theme-spezifisches Styling
    const isClassic = currentTheme === THEME_CLASSIC;

    return (
        <Card
            elevation={elevation}
            sx={{
                maxWidth: maxWidth || '650px',
                mx: 'auto',
                borderRadius: isClassic ? '4px' : '16px', // Kleinerer Radius im klassischen Theme
                overflow: 'hidden',
                ...props.sx
            }}
        >
            {/* Farbiger Balken am oberen Rand - nur im modernen Theme anzeigen */}
            {!isClassic && (
                <Box sx={{ height: '8px', bgcolor: 'primary.main' }} />
            )}

            <CardContent sx={{ p: isClassic ? { xs: 2, sm: 3 } : { xs: 2, sm: 4 } }}>
                {title && (
                    <Typography
                        variant="h5"
                        gutterBottom
                        textAlign="center"
                        fontWeight={isClassic ? "400" : "500"}
                        color="primary"
                        sx={{ mb: subtitle ? 1 : 4 }}
                    >
                        {title}
                    </Typography>
                )}
                {subtitle && (
                    <Typography
                        variant="body1"
                        color="text.secondary"
                        align="center"
                        sx={{ mb: 4 }}
                    >
                        {subtitle}
                    </Typography>
                )}

                {children}
            </CardContent>
        </Card>
    );
};

/**
 * DrkIconCircle - Runder Icon-Container
 */
export const DrkIconCircle = ({ icon, color = "primary", size = "medium", sx = {} }) => {
    // Größenoptionen
    const sizes = {
        small: { circle: 50, icon: 24 },
        medium: { circle: 60, icon: 30 },
        large: { circle: 80, icon: 45 },
    };

    const { circle, icon: iconSize } = sizes[size] || sizes.medium;
    const bgColor = color === "primary"
        ? 'rgba(228, 100, 80, 0.1)'
        : color === "secondary"
            ? 'rgba(76, 175, 80, 0.1)'
            : 'rgba(0, 0, 0, 0.05)';

    const iconColor = color === "primary"
        ? '#E46450'
        : color === "secondary"
            ? '#4CAF50'
            : 'rgba(0, 0, 0, 0.6)';

    return (
        <Box sx={{
            width: circle,
            height: circle,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: bgColor,
            mb: 2,
            ...sx
        }}>
            {React.cloneElement(icon, { sx: { fontSize: iconSize, color: iconColor } })}
        </Box>
    );
};

/**
 * DrkInfoBox - Box für Informationstexte mit leichtem Hintergrund
 */
export const DrkInfoBox = ({ children, color = "primary", sx = {} }) => {
    const { currentTheme } = useThemeContext();
    const isClassic = currentTheme === THEME_CLASSIC;

    // Theme-spezifisches Styling für Hintergrundfarbe und Border-Radius
    const bgColor = isClassic
        ? 'rgba(0, 0, 0, 0.03)' // Neutralerer Hintergrund im klassischen Theme
        : color === "primary"
            ? 'rgba(228, 100, 80, 0.05)'
            : color === "secondary"
                ? 'rgba(76, 175, 80, 0.05)'
                : 'rgba(0, 0, 0, 0.03)';

    return (
        <Box
            sx={{
                py: 3,
                px: 2,
                bgcolor: bgColor,
                borderRadius: isClassic ? '4px' : '12px',
                textAlign: 'center',
                ...sx
            }}
        >
            {children}
        </Box>
    );
};

/**
 * DrkLoginLink - Standardisierter Link zur Anmeldeseite
 */
export const DrkLoginLink = ({ text = "Zurück zur Anmeldung" }) => (
    <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Link
            href="/login"
            sx={{
                color: 'primary.main',
                textDecoration: 'none',
                fontWeight: 'medium',
                '&:hover': {
                    textDecoration: 'underline'
                },
                display: 'inline-flex',
                alignItems: 'center'
            }}
        >
            {text}
        </Link>
    </Box>
);

/**
 * DrkDialogCard - Spezialisierte Kartenkomponente für Dialoge
 * Optimiert für die Verwendung innerhalb von Material-UI-Dialogen
 */
export const DrkDialogCard = ({ children, title, subtitle, ...props }) => {
    const { currentTheme } = useThemeContext();
    const isClassic = currentTheme === THEME_CLASSIC;

    return (
        <Box
            sx={{
                borderRadius: isClassic ? '4px' : '8px',
                overflow: 'hidden',
                ...props.sx
            }}
        >
            {/* Farbiger Balken am oberen Rand - nur im modernen Theme und wenn angefordert */}
            {!isClassic && props.showHeaderBar && (
                <Box sx={{ height: '4px', bgcolor: 'primary.main' }} />
            )}

            <Box sx={{ p: isClassic ? { xs: 1, sm: 2 } : { xs: 1.5, sm: 2.5 } }}>
                {title && (
                    <Typography
                        variant="h6"
                        gutterBottom
                        fontWeight={isClassic ? "400" : "500"}
                        color="primary"
                        sx={{ mb: subtitle ? 1 : 2 }}
                    >
                        {title}
                    </Typography>
                )}
                {subtitle && (
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mb: 2 }}
                    >
                        {subtitle}
                    </Typography>
                )}

                {children}
            </Box>
        </Box>
    );
};

/**
 * DrkDataGrid - Angepasste DataGrid-Komponente mit PersistentDataGrid-Funktionalität
 * Erweitert die MUI DataGrid mit persistentem Speicher (analog zu PersistentDataGrid)
 */
export const DrkDataGrid = ({
    rows,
    columns,
    storageKey,
    loading = false,
    error = null,
    emptyText = "Keine Daten verfügbar",
    onRowClick,
    onColumnWidthChange: externalColumnWidthChange,
    ...props
}) => {
    const { currentTheme } = useThemeContext();
    const isClassic = currentTheme === THEME_CLASSIC;
    const { settings, updateSetting } = useUser();

    // Auslesen der persistierten Breiten
    const storedWidths = useMemo(() => settings[storageKey] || {}, [settings, storageKey]);

    // Mergen der übergebenen Spalten mit den persistierten Breiten
    const mergedColumns = useMemo(() => {
        return columns.map((col) => ({
            ...col,
            width: storedWidths[col.field] || col.width || 150,
        }));
    }, [columns, storedWidths]);

    // Handler für Spaltenbreitenänderung
    const handleColumnWidthChange = (params, event) => {
        const { colDef, width } = params;
        // Aktualisierte Breiten speichern
        const updatedWidths = { ...storedWidths, [colDef.field]: width };
        updateSetting(storageKey, updatedWidths);

        // Falls ein externer Handler existiert, diesen auch aufrufen
        if (externalColumnWidthChange) {
            externalColumnWidthChange(params, event);
        }
    };

    return (
        <Paper
            elevation={0}
            sx={{
                width: '100%',
                mb: 2,
                borderRadius: isClassic ? 1 : 2,
                border: '1px solid #e0e0e0',
                overflow: 'hidden'
            }}
        >
            {error && (
                <Box sx={{ p: 2, color: 'error.main', bgcolor: 'error.light' }}>
                    <Typography variant="body2">{error}</Typography>
                </Box>
            )}

            <DataGrid
                rows={rows}
                columns={mergedColumns} // Verwenden der gemergten Spalten
                onColumnWidthChange={handleColumnWidthChange}
                autoHeight
                disableSelectionOnClick
                loading={loading}
                onRowClick={onRowClick}
                sx={{
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: isClassic ? '#f5f5f5' : 'rgba(228, 100, 80, 0.05)',
                        fontWeight: 'bold',
                    },
                    '& .MuiDataGrid-cell:focus': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-row:hover': {
                        backgroundColor: isClassic ? '#f5f5f5' : 'rgba(228, 100, 80, 0.03)',
                    },
                    ...props.sx
                }}
                localeText={{
                    noRowsLabel: emptyText,
                    footerRowSelected: (count) => `${count} Zeile${count !== 1 ? 'n' : ''} ausgewählt`,
                }}
                {...props}
            />
        </Paper>
    );
};

/**
 * DrkFormField - Formularfeld-Komponente mit einheitlichem Styling
 * Unterstützt verschiedene Feldtypen wie Text, Select, Checkbox, usw.
 */
export const DrkFormField = forwardRef(({
    label,
    type = 'text',
    error,
    helperText,
    fullWidth = true,
    required = false,
    options = [], // Für Select-Felder
    value,
    onChange,
    ...props
}, ref) => {
    const { currentTheme } = useThemeContext();
    const isClassic = currentTheme === THEME_CLASSIC;
    const borderRadius = isClassic ? '4px' : '8px';

    // Basierend auf dem Typ des Feldes rendern wir unterschiedliche Komponenten
    switch (type) {
        case 'select':
            return (
                <FormControl
                    fullWidth={fullWidth}
                    error={!!error}
                    required={required}
                    margin="normal"
                    {...props}
                >
                    <InputLabel>{label}</InputLabel>
                    <Select
                        value={value}
                        onChange={onChange}
                        label={label}
                        ref={ref}
                        sx={{ borderRadius }}
                    >
                        {options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                    {(error || helperText) && (
                        <FormHelperText>{error || helperText}</FormHelperText>
                    )}
                </FormControl>
            );

        case 'checkbox':
            return (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={value}
                            onChange={onChange}
                            ref={ref}
                            {...props}
                        />
                    }
                    label={label}
                />
            );

        // Für alle anderen Typen (text, password, email, etc.) verwenden wir TextField
        default:
            return (
                <TextField
                    label={label}
                    type={type}
                    fullWidth={fullWidth}
                    error={!!error}
                    helperText={error || helperText}
                    required={required}
                    margin="normal"
                    value={value}
                    onChange={onChange}
                    InputProps={{
                        sx: { borderRadius }
                    }}
                    ref={ref}
                    {...props}
                />
            );
    }
});

/**
 * DrkActionBar - Standardisierte Komponente für Aktionsleisten
 */
export const DrkActionBar = ({
    searchTerm = '',
    onSearchChange,
    actions = [],
    showSearch = true,
    searchPlaceholder = "Suchen...",
    sx = {}
}) => {
    const { currentTheme } = useThemeContext();
    const isClassic = currentTheme === THEME_CLASSIC;

    // State für Suchfeld
    const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm);

    // Handling für die Suche
    const handleSearchChange = (event) => {
        const newValue = event.target.value;
        setLocalSearchTerm(newValue);
        if (onSearchChange) {
            onSearchChange(newValue);
        }
    };

    // Handling für das Löschen der Suche
    const handleClearSearch = () => {
        setLocalSearchTerm('');
        if (onSearchChange) {
            onSearchChange('');
        }
    };

    return (
        <Paper
            elevation={0}
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 1,
                mb: 2,
                borderRadius: isClassic ? 1 : 2,
                bgcolor: 'background.paper',
                ...sx
            }}
        >
            {showSearch && (
                <TextField
                    placeholder={searchPlaceholder}
                    variant="outlined"
                    size="small"
                    value={localSearchTerm}
                    onChange={handleSearchChange}
                    sx={{
                        width: { xs: '60%', sm: '70%' },
                        '& .MuiOutlinedInput-root': {
                            borderRadius: isClassic ? 1 : 2
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon color="action" />
                            </InputAdornment>
                        ),
                        endAdornment: localSearchTerm ? (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="clear search"
                                    onClick={handleClearSearch}
                                    edge="end"
                                    size="small"
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                        ) : null
                    }}
                />
            )}

            {/* Actions-Bereich für Buttons */}
            <Box sx={{ display: 'flex', gap: 1 }}>
                {actions.map((action, index) => (
                    <Button
                        key={index}
                        variant={action.variant || "contained"}
                        color={action.color || "primary"}
                        startIcon={action.icon}
                        onClick={action.onClick}
                        disabled={action.disabled}
                        size={action.size || "medium"}
                    >
                        {action.label}
                    </Button>
                ))}
            </Box>
        </Paper>
    );
};

/**
 * DrkPreviewFrame - Spezieller Container für Vorschau-Inhalte
 */
export const DrkPreviewFrame = ({
    html,
    height = '600px',
    loading = false,
    error = null,
    showCopyButton = true,
    onCopy,
    sx = {}
}) => {
    const { currentTheme } = useThemeContext();
    const isClassic = currentTheme === THEME_CLASSIC;

    // Handling für das Kopieren des HTML-Codes
    const handleCopy = () => {
        if (html && onCopy) {
            onCopy(html);
        } else if (html) {
            navigator.clipboard.writeText(html);
        }
    };

    return (
        <Box sx={{ position: 'relative', ...sx }}>
            {/* Fehleranzeige */}
            {error && (
                <Box
                    sx={{
                        p: 2,
                        color: 'error.main',
                        bgcolor: 'error.light',
                        borderRadius: isClassic ? 1 : 2,
                        mb: 2
                    }}
                >
                    <Typography variant="body2">{error}</Typography>
                </Box>
            )}

            {/* Loading-Indikator */}
            {loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: height,
                        bgcolor: '#f9f9f9',
                        borderRadius: isClassic ? 1 : 2,
                        border: '1px solid #ddd'
                    }}
                >
                    <CircularProgress size={40} />
                </Box>
            ) : (
                <Box
                    sx={{
                        border: '1px solid #ddd',
                        borderRadius: isClassic ? 1 : 2,
                        overflow: 'hidden',
                        height: height,
                        position: 'relative'
                    }}
                >
                    {/* Copy-Button */}
                    {showCopyButton && html && (
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<ContentCopyIcon />}
                            onClick={handleCopy}
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                zIndex: 2,
                                opacity: 0.9,
                                '&:hover': {
                                    opacity: 1
                                }
                            }}
                        >
                            Kopieren
                        </Button>
                    )}

                    {/* Der eigentliche Frame mit dem HTML-Inhalt */}
                    <iframe
                        srcDoc={html}
                        title="Vorschau"
                        width="100%"
                        height="100%"
                        style={{ border: 'none' }}
                    />
                </Box>
            )}
        </Box>
    );
};

/**
 * DrkDialog - Spezieller moderner Dialog mit DRK-Styling, 
 * inspiriert vom AvailabilityCheckDialog
 */
export const DrkDialog = ({
    open,
    onClose,
    title,
    children,
    actions,
    maxWidth = "md",
    fullWidth = true,
    headerActions = null,
    ...props
}) => {
    const { currentTheme } = useThemeContext();
    const isClassic = currentTheme === THEME_CLASSIC;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            PaperProps={{
                sx: {
                    borderRadius: isClassic ? '4px' : '12px',
                    overflow: 'hidden'
                }
            }}
            {...props}
        >
            {!isClassic ? (
                // Modernes Theme mit farbiger Titelleiste
                <DialogTitle sx={{
                    backgroundColor: 'primary.main',
                    color: '#fff',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <Typography variant="inherit">
                        {title}
                    </Typography>
                    {headerActions && (
                        <Box>
                            {headerActions}
                        </Box>
                    )}
                </DialogTitle>
            ) : (
                // Klassisches Theme mit Standard-Titelleiste
                <DialogTitle>
                    {title}
                </DialogTitle>
            )}

            <DialogContent sx={{ p: isClassic ? 2 : 3, pt: isClassic ? 2 : 2, backgroundColor: isClassic ? 'transparent' : '#f9fafb' }}>
                {children}
            </DialogContent>

            {actions && (
                <DialogActions sx={{ justifyContent: 'flex-end', p: 2, backgroundColor: isClassic ? 'transparent' : '#f9fafb' }}>
                    {actions}
                </DialogActions>
            )}
        </Dialog>
    );
};

export const DrkTransportDesignBox = ({ children, isExpertMode, minHeight, ...props }) => {
    const { currentTheme } = useThemeContext();
    const isClassic = currentTheme === THEME_CLASSIC;
    const showTopBorder = false;
    return (
        <Box
            mt={isExpertMode ? 0 : 4}
            mb={2}
            pt={isExpertMode ? 2 : 12}
            pl={isExpertMode ? 2 : 4}
            pr={isExpertMode ? 2 : 4}
            pb={isExpertMode ? 2 : 4}
            sx={{
                background: 'linear-gradient(180deg, #FFFFFF 0%, #F8F8F8 50%, #F5F5F5 100%)', // Verbesserter Gradient
                borderRadius: isClassic ? '4px' : '16px', // Modernerer Radius im neuen Design
                width: isExpertMode ? 'auto' : '100%',
                border: isClassic ? '1px solid #F0F0F0' : '1px solid rgba(228, 100, 80, 0.1)', // Subtile farbige Border im modernen Theme
                boxShadow: isClassic ? 'none' : '0 4px 12px rgba(0, 0, 0, 0.03)', // Leichter Schatten im modernen Theme
                position: 'relative',
                zIndex: 1,
                transition: 'all 0.2s ease-in-out', // Smooth transition für Hover-Effekte
                '&:hover': {
                    boxShadow: isClassic ? 'none' : '0 6px 16px rgba(0, 0, 0, 0.05)', // Verstärkter Schatten beim Hover im modernen Theme
                },
            }}
            minHeight={minHeight}
            {...props}
        >
            {/* Dekorativer Akzent am oberen Rand - nur im modernen Theme UND im Expertenmodus */}
            {!isClassic && isExpertMode && showTopBorder && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: '5%',
                        width: '90%',
                        height: '4px',
                        background: 'linear-gradient(90deg, rgba(228, 100, 80, 0.8) 0%, rgba(228, 100, 80, 0.4) 100%)',
                        borderRadius: '0 0 4px 4px',
                    }}
                />
            )}
            {children}
        </Box>
    );
};

export const DrkBooleanButtonGroup = ({
    name,
    fieldName,
    label,
    options,
    formData,
    setFormData,
    ...props
}) => {
    const { currentTheme } = useThemeContext();
    const isClassic = currentTheme === THEME_CLASSIC;

    const handleChange = (value) => {
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };

    return (
        <Box sx={{ mb: 2 }}>
            <Typography variant="body1" gutterBottom>{label}</Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
                {options.map((option) => (
                    <Button
                        key={option.value}
                        variant={formData[fieldName] === option.value ? 'contained' : 'outlined'}
                        color="primary"
                        onClick={() => handleChange(option.value)}
                        sx={{
                            borderRadius: isClassic ? '4px' : '8px',
                            textTransform: 'none',
                        }}
                        {...props}
                    >
                        {option.label}
                    </Button>
                ))}
            </Box>
        </Box>
    );
};

export const DrkAutocomplete = ({
    options,
    getOptionLabel,
    onChange,
    label,
    error,
    helperText,
    ...props
}) => {
    const { currentTheme } = useThemeContext();
    const isClassic = currentTheme === THEME_CLASSIC;

    return (
        <MuiAutocomplete
            options={options}
            getOptionLabel={getOptionLabel}
            onChange={onChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    error={error}
                    helperText={helperText}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: isClassic ? '4px' : '8px',
                        },
                    }}
                />
            )}
            {...props}
        />
    );
};

const DrkComponents = {
    DrkPageContainer,
    DrkCard,
    DrkIconCircle,
    DrkInfoBox,
    DrkLoginLink,
    DrkDialogCard,
    DrkDataGrid,
    DrkFormField,
    DrkActionBar,
    DrkPreviewFrame,
    DrkDialog,
    DrkTransportDesignBox,
    DrkBooleanButtonGroup,
    DrkAutocomplete,
};

export default DrkComponents;


