// src/dialogs/AssignStationDialog.js
import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    Button,
    List,
    ListItem,
    ListItemText,
    IconButton,
    InputAdornment
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useUser } from '../../components/userContext';
import { useSnackbar } from 'notistack';

/**
 * Wiederverwendbarer Dialog, um einem Fahrzeug eine Wache zuzuweisen.
 * 
 * Props:
 *  - open (bool)
 *  - onClose(success: bool): schließt den Dialog. success = true, wenn erfolgreich zugewiesen
 *  - vehicle (object): das Fahrzeug, dem wir eine Wache zuweisen
 *  - onAssigned(): optional, wird aufgerufen, wenn die Zuordnung erfolgreich war
 */
const AssignStationDialog = ({ open, onClose, vehicle, onAssigned }) => {
    const { user } = useUser();
    const [stations, setStations] = useState([]);
    const [stationSearch, setStationSearch] = useState('');
    // eslint-disable-next-line 
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    // Wachen laden (nur wenn Dialog offen)
    useEffect(() => {
        if (open) {
            fetchStations();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const fetchStations = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/stations`, {
                headers: { Authorization: user.api_key }
            });
            if (!response.ok) {
                throw new Error('Fehler beim Laden der Wachen');
            }
            const data = await response.json();
            setStations(data);
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleAssign = async (station) => {
        // station ist das gewählte Station-Objekt
        if (!vehicle) return;
        try {
            const resp = await fetch(`${process.env.REACT_APP_API_URI}/api/vehicles/assignStation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user.api_key
                },
                body: JSON.stringify({
                    vehicle_id: vehicle.vehicle_id,
                    station_id: station.station_id
                })
            });
            if (!resp.ok) {
                const errData = await resp.json();
                throw new Error(errData.error || 'Fehler bei der Wache-Zuordnung');
            }

            // Erfolg
            enqueueSnackbar('Wache wurde erfolgreich zugeordnet', { variant: 'success' });
            onClose(true);
            onAssigned && onAssigned();
        } catch (err) {
            enqueueSnackbar(`Fehler beim Wache-Wechsel: ${err.message}`, { variant: 'error' });
        }
    };

    return (
        <Dialog open={open} onClose={() => onClose(false)}
            fullWidth
            maxWidth="sm"
            scroll="paper"
        >
            <DialogTitle>Wache zuordnen</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Bitte wählen Sie eine Wache aus.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Wache suchen"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={stationSearch}
                    onChange={e => setStationSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: stationSearch && (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setStationSearch('')}>
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />

                <List sx={{ maxHeight: 600, overflow: 'auto' }}>
                    {stations
                        .filter(s =>
                            s.name?.toLowerCase().includes(stationSearch.toLowerCase()) ||
                            s.city?.toLowerCase().includes(stationSearch.toLowerCase())
                        )
                        .map(station => (
                            <ListItem
                                key={station.station_id}
                                button
                                onClick={() => handleAssign(station)}
                            >
                                <ListItemText
                                    primary={station.name}
                                    secondary={
                                        `${station.street || ''} ${station.house_number || ''}, ` +
                                        `${station.postal_code || ''} ${station.city || ''}`
                                    }
                                />
                            </ListItem>
                        ))
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(false)}>Abbrechen</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AssignStationDialog;