import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Box,
    Grid,
    Button,
    Paper,
    CircularProgress,
    Chip,
    IconButton,
    Tooltip,
    useTheme,
    Tab,
    Tabs,
    // Für den Kalender
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import {
    CheckCircle as CheckCircleIcon,
    Warning as WarningIcon,
    Cancel as CancelIcon,
    Schedule as ScheduleIcon,
    ArrowForward as ArrowForwardIcon,
    LocationOn as LocationOnIcon,
    NavigateNext as NavigateNextIcon,
    Refresh as RefreshIcon,
    CalendarMonth as CalendarMonthIcon
} from '@mui/icons-material';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { useUser } from '../../components/userContext';

const AvailabilityCheckDialog = ({
    open,
    onClose,
    onSelectTimeSlot,
    requestData = {},
    preloadedData = null
}) => {
    const theme = useTheme();
    const { user } = useUser();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [availabilityData, setAvailabilityData] = useState(null);
    const [showCalendar, setShowCalendar] = useState(false);
    const [calendarLoading, setCalendarLoading] = useState(false);
    const [calendarData, setCalendarData] = useState([]);
    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedTab, setSelectedTab] = useState('matrix');

    // Format für die Anzeige der Zeit
    const formatTimeDisplay = (timeString) => {
        return timeString ? dayjs(timeString, 'HH:mm:ss').format('HH:mm') : '';
    };

    // Format für das Datum
    const formatDate = (dateString) => {
        return dateString ? dayjs(dateString).format('DD.MM.YYYY') : '';
    };

    // Anzeige für die Ampelfarben
    const getStatusDisplay = (status) => {
        switch (status) {
            case 'green':
                return {
                    icon: <CheckCircleIcon sx={{ color: '#4caf50' }} />,
                    text: 'Mehrere Fahrzeuge verfügbar',
                    color: '#4caf50',
                    textColor: '#fff',
                    backgroundColor: '#e8f5e9'
                };
            case 'yellow':
                return {
                    icon: <WarningIcon sx={{ color: '#ff9800' }} />,
                    text: 'Nur ein Fahrzeug verfügbar',
                    color: '#ff9800',
                    textColor: '#fff',
                    backgroundColor: '#fff8e1'
                };
            case 'red':
                return {
                    icon: <CancelIcon sx={{ color: '#f44336' }} />,
                    text: 'Keine Fahrzeuge verfügbar',
                    color: '#f44336',
                    textColor: '#fff',
                    backgroundColor: '#ffebee'
                };
            default:
                return {
                    icon: <CancelIcon sx={{ color: '#757575' }} />,
                    text: 'Status unbekannt',
                    color: '#757575',
                    textColor: '#fff',
                    backgroundColor: '#f5f5f5'
                };
        }
    };

    // Zeitslot-Auswahl
    const handleTimeSlotSelect = (slot) => {
        if (slot.status === 'red') return; // Keine Aktion bei roten Slots

        // Überprüfung, ob der Termin in der Vergangenheit liegt
        const slotTime = dayjs(`${slot.date} ${slot.time}`);
        const now = dayjs();

        if (slotTime.isBefore(now)) {
            return; // Keine Aktion bei Terminen in der Vergangenheit
        }

        if (onSelectTimeSlot) {
            onSelectTimeSlot({
                date: slot.date,
                time: slot.time,
                availableVehicles: slot.availableVehicles
            });
        }

        onClose();
    };

    // Lade die Verfügbarkeitsdaten
    const fetchAvailabilityData = async () => {
        setLoading(true);
        setError(null);

        try {
            // API-Anfrage
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/availability/check`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': user.api_key
                },
                body: JSON.stringify(requestData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Fehler bei der Verfügbarkeitsprüfung');
            }

            const data = await response.json();
            setAvailabilityData(data);
        } catch (err) {
            console.error('Fehler beim Abrufen der Verfügbarkeitsdaten:', err);
            setError(err.message || 'Fehler beim Laden der Verfügbarkeitsdaten');
        } finally {
            setLoading(false);
        }
    };

    // Lade Kalender-Daten für mehrere Tage
    const fetchCalendarData = async () => {
        setCalendarLoading(true);

        try {
            const now = dayjs();
            const requestedDay = dayjs(requestData.date);

            // 3 Tage vor und nach dem Wunschtag
            const startDay = requestedDay.subtract(3, 'day');
            const days = [];

            // Füge 7 Tage hinzu, aber keine Tage in der Vergangenheit
            for (let i = 0; i < 7; i++) {
                const currentDay = startDay.add(i, 'day');
                // Überspringe Tage in der Vergangenheit
                if (currentDay.isBefore(now, 'day')) continue;
                days.push(currentDay.format('YYYY-MM-DD'));
            }

            // Anfragen für alle Tage starten
            const results = await Promise.all(
                days.map(day =>
                    fetch(`${process.env.REACT_APP_API_URI}/api/availability/day`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': user.api_key
                        },
                        body: JSON.stringify({
                            ...requestData,
                            date: day
                        })
                    })
                        .then(res => res.json())
                        .catch(err => ({
                            success: false,
                            date: day,
                            error: err.message
                        }))
                )
            );

            // Gültige Ergebnisse sammeln
            const validResults = results.filter(r => r.success);
            setCalendarData(validResults);

            // Setze den ausgewählten Tag automatisch auf den angeforderten Tag
            setSelectedDay(requestedDay.format('YYYY-MM-DD'));

        } catch (err) {
            console.error('Fehler beim Laden der Kalenderdaten:', err);
        } finally {
            setCalendarLoading(false);
        }
    };

    // Lade Daten beim Öffnen des Dialogs
    // Verwenden der vorgeladenen Daten, wenn vorhanden
    // In useEffect in AvailabilityCheckDialog
    useEffect(() => {
        if (open && requestData.date && requestData.time) {
            // Prüfen ob die vorgeladenen Daten mit der aktuellen Anfrage übereinstimmen
            const isPreloadedDataValid = preloadedData &&
                preloadedData.fromAddress &&
                preloadedData.fromAddress.includes(requestData.from_street) &&
                preloadedData.fromAddress.includes(requestData.from_postal_code) &&
                preloadedData.toAddress &&
                preloadedData.toAddress.includes(requestData.to_street) &&
                preloadedData.toAddress.includes(requestData.to_postal_code);

            if (isPreloadedDataValid) {
                // Wenn vorgeladene Daten gültig sind, diese direkt verwenden
                setAvailabilityData(preloadedData);
                setLoading(false);
            } else {
                // Ansonsten neu laden
                fetchAvailabilityData();
            }
            setShowCalendar(false);
        }
        // eslint-disable-next-line 
    }, [open, requestData, preloadedData]);

    // Toggle Kalenderansicht und lade Daten, wenn nötig
    const toggleCalendarView = () => {
        // if (!showCalendar && calendarData.length === 0) {
        if (!showCalendar) {
            fetchCalendarData();
        }
        setShowCalendar(!showCalendar);
        setSelectedTab(showCalendar ? 'matrix' : 'calendar');
    };

    // Keine Daten vorhanden
    if (!requestData || !requestData.date || !requestData.time) {
        return (
            <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
                <DialogTitle>Verfügbarkeitsprüfung</DialogTitle>
                <DialogContent>
                    <Typography>
                        Keine ausreichenden Daten für die Verfügbarkeitsprüfung vorhanden.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Schließen</Button>
                </DialogActions>
            </Dialog>
        );
    }

    // Prüfen, ob der gewünschte Termin in der Vergangenheit liegt
    const requestedTime = dayjs(`${requestData.date} ${requestData.time}`);
    const isRequestedTimeInPast = requestedTime.isBefore(dayjs());

    // Funktion zum Rendern des Kalender-Inhalts
    const renderCalendarContent = () => {
        if (calendarLoading) {
            return (
                <Box display="flex" justifyContent="center" alignItems="center" py={3}>
                    <CircularProgress size={40} />
                    <Typography sx={{ ml: 2 }}>
                        Lade Kalenderdaten... Dieser Vorgang kann einige Minuten in Anspruch nehmen.
                    </Typography>
                </Box>
            );
        }

        if (calendarData.length === 0) {
            return (
                <Typography variant="body1" align="center" py={3}>
                    Keine Kalenderdaten verfügbar.
                </Typography>
            );
        }

        // Sortiere die Daten nach Datum
        const sortedData = [...calendarData].sort((a, b) =>
            dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1
        );

        return (
            <Box py={2}>
                {/* Tage-Navigation */}
                <Box mb={2} display="flex" justifyContent="center">
                    <Tabs
                        value={selectedDay || sortedData[0].date}
                        onChange={(e, newValue) => setSelectedDay(newValue)}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {sortedData.map(day => (
                            <Tab
                                key={day.date}
                                label={dayjs(day.date).format('dd, DD.MM')}
                                value={day.date}
                            />
                        ))}
                    </Tabs>
                </Box>

                {/* Stunden-Tabelle für den ausgewählten Tag */}
                {selectedDay && (
                    <Box>
                        <TableContainer component={Paper} sx={{ maxHeight: 400, borderRadius: '8px' }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="15%" sx={{ fontWeight: 'bold' }}>Uhrzeit</TableCell>
                                        <TableCell width="25%" sx={{ fontWeight: 'bold' }}>Status</TableCell>
                                        <TableCell width="45%" sx={{ fontWeight: 'bold' }}>Verfügbarkeit</TableCell>
                                        <TableCell width="15%" sx={{ fontWeight: 'bold' }} align="center">Aktion</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedData.find(d => d.date === selectedDay)?.timeSlots.map((slot) => {
                                        // Erstelle ein dayjs Objekt für diesen Zeitslot
                                        const slotTime = dayjs(`${selectedDay}T${slot.timeSlot}:00`);
                                        // Prüfe, ob dieser Zeitslot in der Vergangenheit liegt
                                        const isInPast = slotTime.isBefore(dayjs());
                                        // Konvertiere raw_status zu einem Status-Objekt für die Farbgebung
                                        const statusDisplay = getStatusDisplay(slot.raw_status);

                                        return (
                                            <TableRow
                                                key={slot.timeSlot}
                                                sx={{
                                                    backgroundColor: statusDisplay.backgroundColor,
                                                    opacity: isInPast ? 0.5 : 1,
                                                    cursor: !isInPast && slot.raw_status !== 'red' ? 'pointer' : 'default',
                                                    '&:hover': !isInPast && slot.raw_status !== 'red' ? {
                                                        backgroundColor: '#f0f0f0'
                                                    } : {}
                                                }}
                                            >
                                                <TableCell>
                                                    <Typography fontWeight="bold">{slot.timeSlot} Uhr</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Box display="flex" alignItems="center">
                                                        {statusDisplay.icon}
                                                        <Typography variant="body2" sx={{ ml: 1 }}>
                                                            {slot.raw_status === 'green' ? 'Verfügbar' :
                                                                slot.raw_status === 'yellow' ? 'Eingeschränkt' : 'Nicht verfügbar'}
                                                        </Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2">
                                                        {slot.availability}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Button
                                                        variant="outlined"
                                                        size="small"
                                                        disabled={isInPast || slot.raw_status === 'red' || slot.availableVehicles === 0}
                                                        onClick={() => handleTimeSlotSelect({
                                                            date: selectedDay,
                                                            time: `${slot.timeSlot}:00`,
                                                            status: slot.raw_status,
                                                            availableVehicles: slot.availableVehicles
                                                        })}
                                                    >
                                                        Wählen
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                )}
            </Box>
        );
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: '12px',
                    overflow: 'hidden'
                }
            }}
        >
            <DialogTitle sx={{
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Typography variant="inherit">
                    Verfügbarkeitsprüfung für Transport
                </Typography>
                <Box>
                    <IconButton
                        onClick={toggleCalendarView}
                        sx={{ color: '#fff', mr: 1 }}
                    >
                        <CalendarMonthIcon />
                    </IconButton>
                    <IconButton
                        onClick={fetchAvailabilityData}
                        disabled={loading}
                        sx={{ color: '#fff' }}
                    >
                        <RefreshIcon />
                    </IconButton>
                </Box>
            </DialogTitle>

            <DialogContent sx={{ p: 3, pt: 2, backgroundColor: '#f9fafb' }}>
                {/* Header mit Transportdetails */}
                <Paper
                    elevation={1}
                    sx={{
                        p: 2,
                        mb: 3,
                        borderRadius: '8px',
                        backgroundColor: '#fff'
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Box display="flex" alignItems="center" mb={1}>
                                <ScheduleIcon sx={{ color: theme.palette.primary.main, mr: 1 }} />
                                <Typography variant="subtitle1" fontWeight="bold">
                                    Wunschtermin
                                </Typography>
                            </Box>
                            <Typography variant="h5" fontWeight="medium" sx={{ pl: 4 }}>
                                {formatDate(requestData.date)} um {formatTimeDisplay(requestData.time)}
                            </Typography>
                            {isRequestedTimeInPast && (
                                <Typography variant="body2" color="error" sx={{ pl: 4, mt: 1 }}>
                                    Dieser Termin liegt in der Vergangenheit und kann nicht ausgewählt werden.
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box display="flex" alignItems="center" mb={1}>
                                <LocationOnIcon sx={{ color: theme.palette.primary.main, mr: 1 }} />
                                <Typography variant="subtitle1" fontWeight="bold">
                                    Strecke
                                </Typography>
                            </Box>
                            <Box sx={{ pl: 4, display: 'flex', flexDirection: 'column' }}>
                                <Box display="flex" alignItems="flex-start">
                                    <Box sx={{ maxWidth: '45%', mr: 1 }}>
                                        <Typography variant="body1" sx={{
                                            wordBreak: 'break-word',
                                            overflow: 'hidden',
                                            lineHeight: 1.2
                                        }}>
                                            {availabilityData?.fromAddress || "Von"}
                                        </Typography>
                                    </Box>
                                    <ArrowForwardIcon sx={{ mx: 1, flexShrink: 0, mt: 0.5 }} />
                                    <Box sx={{ maxWidth: '45%', ml: 1 }}>
                                        <Typography variant="body1" sx={{
                                            wordBreak: 'break-word',
                                            overflow: 'hidden',
                                            lineHeight: 1.2
                                        }}>
                                            {availabilityData?.toAddress || "Nach"}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>

                {/* Tabs für die verschiedenen Ansichten (nur anzeigen, wenn Kalenderdaten geladen sind) */}
                {showCalendar && calendarData.length > 0 && (
                    <Box mb={2}>
                        <Tabs
                            value={selectedTab}
                            onChange={(e, newValue) => setSelectedTab(newValue)}
                            variant="fullWidth"
                        >
                            <Tab label="Verfügbarkeit" value="matrix" />
                            <Tab label="Kalender" value="calendar" />
                        </Tabs>
                    </Box>
                )}

                {/* Loading-Zustand */}
                {loading && (
                    <Box display="flex" justifyContent="center" alignItems="center" py={5}>
                        <CircularProgress />
                        <Typography sx={{ ml: 2 }}>
                            Verfügbarkeit wird geprüft...
                        </Typography>
                    </Box>
                )}

                {/* Fehler-Zustand */}
                {error && !loading && (
                    <Paper
                        elevation={0}
                        sx={{
                            p: 3,
                            backgroundColor: '#ffebee',
                            borderRadius: '8px',
                            textAlign: 'center',
                            mb: 2
                        }}
                    >
                        <Typography color="error" gutterBottom>
                            {error}
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<RefreshIcon />}
                            onClick={fetchAvailabilityData}
                            sx={{ mt: 1 }}
                        >
                            Erneut versuchen
                        </Button>
                    </Paper>
                )}

                {/* Ansichten - Matrix oder Kalender */}
                {!loading && !error && availabilityData && (
                    <>
                        {/* Matrix-Ansicht */}
                        {(!showCalendar || selectedTab === 'matrix') && (
                            <>
                                {/* Alternative Vorschläge wenn gewünschte Zeit nicht verfügbar */}
                                {availabilityData.alternatives && availabilityData.alternatives.length > 0 && (
                                    <Box mb={3}>
                                        <Typography variant="subtitle1" fontWeight="bold" mb={1}>
                                            Alternative Zeitvorschläge
                                        </Typography>
                                        <Paper sx={{ p: 2, borderRadius: '8px', overflow: 'hidden' }}>
                                            <Grid container spacing={2}>
                                                {availabilityData.alternatives.map((alt, index) => {
                                                    const status = getStatusDisplay(alt.status);
                                                    // Prüfen, ob der Alternative Termin in der Vergangenheit liegt
                                                    const altTime = dayjs(`${alt.date} ${alt.time}`);
                                                    const isAltInPast = altTime.isBefore(dayjs());

                                                    // Überspringe Termine in der Vergangenheit
                                                    if (isAltInPast) return null;

                                                    return (
                                                        <Grid item xs={12} sm={4} key={`alt-${index}`}>
                                                            <Paper
                                                                elevation={1}
                                                                sx={{
                                                                    p: 2,
                                                                    borderRadius: '8px',
                                                                    backgroundColor: status.backgroundColor,
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    height: '100%',
                                                                    maxHeight: '140px',
                                                                    transition: 'transform 0.2s',
                                                                    cursor: 'pointer',
                                                                    '&:hover': {
                                                                        transform: 'translateY(-4px)',
                                                                        boxShadow: '0 8px 16px rgba(0,0,0,0.1)'
                                                                    }
                                                                }}
                                                                onClick={() => handleTimeSlotSelect(alt)}
                                                            >
                                                                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                                                    <Box display="flex" alignItems="center">
                                                                        {status.icon}
                                                                        <Typography variant="body2" sx={{ ml: 1 }}>
                                                                            {dayjs(alt.date).format('ddd, DD.MM')}
                                                                        </Typography>
                                                                    </Box>
                                                                    <Chip
                                                                        label={formatTimeDisplay(alt.time)}
                                                                        size="small"
                                                                        sx={{
                                                                            backgroundColor: status.color,
                                                                            color: '#fff',
                                                                            fontWeight: 'bold'
                                                                        }}
                                                                    />
                                                                </Box>
                                                                <Typography variant="body2">
                                                                    verfügbar
                                                                </Typography>
                                                                <Box display="flex" justifyContent="flex-end" mt="auto" pt={1}>
                                                                    <Button
                                                                        variant="text"
                                                                        size="small"
                                                                        endIcon={<NavigateNextIcon />}
                                                                        onClick={() => handleTimeSlotSelect(alt)}
                                                                    >
                                                                        Auswählen
                                                                    </Button>
                                                                </Box>
                                                            </Paper>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </Paper>
                                    </Box>
                                )}

                                <Typography variant="subtitle1" fontWeight="bold" mb={1}>
                                    Verfügbarkeit im Zeitraum
                                </Typography>

                                <Paper elevation={1} sx={{ p: 2, borderRadius: '8px', overflow: 'hidden' }}>
                                    <Grid container spacing={3}>
                                        {availabilityData.availabilityMatrix.map((slot, index) => {
                                            const status = getStatusDisplay(slot.status);
                                            const isRequested = slot.isCentral;

                                            // Prüfen, ob dieser Zeitslot in der Vergangenheit liegt
                                            const slotTime = dayjs(`${slot.date} ${slot.time}`);
                                            const isSlotInPast = slotTime.isBefore(dayjs());

                                            const displayTime = isRequested
                                                ? formatTimeDisplay(requestData.time)
                                                : formatTimeDisplay(slot.time);

                                            // Überspringe Termine in der Vergangenheit
                                            if (isSlotInPast) return null;

                                            return (
                                                <Grid item xs={3} sm={2} key={`slot-${index}`}>
                                                    <Tooltip title={status.text}>
                                                        <Paper
                                                            elevation={isRequested ? 3 : 1}
                                                            sx={{
                                                                p: 1,
                                                                backgroundColor: status.backgroundColor,
                                                                borderRadius: '8px',
                                                                border: isRequested ? `2px solid ${theme.palette.primary.main}` : 'none',
                                                                textAlign: 'center',
                                                                height: '100%',
                                                                maxHeight: '110px',
                                                                minHeight: '110px',
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                                opacity: slot.status === 'red' ? 0.7 : 1,
                                                                cursor: slot.status === 'red' ? 'default' : 'pointer',
                                                                transition: 'transform 0.2s, box-shadow 0.2s',
                                                                overflow: 'hidden',
                                                                '&:hover': slot.status === 'red' ? {} : {
                                                                    transform: 'translateY(-4px)',
                                                                    boxShadow: '0 4px 10px rgba(0,0,0,0.1)'
                                                                }
                                                            }}
                                                            onClick={() => {
                                                                // Wenn es sich um den angeforderten Slot handelt, verwende die exakte Zeit
                                                                if (isRequested) {
                                                                    handleTimeSlotSelect({
                                                                        ...slot,
                                                                        time: requestData.time
                                                                    });
                                                                } else {
                                                                    handleTimeSlotSelect(slot);
                                                                }
                                                            }}
                                                        >
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                                                <Typography variant="caption" display="block">
                                                                    {dayjs(slot.date).format('ddd')}
                                                                </Typography>
                                                                <Box display="flex" justifyContent="center" my={0.5}>
                                                                    {status.icon}
                                                                </Box>
                                                                <Typography variant="body2" fontWeight="bold">
                                                                    {displayTime}
                                                                </Typography>
                                                                <Typography
                                                                    variant="caption"
                                                                    display="block"
                                                                    mt={0.5}
                                                                    sx={{ fontSize: '0.7rem' }}
                                                                >
                                                                    {slot.status === 'green' ? 'verfügbar' :
                                                                        slot.availableVehicles === 1 ? '1 Fahrzeug' :
                                                                            'Keine Fahrzeuge'}
                                                                </Typography>
                                                                {isRequested && (
                                                                    <Chip
                                                                        label="Gewünscht"
                                                                        size="small"
                                                                        sx={{
                                                                            mt: 0.5,
                                                                            fontSize: '0.6rem',
                                                                            height: 16,
                                                                            backgroundColor: theme.palette.primary.main,
                                                                            color: '#fff'
                                                                        }}
                                                                    />
                                                                )}
                                                            </Box>
                                                        </Paper>
                                                    </Tooltip>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Paper>

                                <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                                    <Box>
                                        <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                                            <CheckCircleIcon sx={{ color: '#4caf50', mr: 0.5, fontSize: '1rem' }} />
                                            Mehrere Fahrzeuge verfügbar
                                        </Typography>
                                        <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                                            <WarningIcon sx={{ color: '#ff9800', mr: 0.5, fontSize: '1rem' }} />
                                            Nur ein Fahrzeug verfügbar
                                        </Typography>
                                        <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <CancelIcon sx={{ color: '#f44336', mr: 0.5, fontSize: '1rem' }} />
                                            Keine Fahrzeuge verfügbar
                                        </Typography>
                                    </Box>

                                    {/* Nur anzeigen, wenn alle sichtbaren Slots "rot" sind */}
                                    {availabilityData.availabilityMatrix.filter(slot => {
                                        const slotTime = dayjs(`${slot.date} ${slot.time}`);
                                        return !slotTime.isBefore(dayjs()); // nur zukünftige Slots
                                    }).every(slot => slot.status === 'red') && (
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                startIcon={<CalendarMonthIcon />}
                                                onClick={toggleCalendarView}
                                                sx={{
                                                    ml: 2,
                                                    fontSize: '0.8rem',
                                                    borderRadius: '20px'
                                                }}
                                            >
                                                Im Kalender nach freien Terminen suchen
                                            </Button>
                                        )}
                                </Box>
                            </>
                        )}

                        {/* Kalender-Ansicht */}
                        {showCalendar && selectedTab === 'calendar' && renderCalendarContent()}
                    </>
                )}
            </DialogContent>

            <DialogActions sx={{ justifyContent: 'space-between', p: 2, backgroundColor: '#f9fafb' }}>
                <Button onClick={onClose} color="inherit">
                    Abbrechen
                </Button>
                {!loading && !error && availabilityData && !isRequestedTimeInPast && (
                    <>
                        {/* Erfolgsmeldung anzeigen, wenn Wunschtermin verfügbar ist */}
                        {availabilityData.availabilityMatrix.find(s => s.isCentral)?.status !== 'red' && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mr: 2,
                                    backgroundColor: '#e8f5e9',
                                    px: 2,
                                    py: 1,
                                    borderRadius: '20px'
                                }}
                            >
                                <CheckCircleIcon sx={{ color: '#4caf50', mr: 1 }} />
                                <Typography variant="body2" color="success.dark">
                                    Ihr Wunschtermin {formatTimeDisplay(requestData.time)} Uhr ist verfügbar.
                                </Typography>
                            </Box>
                        )}
                        <Button
                            data-testid="requested-time-button"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                // Finde den gewünschten Zeitslot mit der exakten Zeit
                                const requestedTimeSlot = {
                                    date: requestData.date,
                                    time: requestData.time,
                                    availableVehicles: availabilityData.availabilityMatrix.find(s => s.isCentral)?.availableVehicles || 0
                                };

                                // Prüfe ob der gewünschte Zeitslot verfügbar ist
                                const centralSlot = availabilityData.availabilityMatrix.find(s => s.isCentral);
                                if (centralSlot && centralSlot.status !== 'red') {
                                    handleTimeSlotSelect(requestedTimeSlot);
                                }
                            }}
                            disabled={
                                !availabilityData ||
                                isRequestedTimeInPast ||
                                !availabilityData.availabilityMatrix.find(s => s.isCentral)?.availableVehicles
                            }
                        >
                            Gewünschte Zeit übernehmen
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default AvailabilityCheckDialog;