import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    Chip,
    Avatar,
    Tooltip,
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import {
    DirectionsCar as CarIcon,
    LocalShipping as TruckIcon,
    AirportShuttle as VanIcon,
    Info as InfoIcon,
    // MoreVert as MoreVertIcon,
    BuildCircle as BuildCircleIcon
} from '@mui/icons-material';
import { TIME_LABELS_HEIGHT, ROW_HEIGHT } from './timelineUtils';
import VehiclePopover from './VehiclePopover';
import VehicleReplanDialog from './VehicleReplanDialog';

function VehicleColumn({ vehicles, countyColors = {}, onSuccess }) {
    const [popoverAnchor, setPopoverAnchor] = useState(null);
    const [popoverContent, setPopoverContent] = useState(null);
    const [contextMenu, setContextMenu] = useState(null);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [replanDialogOpen, setReplanDialogOpen] = useState(false);

    const handleVehicleInfo = (event, vehicle) => {
        event.stopPropagation();
        setPopoverAnchor(event.currentTarget);
        setPopoverContent(vehicle);
    };

    const handlePopoverClose = () => {
        setPopoverAnchor(null);
        setPopoverContent(null);
    };

    // Kontextmenü öffnen
    const handleContextMenu = (event, vehicle) => {
        event.preventDefault();
        event.stopPropagation();
        setContextMenu({ mouseX: event.clientX, mouseY: event.clientY });
        setSelectedVehicle(vehicle);
    };

    /*
    // Menü mit MoreVert Button öffnen
    const handleMenuOpen = (event, vehicle) => {
        event.stopPropagation();
        setContextMenu({ mouseX: event.clientX, mouseY: event.clientY });
        setSelectedVehicle(vehicle);
    };*/

    // Kontextmenü schließen
    const handleContextMenuClose = () => {
        setContextMenu(null);
    };

    // Fahrzeug außer Dienst stellen
    const handleReplanVehicle = () => {
        setContextMenu(null);
        setReplanDialogOpen(true);
    };

    // Verbeserte Fahrzeugtyp-Icon-Auswahl
    const getVehicleTypeIcon = (vehicleName) => {
        if (vehicleName.includes('LKW') || vehicleName.includes('Truck')) {
            return <TruckIcon fontSize="small" />;
        } else if (vehicleName.includes('Bus') || vehicleName.includes('Van')) {
            return <VanIcon fontSize="small" />;
        }
        return <CarIcon fontSize="small" />;
    };

    // Status-Farbe und Bezeichnung
    const getVehicleStatusInfo = (status) => {
        switch (status) {
            case 'active':
            case 'available':
                return {
                    color: '#4caf50',
                    bg: '#f5f5f5',
                    text: 'Verfügbar'
                };
            case 'inactive':
                return {
                    color: '#f44336',
                    bg: '#ffebee',
                    text: 'Nicht verfügbar'
                };
            case 'maintenance':
                return {
                    color: '#ff9800',
                    bg: '#fff8e1',
                    text: 'Wartung'
                };
            default:
                return {
                    color: '#9e9e9e',
                    bg: '#f5f5f5',
                    text: 'Unbekannt'
                };
        }
    };

    // Landkreisfarbe 
    const getCountyColor = (lk) => {
        if (countyColors && lk in countyColors) {
            return countyColors[lk];
        }

        // Fallback-Farben
        const defaultColors = {
            'RV': '#e91e63',  // Pink
            'FN': '#2196f3',  // Blau
            'SIG': '#ff9800'  // Orange
        };

        return defaultColors[lk] || '#9e9e9e';  // Grau als Standardfarbe
    };

    return (
        <Box
            sx={{
                width: 220,
                flexShrink: 0,
                backgroundColor: '#f8f9fa',
                position: 'sticky',
                left: 0,
                zIndex: 1000,
                height: '100%',
                boxSizing: 'border-box',
                borderRight: '1px solid #e0e0e0'
            }}
        >
            {/* Header für die Fahrzeugspalte */}
            <Box
                sx={{
                    height: TIME_LABELS_HEIGHT,
                    borderBottom: '1px solid #e0e0e0',
                    display: 'flex',
                    alignItems: 'center',
                    px: 1.5,
                    backgroundColor: '#f0f2f5',
                }}
            >
                <Typography
                    variant="subtitle2"
                    sx={{
                        fontWeight: 600,
                        color: '#3f51b5'
                    }}
                >
                    Fahrzeuge
                </Typography>
            </Box>

            {/* Fahrzeugliste */}
            {vehicles.map((v) => {
                const statusInfo = getVehicleStatusInfo(v.vehicle_status);

                return (
                    <Box
                        key={v.vehicle_id}
                        sx={{
                            height: ROW_HEIGHT,
                            display: 'flex',
                            alignItems: 'center',
                            borderBottom: '1px solid #e0e0e0',
                            backgroundColor: statusInfo.bg,
                            boxSizing: 'border-box',
                            padding: '4px 8px',
                            transition: 'all 0.2s ease',
                            '&:hover': {
                                backgroundColor: '#e8eaf6',
                                boxShadow: 'inset 0 0 0 1px #c5cae9',
                            }
                        }}
                        onContextMenu={(e) => handleContextMenu(e, v)}
                    >
                        {/* Landkreis-Avatar mit Tooltip */}
                        <Tooltip title={`Landkreis: ${v.lk}`}>
                            <Avatar
                                sx={{
                                    width: 32,
                                    height: 32,
                                    bgcolor: getCountyColor(v.lk),
                                    fontSize: '0.75rem',
                                    marginRight: '8px',
                                    fontWeight: 'bold'
                                }}
                            >
                                {v.lk}
                            </Avatar>
                        </Tooltip>

                        {/* Fahrzeuginformationen */}
                        <Box sx={{ overflow: 'hidden', flex: 1 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography
                                    variant="subtitle2"
                                    noWrap
                                    sx={{
                                        fontWeight: 'bold',
                                        lineHeight: 1.1,
                                        color: '#495057'
                                    }}
                                >
                                    {v.vehicle_name}
                                </Typography>

                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Tooltip title="Fahrzeugdetails">
                                        <IconButton
                                            size="small"
                                            onClick={(e) => handleVehicleInfo(e, v)}
                                            sx={{ ml: 0.5 }}
                                        >
                                            <InfoIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>

                            {/* Stationsname */}
                            {v.station_name && (
                                <Typography
                                    variant="caption"
                                    noWrap
                                    sx={{
                                        color: 'grey.600',
                                        lineHeight: 1.1,
                                        fontSize: '0.65rem',
                                        display: 'block'
                                    }}
                                >
                                    {v.station_name}
                                </Typography>
                            )}

                            {/* Status und Funkrufname */}
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5, gap: '4px' }}>
                                {v.radio_call_sign && (
                                    <Chip
                                        size="small"
                                        label={v.radio_call_sign}
                                        icon={getVehicleTypeIcon(v.vehicle_name)}
                                        sx={{
                                            height: '16px',
                                            fontSize: '0.6rem',
                                            '& .MuiChip-label': {
                                                padding: '0 4px'
                                            }
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                    </Box>
                );
            })}

            {/* Footer für die Fahrzeugspalte */}
            <Box sx={{ height: TIME_LABELS_HEIGHT, borderTop: '1px solid #e0e0e0' }} />

            {/* Fahrzeugdetails-Popover */}
            <VehiclePopover
                anchorEl={popoverAnchor}
                content={popoverContent}
                onClose={handlePopoverClose}
            />

            {/* Kontextmenü */}
            <Menu
                open={contextMenu !== null}
                onClose={handleContextMenuClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                <MenuItem onClick={handleReplanVehicle}>
                    <ListItemIcon>
                        <BuildCircleIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Fahrzeug außer Dienst stellen</ListItemText>
                </MenuItem>
            </Menu>

            {/* Dialog für Fahrzeug-Umplanung */}
            {selectedVehicle && (
                <VehicleReplanDialog
                    open={replanDialogOpen}
                    onClose={() => setReplanDialogOpen(false)}
                    vehicle={selectedVehicle}
                    onSuccess={() => {
                        onSuccess();
                    }}
                />
            )}
        </Box>
    );
}

VehicleColumn.propTypes = {
    vehicles: PropTypes.arrayOf(
        PropTypes.shape({
            vehicle_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            vehicle_name: PropTypes.string.isRequired,
            station_name: PropTypes.string,
            radio_call_sign: PropTypes.string,
            license_plate: PropTypes.string,
            vehicle_status: PropTypes.string,
            station_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            lk: PropTypes.string,
            betreiber: PropTypes.string,
        })
    ).isRequired,
    countyColors: PropTypes.object
};

export default VehicleColumn;