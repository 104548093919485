import React, { createContext, useState, useContext, useEffect } from 'react';
import { useUser } from './userContext';

// Kontext erstellen
const ConfigContext = createContext(null);

// Provider-Komponente
export function ConfigProvider({ children }) {
    const { user } = useUser();
    const [config, setConfig] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    // Config beim ersten Laden abrufen
    useEffect(() => {
        const fetchConfig = async () => {
            if (!user || !user.api_key) {
                setIsLoading(false);
                return;
            }

            try {
                // Alle benötigten Konfigurationsparameter auf einmal abrufen
                const response = await fetch(`${process.env.REACT_APP_API_URI}/api/config/filtered/mod_`, {
                    headers: {
                        'Authorization': user.api_key,
                    },
                });

                if (response.ok) {
                    const data = await response.json();

                    // Konvertiere die erhaltenen Werte in ihre korrekten Typen
                    const parsedConfig = Object.entries(data).reduce((acc, [key, value]) => {
                        // Boolean-Werte korrekt parsen
                        if (value === 'true' || value === 'false') {
                            acc[key] = value === 'true';
                        } else {
                            acc[key] = value;
                        }
                        return acc;
                    }, {});

                    //console.log('Konfiguration geladen:', parsedConfig);
                    setConfig(parsedConfig);
                } else {
                    throw new Error('Fehler beim Laden der Konfiguration');
                }
            } catch (err) {
                console.error('Config loading error:', err);
                setError(err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchConfig();
    }, [user]);

    // Funktion zum Abrufen einzelner Konfigurationsparameter mit Fallback-Wert
    const getConfigValue = (key, defaultValue = null) => {
        return config[key] !== undefined ? config[key] : defaultValue;
    };

    return (
        <ConfigContext.Provider value={{ config, isLoading, error, getConfigValue }}>
            {children}
        </ConfigContext.Provider>
    );
}

// Custom Hook für einfachen Zugriff
export function useConfig() {
    const context = useContext(ConfigContext);
    if (!context) {
        throw new Error('useConfig must be used within a ConfigProvider');
    }
    return context;
}