// src/pages/resetPasswordPage.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Alert, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { isPasswordValid } from '../misc/helperFunctions';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

// Importiere die wiederverwendbaren Komponenten
import {
    DrkPageContainer,
    DrkCard,
    DrkIconCircle,
    DrkInfoBox,
    DrkLoginLink
} from '../components/DrkComponents';

const PasswordResetPage = () => {
    const [token, setToken] = useState('');
    const [errors, setErrors] = useState({});
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordResetStatus, setPasswordResetStatus] = useState('pending');
    const [confirmedUser, setConfirmedUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const urlToken = urlSearchParams.get('token');
        setToken(urlToken);

        if (urlToken) {
            fetch(`${process.env.REACT_APP_API_URI}/api/users/pwtoken/${urlToken}`)
                .then(response => response.json())
                .then(data => {
                    if (data && data.user_id) {
                        setConfirmedUser(data);
                    } else {
                        setPasswordResetStatus('error');
                    }
                })
                .catch(error => {
                    console.error('Fehler bei der API-Anfrage:', error);
                    setPasswordResetStatus('error');
                });
        } else {
            console.error('Kein Token in der URL gefunden.');
            setPasswordResetStatus('error');
        }
    }, []);

    const handlePasswordReset = async () => {
        // Validate new password and confirmation
        const newErrors = {};
        if (!newPassword) {
            newErrors.newPassword = 'Neues Passwort erforderlich';
        }
        if (!isPasswordValid(newPassword)) {
            newErrors.newPassword = 'Passwort muss mindestens 6 Zeichen lang sein, einen Großbuchstaben und ein Sonderzeichen enthalten';
        }
        if (!confirmPassword) {
            newErrors.confirmPassword = 'Passwortbestätigung erforderlich';
        }
        if (newPassword !== confirmPassword) {
            newErrors.confirmPassword = 'Passwörter stimmen nicht überein';
        }
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0 && confirmedUser) {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URI}/api/users/${confirmedUser.user_id}/password`,
                    {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: confirmedUser.api_key,
                        },
                        body: JSON.stringify({
                            currentPassword: token,
                            newPassword: newPassword,
                        }),
                    }
                );

                if (response.ok) {
                    console.log('Password updated successfully');
                    setPasswordResetStatus('success');
                    setTimeout(() => {
                        navigate('/login');
                    }, 3000);
                } else {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Fehler beim Zurücksetzen des Passworts');
                }
            } catch (error) {
                console.error('Fehler bei der API-Anfrage:', error);
                setErrors({ api: error.message || 'Unbekannter Fehler beim Zurücksetzen des Passworts' });
            }
        }
    };

    return (
        <DrkPageContainer maxWidth="lg">
            <DrkCard
                title="Passwort zurücksetzen"
                subtitle={
                    passwordResetStatus === 'pending'
                        ? "Bitte geben Sie Ihr neues Passwort ein und bestätigen Sie es."
                        : null
                }
                maxWidth="600px"
            >
                {passwordResetStatus === 'pending' && confirmedUser ? (
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                            <DrkIconCircle icon={<LockOutlinedIcon />} />
                        </Box>

                        {errors && Object.values(errors).length > 0 && (
                            <Alert
                                severity="error"
                                sx={{ mb: 3, borderRadius: 2 }}
                            >
                                {Object.values(errors).join(', ')}
                            </Alert>
                        )}

                        <Box sx={{ mb: 3 }}>
                            <TextField
                                fullWidth
                                label="Neues Passwort"
                                type="password"
                                value={newPassword}
                                onChange={(event) => setNewPassword(event.target.value)}
                                margin="normal"
                                error={!!errors.newPassword}
                                helperText={errors.newPassword}
                                InputProps={{
                                    sx: { borderRadius: '8px' }
                                }}
                            />

                            <TextField
                                fullWidth
                                label="Passwort bestätigen"
                                type="password"
                                value={confirmPassword}
                                onChange={(event) => setConfirmPassword(event.target.value)}
                                margin="normal"
                                error={!!errors.confirmPassword}
                                helperText={errors.confirmPassword}
                                InputProps={{
                                    sx: { borderRadius: '8px' }
                                }}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                startIcon={<LockOutlinedIcon />}
                                onClick={handlePasswordReset}
                                sx={{
                                    minWidth: '220px',
                                    py: 1.5
                                }}
                            >
                                Passwort zurücksetzen
                            </Button>
                        </Box>
                    </>
                ) : (
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                            <DrkIconCircle
                                icon={passwordResetStatus === 'success'
                                    ? <CheckCircleOutlineIcon />
                                    : <ErrorOutlineIcon />
                                }
                                color={passwordResetStatus === 'success' ? "secondary" : "primary"}
                            />
                        </Box>

                        <Divider sx={{ mb: 3 }} />

                        <DrkInfoBox color={passwordResetStatus === 'success' ? "secondary" : "primary"}>
                            <Typography variant="body1" paragraph>
                                {passwordResetStatus === 'success'
                                    ? "Ihr Passwort wurde erfolgreich zurückgesetzt. Sie werden in wenigen Sekunden zur Anmeldeseite weitergeleitet."
                                    : "Es ist ein Fehler aufgetreten. Der Link zum Zurücksetzen des Passworts ist ungültig oder abgelaufen. Bitte fordern Sie einen neuen Link an."}
                            </Typography>
                        </DrkInfoBox>
                    </>
                )}

                <DrkLoginLink />
            </DrkCard>
        </DrkPageContainer>
    );
};

export default PasswordResetPage;