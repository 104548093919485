import React, { useEffect, useState } from 'react';
import {
    Button, TextField, Typography, Alert,
    Link, Box, Collapse, Card, CardContent
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../components/userContext';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const Login = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const navigate = useNavigate();
    const { setUser } = useUser();

    const [serverError, setServerError] = useState('');
    const [errors, setErrors] = useState({});
    const [open, setOpen] = useState(false);
    const [willPasswordChange, setWillPasswordChange] = useState(false);
    // eslint-disable-next-line
    const [isChrome, setIsChrome] = useState(false);

    useEffect(() => {
        // Chrome-Erkennung (wird nicht mehr für die InputLabelProps verwendet)
        const isChromeBrowser = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        setIsChrome(isChromeBrowser);

        // Globale CSS-Regel hinzufügen, um das gelbe Autofill zu überschreiben
        const style = document.createElement('style');
        style.textContent = `
            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
                -webkit-box-shadow: 0 0 0 30px white inset !important;
                -webkit-text-fill-color: rgba(0, 0, 0, 0.87) !important;
                transition: background-color 5000s ease-in-out 0s;
            }
        `;
        document.head.appendChild(style);

        return () => {
            document.head.removeChild(style);
        };
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        const lowercaseValue = name === 'email' ? value.toLowerCase() : value;

        setFormData((prevData) => ({
            ...prevData,
            [name]: lowercaseValue,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        console.log('isChrome: ', isChrome);
        let isValid = true;
        let newErrors = {};

        if (!validateEmail(formData.email)) {
            isValid = false;
            newErrors.email = "Ungültige E-Mail-Adresse";
        }

        if (willPasswordChange) {
            await handlePasswordReset();
            return;
        }

        if (!formData.password) newErrors.password = 'Passwort ist erforderlich';

        setErrors(newErrors);

        if (isValid && Object.keys(newErrors).length === 0) {
            console.log('Try to logon on: ' + process.env.REACT_APP_API_URI + '/api/login');

            try {
                const response = await fetch(process.env.REACT_APP_API_URI + '/api/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        username: formData.email,
                        password: formData.password,
                    }),
                });

                const data = await response.json();

                if (data.status === 'success') {
                    localStorage.setItem('token', data.token);

                    setUser({
                        token: data.token,
                        user_id: data.user_id,
                        username: data.username,
                        institutionId: data.institution_id,
                        registrationStatus: data.registration_status,
                        accountActivated: data.account_activated,
                        role: data.role,
                        api_key: data.api_key,
                    });

                    console.log('user signed in: ' + data.username + ' id:' + data.user_id);
                    navigate('/dashboard');
                } else {
                    setErrors({ server: data.message || 'Anmeldung fehlgeschlagen' });
                }
            } catch (error) {
                console.error('Fehler beim Anmelden:', error);
                setErrors({ server: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.' });
            }
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return emailRegex.test(email);
    };

    const handlePasswordResetClick = (event) => {
        event.preventDefault();
        setWillPasswordChange(true);
    };

    const handleBackToLoginClick = (event) => {
        event.preventDefault();
        setWillPasswordChange(false);
        setOpen(false);
    };

    const handlePasswordReset = async () => {
        setErrors({});
        setServerError("");

        if (!validateEmail(formData.email)) {
            setErrors({ email: "Ungültige E-Mail-Adresse" });
            return;
        }

        try {
            console.log("Send PW reset mail to: " + formData.email);

            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/users/send-reset-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: formData.email,
                }),
            });

            if (response.ok) {
                setOpen(true);
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message);
            }
        } catch (error) {
            console.error(error.message);
            setErrors({ server: error.message || 'Fehler beim Zurücksetzen des Passworts' });
        }
    };

    const backgroundImageUrl = `${process.env.REACT_APP_BASE_URL}/login_back.jpg`;

    return (
        <Box
            sx={{
                minHeight: '100vh',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                backgroundImage: `url('${backgroundImageUrl}')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                alignItems: 'center',
                justifyContent: 'center',
                py: 4,
                overflow: 'hidden' // Verhindert Scrollbar
            }}
        >
            <Card
                elevation={3}
                sx={{
                    maxWidth: '500px',
                    width: '100%',
                    borderRadius: '16px',
                    overflow: 'hidden',
                    backgroundColor: 'rgba(255, 255, 255, 1)'
                }}
            >
                {/* Farbiger Balken am oberen Rand */}
                <Box sx={{ height: '8px', bgcolor: '#E46450' }} />

                <CardContent sx={{ p: 4 }}>
                    {/* Logo und Titel */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 4 }}>
                        <img
                            src="/drk_bos_logo_full.png"
                            alt="DRK Logo"
                            style={{ height: '60px', marginBottom: '24px' }}
                        />
                        <Typography
                            variant="h4"
                            component="h1"
                            gutterBottom
                            color="#E46450"
                            fontWeight="bold"
                        >
                            KTW-Anmeldung
                        </Typography>
                        <Typography variant="body1" color="text.secondary" align="center">
                            {willPasswordChange
                                ? 'Geben Sie Ihre E-Mail-Adresse ein, um ein neues Passwort anzufordern'
                                : 'Melden Sie sich mit Ihren Zugangsdaten an'}
                        </Typography>
                    </Box>

                    {/* Fehlermeldungen */}
                    {errors && Object.values(errors).length > 0 &&
                        <Alert
                            severity="error"
                            sx={{
                                mb: 3,
                                borderRadius: '8px',
                                '& .MuiAlert-icon': { alignItems: 'center' }
                            }}
                        >
                            {Object.values(errors).join(', ')}
                        </Alert>
                    }

                    {typeof serverError === 'string' && serverError &&
                        <Alert
                            severity="error"
                            sx={{
                                mb: 3,
                                borderRadius: '8px',
                                '& .MuiAlert-icon': { alignItems: 'center' }
                            }}
                        >
                            {serverError}
                        </Alert>
                    }

                    <Collapse in={open} sx={{ mb: 3 }}>
                        <Alert
                            severity="success"
                            sx={{
                                borderRadius: '8px',
                                '& .MuiAlert-icon': { alignItems: 'center' }
                            }}
                        >
                            Es wurde eine Mail für das Zurücksetzen des Passworts versendet!
                        </Alert>
                    </Collapse>

                    {/* Formular */}
                    <Box component="form" onSubmit={handleSubmit}>
                        <Box sx={{ mb: 3 }}>
                            <TextField
                                fullWidth
                                autoFocus
                                label="E-Mail-Adresse"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                error={!!errors.email}
                                helperText={errors.email}
                                InputProps={{
                                    sx: {
                                        borderRadius: '8px',
                                        // Entfernt gelben Autofill-Hintergrund
                                        '&:-webkit-autofill': {
                                            WebkitBoxShadow: '0 0 0 1000px white inset',
                                            WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)'
                                        },
                                        '&:-webkit-autofill:focus': {
                                            WebkitBoxShadow: '0 0 0 1000px white inset',
                                            WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)'
                                        },
                                        '&:-webkit-autofill:hover': {
                                            WebkitBoxShadow: '0 0 0 1000px white inset',
                                            WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)'
                                        }
                                    },
                                    startAdornment: (
                                        <MailOutlineIcon sx={{ color: 'action.active', mr: 1 }} />
                                    ),
                                }}
                                sx={{
                                    mb: 2,
                                    '& .MuiInputLabel-root': {
                                        ml: 4 // Verschiebt das Label nach rechts
                                    },
                                    '& .MuiInputLabel-shrink': {
                                        ml: 0 // Beim Schrumpfen zurücksetzen
                                    }
                                }}
                            />

                            {!willPasswordChange && (
                                <TextField
                                    fullWidth
                                    label="Passwort"
                                    name="password"
                                    type="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    error={!!errors.password}
                                    helperText={errors.password}
                                    InputProps={{
                                        sx: {
                                            borderRadius: '8px',
                                            // Entfernt gelben Autofill-Hintergrund
                                            '&:-webkit-autofill': {
                                                WebkitBoxShadow: '0 0 0 1000px white inset',
                                                WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)'
                                            },
                                            '&:-webkit-autofill:focus': {
                                                WebkitBoxShadow: '0 0 0 1000px white inset',
                                                WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)'
                                            },
                                            '&:-webkit-autofill:hover': {
                                                WebkitBoxShadow: '0 0 0 1000px white inset',
                                                WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)'
                                            }
                                        },
                                        startAdornment: (
                                            <LockOutlinedIcon sx={{ color: 'action.active', mr: 1 }} />
                                        ),
                                    }}
                                    sx={{
                                        '& .MuiInputLabel-root': {
                                            ml: 4 // Verschiebt das Label nach rechts
                                        },
                                        '& .MuiInputLabel-shrink': {
                                            ml: 0 // Beim Schrumpfen zurücksetzen
                                        }
                                    }}
                                />
                            )}
                        </Box>

                        {/* Submit Button */}
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            size="large"
                            startIcon={willPasswordChange ? <HelpOutlineIcon /> : <LockOutlinedIcon />}
                            sx={{
                                py: 1.5,
                                mt: 2,
                                bgcolor: '#E46450',
                                borderRadius: '30px',
                                textTransform: 'none',
                                fontSize: '1rem',
                                fontWeight: 'medium',
                                '&:hover': {
                                    bgcolor: '#d13b27',
                                    boxShadow: '0 6px 15px rgba(228, 100, 80, 0.4)',
                                },
                                boxShadow: '0 4px 10px rgba(228, 100, 80, 0.3)',
                            }}
                        >
                            {willPasswordChange ? 'Neues Passwort anfordern' : 'Anmelden'}
                        </Button>

                        {/* Links */}
                        <Box
                            sx={{
                                mt: 4,
                                p: 2,
                                bgcolor: 'rgba(228, 100, 80, 0.05)',
                                borderRadius: '8px',
                                textAlign: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1.5
                            }}
                        >
                            <Box>
                                <Typography variant="body2" color="text.secondary">
                                    Ich habe noch keinen Zugang
                                </Typography>
                                <Link
                                    href="/registration"
                                    sx={{
                                        color: '#E46450',
                                        textDecoration: 'none',
                                        fontWeight: 'medium',
                                        display: 'inline-block',
                                        mt: 0.5,
                                        '&:hover': {
                                            textDecoration: 'underline'
                                        }
                                    }}
                                >
                                    Jetzt registrieren!
                                </Link>
                            </Box>

                            {willPasswordChange ? (
                                <Box>
                                    <Link
                                        component="button"
                                        onClick={handleBackToLoginClick}
                                        sx={{
                                            color: '#E46450',
                                            textDecoration: 'none',
                                            fontWeight: 'medium',
                                            '&:hover': {
                                                textDecoration: 'underline'
                                            }
                                        }}
                                    >
                                        Zurück zur Anmeldung
                                    </Link>
                                </Box>
                            ) : (
                                <Box>
                                    <Typography variant="body2" color="text.secondary">
                                        Passwort vergessen?
                                    </Typography>
                                    <Link
                                        component="button"
                                        onClick={handlePasswordResetClick}
                                        sx={{
                                            color: '#E46450',
                                            textDecoration: 'none',
                                            fontWeight: 'medium',
                                            display: 'inline-block',
                                            mt: 0.5,
                                            '&:hover': {
                                                textDecoration: 'underline'
                                            }
                                        }}
                                    >
                                        Neues Passwort per Mail anfordern
                                    </Link>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </CardContent>
            </Card>

        </Box>
    );
};

export default Login;