import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../components/userContext';
import { Chart } from 'react-google-charts';
import {
    CircularProgress,
    Typography,
    Container,
    Box,
    TextField,
    Select,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    InputLabel,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

function VehiclePlanningPage() {
    const { user } = useUser();
    // eslint-disable-next-line
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [chartData, setChartData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [zoomHours, setZoomHours] = useState(24);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [clickedVehicle, setClickedVehicle] = useState(null);
    const [clickedTime, setClickedTime] = useState(null);
    const [activityType, setActivityType] = useState('');
    const chartRef = useRef(null);

    // Mapping der Planungstypen zu festen Farben
    const planningTypeColors = {
        TO_START: '#4285F4',
        LOADING: '#34A853',
        MAIN: '#FBBC05',
        DELIVERY: '#EA4335',
        CLEANING: '#9C27B0',
        TO_GARAGE: '#FF5722',
        BREAK: '#607D8B',
        SERVICE: '#00ACC1',
        CUSTOM: '#8E24AA',
    };

    // Daten abrufen und Timeline-Daten aufbereiten
    useEffect(() => {
        fetchVehiclePlanning();
        // eslint-disable-next-line
    }, [selectedDate]);

    async function fetchVehiclePlanning() {
        setLoading(true);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URI}/api/vehicles/planning?date=${selectedDate.format(
                    'YYYY-MM-DD'
                )}`,
                {
                    headers: { Authorization: user.api_key },
                }
            );
            if (!response.ok)
                throw new Error('Fehler beim Laden der Fahrzeugplanung');
            const data = await response.json();
            console.log('Fetched Data:', data);

            // Header-Zeile: Vehicle ID, Task Label, Start, End, Style
            const timelineData = [
                [
                    { type: 'string', label: 'Vehicle ID' },
                    { type: 'string', label: 'Task Label' },
                    { type: 'date', label: 'Start Date' },
                    { type: 'date', label: 'End Date' },
                    { role: 'style' },
                ],
            ];

            // Für die linke Fahrzeugspalte ermitteln wir alle eindeutigen Fahrzeuge
            const vehicleMap = new Map();

            // Gruppierung nach Transport-ID (für den Rahmen – Workaround, da nicht nativ unterstützt)
            const transportGroups = {};

            data.forEach((item) => {
                const vehicleId = item.vehicle_name || `Fahrzeug ${item.vehicle_id}`;
                if (!vehicleMap.has(vehicleId)) {
                    vehicleMap.set(vehicleId, item);
                }

                // Farbwahl basierend auf planning_type
                const color = planningTypeColors[item.planning_type] || '#9E9E9E';
                const style = `background-color: ${color}`;

                // Leeren String für das Task-Label einfügen (um es im Chart auszublenden)
                timelineData.push([
                    vehicleId,
                    '',
                    new Date(item.planned_start),
                    new Date(item.planned_end),
                    style,
                ]);

                // Gruppierung nach transport_id (für Rahmen – Hinweis in der UI)
                if (item.transport_id) {
                    if (!transportGroups[item.transport_id]) {
                        transportGroups[item.transport_id] = {
                            vehicleId: vehicleId,
                            start: new Date(item.planned_start),
                            end: new Date(item.planned_end),
                        };
                    } else {
                        const group = transportGroups[item.transport_id];
                        group.start = new Date(
                            Math.min(group.start, new Date(item.planned_start))
                        );
                        group.end = new Date(
                            Math.max(group.end, new Date(item.planned_end))
                        );
                    }
                }
            });

            // Hinweis: Das Zeichnen eines Rahmens um alle Abschnitte eines Transports
            // (gleiche transport_id) wird von react-google-charts nicht nativ unterstützt.
            // Ein Workaround über DOM-Manipulationen wäre möglich, ist aber weniger stabil.

            console.log('Timeline Data:', timelineData);
            setChartData(timelineData);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    // Berechnung des viewWindow basierend auf dem gewählten Zoomlevel
    const dayStart = selectedDate.startOf('day').toDate();
    const dayEnd = selectedDate.endOf('day').toDate();
    const viewWindow = {
        min: dayStart,
        max: new Date(dayStart.getTime() + zoomHours * 60 * 60 * 1000),
    };

    // Eindeutige Fahrzeugliste (für die linke Spalte)
    const uniqueVehicles = Array.from(
        new Set(chartData.slice(1).map((row) => row[0]))
    );

    // Zoom-Level ändern
    const handleZoomChange = (event) => {
        setZoomHours(Number(event.target.value));
    };

    // Klick-Handler im Chart: Ermittelt anhand der Klickkoordinaten Fahrzeug und Uhrzeit
    const handleChartClick = (event) => {
        if (!chartRef.current) return;
        const rect = chartRef.current.getBoundingClientRect();
        const clickX = event.clientX - rect.left;
        const clickY = event.clientY - rect.top;

        // Horizontale Umrechnung: Klickposition -> Uhrzeit
        const chartWidth = rect.width;
        const timeRange = viewWindow.max.getTime() - viewWindow.min.getTime();
        const clickedTimeMs =
            viewWindow.min.getTime() + (clickX / chartWidth) * timeRange;
        const clickedDate = new Date(clickedTimeMs);

        // Vertikale Umrechnung: Ermitteln der Fahrzeugreihe (Annahme: feste Zeilenhöhe von 40px, Header ca. 40px)
        const headerOffset = 40;
        const rowHeight = 40;
        const rowIndex = Math.floor((clickY - headerOffset) / rowHeight);
        const vehicleId = uniqueVehicles[rowIndex];

        console.log('Chart Click:', { vehicleId, clickedTime: clickedDate });
        if (vehicleId) {
            setClickedVehicle(vehicleId);
            setClickedTime(clickedDate);
            setActivityType('');
            setDialogOpen(true);
        }
    };

    // Beim Speichern im Dialog wird über die API ein neuer Planungseintrag angelegt
    const handleDialogSave = async () => {
        const payload = {
            vehicle_id: clickedVehicle,
            start: clickedTime.toISOString(),
            // Beispielhaft: Defaultdauer 1 Stunde
            end: new Date(clickedTime.getTime() + 60 * 60 * 1000).toISOString(),
            planning_type: activityType,
        };
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URI}/api/vehicles/planning`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: user.api_key,
                    },
                    body: JSON.stringify(payload),
                }
            );
            if (!response.ok) {
                throw new Error('Fehler beim Speichern der Aktivität');
            }
            // Nach erfolgreichem Speichern wird die Planung neu geladen
            fetchVehiclePlanning();
            setDialogOpen(false);
        } catch (err) {
            console.error(err);
            // Hier könnte eine Fehlermeldung angezeigt werden
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Container maxWidth="lg">
                <Box my={4}>
                    <Typography variant="h4" gutterBottom align="center">
                        Fahrzeug-Planungsübersicht
                    </Typography>
                    <Box
                        mb={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <DatePicker
                            label="Tag auswählen"
                            value={selectedDate}
                            onChange={(newValue) => setSelectedDate(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <Box ml={2}>
                            <FormControl variant="outlined" size="small">
                                <InputLabel id="zoom-select-label">Zoom</InputLabel>
                                <Select
                                    labelId="zoom-select-label"
                                    label="Zoom"
                                    value={zoomHours}
                                    onChange={handleZoomChange}
                                >
                                    <MenuItem value={24}>24h</MenuItem>
                                    <MenuItem value={12}>12h</MenuItem>
                                    <MenuItem value={4}>4h</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box display="flex">
                        {/* Linke Spalte: Individuelle Fahrzeugansicht analog zum vehicle_data-Template */}
                        <Box flex="0 0 300px">
                            {uniqueVehicles.map((vehicleId, index) => (
                                <Box key={index} mb={1} height={40}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            p: 1,
                                            border: '1px solid',
                                            borderColor: 'grey.300',
                                            borderRadius: 2,
                                            backgroundColor: 'background.paper',
                                        }}
                                    >
                                        {/* Hier kann das Template erweitert werden (z. B. mit Bild, Kennzeichen etc.) */}
                                        <Typography variant="subtitle2" fontWeight="bold">
                                            {vehicleId}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                        {/* Rechte Spalte: Timeline Chart ohne Standard-Reihenbeschriftung */}
                        <Box flex="1" ref={chartRef} onClick={handleChartClick}>
                            <Chart
                                chartType="Timeline"
                                width="100%"
                                height={`${uniqueVehicles.length * 40 + 40}px`}
                                data={chartData}
                                options={{
                                    timeline: {
                                        showRowLabels: false, // Standard-Reihenbeschriftung ausblenden
                                        groupByRowLabel: true,
                                        barLabelStyle: { fontSize: 12 },
                                    },
                                    hAxis: {
                                        format: 'HH:mm',
                                        minValue: dayStart,
                                        maxValue: dayEnd,
                                        viewWindowMode: 'pretty',
                                        viewWindow: viewWindow,
                                        gridlines: { count: 24 },
                                    },
                                    explorer: {
                                        actions: ['dragToZoom', 'rightClickToReset'],
                                        axis: 'horizontal',
                                        keepInBounds: true,
                                        maxZoomIn: 0.1,
                                    },
                                    // Hinweis: Die native Timeline unterstützt keine doppelte Zeitachsenbeschriftung.
                                    // Daher rendern wir zusätzlich eigene Komponenten.
                                }}
                            />
                            {/* Zusätzliche Zeitachsenbeschriftung oberhalb und unterhalb des Diagramms */}
                            <Box display="flex" justifyContent="space-between" mt={1}>
                                <Typography variant="caption">
                                    {dayjs(dayStart).format('HH:mm')}
                                </Typography>
                                <Typography variant="caption">
                                    {dayjs(dayEnd).format('HH:mm')}
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" mt={1}>
                                <Typography variant="caption">
                                    {dayjs(dayStart).format('HH:mm')}
                                </Typography>
                                <Typography variant="caption">
                                    {dayjs(dayEnd).format('HH:mm')}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {/* Dialog zum Hinzufügen einer Aktivität */}
                <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                    <DialogTitle>Neue Aktivität hinzufügen</DialogTitle>
                    <DialogContent>
                        <Typography variant="body2">
                            Fahrzeug: {clickedVehicle}
                        </Typography>
                        <Typography variant="body2">
                            Startzeit:{' '}
                            {clickedTime ? dayjs(clickedTime).format('HH:mm') : ''}
                        </Typography>
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="activity-select-label">Aktivität</InputLabel>
                            <Select
                                labelId="activity-select-label"
                                label="Aktivität"
                                value={activityType}
                                onChange={(e) => setActivityType(e.target.value)}
                            >
                                <MenuItem value="BREAK">Pause</MenuItem>
                                <MenuItem value="SERVICE">Service</MenuItem>
                                {/* Weitere Aktivitäten können hier ergänzt werden */}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDialogOpen(false)}>Abbrechen</Button>
                        <Button
                            onClick={handleDialogSave}
                            variant="contained"
                            color="primary"
                        >
                            Speichern
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </LocalizationProvider>
    );
}

export default VehiclePlanningPage;