import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { TIME_LABELS_HEIGHT } from './timelineUtils';

// Array von passenderen Farben für Zeitsegmente (Tag/Nacht-Rhythmus visualisieren)
const timeColors = [
    { start: 0, end: 6, color: '#e3f2fd', label: 'Nacht' },   // Dunkelblau für Nachtzeit (0-6 Uhr)
    { start: 6, end: 12, color: '#e8f5e9', label: 'Vormittag' }, // Hellgrün für Morgen (6-12 Uhr)
    { start: 12, end: 18, color: '#fffde7', label: 'Nachmittag' }, // Gelb für Nachmittag (12-18 Uhr)
    { start: 18, end: 24, color: '#fff3e0', label: 'Abend' }  // Orange für Abend (18-24 Uhr)
];

// Stunden im 24-Stunden-Format formatieren
function formatHourLabel(hour) {
    if (hour === 24) return '24:00';
    return `${String(hour).padStart(2, '0')}:00`;
}

const TimelineHeader = ({ totalWidth, hourWidth }) => {
    return (
        <Box
            sx={{
                position: 'sticky',
                top: 0,
                left: 0,
                width: totalWidth,
                height: TIME_LABELS_HEIGHT,
                borderBottom: '1px solid #e0e0e0',
                zIndex: 99,
                display: 'flex',
                backgroundColor: '#fff',
                overflow: 'hidden',
            }}
        >
            {/* Zeitsegmente */}
            {timeColors.map((segment) => (
                <Box
                    key={`segment-${segment.start}`}
                    sx={{
                        position: 'absolute',
                        left: segment.start * hourWidth,
                        width: (segment.end - segment.start) * hourWidth,
                        height: '100%',
                        backgroundColor: segment.color,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderLeft: segment.start > 0 ? '1px solid #e0e0e0' : 'none',
                        borderRight: segment.end < 24 ? '1px solid #e0e0e0' : 'none',
                    }}
                >
                    <Typography
                        variant="caption"
                        sx={{
                            opacity: 0.7,
                            fontWeight: 500,
                            fontSize: '0.7rem',
                            textTransform: 'uppercase',
                            letterSpacing: '0.5px',
                        }}
                    >
                        {segment.label}
                    </Typography>
                </Box>
            ))}

            {/* Stunden-Markierungen */}
            {Array.from({ length: 25 }).map((_, hour) => (
                <Box
                    key={`hour-${hour}`}
                    sx={{
                        position: 'absolute',
                        left: hour * hourWidth,
                        top: 0,
                        height: '100%',
                        width: '1px',
                        backgroundColor: hour % 6 === 0 ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.05)',
                        zIndex: hour % 6 === 0 ? 2 : 1,
                    }}
                />
            ))}

            {/* Stunden-Labels (nur bei Hauptstunden: 0, 6, 12, 18, 24) */}
            {[0, 6, 12, 18, 24].map((hour) => (
                <Typography
                    key={`label-${hour}`}
                    variant="caption"
                    sx={{
                        position: 'absolute',
                        // left: hour * hourWidth - 20,
                        left: hour === 0 ? hour * hourWidth + 4 : hour === 24 ? hour * hourWidth - 44 : hour * hourWidth - 20,
                        width: 40,
                        textAlign: 'center',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        fontWeight: 'bold',
                        fontSize: '0.85rem',
                        zIndex: 3,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        padding: '2px 4px',
                        borderRadius: '4px',
                    }}
                >
                    {formatHourLabel(hour)}
                </Typography>
            ))}

            {/* Kleinere Zeitmarkierungen für jede zweite Stunde */}
            {Array.from({ length: 12 }).map((_, i) => {
                const hour = i * 2;
                if (hour % 6 === 0) return null; // Überspringen, wenn bereits durch Hauptstunden abgedeckt

                return (
                    <Typography
                        key={`minor-${hour}`}
                        variant="caption"
                        sx={{
                            position: 'absolute',
                            left: hour * hourWidth - 16,
                            width: 32,
                            textAlign: 'center',
                            bottom: 4,
                            fontSize: '0.7rem',
                            color: 'rgba(0, 0, 0, 0.6)',
                        }}
                    >
                        {formatHourLabel(hour)}
                    </Typography>
                );
            })}
        </Box>
    );
};

TimelineHeader.propTypes = {
    totalWidth: PropTypes.number.isRequired,
    hourWidth: PropTypes.number.isRequired,
};

export default TimelineHeader;