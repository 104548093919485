import React, { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Route, Routes, useNavigate, useLocation, BrowserRouter as Router } from 'react-router-dom';
import ThemeContextProvider from './theme/ThemeContext';
import Dashboard from './components/dashboard';
import RegistrationPage from './pages/registration';
import ConfirmationPage from './pages/confirmationRegistration';
import Login from './pages/login';
import { UserProvider } from './components/userContext';
import { ConfigProvider } from './components/configContext';
import { PrivateRoute } from './components/privateroute';
import ErrorPage from './pages/error-page';
import UsersList from './pages/usersPageAdmin';
import MainLayout from './MainLayout';
import EditUserPage from './pages/editUserPage';
import InstitutionPage from './pages/institutionPage';
import ChangePasswordPage from './pages/changePasswordPage';
import TransportFormPage from './pages/transportFormPage';
import TransportList from './pages/transportList';
import HeadOfSeriesList from './pages/transportListForSeries';
import Impressum from './pages/impressum';
import Datenschutz from './pages/datenschutz';
import InstitutionsList from './pages/institutionsList';
import PasswordResetPage from './pages/resetPasswordPage';
import LogsPage from './pages/logsPage';
import TimeSlotManagement from './pages/timeSlotManagement';
import InfektionenList from './pages/infektionenList';
import ConfigTabs from './pages/configTabs';
import ReleaseNotes from './pages/releaseNotes';
import ErrorBoundary from './components/errorBoundary';
import ErrorComponent from './pages/ErrorComponent';
import StammdatenPage from './pages/admins/stammdatenPage';
import StationPage from './pages/admins/stationPage';
import VehiclePage from './pages/admins/vehiclesPage';
import ShiftPlanPage from './pages/admins/shiftPlanPage';
import Flottenverwaltung from './pages/admins/FlottenverwaltungList';
import ImportExportPage from './pages/admins/ImportExportPage';
import SetupPage from './pages/admins/SetupPage';
import PlanningPage from './pages/planningDetails';
import VehiclePlanningPage from './pages/planning/VehiclePlanningPage';
import VehicleTimeline from './pages/planning/VehicleTimelinePage';
import MailTemplatesPage from './pages/MailTemplatesPage';

const RedirectToLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/login');
    }
  }, [location, navigate]);

  return null;
};

// Main App component
function App() {

  return (

    <UserProvider>
      <ThemeContextProvider>
        <CssBaseline />
        <ConfigProvider>
          <Router>
            <Routes>
              <Route path="/" element={<RedirectToLogin />} />
              <Route path="/registration" element={<MainLayout><RegistrationPage /></MainLayout>} />
              <Route path="/confirmation" element={<MainLayout><ConfirmationPage /></MainLayout>} />
              <Route path="/reset-password" element={<MainLayout><PasswordResetPage /></MainLayout>} />
              <Route path="/login" element={<Login />} />
              <Route path="/dashboard" element={
                <PrivateRoute>
                  <MainLayout>
                    <Dashboard />
                  </MainLayout>
                </PrivateRoute>
              } errorElement={<ErrorPage />} />
              <Route path="/userspage" element={<ErrorBoundary><PrivateRoute adminOnly><MainLayout><UsersList /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/transportlist" element={<ErrorBoundary><PrivateRoute><MainLayout><TransportList /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/transport" element={<ErrorBoundary><PrivateRoute><MainLayout><TransportFormPage /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/transportseries" element={<ErrorBoundary><PrivateRoute><MainLayout><HeadOfSeriesList /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/transport/:id/:copy?" element={<ErrorBoundary><PrivateRoute><MainLayout><TransportFormPage /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/institutions" element={<ErrorBoundary><PrivateRoute adminOnly><MainLayout><InstitutionsList /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/institutionpage" element={<ErrorBoundary><PrivateRoute adminOnly><MainLayout><InstitutionPage /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/institutionpage/:institution_id" element={<ErrorBoundary><PrivateRoute adminOnly><MainLayout><InstitutionPage /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/logs" element={<ErrorBoundary><PrivateRoute adminOnly><MainLayout><LogsPage /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/timeSlotManagement" element={<ErrorBoundary><PrivateRoute adminOnly><MainLayout><TimeSlotManagement /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/infections" element={<ErrorBoundary><PrivateRoute adminOnly><MainLayout><InfektionenList /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/params" element={<ErrorBoundary><PrivateRoute adminOnly><MainLayout><ConfigTabs /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/edituser" element={<ErrorBoundary><PrivateRoute adminOnly><MainLayout><EditUserPage /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/edituser/:username" element={<ErrorBoundary><PrivateRoute adminOnly><MainLayout><EditUserPage /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/changepassword" element={<ErrorBoundary><PrivateRoute><MainLayout><ChangePasswordPage /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/impressum" element={<ErrorBoundary><PrivateRoute><MainLayout><Impressum /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/datenschutz" element={<ErrorBoundary><PrivateRoute><MainLayout><Datenschutz /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/releasenotes" element={<ErrorBoundary><PrivateRoute><MainLayout><ReleaseNotes /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/test-error" element={<ErrorBoundary><PrivateRoute><MainLayout><ErrorComponent /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/stammdaten" element={<ErrorBoundary><PrivateRoute adminOnly><MainLayout><StammdatenPage /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/stationpage" element={<ErrorBoundary><PrivateRoute adminOnly><MainLayout><StationPage /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/stationpage/:station_id" element={<ErrorBoundary><PrivateRoute adminOnly><MainLayout><StationPage /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/vehiclepage" element={<ErrorBoundary><PrivateRoute adminOnly><MainLayout><VehiclePage /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/vehiclepage/:vehicle_id" element={<ErrorBoundary><PrivateRoute adminOnly><MainLayout><VehiclePage /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/shiftplanpage" element={<ErrorBoundary><PrivateRoute adminOnly><MainLayout><ShiftPlanPage /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/shiftplanpage/:id" element={<ErrorBoundary><PrivateRoute adminOnly><MainLayout><ShiftPlanPage /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/flottenverwaltung" element={<ErrorBoundary><PrivateRoute adminOnly><MainLayout><Flottenverwaltung /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/importexport" element={<ErrorBoundary><PrivateRoute adminOnly><MainLayout><ImportExportPage /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/setuppage" element={<ErrorBoundary><PrivateRoute adminOnly><MainLayout><SetupPage /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/planning/:planning_id" element={<ErrorBoundary><PrivateRoute adminOnly><MainLayout><PlanningPage /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/planning/transport/:transport_id" element={<ErrorBoundary><PrivateRoute adminOnly><MainLayout><PlanningPage /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/gantt" element={<ErrorBoundary><PrivateRoute adminOnly><MainLayout><VehiclePlanningPage /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/timeline" element={<ErrorBoundary><PrivateRoute adminOnly><MainLayout><VehicleTimeline /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />
              <Route path="/mail-templates" element={<ErrorBoundary><PrivateRoute adminOnly><MainLayout><MailTemplatesPage /></MainLayout></PrivateRoute></ErrorBoundary>} errorElement={<ErrorPage />} />

            </Routes>
          </Router>
        </ConfigProvider>
      </ThemeContextProvider>
    </UserProvider>
  );
}


export default App;

