// Modified TransportPopover.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Popover, Box, Typography, Divider, IconButton, Paper } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import DateTimeHelper from 'ktw-shared';
import BreakPopover from './BreakPopover';

// Configure dayjs with timezone plugin
dayjs.extend(utc);
dayjs.extend(timezone);

// Helper function to format datetime to Berlin timezone
const formatBerlinTime = (dateTime) => {
    if (!dateTime) return '-';
    return dayjs(dateTime).tz('Europe/Berlin').format('DD.MM.YYYY HH:mm');
};

// Helper function to format time only to Berlin timezone
const formatBerlinTimeOnly = (dateTime) => {
    if (!dateTime) return '-';
    return dayjs(dateTime).tz('Europe/Berlin').format('HH:mm');
};

// Helper function to get address
const getAddress = (institution, street, postalCode, city) => {
    if (institution) return institution;

    const addressParts = [];
    if (street) addressParts.push(street);
    if (postalCode || city) {
        const location = [postalCode, city].filter(Boolean).join(' ');
        if (location) addressParts.push(location);
    }

    return addressParts.join(', ') || '-';
};

function TransportPopover({ anchorEl, content, onClose }) {
    if (!content) return null;

    // Check if this is a break transport type
    if (content.transport_type === 'BREAK') {
        return <BreakPopover anchorEl={anchorEl} content={content} onClose={onClose} />;
    }

    // Find specific transport segments
    const toStartSegment = content.transport_segments?.find(segment => segment.planning_type === 'TO_START');
    const loadingSegment = content.transport_segments?.find(segment => segment.planning_type === 'LOADING');
    const mainSegment = content.transport_segments?.find(segment => segment.planning_type === 'MAIN');
    const toGarageSegment = content.transport_segments?.find(segment => segment.planning_type === 'TO_GARAGE');

    // Get from and to addresses
    const fromAddress = getAddress(
        content.from_institution,
        content.from_street,
        content.from_postal_code,
        content.from_city
    );

    const toAddress = getAddress(
        content.to_institution,
        content.to_street,
        content.to_postal_code,
        content.to_city
    );

    const departureDateTime = DateTimeHelper.combineDateAndTime(
        content.departure_date,
        content.departure_time
    );

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            disableRestoreFocus
        // Removed pointerEvents: 'none' to allow interaction with the popover
        >
            <Paper
                elevation={3}
                sx={{
                    maxWidth: 350,
                    bgcolor: '#333',
                    color: 'white',
                    borderRadius: 1,
                    overflow: 'hidden'
                }}
            >
                {/* Header with title and close button */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 1,
                        pl: 2,
                        bgcolor: 'rgba(255,255,255,0.1)'
                    }}
                >
                    <Typography variant="subtitle1" fontWeight="bold">
                        Fahrzeug: {content.vehicle_name || content.vehicle_id}
                    </Typography>
                    <IconButton
                        size="small"
                        onClick={onClose}
                        sx={{
                            color: 'white',
                            '&:hover': {
                                bgcolor: 'rgba(255,255,255,0.2)'
                            }
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Box>

                <Box sx={{ p: 2 }}>
                    <Typography variant="body2">
                        {content.transport_type}: {content.first_name} {content.last_name}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                        {formatBerlinTime(departureDateTime)}
                    </Typography>

                    <Divider sx={{ borderColor: 'rgba(255,255,255,0.1)', my: 1 }} />

                    <Typography variant="body2" sx={{ display: 'flex' }}>
                        <Box component="span" sx={{ minWidth: 140 }}>Von:</Box>
                        <Box component="span" sx={{ flex: 1 }}>{fromAddress}</Box>
                    </Typography>
                    <Typography variant="body2" sx={{ display: 'flex', mb: 1 }}>
                        <Box component="span" sx={{ minWidth: 140 }}>Nach:</Box>
                        <Box component="span" sx={{ flex: 1 }}>{toAddress}</Box>
                    </Typography>

                    <Divider sx={{ borderColor: 'rgba(255,255,255,0.1)', my: 1 }} />

                    {toStartSegment && (
                        <Typography variant="body2" sx={{ display: 'flex' }}>
                            <Box component="span" sx={{ minWidth: 140 }}>Abfahrt:</Box>
                            <Box component="span" sx={{ flex: 1 }}>{formatBerlinTimeOnly(toStartSegment.planned_start)}</Box>
                        </Typography>
                    )}

                    {loadingSegment && (
                        <Typography variant="body2" sx={{ display: 'flex' }}>
                            <Box component="span" sx={{ minWidth: 140 }}>Beladung:</Box>
                            <Box component="span" sx={{ flex: 1 }}>{formatBerlinTimeOnly(loadingSegment.planned_start)}</Box>
                        </Typography>
                    )}

                    {mainSegment && (
                        <Typography variant="body2" sx={{ display: 'flex' }}>
                            <Box component="span" sx={{ minWidth: 140 }}>Ankunft Zielort:</Box>
                            <Box component="span" sx={{ flex: 1 }}>{formatBerlinTimeOnly(mainSegment.planned_end)}</Box>
                        </Typography>
                    )}

                    {toGarageSegment && (
                        <Typography variant="body2" sx={{ display: 'flex' }}>
                            <Box component="span" sx={{ minWidth: 140 }}>Rückfahrt Wache:</Box>
                            <Box component="span" sx={{ flex: 1 }}>{formatBerlinTimeOnly(toGarageSegment.planned_start)}</Box>
                        </Typography>
                    )}

                    <Divider sx={{ borderColor: 'rgba(255,255,255,0.1)', my: 1 }} />

                    <Typography variant="body2" sx={{ display: 'flex' }}>
                        <Box component="span" sx={{ minWidth: 140 }}>Frühester Start:</Box>
                        <Box component="span" sx={{ flex: 1 }}>{formatBerlinTimeOnly(content.earliest_start)}</Box>
                    </Typography>
                    <Typography variant="body2" sx={{ display: 'flex' }}>
                        <Box component="span" sx={{ minWidth: 140 }}>Spätester Start:</Box>
                        <Box component="span" sx={{ flex: 1 }}>{formatBerlinTimeOnly(content.latest_start)}</Box>
                    </Typography>

                    <Divider sx={{ borderColor: 'rgba(255,255,255,0.1)', my: 1 }} />

                    <Typography variant="caption" sx={{ display: 'flex', fontStyle: 'italic', color: 'gray' }}>
                        <Box component="span" sx={{ minWidth: 140 }}>Transport-Id:</Box>
                        <Box component="span" sx={{ flex: 1 }}>{content.transport_id}</Box>
                    </Typography>
                </Box>
            </Paper>
        </Popover>
    );
}

TransportPopover.propTypes = {
    anchorEl: PropTypes.object,
    content: PropTypes.object,
    onClose: PropTypes.func.isRequired,
};

export default TransportPopover;