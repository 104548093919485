import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useUser } from './userContext';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { DrkCard, DrkInfoBox } from './DrkComponents'; // Import DRK-Komponenten

const ContentAdmin = () => {

    const { user } = useUser();
    const [systemInfo, setSystemInfo] = useState({});

    useEffect(() => {
        const fetchSystemInfo = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URI + '/api/system-info', {
                    headers: {
                        'Authorization': user.api_key,
                    },
                });
                if (!response.ok) {
                    throw new Error('Fehler beim Abrufen der Systeminformationen');
                }
                const data = await response.json();
                setSystemInfo(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchSystemInfo();
        // eslint-disable-next-line
    }, []); // Run only once when component mounts

    const backgroundImageUrl = `${process.env.REACT_APP_BASE_URL}/img_it.jpg`;


    return (
        <Box>
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: '300px',
                    backgroundImage: `url(${backgroundImageUrl})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    marginBottom: '20px',
                    borderRadius: '16px', // Anpassung an Theme-Standards
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                }}
            />
            <Grid container spacing={4} columns={{ xs: 4, md: 12 }}>
                <Grid item xs={6}>
                    {/* Ersetzen von Paper mit DrkCard für konsistentes Theme-basiertes Styling */}
                    <DrkCard
                        title="Willkommen bei KTW-Anmeldung.de!"
                        elevation={3}
                        sx={{ minHeight: '400px', maxWidth: '100%' }}
                    >
                        <Typography variant="body1" align="center" sx={{ marginTop: 2, fontStyle: 'italic' }}>
                            Sie sind als <strong>Administrator</strong> des Systems angemeldet.<br /> Hier einige wichtige Informationen für Sie:
                        </Typography>
                        <Typography variant="body1" align="center" sx={{ marginTop: 2 }}>
                            Als Administrator stehen Ihnen folgende Funktionen zur Verfügung:
                        </Typography>
                        <br />
                        <DrkInfoBox sx={{ textAlign: 'left' }}>
                            <Box component="ul" sx={{ paddingInlineStart: '20px', margin: '0', listStyleType: 'disc' }}>
                                <Typography variant="body1">
                                    <li>
                                        <RouterLink
                                            to="/userspage"
                                        >
                                            <strong>Benutzerverwaltung:</strong>
                                        </RouterLink> Hier können Sie Benutzer hinzufügen, bearbeiten und löschen. Sie haben außerdem die Möglichkeit, Benutzerrollen und -berechtigungen festzulegen.
                                    </li>
                                    <br />
                                    <li><strong>Systemkonfiguration:</strong> Passen Sie Systemeinstellungen an, integrieren Sie verschiedene Elemente und nehmen Sie andere backend-bezogene Konfigurationen vor.</li>
                                    <br />
                                    <li>
                                        <RouterLink
                                            to="/transportlist"
                                        >
                                            <strong>Bestellübersicht:</strong>
                                        </RouterLink> Sie haben eine vollständige Ansicht über alle Bestellungen, unabhängig von ihrem aktuellen Status.
                                    </li>
                                    <br />
                                    <li>
                                        <RouterLink
                                            to="/releasenotes"
                                        >
                                            <strong>Release-Notes:</strong>
                                        </RouterLink> Was gibt's Neues? Schauen Sie in die Release-Notes...
                                    </li>
                                </Typography>
                            </Box>
                        </DrkInfoBox>
                    </DrkCard>
                </Grid>
                <Grid item xs={6}>
                    {/* Ersetzen von Paper mit DrkCard für konsistentes Theme-basiertes Styling */}
                    <DrkCard
                        title="Systeminformationen"
                        elevation={3}
                        sx={{ minHeight: '400px', maxWidth: '100%' }}
                    >
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <Tooltip title="Akzeptierte, also geplante Transporte, die demnächst durchgeführt werden.">
                                                <Typography variant="body1">Akzeptierte Transporte (aktiv):</Typography>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">{systemInfo.activeTransports}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="body1">
                                                <Tooltip title="Einige neue Transporte (Bestellungen) haben den Status 'pending'. Bitte neue Transporte freigeben oder ablehnen.">
                                                    <RouterLink
                                                        to={{
                                                            pathname: "/transportlist",
                                                            search: "?filterStatus=pending"
                                                        }}
                                                        style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
                                                    >
                                                        <strong>Transporte zur Bestätigung:</strong>
                                                        {systemInfo.pendingTransports > 0 && (
                                                            <span style={{ marginTop: '2px', marginLeft: '8px', color: 'red' }}>
                                                                <WarningAmberIcon fontSize="small" />
                                                            </span>
                                                        )}
                                                    </RouterLink>
                                                </Tooltip>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">{systemInfo.pendingTransports}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Tooltip title="Transporte (Bestellungen), die heute mit KTW-Anmeldung angelegt wurden.">
                                                <Typography variant="body1">Heute angelegte Transporte:</Typography>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">{systemInfo.todayCreatedTransports}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Tooltip title="Anzahl aktiver Benutzer, die bei KTW-Anmeldung registriert sind.">
                                                <Typography variant="body1">Aktive Benutzer:</Typography>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">{systemInfo.activeUsers}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="body1">
                                                <Tooltip title="Einige Benutzer haben sich neu registriert. Bitte neue Benutzer freigeben, löschen oder sperren.">
                                                    <RouterLink
                                                        to="/userspage"
                                                        style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
                                                    >
                                                        <strong>Offene Benutzer-Registrierungen:</strong>
                                                        {systemInfo.pendingUsers > 0 && (
                                                            <span style={{ marginTop: '2px', marginLeft: '8px', color: 'red' }}>
                                                                <WarningAmberIcon fontSize="small" />
                                                            </span>
                                                        )}
                                                    </RouterLink>
                                                </Tooltip>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">{systemInfo.pendingUsers}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DrkCard>
                </Grid>
            </Grid>
        </Box>
    );
}

export default ContentAdmin;